import {
  REMOVE_MEMBER_FROM_GROUP_REQUEST,
  REMOVE_MEMBER_FROM_GROUP_REQUEST_SUCCESS,
} from './types';

/** Action - remove membel from group.
 * @param {string} removeMemberModel - The remove member from group model
 * @return {Object} Return object
 */

export function removeMember(removeMemberModel) {
  return {
    type: REMOVE_MEMBER_FROM_GROUP_REQUEST,
    payload: {
      removeMemberModel,
    },
  };
}

/** On successful remove member from group
 * @param {string} isSuccessful - if remove member from group went alright
 * @return {any} Return object
 */
export function removeMemberSuccess(isSuccessful) {
  return {
    type: REMOVE_MEMBER_FROM_GROUP_REQUEST_SUCCESS,
    payload: {
      isSuccessful,
    },
  };
}
