import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { retrieveExternalProvider } from '../../../../../../../../../cms/Utils';
import managementSkillsImage from '../../../../../../../../../common/images/management-skills-updated.svg';
import softSkillsImage from '../../../../../../../../../common/images/soft-skills-updated.svg';
import techSkillsImage from '../../../../../../../../../common/images/tech-skills-updated.svg';
import otherImage from '../../../../../../../../../common/images/other-skills-updated.svg';
import tagGreenImage from '../../../../../../../../../common/images/tag-green.svg';
import tagOrangeImage from '../../../../../../../../../common/images/tag-orange.svg';
import pluralsightImage from '../../../../../../../../../common/images/logo-small-pluralsight.png';
import udemyImage from '../../../../../../../../../common/images/logo-small-udemy.png';
import {
  GROUP_MANAGEMENT_LABELS,
  EXTERNAL_PROVIDERS,
  BUTTON_LABELS,
} from '../../../../../../../../../cms';
import { removeTraining } from '../../RemoveTrainingFromGroup/actions';
import './GroupTrainingCard.scss';
import {
  BaseModal,
  ConfirmAction,
} from '../../../../../../../../../common/components';

export default function GroupTrainingCard({ groupTraining, groupId }) {
  const dispatch = useDispatch();
  const [externalProvider, setExternalProvider] = useState('');
  const [isConfirmDeleteModalOpened, setIsConfirmDeleteModalOpened] =
    useState(false);
  const [trainingToRemove, setTrainingToRemove] = useState('');

  const closeDeleteConfirmationModal = () => {
    setIsConfirmDeleteModalOpened(false);
    setTrainingToRemove(undefined);
  };

  const openDeleteConfirmationModal = (trainingToRemove) => {
    setIsConfirmDeleteModalOpened(true);
    setTrainingToRemove(trainingToRemove);
  };

  const removeTrainingFromGroup = () => {
    const model = {
      trainingId: trainingToRemove,
      groupExternalId: groupId,
    };

    dispatch(removeTraining(model));
    closeDeleteConfirmationModal();
  };

  useEffect(() => {
    setExternalProvider(retrieveExternalProvider(groupTraining.url));
  }, [groupTraining.url]);

  return (
    <>
      <div className="mt-4 mr-3 pb-1 d-flex justify-content-center">
        <div className="group-training-container">
          <button
            onClick={() => openDeleteConfirmationModal(groupTraining.id)}
            type="button"
            className="close remove-training"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="group-training-img-container mb-2">
            {externalProvider ? (
              <img
                src={
                  externalProvider === EXTERNAL_PROVIDERS.PLURALSIGHT
                    ? pluralsightImage
                    : udemyImage
                }
                width={
                  externalProvider === EXTERNAL_PROVIDERS.PLURALSIGHT ? 55 : 80
                }
                height={
                  externalProvider === EXTERNAL_PROVIDERS.PLURALSIGHT ? 60 : 50
                }
                alt={
                  externalProvider === EXTERNAL_PROVIDERS.PLURALSIGHT
                    ? EXTERNAL_PROVIDERS.PLURALSIGHT
                    : EXTERNAL_PROVIDERS.UDEMY
                }
                className={`mt-2 pt-3 ${
                  externalProvider === EXTERNAL_PROVIDERS.PLURALSIGHT
                    ? 'ml-2 pl-2'
                    : 'pb-1 udemy-image'
                }`}
              />
            ) : (
              {
                ManagementSkills: (
                  <img
                    src={managementSkillsImage}
                    alt="ManagementSkills"
                    width={60}
                    height={60}
                    className="mt-2 ml-2 pl-1 mt-3"
                  />
                ),
                SoftSkills: (
                  <img
                    src={softSkillsImage}
                    alt="SoftSkills"
                    width={60}
                    height={60}
                    className="mt-2 ml-3 mt-3"
                  />
                ),
                TechnicalSkills: (
                  <img
                    src={techSkillsImage}
                    alt="TechnicalSkills"
                    width={69}
                    height={52}
                    className="mt-2 ml-1 mt-3 mb-2"
                  />
                ),
                Other: (
                  <img
                    src={otherImage}
                    alt="Other"
                    width={56}
                    className="mt-4 ml-3 mb-2"
                  />
                ),
              }[groupTraining.type]
            )}
          </div>
          <div
            title={groupTraining.title}
            className="text-center text-truncate group-training-display-title mt-1 "
          >
            {groupTraining.title}
          </div>
          <div className="text-center cost-training">
            <img
              alt=""
              height={50}
              src={groupTraining > 0 ? tagOrangeImage : tagGreenImage}
            />
          </div>
        </div>
      </div>
      <BaseModal
        open={isConfirmDeleteModalOpened}
        onClose={closeDeleteConfirmationModal}
        showCloseBtnOutside
      >
        <ConfirmAction
          text={GROUP_MANAGEMENT_LABELS.REMOVE_TRAINING}
          btnText={BUTTON_LABELS.DELETE_BUTTON}
          onConfirm={removeTrainingFromGroup}
          onClose={closeDeleteConfirmationModal}
        />
      </BaseModal>
    </>
  );
}
