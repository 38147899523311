import { useState, useEffect } from 'react';
import { Button, Divider } from '@mui/material';
import './AddMembersToGroup.scss';
import SearchEmployee from '../../../../../../../../components/SearchEmployee/SearchEmployee';
import {
  BUTTON_LABELS,
  NOTIFICATION_LABELS,
  MODAL_VALUES,
  GROUP_MANAGEMENT_LABELS,
} from '../../../../../../../../cms';
import { addMembers } from './actions';
import { getAllGroups } from '../../../actions';
import { sendNotificationsForAssignedTrainings } from '../../../SendNotificationsForAssignedTrainings/actions';
import {
  BaseModal,
  ErrorMessage,
  SuccessMessage,
} from '../../../../../../../../common/components';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../rtk/hooks';

export default function AddMembersToGroup({ onClose, group }) {
  const dispatch = useAppDispatch();
  const { addMembersSuccess } = useAppSelector((state) => state.groups);

  const [isErrorModalOpened, setIsErrorModalOpened] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState(group.members);
  const [initialMembers, setInitialMembers] = useState(group.members);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSuccessClose = () => {
    setShowSuccess(false);
    onClose();
    dispatch(getAllGroups());
  };

  const handleErrorClose = () => {
    setIsErrorModalOpened(false);
  };

  const updateSelectedMembersHandler = (employees) =>
    setSelectedMembers(employees);

  const submitAddMembersToGroup = () => {
    let membersExternalIds = selectedMembers.map((e) => e.externalId);
    if (membersExternalIds.length > 0) {
      const model = {
        groupId: group.id,
        memberIds: membersExternalIds,
      };

      const lastAddMembersToGroupModel = {
        id: group.id,
        title: group.title,
        description: group.description,
        members: selectedMembers,
        assignedTrainings: group.trainings,
      };

      dispatch(addMembers(model, lastAddMembersToGroupModel));
    } else {
      setIsErrorModalOpened(true);
      setTimeout(() => {
        handleErrorClose();
      }, MODAL_VALUES.MODAL_CLOSE_TEN_SECONDS_TIMEOUT);
    }
  };

  useEffect(() => {
    if (addMembersSuccess) {
      setShowSuccess(true);
      setTimeout(() => {
        handleSuccessClose();
      }, MODAL_VALUES.MODAL_CLOSE_TIMEOUT);

      // Get only the new members
      const finalMembersToSendNotifications = selectedMembers.filter(
        ({ externalId: externalId1 }) =>
          !initialMembers.some(
            ({ externalId: externalId2 }) => externalId1 === externalId2,
          ),
      );

      const notificationsModel = {
        groupId: group.id,
        memberIds: finalMembersToSendNotifications.map((m) => m.externalId),
        trainingIds: [],
      };

      dispatch(sendNotificationsForAssignedTrainings(notificationsModel));
    }
  }, [addMembersSuccess]);

  return (
    <div className="add-members-to-group">
      <h6 className="add-members-to-group-header">Add employees</h6>
      <Divider flexItem />
      <div className="add-members-to-group-content">
        <SearchEmployee
          updateSelectedEmployeesHandler={updateSelectedMembersHandler}
          searchBoxInputId="search-employee-box-input"
          focus={false}
          formErrors={{}}
          selectedEmployees={selectedMembers}
        />
      </div>
      <Divider flexItem />
      <div className="add-members-to-group-btns">
        <Button variant="outlined" onClick={onClose}>
          {BUTTON_LABELS.CANCEL}
        </Button>
        <Button
          variant="contained"
          className="add-members-to-group-save-changes-btn"
          onClick={submitAddMembersToGroup}
        >
          {BUTTON_LABELS.SAVE_CHANGES}
        </Button>
      </div>
      <BaseModal hideCloseBtn open={showSuccess} onClose={handleSuccessClose}>
        <SuccessMessage text={NOTIFICATION_LABELS.ADD_MEMBERS_TO_GROUP} />
      </BaseModal>
      <BaseModal
        hideCloseBtn
        open={isErrorModalOpened}
        onClose={handleErrorClose}
      >
        <ErrorMessage text={GROUP_MANAGEMENT_LABELS.REMOVE_MEMBER_FORBIDDEN} />
      </BaseModal>
    </div>
  );
}
