import React, { useState } from 'react';
import { Button } from '@mui/material';
import './ContactForm.scss';
import contactFormImageMobile from '../../../common/images/contact-form-image-mobile.png';
import { validateEmailAddress } from '../../../common/input_helpers';
import ContactFormSuccess from './ContactFormSuccess/ContactFormSuccess';
import useMediaQuery from '../../../common/hooks/useMediaQuery';
import { CONTACT_FORM_LABELS, SCREEN_SIZES } from '../../../cms';
import { addSmallStyle } from '../../../cms/Utils';
import { CustomTextField } from '../../../common/components';
import { useDataLayerEvent } from '../../../common/hooks/useDataLayerEvent';
import { DataLayerEvents } from '../../../cms/enums';
import { useContactUsMutation } from '../../../rtk/api/contactApi';
import { ContactUsModel } from '../../../cms/types';

type ContactFormType = 'Contact Us' | 'Feedback Form';

interface ContactFormProps {
  title: ContactFormType;
}

interface ContactFormDataPair {
  value: string;
  hasError: boolean;
}
interface ContactFormData {
  firstName: ContactFormDataPair;
  lastName: ContactFormDataPair;
  email: ContactFormDataPair;
  subject: ContactFormDataPair;
  message: ContactFormDataPair;
}

const ContactForm = ({ title }: ContactFormProps) => {
  const sendDataLayerEvent = useDataLayerEvent();
  const [contactUs, { isLoading, isSuccess, isError }] = useContactUsMutation();
  const isScreenSmall = useMediaQuery(SCREEN_SIZES.SMALL);

  const initialPairData: ContactFormDataPair = {
    value: '',
    hasError: false,
  };

  const [formData, setFormData] = useState<ContactFormData>({
    firstName: initialPairData,
    lastName: initialPairData,
    email: initialPairData,
    subject: initialPairData,
    message: initialPairData,
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const input = e.target;
    const { name } = input;
    const { value } = input;

    setFormData((prev) => ({
      ...prev,
      [name]: {
        value,
        hasError: !validateInput(name, value),
      },
    }));
  };

  const validateInput = (name: string, value: string): boolean => {
    let inputValidity = false;

    if (value) {
      inputValidity = true;

      if (name === 'email') {
        inputValidity = validateEmailAddress(value);
      }
    }

    return inputValidity;
  };

  const checkFormValidity = (): boolean => {
    let formValidity = true;

    for (const [key, value] of Object.entries(formData)) {
      const inputValue = value.value;

      const isInputValid = validateInput(key, inputValue);

      setFormData((prev) => ({
        ...prev,
        [key]: {
          ...prev[key as keyof ContactFormData],
          hasError: !isInputValid,
        },
      }));

      if (!isInputValid) {
        formValidity = false;
      }
    }

    return formValidity;
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (checkFormValidity()) {
      const model: ContactUsModel = {
        name: `${formData.firstName.value} ${formData.lastName.value}`,
        senderEmail: formData.email.value,
        subject: formData.subject.value,
        content: formData.message.value,
      };

      contactUs(model)
        .unwrap()
        .then(() => {
          if (title === 'Contact Us') {
            sendDataLayerEvent({
              event: DataLayerEvents.SUBMIT_CONTACT_FORM,
            });
          }
        })
        .catch(() => {});
    }
  };

  const errorMessage = (
    <p className="contact-form-error-message">
      {isError && CONTACT_FORM_LABELS.SUBMIT_ERROR}
    </p>
  );

  return (
    <div className={`contact-form-container${addSmallStyle(isScreenSmall)}`}>
      <img
        src={contactFormImageMobile}
        alt="Contact form"
        className={`contact-form-image${addSmallStyle(isScreenSmall)}`}
      />
      {isSuccess ? (
        <ContactFormSuccess />
      ) : (
        <form
          className={`contact-form${addSmallStyle(isScreenSmall)}`}
          onSubmit={onSubmit}
          noValidate
        >
          <h2
            className={`${
              title === 'Feedback Form'
                ? 'feedback-form-title'
                : 'contact-form-title'
            }`}
          >
            {title}
          </h2>
          <fieldset className="contact-form-full-name">
            <CustomTextField
              label="First Name"
              name="firstName"
              onChange={handleInputChange}
              value={formData.firstName.value}
              required
              error={formData.firstName.hasError}
              className="contact-form-field-container"
            />
            <CustomTextField
              label="Last Name"
              name="lastName"
              onChange={handleInputChange}
              value={formData.lastName.value}
              required
              error={formData.lastName.hasError}
              className="contact-form-field-container"
            />
          </fieldset>
          <CustomTextField
            error={formData.email.hasError}
            label="Email"
            name="email"
            onChange={handleInputChange}
            value={formData.email.value}
            required
          />
          <CustomTextField
            error={formData.subject.hasError}
            label="Subject"
            name="subject"
            onChange={handleInputChange}
            value={formData.subject.value}
            required
          />
          <CustomTextField
            label="Message"
            name="message"
            onChange={handleInputChange}
            value={formData.message.value}
            error={formData.message.hasError}
            required
            multiline
            rows={6}
          />

          {errorMessage}

          <Button
            className="contact-form-send-btn"
            variant="contained"
            type="submit"
            disabled={isLoading}
          >
            Send
          </Button>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
