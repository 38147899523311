import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash === '')
      document
        .getElementById('scrollableDiv')
        ?.scroll({ behavior: 'smooth', top: 0, left: 0 });
    else {
      const elementId = hash.replace('#', '');
      document.getElementById(elementId)?.scrollIntoView();
    }
  }, [pathname, hash]);

  return null;
};

export default ScrollToTop;
