import { PersonalApplication } from '../../../../rtk/api/applicationApi';
import {
  ApplicationStatuses,
  ModalTypes,
  MyTrainingsAndEventsTypes,
} from '../../../../cms/enums';
import { useApplyForTrainingMutation } from '../../../../rtk/api/trainingApi';
import {
  useCompleteTrainingApplicationMutation,
  useDropTrainingApplicationMutation,
  useStartTrainingApplicationMutation,
} from '../../../../rtk/api/trainingApplicationApi';
import { useApplyForEventMutation } from '../../../../rtk/api/eventApi';
import {
  useCompleteEventApplicationMutation,
  useDropEventApplicationMutation,
  useStartEventApplicationMutation,
} from '../../../../rtk/api/eventApplicationApi';
import {
  BUTTON_LABELS,
  FAILED_REQUEST_MESSAGE,
  MODAL_CLOSE_BTNS,
  MODAL_VALUES,
  SUCCESSUL_REQUEST_MESSAGES,
} from '../../../../cms';
import { useAppDispatch } from '../../../../rtk/hooks';
import { openModal } from '../../../../rtk/features/modalsSlice';
import { MenuOptions } from '../../../../cms/types';
import DotMenu from '../../../../common/components/DotMenu/DotMenu';

type ChangeApplicationStatusMenuProps = {
  application: PersonalApplication;
};

const ChangeApplicationStatusMenu = ({
  application,
}: ChangeApplicationStatusMenuProps) => {
  const {
    id,
    status,
    type,
    entryId,
    title,
    isEntityArchived,
    isEntityExpired,
  } = application;
  const dispatch = useAppDispatch();
  const [applyForTraining] = useApplyForTrainingMutation();
  const [applyForEvent] = useApplyForEventMutation();
  const [startTrainingApplication] = useStartTrainingApplicationMutation();
  const [startEventApplication] = useStartEventApplicationMutation();
  const [completeTrainingApplication] =
    useCompleteTrainingApplicationMutation();
  const [completeEventApplication] = useCompleteEventApplicationMutation();
  const [dropTrainingApplication] = useDropTrainingApplicationMutation();
  const [dropEventApplication] = useDropEventApplicationMutation();

  const shouldHideMenu =
    status === ApplicationStatuses.COMPLETED ||
    ((isEntityArchived || isEntityExpired) &&
      (status === null ||
        [
          ApplicationStatuses.COMPLETED,
          ApplicationStatuses.DROPPED,
          ApplicationStatuses.REJECTED,
        ].includes(status)));

  const showApply =
    status === null ||
    [ApplicationStatuses.DROPPED, ApplicationStatuses.REJECTED].includes(
      status,
    );

  const showDrop =
    status !== null &&
    [
      ApplicationStatuses.PENDING,
      ApplicationStatuses.APPROVED,
      ApplicationStatuses.IN_PROGRESS,
    ].includes(status);

  const showStart = status === ApplicationStatuses.APPROVED;
  const showComplete = status === ApplicationStatuses.IN_PROGRESS;

  const handleOnRequestSuccess = (message: string) => {
    dispatch(
      openModal({
        modalType: ModalTypes.SUCCESS_MESSAGE,
        modalData: message,
        timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
      }),
    );
  };

  const handleOnCompleteRequestSuccess = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.RATE_TRAINING_FORM,
        modalData: {
          trainingId: entryId,
          trainingTitle: title,
        },
        closeBtn: MODAL_CLOSE_BTNS.OUTSIDE,
      }),
    );
  };

  const handleOnRequestError = (error: any) => {
    const errorMessage = error?.data?.message ?? FAILED_REQUEST_MESSAGE;

    dispatch(
      openModal({
        modalType: ModalTypes.ERROR_MESSAGE,
        modalData: errorMessage,
        timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
      }),
    );
  };

  const handleOnApply = () => {
    if (type === MyTrainingsAndEventsTypes.TRAINING) {
      applyForTraining(entryId)
        .unwrap()
        .then(() =>
          handleOnRequestSuccess(SUCCESSUL_REQUEST_MESSAGES.APPLY_TRAINING),
        )
        .catch(handleOnRequestError);
    } else
      applyForEvent(entryId)
        .unwrap()
        .then(() =>
          handleOnRequestSuccess(SUCCESSUL_REQUEST_MESSAGES.APPLY_EVENT),
        )
        .catch(handleOnRequestError);
  };

  const handleOnStart = () => {
    if (type === MyTrainingsAndEventsTypes.TRAINING)
      startTrainingApplication(id)
        .unwrap()
        .then(() =>
          handleOnRequestSuccess(SUCCESSUL_REQUEST_MESSAGES.START_TRAINING),
        )
        .catch(handleOnRequestError);
    else
      startEventApplication(id)
        .unwrap()
        .then(() =>
          handleOnRequestSuccess(SUCCESSUL_REQUEST_MESSAGES.START_EVENT),
        )
        .catch(handleOnRequestError);
  };

  const handleOnComplete = () => {
    if (type === MyTrainingsAndEventsTypes.TRAINING)
      completeTrainingApplication(id)
        .unwrap()
        .then(handleOnCompleteRequestSuccess)
        .catch(handleOnRequestError);
    else
      completeEventApplication(id)
        .unwrap()
        .then(() =>
          handleOnRequestSuccess(SUCCESSUL_REQUEST_MESSAGES.COMPLETE_EVENT),
        )
        .catch(handleOnRequestError);
  };

  const handleOnDrop = () => {
    if (type === MyTrainingsAndEventsTypes.TRAINING)
      dropTrainingApplication(id)
        .unwrap()
        .then(() =>
          handleOnRequestSuccess(SUCCESSUL_REQUEST_MESSAGES.DROP_TRAINING),
        )
        .catch(handleOnRequestError);
    else
      dropEventApplication(id)
        .unwrap()
        .then(() =>
          handleOnRequestSuccess(SUCCESSUL_REQUEST_MESSAGES.DROP_EVENT),
        )
        .catch(handleOnRequestError);
  };

  const generateMenuOptions = (): MenuOptions[] => {
    return [
      {
        label: BUTTON_LABELS.APPLY,
        show: showApply,
        action: handleOnApply,
      },
      {
        label: BUTTON_LABELS.DROPOUT,
        show: showDrop,
        action: handleOnDrop,
      },
      {
        label: BUTTON_LABELS.START,
        show: showStart,
        action: handleOnStart,
      },
      {
        label: BUTTON_LABELS.COMPLETE,
        show: showComplete,
        action: handleOnComplete,
      },
    ];
  };

  if (shouldHideMenu) return null;

  return (
    <DotMenu
      options={generateMenuOptions()}
      btnClassName="change-application-status-menu-btn"
      menuClassName="change-application-status-menu"
    />
  );
};

export default ChangeApplicationStatusMenu;
