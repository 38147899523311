import {
  SEND_NOTIFICATIONS_FOR_ASSIGNED_TRAININGS_REQUEST,
  SEND_NOTIFICATIONS_FOR_ASSIGNED_TRAININGS_REQUEST_SUCCESS,
} from './types';

/** Action - send notifications for assigned trainings.
 * @param {string} sendNotificationsModel - Send notifications for assigned trainings model
 * @return {Object} Return object
 */

export function sendNotificationsForAssignedTrainings(sendNotificationsModel) {
  return {
    type: SEND_NOTIFICATIONS_FOR_ASSIGNED_TRAININGS_REQUEST,
    payload: {
      sendNotificationsModel,
    },
  };
}

/** On successful send notifications for assigned trainings
 * @param {string} isSuccessful - if send notifications for assigned trainings went alright
 * @return {any} Return object
 */
export function sendNotificationsForAssignedTrainingsSuccess(isSuccessful) {
  return {
    type: SEND_NOTIFICATIONS_FOR_ASSIGNED_TRAININGS_REQUEST_SUCCESS,
    payload: {
      isSuccessful,
    },
  };
}
