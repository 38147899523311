import axiosInstance from './axiosInstance';
import {
  CREATE_GROUP_URL,
  EDIT_GROUP_URL,
  ADD_MEMBERS_TO_GROUP_URL,
  ASSIGN_TRAININGS_TO_GROUP_URL,
  REMOVE_MEMBER_FROM_GROUP_URL,
  REMOVE_TRAINING_FROM_GROUP_URL,
  SEND_NOTIFICIONS_FOR_ASSIGNED_TRAININGS_URL,
  GET_ALL_GROUPS_URL,
  GET_GROUP_URL,
  GET_GROUPS_URL,
  DELETE_GROUP_URL,
} from './config';

export const api = {
  async getAll(requestModel) {
    return axiosInstance.get(GET_ALL_GROUPS_URL, {
      params: {
        pageNumber: requestModel.pageNumber,
        pageSize: requestModel.pageSize,
      },
    });
  },
  async getGroups() {
    return axiosInstance.get(GET_GROUPS_URL);
  },
  async getGroup(externalGroupId) {
    return axiosInstance.get(`${GET_GROUP_URL}/${externalGroupId}`);
  },
  async createGroup(createGroupModel) {
    return axiosInstance.post(CREATE_GROUP_URL, createGroupModel);
  },
  async editGroup(editGroupModel) {
    return axiosInstance.put(EDIT_GROUP_URL, editGroupModel);
  },
  async addMembers(addMembersModel) {
    return axiosInstance.post(ADD_MEMBERS_TO_GROUP_URL, addMembersModel);
  },
  async assignGroupTrainings(assignGroupTrainingsModel) {
    return axiosInstance.post(
      ASSIGN_TRAININGS_TO_GROUP_URL,
      assignGroupTrainingsModel,
    );
  },
  async removeMember(removeMemberModel) {
    return axiosInstance.post(REMOVE_MEMBER_FROM_GROUP_URL, removeMemberModel);
  },
  async removeTraining(removeTrainingModel) {
    return axiosInstance.post(
      REMOVE_TRAINING_FROM_GROUP_URL,
      removeTrainingModel,
    );
  },
  async sendNotificationsForAssignedTrainings(sendNotificationsModel) {
    return axiosInstance.post(
      SEND_NOTIFICIONS_FOR_ASSIGNED_TRAININGS_URL,
      sendNotificationsModel,
    );
  },
  async deleteGroup(groupIds) {
    return axiosInstance.post(DELETE_GROUP_URL, groupIds);
  },
};
