import {
  MenuItem,
  Stack,
  Typography,
  InputAdornment,
  Grid,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { AccessTimeRounded } from '@mui/icons-material';
import { IoLayersSharp } from 'react-icons/io5';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { TRAINING_DETAILS, TRAINING_LEVELS } from '../../../cms';
import { CustomTextField } from '../../../common/components';

const DetailsSection = () => {
  const { control } = useFormContext();

  return (
    <Stack spacing="0.75rem">
      <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
        {TRAINING_DETAILS.DETAILS_TEXT}
      </Typography>
      <Grid container spacing="1rem">
        <Grid item xs={12} sm="auto" lg={4}>
          <Controller
            name="level"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <CustomTextField
                select
                required
                fullWidth
                color="secondary"
                label={TRAINING_DETAILS.LEVEL_TEXT}
                name="level"
                value={value}
                onBlur={onBlur}
                inputRef={ref}
                error={Boolean(error)}
                helperText={error?.message}
                className="level-select"
                inputProps={{
                  className: 'level-input',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IoLayersSharp />
                    </InputAdornment>
                  ),
                }}
                SelectProps={{
                  MenuProps: {
                    MenuListProps: {
                      className: 'level-select-menu',
                    },
                  },
                }}
                onChange={onChange}
              >
                {TRAINING_LEVELS.map((level) => {
                  return (
                    <MenuItem key={level} value={level}>
                      {level}
                    </MenuItem>
                  );
                })}
              </CustomTextField>
            )}
          />
        </Grid>
        <Grid item xs={12} sm="auto">
          <Controller
            name="duration"
            control={control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <CustomTextField
                required
                color="secondary"
                type="number"
                label={TRAINING_DETAILS.TOTAL_DURATION_TEXT}
                name="duration"
                inputProps={{
                  className: 'duration-input',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccessTimeRounded />
                    </InputAdornment>
                  ),
                }}
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                inputRef={ref}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm="auto">
          <Controller
            name="lessons"
            control={control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <CustomTextField
                color="secondary"
                type="number"
                label={TRAINING_DETAILS.LESSONS_TEXT}
                name="lessons"
                inputProps={{
                  className: 'lessons-input',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DescriptionOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                inputRef={ref}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default DetailsSection;
