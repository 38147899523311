import aboutUsImage from '../../common/images/about-us.png';
import CookieTable from './CookieTable/CookieTable';
import PolicySection from '../../common/components/PolicySection/PolicySection';

const CookiePolicy = () => {
  return (
    <div className="cookie-policy-page policy-page">
      <header>
        <img src={aboutUsImage} alt="Cookie Policy" />

        <h1>Cookie Policy</h1>

        <p>
          This privacy statement sets out which personal data Strypes collect
          from you through our interaction with you and through our services and
          products, in which way this takes place, the role of cookies, for
          which we use the personal data, how long we store personal data, how
          you can view and change the personal data stored by us and how your
          personal data is protected by us.
        </p>
      </header>

      <PolicySection heading="What are cookies?">
        <p>
          This Cookie Policy explains what cookies are and how we use them, the
          types of cookies we use i.e, the information we collect using cookies
          and how that information is used, and how to manage the cookie
          settings.
        </p>
        <p>
          Cookies are small text files that are used to store small pieces of
          information. They are stored on your device when the website is loaded
          on your browser. These cookies help us make the website function
          properly, make it more secure, provide better user experience, and
          understand how the website performs and to analyze what works and
          where it needs improvement.
        </p>
      </PolicySection>

      <PolicySection heading="How do we use cookies?">
        <p>
          As most of the online services, our website uses first-party and
          third-party cookies for several purposes. First-party cookies are
          mostly necessary for the website to function the right way, and they
          do not collect any of your personally identifiable data.
        </p>
        <p>
          The third-party cookies used on our website are mainly for
          understanding how the website performs, how you interact with our
          website, keeping our services secure, providing advertisements that
          are relevant to you, and all in all providing you with a better and
          improved user experience and help speed up your future interactions
          with our website.
        </p>
      </PolicySection>

      <PolicySection heading="Types of cookies we use">
        <CookieTable />
      </PolicySection>

      <PolicySection heading="Manage cookie preferences">
        <p>
          You can change your cookie preferences any time by clicking the above
          button. This will let you revisit the cookie consent banner and change
          your preferences or withdraw your consent right away.
        </p>
        <p>
          In addition to this, different browsers provide different methods to
          block and delete cookies used by websites. You can change the settings
          of your browser to block/delete the cookies. Listed below are the
          links to the support documents on how to manage and delete cookies
          from the major web browsers.
        </p>
        <p>
          <a href="https://support.google.com/accounts/answer/32050">Chrome</a>
        </p>
        <p>
          <a href="https://support.apple.com/en-in/guide/safari/sfri11471/mac">
            Safari
          </a>
        </p>
        <p>
          <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US">
            Firefox
          </a>
        </p>
        <p>
          <a href="https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc">
            Internet Explorer
          </a>
        </p>
        <p>
          If you are using any other web browser, please visit your browser’s
          official support documents.
        </p>
      </PolicySection>
    </div>
  );
};
export default CookiePolicy;
