import {
  GET_ALL_MANAGERS_REQUEST,
  GET_ALL_MANAGERS_REQUEST_ERROR,
  GET_ALL_MANAGERS_REQUEST_SUCCESS,
} from './types';

/** Action - get all managers in the system.
 * @return {Object} Return object
 */
export function getAllManagers(departmentId: number) {
  return {
    type: GET_ALL_MANAGERS_REQUEST,
    payload: {
      departmentId,
    },
  };
}

/** On successful get of all managers
 * @param {Array} allManagers
 * @return {any} Return object
 */
export function getAllManagersSuccess(allManagers: any) {
  return {
    type: GET_ALL_MANAGERS_REQUEST_SUCCESS,
    payload: {
      allManagers,
    },
  };
}

/** Handle error
 * @param {string} errorMessage - Error message
 */
export function getAllManagersError(errorMessage: any) {
  return {
    type: GET_ALL_MANAGERS_REQUEST_ERROR,
    payload: errorMessage,
  };
}
