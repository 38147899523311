import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import userGuideImage from '../../common/images/user-guide-image.png';
import {
  USER_GUIDE_DESCRIPTIONS,
  USER_GUIDE_BTNS,
  USER_GUIDE_TITLES,
} from '../../cms';
import './UserGuide.scss';

type PageCounterSliceProps = {
  active: boolean;
};

type UserGuideModalProps = {
  onClose: () => void;
};

const PageCounterSlice = ({ active }: PageCounterSliceProps) => (
  <div
    className={`page-counter-slice ${active && 'page-counter-slice-active'}`}
  />
);

const pageCounterSlices: number[] = [];
for (let i = 0; i < USER_GUIDE_TITLES.length; i++) {
  pageCounterSlices.push(i);
}

const UserGuide = ({ onClose }: UserGuideModalProps) => {
  const [title, setTitle] = useState<string>(USER_GUIDE_TITLES[0]);
  const [description, setDescription] = useState(USER_GUIDE_DESCRIPTIONS[0]);
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    setTitle(USER_GUIDE_TITLES[page]);
    setDescription(USER_GUIDE_DESCRIPTIONS[page]);
  }, [page]);

  return (
    <div className="user-guide">
      <img className="user-guide-image" alt="" src={userGuideImage} />

      <div className="page-counter-slices">
        {pageCounterSlices.map((slice) => (
          <PageCounterSlice key={slice} active={slice <= page} />
        ))}
      </div>

      <div className="body">
        <div className="user-guide-title pt-4">{title}</div>
        <div className="user-guide-description pt-3">{description}</div>
      </div>

      <div className="footer">
        <div className="page-counter">{page > 0 && `${page}/8`}</div>
        <Button
          variant="outlined"
          className="prev-button"
          onClick={() => (page === 0 ? onClose() : setPage(page - 1))}
        >
          {page === 0 ? USER_GUIDE_BTNS.SKIP : USER_GUIDE_BTNS.PREVIOUS}
        </Button>
        <Button
          variant="contained"
          className="next-button"
          onClick={() => (page === 8 ? onClose() : setPage(page + 1))}
        >
          {page === 8 ? USER_GUIDE_BTNS.DONE : USER_GUIDE_BTNS.NEXT}
        </Button>
      </div>
    </div>
  );
};

export default UserGuide;
