import { put, take, call } from 'redux-saga/effects';
import { UPDATE_PROFESSIONS } from './types.js';
import { updateProfessionsSuccess } from './actions.js';

/** If detect type: UPDATE_PROFESSIONS call updateProfessions() */
export default function* updateProfessionsSaga() {
  while (true) {
    const updateProfessionsRequest = yield take(UPDATE_PROFESSIONS);
    if (updateProfessionsRequest.payload) {
      const { updatedProfessions } = updateProfessionsRequest.payload;
      yield call(updateProfessions, updatedProfessions);
    }
  }
}

/** Update job titles */
function* updateProfessions(updatedProfessions) {
  yield put(updateProfessionsSuccess(updatedProfessions));
}
