import { ToggleButton, styled } from '@mui/material';
import { COLORS } from '../../../cms';

const StyledToggleButton = styled(ToggleButton)({
  backgroundColor: COLORS.SECONDARY_LIGHTER,
  color: COLORS.SECONDARY,
  '&:hover': {
    backgroundColor: COLORS.SECONDARY_LIGHTER,
    color: COLORS.SECONDARY,
  },
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: COLORS.SECONDARY,
    color: COLORS.WHITE,
  },
  '&.toggle-btn-error': {
    backgroundColor: COLORS.ERROR_LIGHT,
    color: COLORS.ERROR,
  },
  textTransform: 'none',
  borderRadius: '2rem !important',
  border: 'none',
  padding: '0.5rem 1rem',
  fontSize: 12,
  fontFamily: 'OpenSans',
  gap: '0.5rem',
});

export default StyledToggleButton;
