import { CREATE_GROUP_REQUEST, CREATE_GROUP_REQUEST_SUCCESS } from './types';

/** Action - create a group.
 * @param {string} createGroupModel - The create a group model
 * @return {Object} Return object
 */
export function createGroup(createGroupModel) {
  return {
    type: CREATE_GROUP_REQUEST,
    payload: {
      createGroupModel,
    },
  };
}

/** On successful create of a group
 * @param {string} isSuccessful - if creation went alright
 * @return {any} Return object
 */
export function createGroupSuccess(isSuccessful, createdGroupId) {
  return {
    type: CREATE_GROUP_REQUEST_SUCCESS,
    payload: {
      isSuccessful,
      createdGroupId,
    },
  };
}
