import React, { FC } from 'react';
import { FILE_UPLOAD_DROP_ZONE } from '../../../../../cms';

// css
import './FileDropZoneFiles.scss';

interface FileDropZoneFilesProps {
  files: File[];
  checkForImporting: boolean;
  isImporting: boolean;
  clearFileSelection: Function;
  allowMultiple: boolean;
  disableClearFile: boolean;
}

const FileDropZoneFiles: FC<FileDropZoneFilesProps> = (props) => {
  const onClickClearFileSelection = () => props.clearFileSelection();

  return (
    <>
      {props.checkForImporting ? (
        <div>
          <div className="file-upload-text uploaded-file">
            {!props.isImporting
              ? props.files[0]?.name
              : FILE_UPLOAD_DROP_ZONE.BEING_IMPORTED}
          </div>
          {!props.isImporting && (
            <div
              onClick={onClickClearFileSelection}
              className="remove-file-symbol"
            >
              <span>&times;</span>
            </div>
          )}
        </div>
      ) : (
        <div>
          {props.allowMultiple ? (
            props.files.map((file: any, index: number) => (
              <React.Fragment key={index}>
                <div className="uploaded-file">{file.name}</div>
                {!props.disableClearFile && (
                  <div
                    onClick={onClickClearFileSelection}
                    className="remove-file-symbol inline-block"
                  >
                    <span>&times;</span>
                  </div>
                )}
                <br />
              </React.Fragment>
            ))
          ) : (
            <>
              <div className="uploaded-file">{props.files[0]?.name}</div>
              <div
                onClick={onClickClearFileSelection}
                className="remove-file-symbol inline-block"
              >
                <span>&times;</span>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default FileDropZoneFiles;
