import { PropsWithChildren } from 'react';
import './FormCheckbox.scss';

type FormCheckboxProps = {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  isRequired?: boolean;
  isValid?: boolean;
};

const FormCheckbox = ({
  checked,
  onChange,
  name,
  isRequired = false,
  isValid = true,
  children,
}: PropsWithChildren<FormCheckboxProps>) => (
  <div className={`form-checkbox${isValid ? '' : '-error'}`}>
    <input
      className="form-checkbox-checkbox"
      type="checkbox"
      id={name}
      name={name}
      checked={checked}
      onChange={onChange}
    />
    <label htmlFor={name} className="form-checkbox-text">
      {isRequired && <span className="form-checkbox-text-required">* </span>}
      {children}
    </label>
  </div>
);

export default FormCheckbox;
