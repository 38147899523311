import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { userApi } from '../api/userApi';

type UserSessionState = {
  id?: number;
  userId?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  accessToken?: string;
  photoUrl?: string;
  refreshToken?: string;
  departmentId?: number;
  isUserLoggedIn: boolean;
};

const initialState: UserSessionState = {
  isUserLoggedIn: false,
};

const userSessionSlice = createSlice({
  name: 'userSession',
  initialState,
  reducers: {
    refreshUserSession: (state, { payload }) => {
      const { accessToken, refreshToken } = payload;

      return {
        ...state,
        accessToken,
        refreshToken,
        isUserLoggedIn: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          userApi.endpoints.login.matchFulfilled,
          userApi.endpoints.externalLogin.matchFulfilled,
        ),
        (_, { payload }) => {
          return {
            ...payload,
            isUserLoggedIn: true,
          };
        },
      )
      .addMatcher(
        userApi.endpoints.updateImage.matchFulfilled,
        (state, { payload }) => {
          return {
            ...state,
            photoUrl: payload,
          };
        },
      );
  },
});

export default userSessionSlice.reducer;
