import {
  GET_ALL_DEPARTMENTS_REQUEST,
  GET_ALL_DEPARTMENTS_REQUEST_ERROR,
  GET_ALL_DEPARTMENTS_REQUEST_SUCCESS,
} from './types';

/** Action - get all departments in the system.
 * @return {Object} Return object
 */
export function getAllDepartments() {
  return {
    type: GET_ALL_DEPARTMENTS_REQUEST,
  };
}

/** On successful get of all departments
 * @param {Array} allDepartments
 * @return {any} Return object
 */
export function getAllDepartmentsSuccess(allDepartments: any) {
  return {
    type: GET_ALL_DEPARTMENTS_REQUEST_SUCCESS,
    payload: {
      allDepartments,
    },
  };
}

/** Handle error
 * @param {string} errorMessage - Error message
 */
export function getAllDepartmentsError(errorMessage: any) {
  return {
    type: GET_ALL_DEPARTMENTS_REQUEST_ERROR,
    payload: errorMessage,
  };
}
