import FeatureCard from './FeatureCard/FeatureCard';
import {
  FEATURES_SECTION_ITEMS,
  LandingPageSections,
  SCREEN_SIZES,
} from '../../cms/index';

import './FeaturesSection.scss';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import { addSmallStyle } from '../../cms/Utils';

const FeaturesSection = () => {
  const isScreenSmall = useMediaQuery(SCREEN_SIZES.SMALL);

  return (
    <section
      id={LandingPageSections.FEATURES}
      className={`features-section-container${addSmallStyle(isScreenSmall)}`}
    >
      <h2 className="features-section-title">Key Features</h2>
      <div
        className={`features-section-items-container${addSmallStyle(
          isScreenSmall,
        )}`}
      >
        {FEATURES_SECTION_ITEMS.map((item) => (
          <FeatureCard key={item.title} item={item} />
        ))}
      </div>
    </section>
  );
};

export default FeaturesSection;
