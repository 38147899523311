import './ContactSection.scss';
import {
  LANDING_PAGE_CONTACT_LABELS,
  LandingPageSections,
  SCREEN_SIZES,
} from '../../cms/index';
import arrowImage from '../../common/images/arrow-image.png';
import ContactForm from './ContactForm/ContactForm';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import { addSmallStyle } from '../../cms/Utils';

type ContactSectionProps = {
  openRequestDemoModal: () => void;
};

const ContactSection = ({ openRequestDemoModal }: ContactSectionProps) => {
  const isScreenSmall = useMediaQuery(SCREEN_SIZES.SMALL);

  return (
    <section
      id={LandingPageSections.CONTACT}
      className={`contact-section-container${addSmallStyle(isScreenSmall)}`}
    >
      <ContactForm title="Contact Us" />
      <h2 className="contact-section-title">
        {LANDING_PAGE_CONTACT_LABELS.CONTACT_TITLE}
      </h2>
      <p className="contact-section-descirption">
        {LANDING_PAGE_CONTACT_LABELS.CONTACT_CONTENT}
      </p>
      <div
        className="contact-section-btn-container"
        style={{
          justifyContent: isScreenSmall ? 'center' : 'flex-start',
        }}
      >
        <img
          src={arrowImage}
          alt="Arrow"
          className="contact-section-arrow-image"
          style={{
            display: isScreenSmall ? 'none' : 'flex',
          }}
        />
        <button
          type="button"
          className="contact-section-request-btn"
          onClick={openRequestDemoModal}
          style={{
            marginTop: isScreenSmall ? 0 : 20,
          }}
        >
          {LANDING_PAGE_CONTACT_LABELS.REQUEST_FREE_TRIAL}
        </button>
      </div>
    </section>
  );
};

export default ContactSection;
