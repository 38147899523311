import React from 'react';

import { GROUP_MANAGEMENT_LABELS } from '../../../../../../../../cms';
import createIcon from '../../../../../../../../common/images/create-blue.svg';
// css
import './EditGroup.scss';

export default function EditGroup({ onClick }) {
  return (
    <>
      <div className="pointer" onClick={() => onClick()}>
        <img
          src={createIcon}
          alt=""
          width={14}
          className="inline-block edit-group-img"
        />
        &nbsp;
        <div className="inline-block edit-group-text">
          {GROUP_MANAGEMENT_LABELS.EDIT_GROUP}
        </div>
      </div>
    </>
  );
}
