import React, { useState } from 'react';
import { Button, Divider, Grid, Stack, Typography } from '@mui/material';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import './EventMainForm.scss';
import {
  ApplicationsLimitSection,
  BaseModal,
  CostSection,
  DatesSection,
  MarkdownTextArea,
  TitleInput,
  LocationSection,
} from '../../../common/components';
import UploadPdf from './UploadPdf/UploadPdf';
import { ADD_EVENT_MODAL_LABELS, EVENT_FORM_TYPES } from '../../../cms';
import FormEventTypes from './FormEventTypes/FormEventTypes';
import EventLinkInput from './EventLinkInput/EventLinkInput';

type EventMainFormProps = {
  type: number;
  setShowAgendaSpeakersForm: (show: boolean) => void;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
};

const EventMainForm = ({
  type,
  setShowAgendaSpeakersForm,
  files,
  setFiles,
}: EventMainFormProps) => {
  const [uploadPdfModalOpened, setUploadPdfModalOpened] = useState(false);

  const handleClosePdfModal = () => {
    setUploadPdfModalOpened(false);
  };

  const removeFile = (name: string) => {
    setFiles((prev) => prev.filter((f: File) => f.name !== name));
  };

  const generateFormTitle = (): string => {
    if (type === EVENT_FORM_TYPES.CREATE)
      return ADD_EVENT_MODAL_LABELS.CREATE_EVENT;
    else if (type === EVENT_FORM_TYPES.EDIT)
      return ADD_EVENT_MODAL_LABELS.EDIT_EVENT;

    return ADD_EVENT_MODAL_LABELS.DUPLICATE_EVENT;
  };

  const formTitle = generateFormTitle();

  return (
    <>
      <Stack spacing="2rem">
        <Typography className="section-title">{formTitle}</Typography>
        <Divider />
        <Grid
          container
          rowSpacing="2rem"
          columnSpacing="3rem"
          alignItems="stretch"
        >
          <Grid item xs={12} md={6}>
            <Stack spacing="2rem">
              <EventLinkInput />
              <TitleInput />
              <FormEventTypes />
              <LocationSection type="Event" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <MarkdownTextArea label={ADD_EVENT_MODAL_LABELS.DESCRIPTION} />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatesSection datesRequired />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing="0.75rem">
              <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                Price & Applications limit
              </Typography>
              <Stack spacing="2rem" direction="row" flexWrap="wrap">
                <CostSection />
                <ApplicationsLimitSection />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              className="event-form-btn-link open-agenda-speakers-form-btn"
              startIcon={<AddCircleIcon />}
              onClick={() => setShowAgendaSpeakersForm(true)}
            >
              {ADD_EVENT_MODAL_LABELS.ADD_AGENDA_SPEAKERS}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" flexWrap="wrap">
              <Button
                className="event-form-btn-link"
                startIcon={<UploadFileRoundedIcon />}
                onClick={() => setUploadPdfModalOpened(true)}
              >
                {ADD_EVENT_MODAL_LABELS.ATTACH_FILE}
              </Button>
              {files?.map((file) => (
                <Button
                  color="secondary"
                  key={file.name}
                  className="event-form-btn-link"
                  startIcon={<CancelIcon />}
                  onClick={() => removeFile(file.name)}
                >
                  {file.name}
                </Button>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <BaseModal open={uploadPdfModalOpened} onClose={handleClosePdfModal}>
        <UploadPdf
          handleClosePdfModal={handleClosePdfModal}
          files={files}
          setFiles={setFiles}
        />
      </BaseModal>
    </>
  );
};

export default EventMainForm;
