import {
  REMOVE_EMPLOYEE_FROM_MY_TEAM_REQUEST,
  REMOVE_EMPLOYEE_FROM_MY_TEAM_REQUEST_SUCCESS,
  REMOVE_EMPLOYEE_FROM_MY_TEAM_REQUEST_ERROR,
} from './types';

interface ErrorResponse {
  response: Response;
}

interface Response {
  data: Data;
}

interface Data {
  message: string;
}

/** Action - Send remove employee from my team request

* @param {number} employeeId - Remove employee from my team model
* @return {Object} Return object
*/
export function removeEmployeeFromMyTeam(employeeId: number) {
  return {
    type: REMOVE_EMPLOYEE_FROM_MY_TEAM_REQUEST,
    payload: {
      employeeId,
    },
  };
}

/** On successful removing an employee to my team
 * @param {string} isSuccessful - if get successful
 * @return {any} Return object
 */
export function removeEmployeeFromMyTeamSuccess(isSuccessful: boolean) {
  return {
    type: REMOVE_EMPLOYEE_FROM_MY_TEAM_REQUEST_SUCCESS,
    payload: {
      isSuccessful,
    },
  };
}

/** Handle error
 * @param {unknown} error - Error response
 */
export function removeEmployeeFromMyTeamError(error: ErrorResponse) {
  return {
    type: REMOVE_EMPLOYEE_FROM_MY_TEAM_REQUEST_ERROR,
    payload: error.response.data.message,
  };
}
