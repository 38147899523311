import dayjs from 'dayjs';
import {
  GET_ADMIN_URL,
  GET_MY_TEAM_SUFFIX,
  REMOVE_USER_FROM_EVENT_URL,
  REMOVE_USER_FROM_TRAINING_URL,
} from '../../api/trainingsPortalBackend/config';
import {
  ApplicationStatuses,
  MyTrainingsAndEventsTypes,
} from '../../cms/enums';
import { api } from './api';
import {
  RemoveUserFromEventModel,
  RemoveUserFromTrainingModel,
} from '../../cms/types';

export type MyTeamApplication = {
  applicantEmail: string;
  applicantFirstName: string;
  applicantJobTitleId: string | null;
  applicantLastName: string;
  applicantPhotoUrl: string;
  applicationId: string;
  applicationType: MyTrainingsAndEventsTypes;
  entryId: string;
  isEvent: boolean;
  title: string;
  type: string;
  url: string;
  submittedOn: Date;
  comment: string | null;
  status: ApplicationStatuses;
};

export type MyTeamEmployee = {
  approvedBudget: number;
  department: string;
  departmentId: string | null;
  email: string;
  externalId: string;
  firstName: string;
  fullName: string;
  id: number;
  isDisabled: boolean;
  isManager: boolean | null;
  jobTitle: string | null;
  lastName: string;
  manager: string;
  managerEmail: string;
  managerExternalId: string;
  managerId: number;
  managerJobTitle: string | null;
  managerPhotoUrl: string;
  pathProgress: number;
  photoUrl: string;
  professionId: number;
  resignedOn: Date | null;
  spentBudget: number;
  terminationDate: Date | null;
};

export type MyTeam = {
  applications: MyTeamApplication[];
  employees: MyTeamEmployee[];
};

type MyTeamApplicationParams = {
  search: string;
  type: MyTrainingsAndEventsTypes | 'All';
  status: ApplicationStatuses | 'All';
};

type MyTeamEmployeeParams = {
  search: string;
};

export const adminApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMyTeamEmployees: build.query<MyTeamEmployee[], MyTeamEmployeeParams>({
      query: () => `/${GET_ADMIN_URL}${GET_MY_TEAM_SUFFIX}`,
      providesTags: ['MyTeamEmployees'],
      transformResponse: (response: MyTeam, meta, arg) =>
        response.employees.filter((e) =>
          e.fullName.toLowerCase().includes(arg.search),
        ),
    }),
    getMyTeamApplications: build.query<
      MyTeamApplication[],
      MyTeamApplicationParams
    >({
      query: () => `/${GET_ADMIN_URL}${GET_MY_TEAM_SUFFIX}`,
      providesTags: ['MyTeamApplications'],
      transformResponse: (response: MyTeam, meta, arg) =>
        response.applications
          .sort((a, b) =>
            dayjs(a.submittedOn).isBefore(b.submittedOn) ? 1 : -1,
          )
          .filter((a) =>
            `${a.applicantFirstName} ${a.applicantLastName}`
              .toLowerCase()
              .includes(arg.search),
          )
          .filter((a) =>
            arg.type === 'All' ? a : a.applicationType === arg.type,
          )
          .filter((a) => (arg.status === 'All' ? a : a.status === arg.status)),
      forceRefetch: () => true,
    }),
    removeUserFromTraining: build.mutation<
      boolean,
      RemoveUserFromTrainingModel
    >({
      query: (model) => ({
        url: REMOVE_USER_FROM_TRAINING_URL,
        method: 'DELETE',
        body: model,
      }),
    }),
    removeUserFromEvent: build.mutation<boolean, RemoveUserFromEventModel>({
      query: (model) => ({
        url: REMOVE_USER_FROM_EVENT_URL,
        method: 'DELETE',
        body: model,
      }),
    }),
  }),
});

export const {
  useGetMyTeamEmployeesQuery,
  useGetMyTeamApplicationsQuery,
  useRemoveUserFromTrainingMutation,
  useRemoveUserFromEventMutation,
} = adminApi;
