import {
  GET_GROUPS_REQUEST,
  GET_GROUPS_REQUEST_ERROR,
  GET_GROUPS_REQUEST_SUCCESS,
} from './types';

/** Action - get all groups in the system.
 * @return {Object} Return object
 */
export function getGroups() {
  return {
    type: GET_GROUPS_REQUEST,
  };
}

/** On successful get of all groups
 * @param {Array} groups - if assignment went alright assign all applications
 * @return {any} Return object
 */
export function getGroupsSuccess(groups) {
  return {
    type: GET_GROUPS_REQUEST_SUCCESS,
    payload: {
      groups,
    },
  };
}

/** Handle error
 * @param {string} errorMessage - Error message
 */
export function getGroupsError(errorMessage) {
  return {
    type: GET_GROUPS_REQUEST_ERROR,
    payload: errorMessage,
  };
}
