import { useLocation, useNavigate } from 'react-router';
import { ROUTE_LINKS } from '../../cms';

type NavigateBackArgs = {
  fallback?: string;
};

export const useNavigateBack = () => {
  const { key } = useLocation();
  const navigate = useNavigate();

  // Go to the fallback route (if provided) or to the default route
  if (key === 'default')
    return ({ fallback }: NavigateBackArgs) =>
      navigate(fallback || ROUTE_LINKS.DEFAULT);

  // Go back
  return (_args: NavigateBackArgs) => navigate(-1);
};
