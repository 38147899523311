import { useState, useEffect } from 'react';
import {
  Checkbox,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupDetails from './GroupDetails/GroupDetails';
import ItemsInGroupPreview from '../../../../../../components/ItemsInGroupPreview/ItemsInGroupPreview';
import ModifyGroup from './ModifyGroup/ModifyGroup';
import './GroupAccordion.scss';

export default function GroupAccordion({
  group,
  openedGroupId,
  onOpenHandler,
  onModifyHandler,
  addSelectedGroupIdsHandler,
  removeSelectedGroupIdsHandler,
}) {
  const [showDetails, setShowDetails] = useState(false);
  const [showEmployees, setShowEmployees] = useState(true);
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (checked) {
      addSelectedGroupIdsHandler(group.id);
    } else {
      removeSelectedGroupIdsHandler(group.id);
    }
  }, [checked]);

  return (
    <Accordion
      disabled={!(openedGroupId === undefined || openedGroupId === group.id)}
      className="root col-12 overflow-hidden"
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon className="notification-comment-btn-icon" />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={() => {
          setShowDetails(!showDetails);
          setShowEmployees(!showEmployees);
          onOpenHandler(group.id);
          if (!showEmployees) onOpenHandler(undefined);
        }}
        className={showDetails ? 'header-border' : ''}
      >
        <Stack
          spacing="0.75rem"
          paddingRight="0.75rem"
          alignItems="center"
          flexDirection="row"
          flexWrap="wrap"
          width="100%"
        >
          <Typography className="group-title">{group.title}</Typography>
          {showEmployees && (
            <ItemsInGroupPreview items={group.members} max={5} />
          )}
          <Checkbox
            checked={checked}
            onChange={handleChange}
            onClick={(e) => {
              e.stopPropagation();
            }}
            color="default"
            size="small"
            className="group-checkbox"
          />
          <ModifyGroup
            showDetails={showDetails}
            group={group}
            onClick={(e) => {
              e.stopPropagation();
              onModifyHandler(group.id);
            }}
          />
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        {showDetails && <GroupDetails groupId={group.id} />}
      </AccordionDetails>
    </Accordion>
  );
}
