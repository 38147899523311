import { put, take, call } from 'redux-saga/effects';
import {
  GET_ALL_EMPLOYEES_REQUEST,
  GET_SINGLE_EMPLOYEE_REQUEST,
} from './types';
import {
  getAllEmployeesError,
  getAllEmployeesSuccess,
  getSingleEmployeeSuccess,
  getSingleEmployeeError,
} from './actions';

/** If detect type: GET_ALL_EMPLOYEES_REQUEST call getAllEmployees() */
export function* getAllEmployeesSaga(api) {
  while (true) {
    const getAllEmployeesRequest = yield take(GET_ALL_EMPLOYEES_REQUEST);
    if (getAllEmployeesRequest.payload) {
      const { model } = getAllEmployeesRequest.payload;
      yield call(getAllEmployees, api, model);
    }
  }
}

/** get all employees */
function* getAllEmployees(api, model) {
  let response;
  try {
    response = yield call(api.getAll, model);
    const allEmployees = response.data;
    yield put(getAllEmployeesSuccess(allEmployees));
  } catch (error) {
    yield put(getAllEmployeesError(error));
  }
}

export function* getSingleEmployeeSaga(api) {
  while (true) {
    const getAllEmployeesRequest = yield take(GET_SINGLE_EMPLOYEE_REQUEST);
    if (getAllEmployeesRequest.payload) {
      const { employeeId } = getAllEmployeesRequest.payload;
      yield call(getSingleEmployee, api, employeeId);
    }
  }
}

function* getSingleEmployee(api, employeeId) {
  let response;
  try {
    response = yield call(api.getOne, employeeId);
    const employeeData = response.data;
    yield put(getSingleEmployeeSuccess(employeeData));
  } catch (error) {
    yield put(getSingleEmployeeError(error));
  }
}
