import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Button,
  LinearProgress,
  Stack,
  SxProps,
  Theme,
  Typography,
  linearProgressClasses,
  styled,
} from '@mui/material';
import { School, Checklist } from '@mui/icons-material';
import { CalendarIcon } from '@mui/x-date-pickers';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  MyTeamEmployee,
  useGetMyTeamEmployeesQuery,
} from '../../../rtk/api/adminApi';
import ResponsiveTable from '../../../common/components/ResponsiveTable/ResponsiveTable';
import {
  COLORS,
  EMPLOYEE_MENU_ACTIONS,
  FAILED_REQUEST_MESSAGE,
  MODAL_VALUES,
  MY_EMPLOYEES_LABELS,
  TRAINING_LABELS,
  ROUTE_LINKS,
  MODAL_CLOSE_BTNS,
  MY_GROUP_LABELS,
} from '../../../cms';
import EmpployeeBio from './EmployeeBio/EmployeeBio';
import { inheritFontStyle } from '../../../cms/Utils';
import {
  BaseModal,
  EmployeesSelect,
  SearchField,
} from '../../../common/components';
import { useAppDispatch } from '../../../rtk/hooks';
import { openModal } from '../../../rtk/features/modalsSlice';
import {
  useAddEmployeesToMyTeamMutation,
  useRemoveEmployeeFromMyTeamMutation,
} from '../../../rtk/api/employeeApi';
import EventsSelect from '../../../forms/PathForm/sections/ContentSection/forms/EventsSelect/EventsSelect';
import {
  ModalAlignments,
  ModalTypes,
  MyEmployeesModals,
} from '../../../cms/enums';
import {
  AssignEventDto,
  EventShortResponse,
} from '../../../cms/types/eventTypes';
import { useAssignEventMutation } from '../../../rtk/api/eventApi';
import { TrainingShortResponse } from '../../../cms/types/trainingTypes';
import TrainingsSelect from '../../../forms/PathForm/sections/ContentSection/forms/TrainingsSelect/TrainingsSelect';
import { useAssignTrainingsToEmployeesMutation } from '../../../rtk/api/trainingApi';
import { useDebounce } from '../../../common/hooks/useDebounce';
import { MenuOptions } from '../../../cms/types';
import DotMenu from '../../../common/components/DotMenu/DotMenu';

const StyledLinearProgress = styled(LinearProgress)({
  height: 10,
  width: 96,
  maxWidth: 200,
  borderRadius: 50,
  flex: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: COLORS.INPUT,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 50,
    backgroundColor: COLORS.ICON_SECOND,
  },
});

const menuOptionsIconSx: SxProps<Theme> = {
  fontSize: 18,
  color: COLORS.TEXT,
};

const MyEmployeesTab = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openedModal, setOpenedModal] = useState<MyEmployeesModals | null>(
    null,
  );
  const [assignTrainings, { isLoading: isAssigningTrainings }] =
    useAssignTrainingsToEmployeesMutation();
  const [assignEvent, { isLoading: isAssigningEvent }] =
    useAssignEventMutation();
  const [addEmployees, { isLoading: isAddingEmployees }] =
    useAddEmployeesToMyTeamMutation();
  const [removeEmployee] = useRemoveEmployeeFromMyTeamMutation();
  const [focusedEmployee, setFocusedEmployee] = useState<MyTeamEmployee | null>(
    null,
  );
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search.trim().toLowerCase());
  const { data: employees, isFetching } = useGetMyTeamEmployeesQuery({
    search: debouncedSearch,
  });
  const existingEmployeeIds = employees?.map((employee) => employee.id);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setSearch(text);
  };

  const generateMenuOptions = useCallback(
    (item: MyTeamEmployee): MenuOptions[] => {
      return [
        {
          label: EMPLOYEE_MENU_ACTIONS.ASSIGN_EVENT,
          icon: <CalendarIcon sx={menuOptionsIconSx} />,
          action: () => {
            setFocusedEmployee(item);
            setOpenedModal(MyEmployeesModals.EVENTS_SELECT);
          },
          className: 'assign-event-to-employee-btn',
        },
        {
          label: EMPLOYEE_MENU_ACTIONS.ASSIGN_TRAINING,
          icon: <School sx={menuOptionsIconSx} />,
          action: () => {
            setFocusedEmployee(item);
            setOpenedModal(MyEmployeesModals.TRAININGS_SELECT);
          },
          className: 'assign-training-to-employee-btn',
        },
        {
          label: EMPLOYEE_MENU_ACTIONS.ASSIGN_PATH,
          icon: <Checklist sx={menuOptionsIconSx} />,
          action: () => {
            navigate(
              `${ROUTE_LINKS.ASSIGN_PATHS}?employeeId=${item.id}&employeeName=${item.fullName}`,
            );
          },
          className: 'assign-path-to-employee-btn',
        },
        {
          label: EMPLOYEE_MENU_ACTIONS.REMOVE_EMPLOYEE,
          icon: <DeleteIcon sx={menuOptionsIconSx} />,
          action: () => {
            handleRemoveEmployee(item.id);
          },
          className: 'remove-my-employee-btn',
        },
      ];
    },
    [employees],
  );

  const handleErrorModal = (error?: any) => {
    const errorMsg = error.data?.message ?? FAILED_REQUEST_MESSAGE;

    dispatch(
      openModal({
        modalType: ModalTypes.ERROR_MESSAGE,
        modalData: errorMsg,
        timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
      }),
    );
  };

  const handleRemoveEmployee = (id: number) => {
    dispatch(
      openModal({
        modalType: ModalTypes.CONFIRM_ACTION,
        closeBtn: MODAL_CLOSE_BTNS.OUTSIDE,
        modalData: {
          text: MY_EMPLOYEES_LABELS.REMOVE_EMPLOYEE_TEXT,
          onConfirm: () => removeEmployee(id),
        },
      }),
    );
  };

  const handleOnEmployeesSelectSubmit = (selectedEmployeeIds: number[]) => {
    addEmployees(selectedEmployeeIds)
      .unwrap()
      .then(handleCloseModal)
      .catch(handleErrorModal);
  };

  const handleTrainingsSelectSubmit = (trainings: TrainingShortResponse[]) => {
    if (trainings.length === 0 || focusedEmployee === null) {
      handleCloseModal();
      return;
    }

    const model = {
      trainingIds: trainings.map((t) => t.id),
      userIds: [focusedEmployee.id],
    };

    assignTrainings(model)
      .unwrap()
      .then(() => {
        dispatch(
          openModal({
            modalType: ModalTypes.SUCCESS_MESSAGE,
            modalData: MY_EMPLOYEES_LABELS.ASSIGN_TRAINING_SUCCESS,
            timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
          }),
        );

        handleCloseModal();
      })
      .catch((error) => handleErrorModal(error));
  };

  const handleOnEventsSelectSubmit = (events: EventShortResponse[]) => {
    if (events.length === 0 || focusedEmployee === null) {
      handleCloseModal();
      return;
    }

    const assignEventDto: AssignEventDto = {
      eventId: events[0].id,
      userIds: [focusedEmployee.id],
    };

    assignEvent(assignEventDto)
      .unwrap()
      .then(() => {
        dispatch(
          openModal({
            modalType: ModalTypes.SUCCESS_MESSAGE,
            modalData: MY_EMPLOYEES_LABELS.ASSIGN_EVENT_SUCCESS,
            timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
          }),
        );

        handleCloseModal();
      })
      .catch((error) => handleErrorModal(error));
  };

  const handleCloseModal = () => {
    setOpenedModal(null);
  };

  return (
    <Stack spacing="2rem" marginTop="2.5rem">
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="flex-end"
        spacing="1rem"
      >
        <SearchField
          value={search}
          onChange={handleSearchChange}
          placeholder={MY_GROUP_LABELS.SEARCH_EMPLOYEE_PLACEHOLDER}
          sx={{
            width: { xs: '100%', sm: 300 },
            marginRight: 'auto',
          }}
        />

        <Button
          className="add-employee-btn"
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenedModal(MyEmployeesModals.EMPLOYEES_SELECT);
          }}
        >
          {MY_EMPLOYEES_LABELS.ADD_EMPLOYEE_BUTTON_TEXT}
        </Button>

        <BaseModal
          open={openedModal === MyEmployeesModals.EMPLOYEES_SELECT}
          onClose={handleCloseModal}
          showCloseBtnOutside
        >
          <EmployeesSelect
            title="Add Employees"
            isSubmitting={isAddingEmployees}
            onSubmit={handleOnEmployeesSelectSubmit}
            employeeIdsToHide={existingEmployeeIds}
          />
        </BaseModal>
      </Stack>

      <ResponsiveTable<MyTeamEmployee>
        data={employees || []}
        isLoading={isFetching}
        columns={[
          {
            key: 'firstName',
            heading: 'Name',
            primary: true,
            width: '20%',
            render: (item: MyTeamEmployee) => (
              <EmpployeeBio
                name={item.fullName}
                photoUrl={item.photoUrl}
                id={item.id}
                learningHub
              />
            ),
          },
          {
            key: 'jobTitle',
            heading: 'Position',
            hide: true,
            primary: true,
            width: '20%',
          },
          {
            key: 'pathProgress',
            heading: 'Completed Trainings',
            hide: true,
            primary: true,
            width: '20%',
            render: (item: MyTeamEmployee) => (
              <Stack
                flexDirection="row"
                alignItems="center"
                gap="0.5rem"
                width="130px"
              >
                <StyledLinearProgress
                  variant="determinate"
                  value={item.pathProgress}
                />
                <Typography sx={{ ...inheritFontStyle }}>
                  {item.pathProgress}%
                </Typography>
              </Stack>
            ),
          },
          {
            key: 'approvedBudget',
            heading: 'Approved Budget ($)',
            hide: true,
            primary: true,
            align: 'right',
            width: '20%',
          },
          {
            key: 'spentBudget',
            heading: 'Spent Budget ($)',
            hide: true,
            primary: true,
            align: 'right',
            width: '18%',
          },
          {
            key: 'id',
            heading: '',
            primary: true,
            align: 'right',
            width: '2%',
            render: (item: MyTeamEmployee) => (
              <DotMenu options={generateMenuOptions(item)} />
            ),
          },
        ]}
      />
      <BaseModal
        open={openedModal === MyEmployeesModals.TRAININGS_SELECT}
        onClose={handleCloseModal}
        alignment={ModalAlignments.RIGHT}
        showCloseBtnOutside
      >
        <TrainingsSelect
          title={`${TRAINING_LABELS.ASSIGN_TRAININGS} to ${focusedEmployee?.fullName}`}
          submitBtnText={TRAINING_LABELS.ASSIGN_TRAININGS}
          isLoading={isAssigningTrainings}
          onSubmit={handleTrainingsSelectSubmit}
        />
      </BaseModal>
      <BaseModal
        open={openedModal === MyEmployeesModals.EVENTS_SELECT}
        onClose={handleCloseModal}
        alignment={ModalAlignments.RIGHT}
        showBlur={false}
      >
        <EventsSelect
          title={`Assign Event to ${focusedEmployee?.fullName}`}
          submitBtnText="Assign Event"
          isSubmitting={isAssigningEvent}
          onSubmit={handleOnEventsSelectSubmit}
          isSingleSelect
        />
      </BaseModal>
    </Stack>
  );
};

export default MyEmployeesTab;
