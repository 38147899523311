import {
  USER_IMPORT_DEFAULT,
  IMPORT_USERS_CSV_REQUEST,
  IMPORT_USERS_CSV_REQUEST_ERROR,
  IMPORT_USERS_CSV_REQUEST_SUCCESS,
} from './types';

/** Action - Import users from CSV file
 * @param {FormData} csvFile - Access Token of the user
 * @return {Object} Return object
 */
export function importUsersCsv(csvFile) {
  return {
    type: IMPORT_USERS_CSV_REQUEST,
    payload: {
      csvFile,
    },
  };
}

/** On successful import of users, will return succcess status.
 * @param {boolean} isSuccessful - training locations
 * @return {any} training locations
 */
export function importUsersCsvSuccess(isSuccessful) {
  return {
    type: IMPORT_USERS_CSV_REQUEST_SUCCESS,
    payload: {
      isSuccessful,
    },
  };
}

/** Handle error
 * @param {string} errorMessage - Error message
 */
export function importUsersCsvError(errorMessage) {
  return {
    type: IMPORT_USERS_CSV_REQUEST_ERROR,
    payload: errorMessage,
  };
}

export function userImportReducerDefault() {
  return {
    type: USER_IMPORT_DEFAULT,
  };
}
