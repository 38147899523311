import { StyledToggleButtonGroup, StyledToggleButton } from '../index';
import { YEAR_QUARTERS } from '../../../cms';

type YearQuarterSelectProps = {
  value: string | null;
  onChange: (newValue: string) => void;
};

const YearQuarterSelect = ({ value, onChange }: YearQuarterSelectProps) => {
  return (
    <StyledToggleButtonGroup
      value={value}
      onChange={(_, newValue: string) => onChange(newValue)}
      aria-label="year quarters"
      exclusive
    >
      {YEAR_QUARTERS.map((quarter) => (
        <StyledToggleButton
          key={quarter}
          value={quarter}
          className="year-quarter"
        >
          {quarter}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

export default YearQuarterSelect;
