import { LoadingButton } from '@mui/lab';
import { Controller, useFormContext } from 'react-hook-form';
import { MAX_LENGTHS } from '../../../cms';
import { CustomTextField } from '../../../common/components';
import { TrainingFormSchemaType } from '../../../cms/validation/trainingValidation';
import { useLazyGetTrainingByExternalLinkQuery } from '../../../rtk/api/trainingApi';
import { validateUrl } from '../../../common/input_helpers';
import { PrefillTrainingResponse } from '../../../cms/types';
import { isApiError } from '../../../cms/Utils';

const TrainingLinkInput = () => {
  const { control, watch, reset, getValues, setError } =
    useFormContext<TrainingFormSchemaType>();
  const watchUrl = watch('url');
  const isUrlValid = validateUrl(watchUrl);

  const [getExternalTrainingData, { isFetching }] =
    useLazyGetTrainingByExternalLinkQuery();

  const onPrefill = () => {
    getExternalTrainingData(watchUrl)
      .unwrap()
      .then((data) => {
        prefillForm(data);
      })
      .catch((error) => {
        if (isApiError(error)) {
          const linkErrorMessage = error.data[0]?.value[0]?.errorMessage;
          if (linkErrorMessage)
            setError('url', {
              message: linkErrorMessage,
            });
        }
      });
  };

  const prefillForm = (prefillTraining: PrefillTrainingResponse) => {
    const duration = prefillTraining.duration ?? 0;
    const lessons = prefillTraining.lessons ?? 0;
    const level = prefillTraining.level ?? 'Beginner';
    const description = prefillTraining.overview ?? '';
    const cost = prefillTraining.price ?? 0;
    const title = prefillTraining.title ?? '';
    const trainerJobTitle = prefillTraining.trainerJobTitle ?? '';

    const trainerFullName = prefillTraining.trainerFullName ?? '';
    const names = trainerFullName.split(' ');
    const trainerFirstName = names.length > 0 ? names[0] : '';
    const trainerLastName = names.length > 1 ? names[1] : '';

    reset({
      ...getValues(),
      duration,
      lessons,
      level,
      description,
      cost,
      title,
      trainerFirstName,
      trainerLastName,
      trainerJobTitle,
    });
  };

  return (
    <Controller
      name="url"
      control={control}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { error },
      }) => (
        <CustomTextField
          fullWidth
          color="secondary"
          label="URL (link to the training)"
          name={name}
          value={value}
          onChange={onChange}
          inputRef={ref}
          onBlur={onBlur}
          helperText={error?.message}
          error={Boolean(error)}
          inputProps={{
            maxLength: MAX_LENGTHS.TITLE_MAX_LENGTH,
            className: 'link-input',
          }}
          InputProps={{
            endAdornment: (
              <LoadingButton
                variant="contained"
                disabled={!isUrlValid}
                loading={isFetching}
                onClick={onPrefill}
                className="prefill-btn"
                sx={{
                  padding: '2px 0.75rem',
                  height: 'auto',
                  minWidth: 0,
                  mr: '13px',
                }}
              >
                Prefill
              </LoadingButton>
            ),
          }}
        />
      )}
    />
  );
};

export default TrainingLinkInput;
