import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import './ContentSection.scss';
import { ModalAlignments, PathItemTypes } from '../../../../cms/enums';
import { BaseModal, PathItemCard } from '../../../../common/components';
import TrainingsSelect from './forms/TrainingsSelect/TrainingsSelect';
import {
  PathItemSchemaType,
  PathSchemaType,
} from '../../../../cms/validation/pathValidation';
import EventsSelect from './forms/EventsSelect/EventsSelect';
import ContentSectionOption from './ContentSectionOption/ContentSectionOption';
import LinkForm from './forms/LinkForm/LinkForm';
import MeetingForm from './forms/MeetingForm/MeetingForm';
import FileForm from './forms/FileForm/FileForm';
import {
  ADD_EVENT_MODAL_LABELS,
  PATH_CONTENT_LABELS,
  TRAINING_LABELS,
} from '../../../../cms';
import { PathContentOption } from '../../../../cms/types/pathTypes';
import { EventShortResponse } from '../../../../cms/types/eventTypes';
import {
  convertServerPathEventToClientPathEvent,
  convertServerPathTrainingToClientPathTraining,
} from '../../../../cms/Utils';
import { TrainingShortResponse } from '../../../../cms/types/trainingTypes';

const ContentSection = () => {
  const { control, watch } = useFormContext<PathSchemaType>();
  const items = watch('items');
  const itemsMethods = useFieldArray({
    control,
    name: 'items',
  });
  const trainingItemIds = items
    .filter((i) => i.type === PathItemTypes.TRAINING)
    .map((t) => t.data.id);
  const eventItemIds = items
    .filter((i) => i.type === PathItemTypes.EVENT)
    .map((e) => e.data.id);
  const [openedContentMoodal, setOpenedContentModal] =
    useState<PathItemTypes | null>(null);
  const [editedItem, setEditedItem] = useState<PathItemSchemaType | null>(null);
  const CONTENT_OPTIONS: PathContentOption[] = [
    {
      type: PathItemTypes.TRAINING,
      label: PATH_CONTENT_LABELS.TRAINING,
      iconName: 'school',
    },
    {
      type: PathItemTypes.EVENT,
      label: PATH_CONTENT_LABELS.EVENT,
      iconName: 'event',
    },
    {
      type: PathItemTypes.LINK,
      label: PATH_CONTENT_LABELS.LINK,
      iconName: 'link',
    },
    {
      type: PathItemTypes.FILE,
      label: PATH_CONTENT_LABELS.FILE,
      iconName: 'file_present',
      disabled: items.filter((i) => i.type === PathItemTypes.FILE).length >= 5,
    },
    {
      type: PathItemTypes.MEETING,
      label: PATH_CONTENT_LABELS.MEETING,
      iconName: 'video_chat',
    },
  ];

  const handleOnModalClose = () => {
    setEditedItem(null);
    setOpenedContentModal(null);
  };

  const handleOnOptionClick = (type: PathItemTypes) => {
    setOpenedContentModal(type);
  };

  const handleOnTrainingsSelect = (trainings: TrainingShortResponse[]) => {
    const newItems = trainings.map((training) =>
      convertServerPathTrainingToClientPathTraining(training),
    );

    handleOnContentFormClose(newItems);
  };

  const handleOnEventsSelectSubmit = (events: EventShortResponse[]) => {
    const newItems = events.map((event) =>
      convertServerPathEventToClientPathEvent(event),
    );

    handleOnContentFormClose(newItems);
  };

  const handleOnContentFormClose = (newItems: PathItemSchemaType[]) => {
    handleOnModalClose();

    let currentItemsLength = items.length;

    if (editedItem && newItems.length !== 0) {
      const index = items.findIndex(
        (item) => item.data.id === editedItem?.data.id,
      );

      const itemsCopy = [...items];
      itemsCopy.splice(index, 1, {
        ...newItems[0],
        data: {
          id: editedItem.data.id,
          ...newItems[0].data,
        },
        position: editedItem?.position,
      });

      itemsMethods.replace(itemsCopy);
    } else {
      itemsMethods.append(
        // Assign position to new items
        newItems.map((item) => ({
          ...item,
          position: ++currentItemsLength,
        })),
      );
    }
  };

  const handleOnPathItemRemove = (position: number) => {
    // Remove item
    const newItems = items.filter((item) => item.position !== position);

    itemsMethods.replace(
      // Update items position
      newItems.map((item, index) => ({
        ...item,
        position: index + 1,
      })),
    );
  };

  const handleOnPathItemEdit = (item: PathItemSchemaType) => {
    setEditedItem(item);
    setOpenedContentModal(item.type);
  };

  const handleOnDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const newItems = [...items];
    // Remove item from prev index
    const [removed] = newItems.splice(source.index, 1);
    // Add item to new position
    newItems.splice(destination.index, 0, removed);

    itemsMethods.replace(
      // Update items position
      newItems.map((item, index) => ({
        ...item,
        position: index + 1,
      })),
    );
  };

  return (
    <Stack className="content-section" spacing="1.5rem">
      <Typography className="content-section-title">3. Add Content</Typography>

      <Stack className="content-section-options" spacing="1.5rem">
        {CONTENT_OPTIONS.map((option) => (
          <ContentSectionOption
            key={option.label}
            onClick={handleOnOptionClick}
            {...option}
          />
        ))}
      </Stack>

      {items.length > 0 && (
        <Stack className="content-section-list-container" spacing="1rem">
          <Typography>
            Added content (reorder content by drag and drop):
          </Typography>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable key="path-content-list" droppableId="path-content-list">
              {(provided) => (
                <Stack
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="content-section-list"
                  spacing="1rem"
                >
                  {items.map((item, index) => (
                    <Draggable
                      key={item.position!.toString()}
                      draggableId={item.position!.toString()}
                      index={index}
                    >
                      {(draggableProvided) => (
                        <PathItemCard
                          draggableProvided={draggableProvided}
                          item={item}
                          onRemove={handleOnPathItemRemove}
                          onEdit={() => {
                            handleOnPathItemEdit(item);
                          }}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </Stack>
      )}
      <BaseModal
        open={openedContentMoodal === PathItemTypes.TRAINING}
        onClose={handleOnModalClose}
        alignment={ModalAlignments.RIGHT}
        showBlur={false}
      >
        <TrainingsSelect
          title={TRAINING_LABELS.ADD_TRAININGS}
          submitBtnText={TRAINING_LABELS.ADD_TRAININGS}
          onSubmit={handleOnTrainingsSelect}
          trainingIdsToHide={trainingItemIds}
        />
      </BaseModal>
      <BaseModal
        open={openedContentMoodal === PathItemTypes.EVENT}
        onClose={handleOnModalClose}
        alignment={ModalAlignments.RIGHT}
        showBlur={false}
      >
        <EventsSelect
          title={ADD_EVENT_MODAL_LABELS.ADD_EVENTS}
          submitBtnText={ADD_EVENT_MODAL_LABELS.ADD_EVENTS}
          onSubmit={handleOnEventsSelectSubmit}
          eventIdsToHide={eventItemIds}
        />
      </BaseModal>
      <BaseModal
        open={openedContentMoodal === PathItemTypes.LINK}
        onClose={handleOnModalClose}
        alignment={ModalAlignments.RIGHT}
        showBlur={false}
      >
        <LinkForm
          initialValues={editedItem}
          onClose={handleOnContentFormClose}
        />
      </BaseModal>
      <BaseModal
        open={openedContentMoodal === PathItemTypes.FILE}
        onClose={handleOnModalClose}
        alignment={ModalAlignments.RIGHT}
        showBlur={false}
      >
        <FileForm
          initialValues={editedItem}
          onClose={handleOnContentFormClose}
        />
      </BaseModal>
      <BaseModal
        open={openedContentMoodal === PathItemTypes.MEETING}
        onClose={handleOnModalClose}
        alignment={ModalAlignments.RIGHT}
        showBlur={false}
      >
        <MeetingForm
          initialValues={editedItem}
          onClose={handleOnContentFormClose}
        />
      </BaseModal>
    </Stack>
  );
};

export default ContentSection;
