import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Stack, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getGroup } from './actions';
import GroupMemberCard from './GroupMemberCard/GroupMemberCard';
import GroupTrainings from './GroupTrainings/GroupTrainings';
import { ADD_GROUP_MODAL_LABELS } from '../../../../../../../cms';
import AddMembersToGroup from './AddMembersToGroup/AddMembersToGroup';
import { BaseModal } from '../../../../../../../common/components';
import './GroupDetails.scss';

export default function GroupDetails({ groupId }) {
  const dispatch = useDispatch();
  const {
    group,
    editSuccess,
    addMembersSuccess,
    assignGroupTrainingsSuccess,
    removeMemberSuccess,
    removeTrainingSuccess,
  } = useSelector((state) => state.groups);

  const [addMembersModalVisible, setAddMembersModalVisible] = useState(false);

  useEffect(() => {
    dispatch(getGroup(groupId));
  }, []);

  useEffect(() => {
    if (
      editSuccess ||
      addMembersSuccess ||
      assignGroupTrainingsSuccess ||
      removeMemberSuccess ||
      removeTrainingSuccess
    )
      dispatch(getGroup(groupId));
  }, [
    editSuccess,
    addMembersSuccess,
    assignGroupTrainingsSuccess,
    removeMemberSuccess,
    removeTrainingSuccess,
  ]);

  const openAddMembersModal = () => {
    setAddMembersModalVisible(true);
  };

  const closeAddMembersModal = () => {
    setAddMembersModalVisible(false);
  };

  return (
    <>
      {groupId === group.id && (
        <Stack spacing="2rem" paddingTop="1rem">
          {group.members && (
            <div className="col-12 pl-0 w-100">
              <Stack
                direction="row"
                spacing="1rem"
                alignItems="center"
                flexWrap="wrap"
              >
                <Typography className="group-members-count">
                  Employees: {group.members.length}
                </Typography>
                <Button
                  className="add-members-to-group-btn"
                  onClick={openAddMembersModal}
                  startIcon={<AddCircleIcon />}
                >
                  {ADD_GROUP_MODAL_LABELS.ADD_EMPLOYEE}
                </Button>
              </Stack>
              <GroupMemberCard
                groupmembers={group.members}
                groupId={group.id}
              />
              <BaseModal
                open={addMembersModalVisible}
                onClose={closeAddMembersModal}
              >
                <AddMembersToGroup
                  onClose={closeAddMembersModal}
                  group={group}
                />
              </BaseModal>
            </div>
          )}
          {group.trainings && (
            <div className="col-12 pl-0 w-100">
              <GroupTrainings group={group} />
            </div>
          )}
        </Stack>
      )}
    </>
  );
}
