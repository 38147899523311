import dayjs from 'dayjs';
import { z } from 'zod';
import {
  APPLICATIONS_LIMITED_OPTIONS,
  COST_OPTIONS,
  VALIDATION_MESSAGES,
} from '..';
import { LOCATION_TYPES, SkillTypes } from '../enums';

export const AgendaEventItemSchema = z
  .object({
    index: z.number().optional(),
    agendaTopic: z.string().min(1, {
      message: VALIDATION_MESSAGES.REQUIRED,
    }),
    date: z.coerce.date().refine((field) => field !== null, {
      message: VALIDATION_MESSAGES.REQUIRED,
    }),
    startTime: z.coerce.date().refine((field) => field !== null, {
      message: VALIDATION_MESSAGES.REQUIRED,
    }),
    endTime: z.coerce.date().refine((field) => field !== null, {
      message: VALIDATION_MESSAGES.REQUIRED,
    }),
  })
  .refine(
    ({ startTime, endTime }) => {
      if (startTime && endTime && !dayjs(endTime).isAfter(startTime, 'minute'))
        return false;

      return true;
    },
    {
      message: VALIDATION_MESSAGES.AFTER_START_TIME,
      path: ['endTime'],
    },
  );
export type AgendaEventItemSchemaType = z.infer<typeof AgendaEventItemSchema>;

export const AgendaItemSchema = z.object({
  eventItems: z.array(AgendaEventItemSchema),
  date: z.coerce.date(),
});
export type AgendaItemSchemaType = z.infer<typeof AgendaItemSchema>;

export const SpeakerSchema = z.object({
  index: z.number().optional(),
  firstName: z.string().min(1, {
    message: VALIDATION_MESSAGES.REQUIRED,
  }),
  lastName: z.string().min(1, {
    message: VALIDATION_MESSAGES.REQUIRED,
  }),
  jobTitle: z.string().nullable(),
  company: z.string().nullable(),
  photoUrl: z.string().nullable(),
});
export type SpeakerSchemaType = z.infer<typeof SpeakerSchema>;

export const EventSchema = z
  .object({
    id: z.number().optional(),
    title: z.string().min(1, {
      message: VALIDATION_MESSAGES.REQUIRED,
    }),
    startDate: z.coerce.date().refine((field) => field !== null, {
      message: VALIDATION_MESSAGES.REQUIRED,
    }),
    endDate: z.coerce.date().refine((field) => field !== null, {
      message: VALIDATION_MESSAGES.REQUIRED,
    }),
    applyBeforeDate: z.coerce.date().refine((field) => field !== null, {
      message: VALIDATION_MESSAGES.REQUIRED,
    }),
    location: z.enum(LOCATION_TYPES),
    eventLocation: z.string(),
    applicationsLimitOption: z.enum(APPLICATIONS_LIMITED_OPTIONS),
    applicationsLimit: z.coerce.number().int({
      message: VALIDATION_MESSAGES.WHOLE_NUMBER,
    }),
    costOption: z.enum(COST_OPTIONS),
    cost: z.coerce.number(),
    type: z.nativeEnum(SkillTypes),
    url: z.string().url({ message: VALIDATION_MESSAGES.VALID_LINK }),
    description: z.string(),
    agenda: z.array(AgendaItemSchema),
    speakers: z.array(SpeakerSchema),
  })
  .refine(
    ({ startDate, endDate }) => {
      if (startDate && endDate && dayjs(endDate).isBefore(startDate, 'minute'))
        return false;

      return true;
    },
    {
      message: VALIDATION_MESSAGES.AFTER_OR_EQUAL_TO_START_DATE,
      path: ['endDate'],
    },
  )
  .refine(
    ({ startDate, applyBeforeDate }) => {
      if (
        startDate &&
        applyBeforeDate &&
        dayjs(applyBeforeDate).isAfter(startDate, 'minute')
      )
        return false;

      return true;
    },
    {
      message: VALIDATION_MESSAGES.BEFORE_OR_EQUAL_TO_START_DATE,
      path: ['applyBeforeDate'],
    },
  )
  .refine(
    ({ applicationsLimitOption, applicationsLimit }) => {
      if (applicationsLimitOption === 'yes' && applicationsLimit <= 0)
        return false;

      return true;
    },
    {
      message: VALIDATION_MESSAGES.POSITIVE_NUMBER,
      path: ['applicationsLimit'],
    },
  )
  .refine(
    ({ costOption, cost }) => {
      if (costOption === 'paid' && cost <= 0) return false;

      return true;
    },
    {
      message: VALIDATION_MESSAGES.POSITIVE_NUMBER,
      path: ['cost'],
    },
  )
  .refine(
    ({ location, eventLocation }) => {
      if (location !== 'Online' && eventLocation.length === 0) return false;

      return true;
    },
    {
      message: VALIDATION_MESSAGES.REQUIRED,
      path: ['eventLocation'],
    },
  );

export type EventSchemaType = z.infer<typeof EventSchema>;
