import { useState } from 'react';
import { IconButton } from '@mui/material';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { BsThreeDots } from 'react-icons/bs';
import './ModifyGroup.scss';
import { COLORS } from '../../../../../../../cms';
import EditGroup from '../GroupDetails/EditGroup/EditGroup';
import DeleteGroup from '../GroupDetails/DeleteGroup/DeleteGroup';
import CreateGroupForm from '../../CreateGroupButton/CreateGroupForm/CreateGroupForm';
import { BaseModal } from '../../../../../../../common/components';

export default function ModifyGroup({ group, onClick }) {
  const [showEditGroupModal, setShowEditGroupModal] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);

  return (
    <>
      {!showEditGroupModal ? (
        <div className="modify-group" onClick={onClick}>
          <IconButton className="modify-group-dots">
            <OverlayTrigger
              trigger="click"
              key="right"
              placement="top"
              rootClose
              overlay={
                <Popover
                  id="popover-modify-group"
                  className={showDeleteGroupModal ? 'd-none' : null}
                >
                  <Popover.Content>
                    <EditGroup onClick={() => setShowEditGroupModal(true)} />
                    <hr style={{ marginTop: 1.5, marginBottom: 1.5 }} />
                    <DeleteGroup
                      groupId={group.id}
                      onClick={() => setShowDeleteGroupModal(true)}
                      onClose={() => setShowDeleteGroupModal(false)}
                    />
                  </Popover.Content>
                </Popover>
              }
            >
              <BsThreeDots
                size={24}
                className="modify-group-dots-button"
                color={COLORS.SECONDARY}
                onClick={(e) => e.preventDefault()}
              />
            </OverlayTrigger>
          </IconButton>
        </div>
      ) : (
        <BaseModal
          showCloseBtnOutside
          open={showEditGroupModal}
          onClose={() => setShowEditGroupModal(false)}
        >
          <CreateGroupForm
            groupFormData={group}
            onCloseHandler={() => setShowEditGroupModal(false)}
            onClick={onClick}
            editMode
          />
        </BaseModal>
      )}
    </>
  );
}
