import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  BUTTON_LABELS,
  GROUP_MANAGEMENT_LABELS,
} from '../../../../../../../../cms';
import trashIcon from '../../../../../../../../common/images/trash-red.svg';
import { deleteGroup } from './actions';
import './DeleteGroup.scss';
import {
  BaseModal,
  ConfirmAction,
} from '../../../../../../../../common/components';

export default function DeleteGroup({ groupId, onClick, onClose }) {
  const dispatch = useDispatch();

  const [isConfirmDeleteModalOpened, setIsConfirmDeleteModalOpened] =
    useState(false);
  const closeDeleteConfirmationModal = () => {
    setIsConfirmDeleteModalOpened(false);
    onClose();
  };

  const deleteSpecifiedGroup = () => {
    dispatch(deleteGroup([groupId]));
    onClose();
  };

  useEffect(() => {
    return () => {
      onClose();
    };
  }, []);

  return (
    <>
      <div
        className="pointer"
        onClick={() => {
          setIsConfirmDeleteModalOpened(true);
          onClick();
        }}
      >
        <img
          color="#0F596E"
          src={trashIcon}
          alt=""
          width="14px"
          className="inline-block"
        />
        &nbsp;
        <div className="inline-block delete-group-text">
          {GROUP_MANAGEMENT_LABELS.DELETE_GROUP}
        </div>
      </div>
      <BaseModal
        open={isConfirmDeleteModalOpened}
        onClose={closeDeleteConfirmationModal}
        showCloseBtnOutside
      >
        <ConfirmAction
          text={GROUP_MANAGEMENT_LABELS.DELETE_CONFIRMATION_TEXT}
          btnText={BUTTON_LABELS.DELETE_BUTTON}
          onConfirm={deleteSpecifiedGroup}
          onClose={closeDeleteConfirmationModal}
        />
      </BaseModal>
    </>
  );
}
