import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Container,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { LandingPageSections } from '../../cms';
import UpskillPortalLogo from '../UpskillPortalLogo/UpskillPortalLogo';
import { useAppDispatch } from '../../rtk/hooks';
import { openModal } from '../../rtk/features/modalsSlice';
import { ModalTypes } from '../../cms/enums';
import './LandingHeader.scss';

const LandingHeader = () => {
  const dispatch = useAppDispatch();
  const { hash } = useLocation();
  const theme = useTheme();
  const isScreenSM = useMediaQuery(theme.breakpoints.down('md'));
  const [isDrawerOpen, setIsOpenDrawer] = useState(false);
  const isOpen = !isScreenSM || isDrawerOpen;
  const activeHash = hash || `#${LandingPageSections.HOME}`;

  const navLinksElements = Object.values(LandingPageSections).map((navlink) => {
    return (
      <Link
        key={navlink}
        to={`/landing#${navlink}`}
        className={`landing-navbar-links-link ${
          activeHash === `#${navlink}` ? 'active' : ''
        }`}
        onClick={() => {
          setIsOpenDrawer(false);
        }}
      >
        {navlink}
      </Link>
    );
  });

  const handleOpenLogin = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.LOGIN_FORM,
        modalData: null,
        closeBtn: 1,
      }),
    );

    setIsOpenDrawer(false);
  };

  return (
    <AppBar component="header" className="landing-header" position="sticky">
      <Container className="landing-navbar-content">
        <Toolbar
          sx={{
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-start', md: 'center' },
            justifyContent: { xs: 'center', md: 'flex-start' },
            gap: { xs: '1.3rem', md: '2rem' },
          }}
          className="landing-navbar-toolbar"
        >
          <Stack direction="row" spacing="1.3rem" alignItems="center">
            {isScreenSM && (
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={() => setIsOpenDrawer((prev) => !prev)}
                sx={{ padding: 0 }}
              >
                <MenuIcon sx={{ fontSize: 30 }} />
              </IconButton>
            )}

            <Link to="/landing#home" className="landing-navbar-logo">
              <UpskillPortalLogo />
            </Link>
          </Stack>

          {isOpen && (
            <Stack
              className="landing-navbar-links"
              spacing={{ xs: '1.3rem', md: '2rem' }}
              sx={{
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: { xs: 'flex-start', md: 'center' },
                alignItems: 'center',
              }}
              component="nav"
            >
              {navLinksElements}
              <Typography
                className="landing-navbar-links-link landing-navbar-links-login"
                onClick={handleOpenLogin}
              >
                Login
              </Typography>
            </Stack>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default LandingHeader;
