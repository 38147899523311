import { useMemo } from 'react';
import { IconContext } from 'react-icons';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './App.scss';
import config from '../../config';
import { persistor, store } from '../../rtk/store';
import MuiThemeProvider from '../MuiThemeProvider/MuiThemeProvider';
import AppRouter from '../AppRouter/AppRouter';

function App() {
  const iconContextValue = useMemo(
    () => ({
      color: 'inherit',
    }),
    [],
  );

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <MuiThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <IconContext.Provider value={iconContextValue}>
              <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
                <AppRouter />
              </GoogleOAuthProvider>
            </IconContext.Provider>
          </LocalizationProvider>
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
