import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Checkbox } from '@mui/material';
import { DATE_FORMATS, SUBMISSIONS_LABELS } from '../../../../cms';
import ApproveDemoRequest from './ApproveDemoRequest/ApproveDemoRequest';
import RejectDemoRequest from './RejectDemoRequest/RejectDemoRequest';
import { ApplicationStatusChip } from '../../../../common/components';
import { ApplicationStatuses } from '../../../../cms/enums';

export default function DemoRequestsTableRow(props: {
  row: any;
  addSelectedDemoRequestsIdsHandler: any;
  removeSelectedDemoRequestsIdsHandler: any;
}) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (checked) {
      props.addSelectedDemoRequestsIdsHandler(row.externalId);
    } else {
      props.removeSelectedDemoRequestsIdsHandler(row.externalId);
    }
  }, [checked]);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          <div className="row">
            <div className="checkbox">
              <Checkbox
                size="small"
                checked={checked}
                onChange={handleChange}
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
                color="default"
              />
            </div>
            <div className="demo-request-full-name">{row.fullName}</div>
          </div>
        </TableCell>
        <TableCell align="left">{row.companyName}</TableCell>
        <TableCell align="left">
          {dayjs(row.createdOn).format(DATE_FORMATS.FULL)}
        </TableCell>
        <TableCell align="left">
          <ApplicationStatusChip
            status={row.status}
            className="application-status"
          />
        </TableCell>
        <TableCell>
          <div className="row">
            {row.status === ApplicationStatuses.PENDING && (
              <div>
                <ApproveDemoRequest id={row.externalId} />
                <RejectDemoRequest id={row.externalId} />
              </div>
            )}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="row">
                <div className="ml-5">
                  <Typography
                    gutterBottom
                    component="div"
                    className="detail-information"
                  >
                    {SUBMISSIONS_LABELS.DETAIL_INFORMATION}
                  </Typography>
                  <Typography gutterBottom component="div" className="row">
                    <div className="mr-2 column">
                      {SUBMISSIONS_LABELS.WORK_EMAIL}
                    </div>{' '}
                    {row.workEmail}
                  </Typography>
                  <Typography gutterBottom component="div" className="row">
                    <div className="mr-2 column">
                      {SUBMISSIONS_LABELS.ROLE_IN_THE_COMPANY}
                    </div>{' '}
                    {row.roleInCompany}
                  </Typography>
                  <Typography gutterBottom component="div" className="row">
                    <div className="mr-2 column">
                      {SUBMISSIONS_LABELS.COMPANY_SIZE}
                    </div>{' '}
                    {row.companySize}
                  </Typography>
                  <Typography gutterBottom component="div" className="row">
                    <div className="mr-2 column">
                      {SUBMISSIONS_LABELS.PHONE_NUMBER}
                    </div>{' '}
                    {row.phoneNumber}
                  </Typography>
                </div>
                {row.status === ApplicationStatuses.REJECTED && (
                  <div className="ml-5 verticalLine" />
                )}
                {row.status === ApplicationStatuses.REJECTED && (
                  <div>
                    <Typography gutterBottom component="div" className="ml-5">
                      <div className="mr-2 pl-2 rejected-color">
                        {SUBMISSIONS_LABELS.REASON_OF_REJECTION}
                      </div>
                      <div className="pl-2 mt-1 comment">{row.comment}</div>
                    </Typography>
                  </div>
                )}
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
