import { FC } from 'react';

import './ContactFormSuccess.scss';
import { Stack } from '@mui/material';

const ContactFormSuccess: FC = () => (
  <Stack justifyContent="center" alignItems="center" padding="1rem">
    <h4>Thank You!</h4>
    <p>Your message has been sent successfully.</p>
    <p>One of our colleagues will get back to you soon.</p>
  </Stack>
);

export default ContactFormSuccess;
