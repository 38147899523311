import { unionBy, isEqual } from 'lodash';
import {
  APPROVE_DEMO_REQUEST_URL,
  CREATE_DEMO_REQUEST_URL,
  DELETE_DEMO_REQUESTS_URL,
  GET_ALL_DEMO_REQUESTS_URL,
  REJECT_DEMO_REQUEST_URL,
} from '../../api/trainingsPortalBackend/config';
import { DemoRequestResponseState } from '../../cms/types';

import { api } from './api';

export type SearchParams = {
  pageNumber: number;
  pageSize: number;
  sortType?: string;
  sortOrder?: string;
  dateFrom?: Date | null;
  dateTo?: Date | null;
  status?: string;
  search?: string;
  refetch: number;
};

export type CreateDemoRequestArgs = {
  firstName: string;
  lastName: string;
  companyName: string;
  companySize: string;
  workEmail: string;
  roleInCompany: string;
  phoneNumber: string;
};

export type DemoRequestApplicationArgs = {
  applicationId: string;
  comment: string;
};

export type DeleteDemoRequestsArgs = {
  applicationIds: string[];
};

export const demoRequestApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDemoRequests: build.query<
      { totalCount: number; demoRequests: DemoRequestResponseState[] },
      SearchParams
    >({
      query: (args) => ({
        url: GET_ALL_DEMO_REQUESTS_URL,
        params: args,
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCacheData, responseData, { arg }) => {
        if (arg?.pageNumber === 1) return responseData;

        return {
          totalCount: responseData.totalCount,
          // Combine both arrays and skip duplicates
          demoRequests: unionBy(
            currentCacheData.demoRequests,
            responseData.demoRequests,
            'externalId',
          ),
        };
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        isEqual(currentArg, previousArg),
    }),
    createDemoRequest: build.mutation<any, CreateDemoRequestArgs>({
      query: (model) => ({
        url: CREATE_DEMO_REQUEST_URL,
        method: 'POST',
        body: model,
      }),
    }),
    approveDemoRequest: build.mutation<any, DemoRequestApplicationArgs>({
      query: (model) => ({
        url: APPROVE_DEMO_REQUEST_URL,
        method: 'POST',
        body: model,
      }),
    }),
    rejectDemoRequest: build.mutation<any, DemoRequestApplicationArgs>({
      query: (model) => ({
        url: REJECT_DEMO_REQUEST_URL,
        method: 'POST',
        body: model,
      }),
    }),
    deleteDemoRequest: build.mutation<any, DeleteDemoRequestsArgs>({
      query: (model) => ({
        url: DELETE_DEMO_REQUESTS_URL,
        method: 'POST',
        body: model,
      }),
    }),
  }),
});

export const {
  useGetDemoRequestsQuery,
  useCreateDemoRequestMutation,
  useApproveDemoRequestMutation,
  useRejectDemoRequestMutation,
  useDeleteDemoRequestMutation,
} = demoRequestApi;
