import { ReactNode } from 'react';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  lighten,
  CssBaseline,
} from '@mui/material';
import { COLORS, muiNoWrap } from '../../cms';

type MuiThemeProviderProps = {
  children: ReactNode;
};

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY,
    },
    secondary: {
      main: COLORS.SECONDARY,
    },
    error: {
      main: COLORS.ERROR,
    },
    success: {
      main: COLORS.SUCCESS,
    },
    warning: {
      main: COLORS.WARNING,
    },
    divider: COLORS.DIVIDER,
    text: {
      primary: COLORS.TEXT,
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          height: 36,
          padding: '0.5rem 1rem',
          borderRadius: '2rem',
          fontFamily: 'OpenSans',
          fontSize: '12px',
          fontWeight: 'normal',
          ...(ownerState.color === 'secondary' && {
            color: COLORS.WHITE,
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
              color: COLORS.WHITE,
            },
          }),
          ...(ownerState.color === 'error' && {
            '&:hover': {
              backgroundColor: theme.palette.error.main,
            },
          }),
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: '6px',
          fontFamily: 'OpenSans',
          fontSize: '12px',
          fontWeight: 'normal',
          padding: '0.5rem 1.5rem',
          height: 37,
          ':disabled': {
            fontWeight: 'normal',
          },
          ...(ownerState.variant === 'text' && {
            '&:hover': {
              backgroundColor: COLORS.WHITE_SMOKE,
            },
          }),
          ...(ownerState.color === 'primary' &&
            ownerState.variant === 'contained' && {
              '&:hover': {
                backgroundColor: lighten(theme.palette.primary.main, 0.2),
              },
            }),
          ...(ownerState.color === 'primary' &&
            ownerState.variant === 'outlined' && {
              '&:hover': {
                backgroundColor: lighten(theme.palette.primary.main, 0.8),
              },
            }),
          ...(ownerState.color === 'secondary' &&
            ownerState.variant === 'contained' && {
              '&:hover': {
                backgroundColor: lighten(theme.palette.secondary.main, 0.2),
              },
            }),
          ...(ownerState.color === 'secondary' &&
            ownerState.variant === 'outlined' && {
              '&:hover': {
                backgroundColor: lighten(theme.palette.secondary.main, 0.8),
              },
            }),
          ...(ownerState.color === 'success' &&
            ownerState.variant === 'contained' && {
              color: COLORS.WHITE,
              '&:hover': {
                backgroundColor: lighten(theme.palette.success.main, 0.2),
              },
            }),
          ...(ownerState.color === 'success' &&
            ownerState.variant === 'outlined' && {
              '&:hover': {
                backgroundColor: lighten(theme.palette.success.main, 0.8),
              },
            }),
          ...(ownerState.color === 'error' &&
            ownerState.variant === 'contained' && {
              '&:hover': {
                backgroundColor: lighten(theme.palette.error.main, 0.2),
              },
            }),
          ...(ownerState.color === 'error' &&
            ownerState.variant === 'outlined' && {
              '&:hover': {
                backgroundColor: lighten(theme.palette.error.main, 0.8),
              },
            }),
        }),
      },
    },
    MuiFab: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.color === 'primary' && {
            '&:hover': {
              backgroundColor: lighten(theme.palette.primary.main, 0.2),
            },
          }),
          fontSize: 24,
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: () => ({
          color: COLORS.SECONDARY,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        noWrap: muiNoWrap,
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: () => ({
          textTransform: 'none',
          fontWeight: 'normal',
          fontFamily: 'OpenSans',
          '&.Mui-selected': {
            fontWeight: 'bold',
          },
        }),
      },
    },
    MuiStack: {
      defaultProps: {
        useFlexGap: true, // Respect spacing (negative margins) of Grids
        sx: {
          minWidth: 0,
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          display: 'flex !important',
          gap: '0.25rem',
          '& .MuiFormLabel-asterisk': {
            order: -1,
            color: COLORS.ERROR,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: () => ({
          fontSize: 'inherit',
        }),
      },
    },
  },
});

const MuiThemeProvider = ({ children }: MuiThemeProviderProps) => (
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      {children}
    </StyledEngineProvider>
  </ThemeProvider>
);

export default MuiThemeProvider;
