import { TextField, TextFieldProps } from '@mui/material';
import './CustomTextField.scss';
import clsx from 'clsx';

const CustomTextField = ({
  error,
  InputProps,
  inputProps,
  FormHelperTextProps,
  className,
  value,
  type = 'text',
  ...rest
}: TextFieldProps) => (
  <TextField
    {...rest}
    variant="standard"
    error={Boolean(error)}
    InputLabelProps={{ shrink: true, className: 'custom-text-field-label' }}
    className={clsx(
      'custom-text-field',
      type === 'number' && 'custom-number-field',
      className,
      inputProps?.className && `${inputProps.className}-container`,
    )}
    InputProps={{
      ...InputProps,
      className: `custom-text-field-input-container${error ? '-error' : ''}`,
      disableUnderline: true,
      type,
    }}
    inputProps={{
      ...inputProps,
      className: clsx('custom-text-field-input', inputProps?.className),
    }}
    FormHelperTextProps={{
      ...FormHelperTextProps,
      className: clsx(
        'custom-text-field-helper-text',
        inputProps?.className && `${inputProps?.className}-helper`,
      ),
    }}
    onFocus={(event) => {
      if (type === 'number') event.target.select();
    }}
    value={value ?? ''}
  />
);

export default CustomTextField;
