import { useEffect } from 'react';
import { Container, Stack } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { ADMIN_NAV_LABELS, ROLES, ROUTE_LINKS } from '../../cms';
import { getUserRoles } from '../../common/userRoleRetriever';
import { TabState } from '../../cms/types';
import { CustomTabs } from '../../common/components';

export default function AdministrationPage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isSuperAdmin = getUserRoles().includes(ROLES.SUPER_ADMIN_ROLE);

  const tabs: TabState[] = [
    {
      label: ADMIN_NAV_LABELS.APPLICATIONS_TEXT,
      link: ROUTE_LINKS.TRAINING_APPLICATIONS,
    },
    {
      label: ADMIN_NAV_LABELS.EVENT_APPLICATIONS,
      link: ROUTE_LINKS.EVENT_APPLICATIONS,
    },
    {
      label: ADMIN_NAV_LABELS.GROUP_MANAGEMENT_TEXT,
      link: ROUTE_LINKS.GROUP_MANAGEMENT,
    },
    {
      label: ADMIN_NAV_LABELS.EMPLOYEE_MANAGEMENT_TEXT,
      link: ROUTE_LINKS.EMPLOYEE_MANAGEMENT,
    },
    {
      label: ADMIN_NAV_LABELS.REPORTS,
      link: ROUTE_LINKS.REPORTS,
    },
    {
      label: ADMIN_NAV_LABELS.SUBMISSIONS,
      link: ROUTE_LINKS.SUBMISSIONS,
      hide: !isSuperAdmin,
    },
  ];

  useEffect(() => {
    if (
      !pathname.includes(ROUTE_LINKS.TRAINING_APPLICATIONS) &&
      !pathname.includes(ROUTE_LINKS.EVENT_APPLICATIONS) &&
      !pathname.includes(ROUTE_LINKS.GROUP_MANAGEMENT) &&
      !pathname.includes(ROUTE_LINKS.EMPLOYEE_MANAGEMENT) &&
      !pathname.includes(ROUTE_LINKS.REPORTS) &&
      !pathname.includes(ROUTE_LINKS.SUBMISSIONS)
    )
      navigate(ROUTE_LINKS.TRAINING_APPLICATIONS, { replace: true });
  }, [pathname]);

  if (pathname === ROUTE_LINKS.ADMINISTRATION) return null;

  return (
    <Container maxWidth="lg">
      <Stack
        className="administration-page"
        spacing="1rem"
        padding={{ xs: '1rem', sm: '2rem' }}
      >
        <CustomTabs
          tabs={tabs}
          value={pathname}
          divider
          className="administration-page-tabs"
          sx={{
            marginBottom: { xs: 0, sm: '1rem' },
          }}
        />
        <Outlet />
      </Stack>
    </Container>
  );
}
