import { FC, useState } from 'react';
import dayjs from 'dayjs';
import {
  Button,
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { AllEmployeesProps } from '../EmployeesTable';
import {
  BUTTON_LABELS,
  DATE_FORMATS,
  EMPLOYEES_MANAGEMENT_LABELS,
  ENVIRONMENT_NAMES,
  ROLES,
} from '../../../../../../cms';
import './EmployeesTableRow.scss';
import config from '../../../../../../config';
import { getUserRoles } from '../../../../../../common/userRoleRetriever';

export interface EmployeesState {
  editSuccess: boolean;
}

export interface RootState {
  allEmployees: EmployeesState;
}

interface EmployeesTableRowProps {
  row: AllEmployeesProps;
  handleDeactivateEmployees: Function;
}

const EmployeesTableRow: FC<EmployeesTableRowProps> = (props) => {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'none !important' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row.fullName}</TableCell>
        <TableCell align="left">{row.email}</TableCell>
        <TableCell align="left">
          {row.resignedOn !== null
            ? dayjs(row.resignedOn).format(DATE_FORMATS.SIMPLE_SLASH)
            : 'N/A'}
        </TableCell>
        <TableCell>
          {row.isDisabled ? (
            <span className="employee-deactivated">Deactivated</span>
          ) : (
            <span className="employee-active">Active</span>
          )}
        </TableCell>
        <TableCell>
          {!row.isDisabled && (
            <Button
              sx={{
                color: 'red',
                textTransform: 'none',
                fontSize: 13,
              }}
              disabled={
                config.ENVIRONMENT === ENVIRONMENT_NAMES.TRIAL &&
                !getUserRoles().includes(ROLES.SUPER_ADMIN_ROLE)
              }
              onClick={() => props.handleDeactivateEmployees(row.id)}
            >
              {BUTTON_LABELS.DEACTIVATE}
            </Button>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="row">
                <div className="ml-5">
                  <Typography
                    gutterBottom
                    component="div"
                    className="detail-information"
                  >
                    {EMPLOYEES_MANAGEMENT_LABELS.DETAIL_INFORMATION}
                  </Typography>
                  <Typography gutterBottom component="div" className="row">
                    <div className="mr-2 p-0 column">
                      {`${EMPLOYEES_MANAGEMENT_LABELS.MANAGER_EMAIL}:`}
                    </div>
                    <div>{row.managerEmail ? row.managerEmail : 'N/A'}</div>
                  </Typography>
                  <Typography gutterBottom component="div" className="row">
                    <div className="mr-2 p-0 column">
                      {`${EMPLOYEES_MANAGEMENT_LABELS.DEPARTMENT}:`}
                    </div>
                    <div>{row.department ? row.department : 'N/A'}</div>
                  </Typography>
                  <Typography gutterBottom component="div" className="row">
                    <div className="mr-2 p-0 column">
                      {`${EMPLOYEES_MANAGEMENT_LABELS.ROLE_IN_THE_COMPANY}:`}
                    </div>
                    <div>{row.jobTitle ? row.jobTitle : 'N/A'}</div>
                  </Typography>
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default EmployeesTableRow;
