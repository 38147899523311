import aboutUsImage from '../../common/images/about-us.png';
import PolicySection from '../../common/components/PolicySection/PolicySection';

const TermsOfServicePage = () => {
  return (
    <div className="terms-of-service-page policy-page">
      <header>
        <img src={aboutUsImage} alt="Terms of Service" />

        <h1>Terms of Service</h1>
      </header>

      <PolicySection heading="Introduction">
        <p>
          Welcome to Upskill Portal. Here is a quick summary of the highlights
          of our <strong>Terms of Service</strong>:
        </p>

        <ul>
          <li>
            <strong>
              Our mission is to help organizations and individuals improve
              Quality and Productivity.
            </strong>
            The Upskill Portal offers a great solution for building up people’s
            skills and track their carrier paths in a single holistic
            environment.
          </li>
          <li>
            <strong>You agree to follow the rules of our Portal</strong>. When
            you use the Portal, you also agree to our Terms of Service and
            accept our Privacy Notice.
          </li>
          <li>
            <strong>
              Please contact us to give feedback and report complaints.
            </strong>{' '}
            If you think someone has violated intellectual property rights,
            other laws, or Strypes Group’s Policies, send an email to{' '}
            <a href="mailto:privacy@strypes.eu">privacy@strypes.eu.</a>
          </li>
        </ul>

        <p>
          <strong>
            We are pleased that you want to use the Upskill Portal and encourage
            you to read the full Terms of Service.
          </strong>
        </p>

        <p>
          Upskill Portal is created and owned by the professionals of Strypes
          EOOD. Strypes EOOD is a lleading provider of end-to-end software
          solutions with 400+ highly skilled developers, we offer our clients
          solutions in the fields of: IT infrastructure management, consultancy,
          remote diagnostics, monitoring and predictive maintenance
          applications, application development, modernization & management,
          mobility and transportation and digital transformation.
          <br />
          Upskill Portal aims to help Companies to provide its employees with a
          solution for building clear career paths, enhancing their professional
          skills and easily tracking their progress towards their career goals.
          <br />
          These terms of service (“Terms of Service”) sets forth the agreement
          (“Agreement”) between you and Upskill Portal(Strypes EOOD),
          hereinafter referred as“Upskill Portal” “we” or “us”. It governs your
          use of the products and services we offer through our website and
          application (collectively the “Upskill Portal”).
        </p>

        <p>
          Please make sure to read it, because, by using the Upskill Portal, you
          consent to these terms.
        </p>

        <ul>
          <li>
            The Mission of the Upskill Portal. Upskill Portal’s mission is to
            help organizations and individuals improve Quality and Productivity.
            The Upskill Portal offers one space for your workforce to Upskill
            Portal and track their own career path goals.
          </li>
          <li>
            Using the Upskill Portal. Who Can Use It. Anyone whose employer
            purchases a plan to use the Upskill Portal.
          </li>
          <li>
            Privacy Policy. Our privacy practices are set forth in our Privacy
            Policy. By use of the Upskill Portal, you agree to accept our
            Privacy Policy.
          </li>
        </ul>
      </PolicySection>

      <PolicySection heading="Term and Termination">
        <p>
          <strong>1. Term.</strong> This Agreement will remain in effect (a) for
          as long as you have an active subscription for the Service or (b) if
          you elect to use the Service for a free trial period, and do not
          purchase a subscription before the end of such period, until the end
          of the free trial period (the "Term").
        </p>
        <p>
          <strong>2. Start Date and Renewal.</strong> Subscriptions purchased by
          you commence on the start date specified when you complete the initial
          payment process and continue until terminated. Subscriptions
          automatically renew. If you would like a separate instance of the
          Service for an affiliate, that affiliate must purchase its own
          subscription to the Service and separately accept this Agreement. 3.
          Cancellation. You are solely responsible for the proper cancellation
          of your subscription. You may cancel your subscription at any time by
          contacting us at{' '}
          <a href="mailto:training-portal@strypes.eu">
            training-portal@strypes.eu.
          </a>
        </p>
        <p>
          <strong>4. Suspension and Termination of Service.</strong> Upskill
          Portal may suspend your access to the Service and terminate this
          Agreement and your use of the Service at any time in the event you
          materially breach this Agreement and you do not cure such breach
          within 30 days of Upskill Portal providing you with written notice of
          such breach (including notice by email), or earlier if a specific
          subscription or Additional Terms provides otherwise. Notwithstanding
          the foregoing, Upskill Portal may immediately suspend or terminate
          your access to the Service without liability if you are in violation
          of Sections 1 of “Your rights”, or Section “Compliance with Laws;
          Disclaimers” of this Agreement, as determined by Upskill Portal in its
          sole discretion.In the event you cancel one or more of your
          subscriptions or this Agreement is terminated by Upskill Portal or
          you, Upskill Portal will refund to you any prepaid fees that would
          have covered any future months of your month-to-month subscription
          after the effective date of termination for all such subscriptions,
          except that no refunds will be granted for the then-current month.
          Notice via email from Upskill Portal will be sent to you at the email
          address you have provided to us. Upskill Portal reserves the right to
          manage its client profile, the risks it will assume, the industries it
          will serve, and the locations where it will do business, including
          choosing to not provide services to certain groups, parties,
          industries, companies, or in certain countries, in its sole
          discretion.
        </p>

        <p>
          <strong>5. Data Downloads and Deletion.</strong> In the event your
          subscription is terminated, other than in instances where it is
          terminated by Upskill Portal for your nonpayment or violation of
          Sections “Your Rights or “Compliance with laws”, you will continue to
          have the ability to download the information provided, inputted, or
          uploaded to your databases in the Upskill Portal Service by you or on
          your behalf (“Data”) for 30 days after the effective date of
          expiration or termination of your subscription. After such 30-day
          period or if your subscription is terminated due to your nonpayment or
          violation of Sections “Your Rights or “Compliance with laws”, Upskill
          Portal shall have no obligation to maintain any Data and shall
          thereafter, unless legally prohibited to do so, or required pursuant
          to Additional Terms, delete all of your Data contained in the Upskill
          Portal Service.
        </p>
      </PolicySection>

      <PolicySection heading="Changes to the Upskill Portal.">
        <p>
          We are always trying to improve your experience on the Upskill Portal.
          We may need to add or change features and we will notify you upfront
          or upon the change is done, depending on the change.
        </p>

        <p>
          We welcome your feedback and suggestions about how to improve the
          Upskill Portal. Feel free to submit feedback through our website By
          submitting feedback, you agree to grant us the right, at our
          discretion, to use, disclose and otherwise exploit the feedback, in
          whole or part, freely and without compensation to you.
        </p>
      </PolicySection>

      <PolicySection heading="Your Rights">
        <p>
          During the Term, Upskill Portal grants you a limited, revocable,
          non-transferable, non-sublicensable, non-exclusive right to access and
          use the hosted software products and related documentation included in
          the Service and all modifications and/or enhancements to any of the
          foregoing via a web browser or other device owned or controlled by you
          for your internal business use. Nothing in this Agreement obligates
          Upskill Portal to deliver or make available to you any copies of
          computer programs or any of the software used to provide the Service
          (the “Software”), whether in object code or source code form. You
          agree to use the Service, only in compliance with all applicable
          local, state, national, and international laws, rules and regulations
          . You shall not, and shall not agree to, and shall not authorize,
          encourage or permit any third party to use the Service:
        </p>

        <ul>
          <li>
            to upload, transmit or otherwise distribute any content that is
            unlawful, defamatory, harassing, fraudulent, obscene, threatening,
            abusive, hateful, contains viruses, or is otherwise objectionable as
            reasonably determined by Upskill Portal;
          </li>
          <li>
            for any fraudulent or inappropriate purpose, or in a manner for
            which it is not intended to be used;
          </li>
          <li>
            to attempt to decipher, decompile, delete, alter or reverse engineer
            any of the Software;
          </li>
          <li>
            to penetration test, hack or otherwise attempt to test the security
            of the Software or Service;
          </li>
          <li>
            to duplicate, make derivative works of, reproduce or exploit any
            part of the Service without the express written permission of
            Upskill Portal;
          </li>
          <li>
            with any robot, spider, other automated device, or manual process to
            monitor or copy any content from the Service other than copying or
            exporting of the Data as contemplated in the documentation; or
          </li>
          <li>
            to rent, lease, distribute, or resell the Software, or access or use
            the Software or Services for developing a competitive solution (or
            contract with a third party to do so), or remove or alter any of the
            logos, trademark, patent or copyright notices, confidentiality or
            proprietary legends or other notices or markings that are on or in
            the Software or displayed in connection with the Service.
          </li>
        </ul>
      </PolicySection>

      <PolicySection heading="Website Availability">
        <p>
          Upskill Portal Website will be accessible to you via the World Wide
          Web portion of the Internet twenty-four (24) hours a day, seven (7)
          days a week, except for scheduled maintenance and required repairs,
          and except for any loss or interruption of Services due to causes
          beyond the control of Upskill Portal or which are not reasonably
          foreseeable, including, but not limited to, interruption or failure of
          telecommunication or digital transmission links and Internet
          slow-downs or failures.
        </p>
      </PolicySection>

      <PolicySection heading="Modification of Service or this Agreement">
        <p>
          <strong> 1. Levels of Service.</strong> The Service may be made
          available in free or paid versions at different levels. Not all
          features and functionality of the Service may be available in each
          version or level. Upskill Portal reserves the right, in its sole
          discretion, to modify, add, or remove portions and/or functionality of
          the Service on a temporary or permanent basis, without liability to
          you or any third party.{' '}
        </p>

        <p>
          <strong>2. Modification of Agreement.</strong> Upskill Portal may
          modify or update this Agreement at any time for reasons such as, but
          not limited to, (a) accounting for changes in laws that are applicable
          to our Service (b) clarifying any wording or terms (c) accounting for
          new products or services (d) preventing or addressing any abuse of
          Services offered etc. In the event Upskill Portal determines it is
          necessary to make a material modification to this Agreement, you will
          be provided with 30 days’ notice of such change and asked to
          affirmatively agree to such modified version of the Agreement. Failure
          to accept the material modifications may result in termination or
          suspension of your access to the Service. Note, however, that your use
          of the Service after modifications to the Agreement become effective
          constitutes your binding acceptance of such changes.
        </p>
      </PolicySection>

      <PolicySection heading="Privacy and Security Safeguards">
        <p>
          Upskill Portal shall maintain commercially reasonable administrative,
          physical, and technical safeguards for protection of the Service, and
          the security of your Data. Upskill Portal shall not (a) disclose your
          Data for any purpose other than to provide the Service except as
          compelled by Applicable Law or as you expressly authorize in writing
          or as otherwise provided in our Privacy Notice, or (b) access your
          Data except to provide the Service and prevent or address service or
          technical problems, at your express request in connection with
          customer support matters, or as provided in our Privacy Notice. In the
          event Upskill Portal is compelled by Applicable Law to disclose your
          Data, we will provide you with reasonable notice thereof, (in advance,
          if possible) if permitted by Applicable Law.
        </p>
      </PolicySection>
      <PolicySection heading="Your Responsibilities">
        <p>
          1.You are solely responsible for your Data, and all uses of your Data
          that occur through your account or any actions taken by your
          employees, admins, consultants, agents etc. in your account. Access
          credentials are for use only by you and by your authorized users, in
          each case in connection with your use of the Service, and you may not
          sell, transfer, share, or sublicense access credentials to any other
          person, or permit any other person to do so. You shall maintain the
          confidentiality of your access credentials and may not transfer them
          to or allow them to be used by any third party, other than by your
          authorized users in connection with the use of the Services. If
          Upskill Portal believes access credentials have been compromised or
          misused, Upskill Portal may change any or all access credentials or
          suspend your account.
        </p>

        <p>
          2. You acknowledge that you retain administrative control as to who is
          granted access to your account with the Service. Each account is
          controlled by an account owner tied to a specific email address and
          may also have one or more admins and/or billing admins to help manage
          the account. Upskill Portal is entitled to rely on communications from
          the account owner and admins when servicing your account. If a person
          within your organization requests a change to the account owner, we
          will attempt to contact the account owner for consent, but to the
          extent that the account owner does not respond to our communications
          or is otherwise unavailable, we will transfer the account owner based
          on our own internal verification methods and at our sole discretion.
        </p>
      </PolicySection>

      <PolicySection heading="Payment Terms; Automatic Renewal">
        <p>
          <strong>1</strong>{' '}
          <mark>
            YOUR SUBSCRIPTION TO THE SERVICE RENEWS AUTOMATICALLY ON A
            MONTH-TO-MONTH BASIS. IF YOU ARE PAYING BY CREDIT CARD, YOUR CREDIT
            CARD WILL BE CHARGED ONCE A MONTH FOR MONTHLY SUBSCRIPTIONS OR AT
            THE TIME ANY PREPAYMENT HAS BEEN DEPLETED BASED ON YOUR NUMBER OF
            EMPLOYEES AND SUBSCRIPTION TO THE SERVICE.
          </mark>{' '}
          Upskill Portal will email you a receipt when your card has been
          charged. If your card cannot be charged, Upskill Portal will notify
          you and you will need to update your payment information. In the event
          you do not update your payment information within 22 days of Upskill
          Portal’s notice, your access to the Service may be suspended and you
          will need to update your card information in order to resume use of
          the Service. There will be no refunds or credits for partial months of
          service.
        </p>
        <p>
          <strong>2. Pricing Adjustments.</strong> We may offer you certain
          discounts if you prepay your month-to-month subscription. If you
          cancel your subscription, or your subscription is suspended for
          nonpayment, before your prepayment has been depleted, we will refund
          any unearned prepayment fees but you will no longer qualify for a
          prepayment discount and you will be charged the difference between the
          monthly and prepayment discount pricing for the number of months your
          subscription was active during a prepayment period. If you cancel only
          a portion of your subscription, you may still qualify for a prepayment
          discount and the prepaid amounts attributed to that portion will be
          used against other services to which you have subscribed.
        </p>
        <p>
          <strong>3. Fees and Taxes.</strong> All fees are inclusive of taxes or
          duties imposed by governing authorities.
        </p>

        <p>
          <strong>4. Subscription Charge Adjustments.</strong> Upskill Portal
          may at any time, upon notice of at least 60 days, or a longer period
          if required by Applicable Law, change the price of your subscription
          or any part thereof, or institute new charges or fees. If you have
          prepaid your month-to-month subscription service, price changes and
          institution of new charges implemented after your prepayment will go
          into effect for your next monthly subscription term after the 60 days'
          notice and the updated fees will be subtracted from your prepayment.
          If you do not agree to any such price changes, then your sole remedy
          is to cancel your subscription and stop using the Service prior to the
          commencement of the renewal subscription period for which the price
          change applies. You are responsible for initiating the cancellation of
          your subscription to the Service.
        </p>
      </PolicySection>

      <PolicySection heading="Intellectual Property Rights">
        <p>
          <strong>1. Upskill Portal Rights.</strong> As between the parties,
          Upskill Portal owns and shall retain all right, title and interest in
          and to (a) the Software, Service, and Beta Service including all
          intellectual property rights therein, and (b) all operational and
          performance data related to your use of the Service and Beta Service,
          including, without limitation, which features are used, time spent
          using the Service and Beta Service, and similar data and metadata
          created in connection with the Service and Beta Service, together with
          analysis of such operational and performance data and derived
          findings, correlations, discoveries, and other insights or learnings
          derived by Upskill Portal from such analysis (collectively,
          “Performance Data”). Upskill Portal may collect, use, and disclose all
          such Performance Data for its business purposes (such as software use
          optimization, product marketing, industry benchmarking, best practices
          guidance, recommendations, or similar reports for distribution to and
          consumption by you and other Upskill Portal customers and prospects,
          etc.), including by combining and analyzing Performance Data with
          other data. Upskill Portal shall not disclose Performance Data to any
          non-affiliated third party unless such Performance Data has been
          anonymized or de-identified, or is disclosed in aggregated form, in
          all cases such that Performance Data does not reveal your identity,
          any of your confidential information, or any personally identifiable
          information that belongs to you or your employees.
        </p>

        <p>
          <strong>2. Your Rights.</strong> You retain all right, title and
          ownership interest in and to your Data. Upskill Portal has no right,
          title or interest in any personally identifiable information contained
          in or related to your Data. If you are a consultant to the client of
          Upskill Portal and provide services to such client that include or
          involve accessing and/or using the Data, you acknowledge that you do
          not own or have any rights or interests in the Data except as
          authorized by the client of Upskill Portal and that such client is the
          owner of the Data in the Upskill Portal Service. You further
          acknowledge and agree that Upskill Portal may follow the instructions
          of the client regarding the Data including removing your access to the
          Data and/or reassigning your roles and permissions related to the
          Data.
        </p>
      </PolicySection>

      <PolicySection heading="Warranties and Limitation of Liability">
        <p>
          <strong>1. Limited Warranty.</strong> Upskill Portal represents,
          warrants, and covenants as follows: (a) the Service will perform
          substantially in accordance with the specifications generally provided
          by Upskill Portal in connection with the Service; (b) the provision of
          the Service will comply with all privacy and data protection laws
          applicable to our business; (c) we will not sell personal information
          provided by you, and we will retain, disclose, or use personal
          information provided by you only for purposes of providing the
          Service, except as otherwise permitted by applicable law; and (d) any
          professional services performed for you by Upskill Portal will be
          performed in a professional and workmanlike manner, with the degree of
          skill and care that is required by sound professional procedures and
          practices.
        </p>

        <p>
          <strong>2. DISCLAIMER.</strong> TO THE MAXIMUM EXTENT PERMITTED BY
          APPLICABLE LAW, EXCEPT FOR THE LIMITED WARRANTIES PROVIDED ABOVE,
          UPSKILL PORTAL HEREBY DISCLAIMS ALL OTHER WARRANTIES, EITHER EXPRESS
          OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE
          WITH RESPECT TO THE SERVICE, BETA SERVICE, SOFTWARE, DOCUMENTATION,
          DELIVERABLES AND OTHER MATERIALS AND/OR SERVICES. UPSKILL PORTAL DOES
          NOT WARRANT THAT OPERATION OF THE SERVICE WILL BE ERROR-FREE OR
          UNINTERRUPTED.
        </p>

        <p>
          <strong>3. LIMITATION OF LIABILITY.</strong> EXCEPT FOR (I) A PARTY’S
          VIOLATION OF THE OTHER PARTY’S INTELLECTUAL PROPERTY RIGHTS, (II)
          EITHER PARTY’S INDEMNIFICATION OBLIGATIONS HEREUNDER, OR (III) EITHER
          PARTY’S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT, (A) IN NO EVENT WILL
          EITHER PARTY BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, SPECIAL,
          PUNITIVE, OR EXEMPLARY DAMAGES ARISING OUT OF THIS AGREEMENT, AND (B)
          THE AGGREGATE LIABILITY OF EITHER PARTY TO THE OTHER WITH RESPECT TO
          THIS AGREEMENT IS LIMITED, TO THE EXTENT PERMITTED UNDER APPLICABLE
          LAW, TO THE FEES COLLECTED BY OR PAYABLE TO UPSKILL PORTAL FROM YOU
          PURSUANT TO THIS AGREEMENT IN THE TWELVE MONTHS PRECEDING THE EVENT
          FROM WHICH THE LIABILITY AROSE. THE FOREGOING LIMITATIONS DO NOT APPLY
          TO YOUR PAYMENT OBLIGATIONS UNDER THIS AGREEMENT. THE PARTIES
          ACKNOWLEDGE THAT THIS AGREEMENT HAS BEEN ENTERED INTO IN RELIANCE UPON
          THESE LIMITATIONS OF LIABILITY AND THAT ALL SUCH LIMITATIONS FORM AN
          ESSENTIAL BASIS OF THE BARGAIN BETWEEN THE PARTIES.
        </p>
      </PolicySection>

      <PolicySection heading="Security Breach">
        <p>
          <strong>1. External Breach.</strong> In the event of an accidental,
          unauthorized or unlawful destruction, loss, alteration, disclosure of,
          or access to, personal data (a “Security Breach”), that impacts the
          personal data you maintain through the Upskill Portal Service, and
          which is perpetrated by anyone other than your employees, contractors
          or agents, upon discovery of such Security Breach, Upskill Portal
          will: (a) initiate remedial actions that are in compliance with
          Applicable Law and consistent with industry standards; and (b) as
          required by Applicable Law, notify you of the Security Breach, its
          nature and scope, the remedial actions Upskill Portal will undertake,
          and the timeline within which Upskill Portal expects to remedy the
          Security Breach. You will be responsible for fulfilling your
          obligations under Applicable Law.
        </p>
        <p>
          <strong> 2. Internal Breach.</strong> In the event of a Security
          Breach, as defined by Applicable Law, which is perpetrated by your
          affiliate, employee, contractor or agent, or due to your failure to
          maintain your systems, network or Data in a secure manner, you shall
          have sole responsibility for initiating remedial actions and you shall
          notify Upskill Portal immediately of the Security Breach and steps you
          will take to remedy such breach. In our sole discretion, we may take
          any action, including suspension of your access to the Service, to
          prevent harm to you, us, the Service, or other third parties. You
          waive any right to make a claim against us for losses you incur that
          may result from our actions.
        </p>

        <p>
          <strong> 3. Breach Notifications.</strong> In the event that either
          party is required to notify individuals impacted by the Security
          Breach or regulatory agencies, the notifying party will provide the
          other party with an opportunity to review and approve the notification
          for accuracy prior to it being delivered, such approval not to be
          unreasonably withheld.
        </p>
      </PolicySection>

      <PolicySection heading="Indemnification">
        <p>
          1. You agree to indemnify, defend and hold harmless Upskill Portal,
          and its subsidiaries, affiliates, assigns, officers, directors,
          agents, and employees from and against any costs, damages, expenses
          (including reasonable attorneys’ fees), judgments, losses and other
          liabilities (including amounts paid in settlement) (“Liabilities”)
          incurred as a result of any third-party action, claim, demand,
          proceeding or suit (“Claim”) to the extent arising from or in
          connection with (a) your access or use of the Software, Beta Service
          and/or Service in violation of this Agreement, (b) any employment
          decision or action you take due to information available through or
          your use of the Service or relating to the inaccuracy or
          incompleteness of any information you make available to your
          employees, independent contractors or candidates by or through the
          Services, including, without limitation, the inaccuracy or
          incompleteness of any information relating to the actual or proposed
          compensation of, or equity ownership by, such employees, independent
          contractors or candidates, (c) User Content or Data provided by you,
          your end users, or other third parties, (d) your violation or alleged
          violation of any third party right, including without limitation any
          right of privacy or any right provided by labor or employment law, and
          (e) your violation or alleged violation of this Agreement.
        </p>
        <p>
          2. Upskill Portal agrees to indemnify, defend and hold harmless you,
          and your affiliates, officers, agents, and employees from and against
          any Liabilities incurred as a result of any third-party Claim made or
          brought against you alleging that your use of the Software, Beta
          Service, and/or Service in accordance with this Agreement infringes
          the intellectual property rights of a third party. Notwithstanding the
          foregoing, in no event shall Upskill Portal have any obligations or
          liability arising from: (a) use of the Software, Beta Service, and/or
          Service in a modified form or in combination with materials or
          software not furnished by Upskill Portal, (b) any User Content,
          information or Data provided by you, your end users, or other third
          parties, and c) Client’s misuse of the Service due specific actions or
          lack of such.
        </p>
        <p>
          3. A party seeking indemnification hereunder shall (a) promptly notify
          the other party in writing of the Claim, (b) give the indemnifying
          party sole control of the defense of such Claim and all negotiations
          for the compromise or settlement thereof (provided that if any
          settlement requires any action or admission by the indemnified party,
          then the settlement will require the indemnified party’s prior
          consent), and (c) provide the indemnifying party with all reasonable
          cooperation, information and assistance in connection with such Claim;
          provided, however, that failure by the indemnified party to provide
          prompt notice of a Claim, grant such sole control, and/or provide such
          cooperation, information and assistance, shall not relieve the
          indemnifying party of its obligations under this clause, except to the
          extent that the indemnifying party is materially prejudiced by such
          failure. The indemnified party may be represented by its own counsel,
          at its own expense.
        </p>
      </PolicySection>

      <PolicySection heading="Governing Law; Jurisdiction">
        <p>
          1. This Agreement shall be governed by and construed in accordance
          with the substantive laws of Bulgaria. Any legal action or proceeding
          with respect to this Agreement must be brought to the competent court
          in Sofia, Bulgaria.
        </p>
      </PolicySection>

      <PolicySection heading="Compliance with Laws; Disclaimers">
        <p>
          1. Each Party shall comply with all Applicable Laws in connection with
          its own activities under this Agreement. The Service can be configured
          and used in ways that do not comply with Applicable Laws and it is
          your sole responsibility to ensure that your employees’ use and your
          use of the Service, including the Beta Service, complies with and is
          in accordance with Applicable Law. In no event shall Upskill Portal be
          responsible or liable for your failure to comply with Applicable Law
          in connection with your use of the Service.
        </p>
        <p>
          2. Upskill Portal does not provide its customers with legal advice
          regarding compliance, data privacy or other relevant Applicable Laws
          in the jurisdictions in which you use the Service, and any statements
          made by Upskill Portal to you shall not constitute legal advice.
        </p>
        <p>
          3. You acknowledge that Upskill Portal exercises no control over your
          specific human resource practices implemented using the Service or
          your decisions as to employment, promotion, advancement, termination,
          notification, or compensation of any employee or authorized user of
          the Service. You further agree and acknowledge that Upskill Portal
          does not have a direct relationship with your employees and that you
          are responsible for all contact, questions, Data updates and
          collection, with and from your employees. In addition, you are
          responsible for the privacy (including adopting and posting your own
          privacy policies governing your treatment of your employees’ Data),
          collection, use, retention and processing of your employees’ Data, and
          providing any and all notices and information to your employees
          regarding the foregoing, in compliance with all Applicable Laws.
          Upskill Portal hereby disclaims all liability arising from your
          decisions and from harmful data or code uploaded to the Service, by
          you and/or your employees, contractors or agents.
        </p>
      </PolicySection>
    </div>
  );
};
export default TermsOfServicePage;
