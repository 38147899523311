import { InputAdornment, TextFieldProps } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import './SearchField.scss';
import CustomTextField from '../CustomTextField/CustomTextField';

const SearchField = ({ className, ...rest }: TextFieldProps) => (
  <CustomTextField
    {...rest}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon
            sx={{
              fontSize: '1rem',
            }}
          />
        </InputAdornment>
      ),
    }}
    className={`search-field${className ? ` ${className}` : ''}`}
  />
);

export default SearchField;
