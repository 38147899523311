import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect, { components } from 'react-select';
import { assignGroupTrainings } from '../../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/GroupAccordeon/GroupDetails/AddTrainingsToGroup/actions';
import './AssignTrainingToGroups.scss';

export default function AssignTrainingToGroups({ createdTrainingId }) {
  const dispatch = useDispatch();
  const { allGroups } = useSelector((state) => state.groups);

  const [optionSelected, setOptionSelected] = useState([]);

  const [groups, setGroups] = useState([]);

  const handleChange = (selected) => {
    setOptionSelected(selected);
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            className="mt-1"
            value={props.data.id}
          />{' '}
          <label className="pl-2 text-dark">{props.label}</label>
        </components.Option>
      </div>
    );
  };

  useEffect(() => {
    if (createdTrainingId && optionSelected.length > 0) {
      const model = {
        trainingIds: [createdTrainingId],
        groupIds: optionSelected.map((e) => e.value),
        excluding: false,
      };
      dispatch(assignGroupTrainings(model));
    }
  }, [createdTrainingId]);

  useEffect(() => {
    let userGroups = [];
    allGroups.forEach((group) => {
      let userGroup = {
        value: group.id,
        label: group.title,
      };
      userGroups.push(userGroup);
    });
    setGroups(userGroups);
  }, [allGroups]);

  return (
    <span
      className="d-inline-block col-12 p-0"
      data-toggle="popover"
      data-trigger="focus"
      data-content=""
    >
      <ReactSelect
        options={groups}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
        }}
        onChange={handleChange}
        allowSelectAll
        value={optionSelected}
        placeholder="Select Group from the List"
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,
          colors: {
            ...theme.colors,
            primary25: 'aliceblue',
            primary: 'white',
            neutral20: '#30889c',
          },
        })}
        styles={{
          control: () => ({
            border: '1px solid #ced4da',
            borderRadius: 5,
            display: 'flex',
            fontSize: 14,
          }),
        }}
      />
    </span>
  );
}
