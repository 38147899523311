import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type PreloaderType = {
  isPreloaderActive: boolean;
  isSectionLoaderActive: boolean;
};

const initialState: PreloaderType = {
  isPreloaderActive: false,
  isSectionLoaderActive: false,
};

const preloaderSlice = createSlice({
  name: 'preloader',
  initialState,
  reducers: {
    togglePreloader: (
      state: PreloaderType,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isPreloaderActive = payload;
    },
    toggleSectionLoader: (
      state: PreloaderType,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isSectionLoaderActive = payload;
    },
  },
});

export const { togglePreloader, toggleSectionLoader } = preloaderSlice.actions;
export default preloaderSlice.reducer;
