import * as MSAL from 'msal';
import config from '../../config';
import { BUTTON_LABELS, LOGIN_PROVIDERS } from '../../cms';
import microsoftIcon from '../../common/images/ms-pictogram.svg';
import './MicrosoftLoginButton.scss';
import { useLoginMutation } from '../../rtk/api/userApi';

// Maybe should be redirected to origin not to /login
const msalConfig = {
  auth: {
    clientId: config.MICROSOFT_CLIENT_ID,
    redirectUri: `${window.location.origin}/login`,
  },
};

export default function MicrosoftLoginButton() {
  const [login] = useLoginMutation();
  const signIn = async () => {
    const msalInstance = new MSAL.UserAgentApplication(msalConfig);
    const request = { scopes: ['openid', 'profile'] };

    msalInstance
      .loginPopup(request)
      .then((response) => {
        login({
          token: response.idToken.rawIdToken,
          provider: LOGIN_PROVIDERS.MICROSOFT,
        });
      })
      .catch(() => {});
  };

  return (
    <button className="login-microsoft-btn" onClick={signIn}>
      <img src={microsoftIcon} alt="Microsoft" />
      <p>{BUTTON_LABELS.SIGN_IN_WITH_MICROSOFT}</p>
    </button>
  );
}
