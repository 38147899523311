import { useOutletContext } from 'react-router-dom';
import {
  LinearProgress,
  Stack,
  Typography,
  linearProgressClasses,
  styled,
} from '@mui/material';
import { COLORS, PATHS_LABELS } from '../../../../cms';
import { calculatePercentage, generatePathItem } from '../../../../cms/Utils';
import { PathItemCard } from '../../../../common/components';
import { PathItemStatuses } from '../../../../cms/enums';
import { PathDetailsPageOutletContext } from '../../../../cms/types/pathTypes';

const StyledLinearProgress = styled(LinearProgress)({
  height: 10,
  maxWidth: 200,
  borderRadius: 50,
  flex: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: COLORS.INPUT,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 50,
    backgroundColor: COLORS.ICON_SECOND,
  },
});

const ContentTab = () => {
  const { isPersonal, items } =
    useOutletContext<PathDetailsPageOutletContext>();

  const completedItemsCount = items.filter((i) =>
    [PathItemStatuses.COMPLETED, PathItemStatuses.DROPPED].includes(
      i.status ?? PathItemStatuses.NOT_STARTED,
    ),
  ).length;

  return (
    <Stack spacing="2rem" marginTop="1rem">
      {isPersonal && (
        <Stack direction="row" alignItems="center" spacing="1rem">
          <Typography>Your Progress:</Typography>
          <StyledLinearProgress
            variant="determinate"
            value={calculatePercentage(completedItemsCount, items.length)}
          />
          <Typography>
            {completedItemsCount} of {items.length}
          </Typography>
        </Stack>
      )}
      <Stack className="path-details-page-content-list" spacing="1rem">
        {items.length > 0 ? (
          items.map((item, index) => {
            const pathItem = generatePathItem(item);

            return <PathItemCard key={index} item={pathItem} />;
          })
        ) : (
          <Typography>{PATHS_LABELS.EMPTY}</Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default ContentTab;
