import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './SuccessMessage.scss';
import { COLORS } from '../../../cms';

type SuccessMessageProps = {
  text: string;
};

const SuccessMessage = ({ text }: SuccessMessageProps) => (
  <div className="success-message">
    <CheckCircleIcon
      sx={{
        fontSize: '1.5rem',
        color: COLORS.SUCCESS,
      }}
      className="success-message-icon"
    />
    <p className="success-message-text">{text}</p>
  </div>
);

export default SuccessMessage;
