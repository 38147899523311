import { EDIT_GROUP_REQUEST, EDIT_GROUP_REQUEST_SUCCESS } from './types';

/** Action - edit group.
 * @param {string} editGroupModel - The edit group model
 * @return {Object} Return object
 */

export function editGroup(editGroupModel, lastEditedGroupModel) {
  return {
    type: EDIT_GROUP_REQUEST,
    payload: {
      editGroupModel,
      lastEditedGroupModel,
    },
  };
}

/** On successful edit of group
 * @param {string} isSuccessful - if edit went alright
 * @return {any} Return object
 */
export function editGroupSuccess(isSuccessful) {
  return {
    type: EDIT_GROUP_REQUEST_SUCCESS,
    payload: {
      isSuccessful,
    },
  };
}
