import {
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_REQUEST_ERROR,
  DELETE_GROUP_REQUEST_SUCCESS,
} from './types';

/** Action - delete group.
 * @param {string} entryId - group id
 * @return {Object} Return object
 */
export function deleteGroup(entryId) {
  return {
    type: DELETE_GROUP_REQUEST,
    payload: {
      entryId,
    },
  };
}

/** On successful delete of group
 * @param {string} isSuccessful - if deleted successful
 * @return {any} Return object
 */
export function deleteGroupSuccess(isSuccessful, entryId) {
  return {
    type: DELETE_GROUP_REQUEST_SUCCESS,
    payload: {
      isSuccessful,
      entryId,
    },
  };
}

/** Handle error
 * @param {string} errorMessage - Error message
 */
export function deleteGroupError(errorMessage) {
  return {
    type: DELETE_GROUP_REQUEST_ERROR,
    payload: errorMessage,
  };
}
