import {
  Avatar,
  Checkbox,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { extractInitials } from '../../../cms/Utils';
import { COLORS } from '../../../cms';
import './EmployeeCard.scss';
import { PathAssignee } from '../../../cms/types/pathTypes';
import { Employee } from '../../../cms/types';

type EmployeeCardProps = {
  employee: PathAssignee | Employee;
  isLast?: boolean;
  onRemove?: (employeeId: number) => void;
  isChecked?: boolean;
  onCheckedChange?: (employeeId: number) => void;
  disabled?: boolean;
};

const EmployeeCard = ({
  employee,
  isLast = false,
  onRemove,
  isChecked,
  onCheckedChange,
  disabled = false,
}: EmployeeCardProps) => {
  const { id, fullName, photoUrl, jobTitle } = employee;
  return (
    <Stack
      className="employee-card"
      spacing="1rem"
      sx={{
        borderBottom: isLast ? 'none' : `0.5px solid ${COLORS.INPUT}`,
      }}
    >
      {isChecked !== undefined && (
        <Checkbox
          className="employee-card-checkbox"
          checked={isChecked}
          onChange={() => onCheckedChange!(id)}
          disabled={disabled}
          size="small"
        />
      )}
      <Avatar className="employee-card-avatar" src={photoUrl ?? undefined}>
        {extractInitials(fullName)}
      </Avatar>
      <Stack flex={1}>
        <Typography className="employee-card-name" noWrap>
          {fullName}
        </Typography>
        <Typography className="employee-card-job-title" noWrap>
          {jobTitle}
        </Typography>
      </Stack>
      {onRemove && (
        <Tooltip title="Remove">
          <IconButton
            onClick={() => onRemove(id)}
            className="employee-card-remove-btn"
            disabled={disabled}
          >
            <CloseIcon className="employee-card-remove-btn-icon" />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default EmployeeCard;
