import {
  RESET_VALIDATION_ERRORS,
  VALIDATION_ERRORS_HANDLER,
} from '../common/validation/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  validationErrors: [],
  haveBeenReset: false,
};

/** Validation errors reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function validationErrorsReducer(state = initialState, action) {
  switch (action.type) {
    /** Action Creator - handle validation errors
     */
    case VALIDATION_ERRORS_HANDLER:
      return {
        ...state,
        validationErrors: action.payload.validationErrors,
        haveBeenReset: false,
      };
    case RESET_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: [],
        haveBeenReset: true,
      };
    default:
      return {
        ...state,
        haveBeenReset: false,
      };
  }
}

export default validationErrorsReducer;
