import { put, take, call } from 'redux-saga/effects';
import { GET_ALL_MANAGERS_REQUEST } from './types';
import { getAllManagersError, getAllManagersSuccess } from './actions';

/** If detect type: GET_ALL_MANAGERS_REQUEST call getAllManagers() */
export default function* getAllManagersSaga(api: any): any {
  while (true) {
    const getAllManagersRequest = yield take(GET_ALL_MANAGERS_REQUEST);
    if (getAllManagersRequest.payload) {
      const { departmentId } = getAllManagersRequest.payload;
      yield call(getAllManagers, api, departmentId);
    }
  }
}

/** get all managers */
function* getAllManagers(api: any, departmentId: number): any {
  let response;
  try {
    response = yield call(api.getAllManagers, departmentId);
    const allManagers = response.data;
    yield put(getAllManagersSuccess(allManagers));
  } catch (error) {
    yield put(getAllManagersError(error));
  }
}
