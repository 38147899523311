import { TableCell } from '@mui/material';
import { Column } from '../ResponsiveTable';

type CustomTableCellProps<T> = {
  column: Column<T>;
  row: T;
};

const CustomTableCell = <T,>({ column, row }: CustomTableCellProps<T>) => {
  const value = column.render
    ? column.render(row)
    : (row[column.key as keyof typeof row] as string);

  return (
    <TableCell align={column.align} className="responsive-cell">
      {value ?? '-'}
    </TableCell>
  );
};

export default CustomTableCell;
