import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { demoRequestApi } from "../api/demoRequestApi";


export type DemoRequestsPaginationState = {
    pageNumber: number,
    pageSize: number,
    sortType: string,
    sortOrder: string,
    dateFrom: Date | null,
    dateTo: Date | null,
    status: string,
    search: string,
    refetch: number;
};

const initialState: DemoRequestsPaginationState = {
    pageNumber: 1,
    pageSize: 20,
    sortType: 'createdOn',
    sortOrder: 'desc',
    dateFrom: null,
    dateTo: null,
    status: '',
    search: '',
    refetch: 0,
};

const demoRequestsPaginationSlice = createSlice({
    name: 'pagination',
    initialState,
    reducers: {
        setDemoRequestsPagination: (
            state: DemoRequestsPaginationState,
            { payload }: PayloadAction<any>,
        ) => {
            return {
                ...state,
                ...payload
            };
        },
        incrementDemoRequestsPageNumber: (state: DemoRequestsPaginationState) => {
            state.pageNumber++;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(
                demoRequestApi.endpoints.approveDemoRequest.matchFulfilled,
                demoRequestApi.endpoints.rejectDemoRequest.matchFulfilled,
                demoRequestApi.endpoints.deleteDemoRequest.matchFulfilled,
            ),
            (state: DemoRequestsPaginationState) => {
                return {
                    ...state,
                    pageNumber: 1,
                    refetch: state.refetch + 1
                };
            },
        );
    },
});

export const { setDemoRequestsPagination, incrementDemoRequestsPageNumber } =
    demoRequestsPaginationSlice.actions;
export default demoRequestsPaginationSlice.reducer;