import { useEffect } from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import { Outlet, useLocation } from 'react-router-dom';
import AppHeader from '../AppHeader/AppHeader';
import AppFooter from '../AppFooter/AppFooter';
import LandingHeader from '../LandingHeader/LandingHeader';
import LandingFooter from '../LandingFooter/LandingFooter';
import FullPagePreloader from '../FullPagePreloader/FullPagePreloader';
import NotificationClient from '../Notification/NotificationClient';
import { invalidateApplicationCacheIfNeeded } from '../../common/components/CacheBust/actions';
import { resetValidationErrors } from '../../common/validation/actions';
import { useAppDispatch, useAppSelector } from '../../rtk/hooks';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import AppModals from '../AppModals/AppModals';
import config from '../../config';
import { ENVIRONMENT_NAMES } from '../../cms';
import './AppLayout.scss';

const AppLayout = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { isUserLoggedIn } = useAppSelector((state) => state.userSession);
  const { isPreloaderActive } = useAppSelector((state) => state.preloader);
  const isTrial = config.ENVIRONMENT === ENVIRONMENT_NAMES.TRIAL;
  const showLanding = isTrial && !isUserLoggedIn;

  useEffect(() => {
    dispatch(resetValidationErrors()); // reset validations on change of url
    dispatch(invalidateApplicationCacheIfNeeded() as AnyAction);
  }, [pathname]);

  return (
    <>
      {isUserLoggedIn && <AppHeader />}
      {showLanding && <LandingHeader />}
      <div id="scrollableDiv" className="page-container">
        <main className="page-content">
          {isPreloaderActive && <FullPagePreloader />}
          <Outlet />
        </main>
        {isUserLoggedIn && <AppFooter />}
        {showLanding && <LandingFooter />}
      </div>
      <NotificationClient />
      <AppModals />
      <ScrollToTop />
    </>
  );
};

export default AppLayout;
