import axiosInstance from './axiosInstance';
import {
  ARCHIVE_EMPLOYEE_URL,
  DEACTIVATE_EMPLOYEE_URL,
  GET_ADMIN_URL,
  GET_MY_TEAM_SUFFIX,
  GET_ALL_MANAGERS,
  GET_ALL_PROFESSIONS,
  GET_ALL_DEPARTMENTS,
  EDIT_EMPLOYEE,
} from './config';

export const api = {
  async getMyTeamInfo() {
    return axiosInstance.get(`${GET_ADMIN_URL}/${GET_MY_TEAM_SUFFIX}`);
  },
  async archiveEmployees() {
    return axiosInstance.post(ARCHIVE_EMPLOYEE_URL);
  },
  async deactivateEmployees(userIds) {
    return axiosInstance.post(DEACTIVATE_EMPLOYEE_URL, userIds);
  },
  async getAllManagers(departmentId) {
    return axiosInstance.get(GET_ALL_MANAGERS, {
      params: {
        departmentId,
      },
    });
  },
  async getAllProfessions() {
    return axiosInstance.get(GET_ALL_PROFESSIONS);
  },
  async getAllDepartments() {
    return axiosInstance.get(GET_ALL_DEPARTMENTS);
  },
  async editEmployee(editEmployeeRequestModel) {
    return axiosInstance.put(EDIT_EMPLOYEE, editEmployeeRequestModel);
  },
};
