import { useState, useEffect } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import groupManagementTitleIcon from '../../../../../../../common/images/group-management-title-icon.svg';
import {
  VALIDATION_LABELS,
  ADD_GROUP_MODAL_LABELS,
  NOTIFICATION_LABELS,
  MODAL_VALUES,
} from '../../../../../../../cms';
import './CreateGroupForm.scss';
import {
  validateCreateGroupFormField,
  checkFormFieldsForErrors,
} from './validation';
import {
  checkForValidationErrorsForField,
  getErrorMessageForField,
} from '../../../../../../../common/validation/helpers';
import { resetValidationErrors } from '../../../../../../../common/validation/actions';
import { createGroup } from './actions';
import SearchEmployee from '../../../../../../../components/SearchEmployee/SearchEmployee';
import CreateGroupButtons from './CreateGroupButtons/CreateGroupButtons';
import errorIcon from '../../../../../../../common/images/error-24px.svg';
import { getAllGroups } from '../../actions';
import SearchAssignedTraining from '../../../../../../../components/SearchAssignedTraining/SearchAssignedTraining';
import { editGroup } from '../../GroupAccordeon/GroupDetails/EditGroup/actions';
import { sendNotificationsForAssignedTrainings } from '../../SendNotificationsForAssignedTrainings/actions';
import {
  BaseModal,
  SuccessMessage,
} from '../../../../../../../common/components';
import { useAppDispatch, useAppSelector } from '../../../../../../../rtk/hooks';

function CreateGroupForm({ groupFormData, onCloseHandler, onClick, editMode }) {
  const dispatch = useAppDispatch();
  const { validationErrors, haveBeenReset } = useAppSelector(
    (state) => state.validationErrors,
  );
  const { createSuccess, editSuccess, createdGroupId } = useAppSelector(
    (state) => state.groups,
  );
  const [formValid, setFormValid] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [title, setGroupTitle] = useState(groupFormData.title ?? '');
  const [description, setDescription] = useState(
    groupFormData.description ?? '',
  );
  const [selectedEmployees, setSelectedEmployees] = useState(
    groupFormData.members ?? [],
  );
  const [selectedTrainings, setSelectedTrainings] = useState(
    groupFormData.assignedTrainings ?? [],
  );
  const groupExternalId = groupFormData.id ?? '';
  const initialEmployees = groupFormData.members ?? [];
  const initialTrainings = groupFormData.assignedTrainings ?? [];

  useEffect(() => {
    if (createSuccess || editSuccess) {
      setShowSuccess(true);
      setTimeout(() => {
        handleSuccessClose();
      }, MODAL_VALUES.MODAL_CLOSE_TIMEOUT);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess, editSuccess, validationErrors]);

  useEffect(() => {
    if (title || selectedEmployees) dispatch(resetValidationErrors());
  }, [title, selectedEmployees]);

  useEffect(() => {
    if (selectedEmployees.length > 0)
      validateField('employees', selectedEmployees);
  }, [selectedEmployees.length]);

  useEffect(() => {
    if (title.length > 0) validateField('title', title);
  }, [haveBeenReset, validationErrors]);

  useEffect(() => {
    if (createSuccess && createdGroupId !== '') {
      const model = {
        groupId: createdGroupId,
        memberIds: selectedEmployees.map((m) => m.externalId),
        trainingIds: [],
      };

      dispatch(sendNotificationsForAssignedTrainings(model));
    }
  }, [createdGroupId]);

  useEffect(() => {
    if (editSuccess) {
      // Get only the new employees
      const finalMembersToSendNotifications = selectedEmployees.filter(
        ({ externalId: externalId1 }) =>
          !initialEmployees.some(
            ({ externalId: externalId2 }) => externalId1 === externalId2,
          ),
      );

      // Get only the new trainings
      const finalTrainingsToSendSendNotifications = selectedTrainings.filter(
        ({ id: id1 }) => !initialTrainings.some(({ id: id2 }) => id1 === id2),
      );

      const notificationsModel = {
        groupId: groupExternalId,
        memberIds: finalMembersToSendNotifications.map((m) => m.externalId),
        trainingIds: finalTrainingsToSendSendNotifications.map((m) => m.id),
      };

      dispatch(sendNotificationsForAssignedTrainings(notificationsModel));
    }
  }, [editSuccess]);

  const closeExternalFormModal = () => {
    onCloseHandler();
    if (title && selectedEmployees) resetValidationErrors();
  };

  const handleSuccessClose = () => {
    setShowSuccess(false);
    closeExternalFormModal();
    dispatch(getAllGroups());
  };

  const changeGroupTitle = (e) => {
    setGroupTitle(e.target.value);
    validateField(e.target.name, e.target.value);
  };

  const changeDescriptionHandler = (e) => {
    setDescription(e.target.value);
  };

  const updateSelectedEmployeesHandler = (employees) =>
    setSelectedEmployees(employees);
  const handleOnSelectedTrainingsChanged = (trainings) =>
    setSelectedTrainings(trainings);

  const validateForm = () => {
    const formFields = ['title', 'employees'];
    const formValues = [title, selectedEmployees];

    for (const [i, value] of formFields.entries()) {
      validateField(value, formValues[i]);
    }

    const formHasErrors = checkFormFieldsForErrors(formErrors);
    setFormValid(!formHasErrors);
    return !formHasErrors;
  };

  const validateField = (fieldName, value) => {
    const fieldValidationErrors = validateCreateGroupFormField(
      fieldName,
      value,
      formErrors,
      validationErrors,
    );
    setFormErrors(fieldValidationErrors);
    const formHasErrors = checkFormFieldsForErrors(formErrors);
    setFormValid(!formHasErrors);
  };

  const submitGroup = () => {
    const employeesExternalIds = [];
    if (selectedEmployees.length > 0) {
      selectedEmployees.forEach((employee) => {
        employeesExternalIds.push(employee.externalId);
      });
    }
    const trainingsExternalIds = [];
    if (selectedTrainings.length > 0) {
      selectedTrainings.forEach((training) => {
        trainingsExternalIds.push(training.id);
      });
    }
    const model = {
      title,
      description,
      groupEmployeesExternalIds: employeesExternalIds,
      groupTrainingsIds: trainingsExternalIds,
    };

    const isFormValid = validateForm();

    if (isFormValid) {
      dispatch(createGroup(model));
    }
  };

  const submitEditGroup = () => {
    const employeesExternalIds = selectedEmployees.map((e) => e.externalId);
    const trainingsExternalIds = selectedTrainings.map(
      (t) => t.externalId || t.id,
    );

    const model = {
      externalId: groupExternalId,
      title,
      description,
      groupEmployeesExternalIds: employeesExternalIds,
      groupTrainingsIds: trainingsExternalIds,
    };

    const lastEditedGroupModel = {
      id: groupExternalId,
      title,
      description,
      members: selectedEmployees,
      assignedTrainings: selectedTrainings,
    };

    const isFormValid = validateForm();

    if (isFormValid) {
      dispatch(editGroup(model, lastEditedGroupModel));
    }
  };

  return (
    <div onClick={onClick} className="create-group-form">
      <div className="row px-5 pt-4">
        <div className="col-1">
          <img src={groupManagementTitleIcon} alt="" width={43} />
        </div>
        <div className="col-6 form-heading pl-4 pt-2 color-chathams-blue">
          {!editMode
            ? ADD_GROUP_MODAL_LABELS.CREATE_NEW_GROUP
            : ADD_GROUP_MODAL_LABELS.EDIT_GROUP}
        </div>
      </div>
      <div className="pt-4">
        <div className="inline-block col-12 pl-5 pr-0">
          <div className="color-dove-gray label-heading pb-2">
            {`*${ADD_GROUP_MODAL_LABELS.GROUP_TITLE}`}
          </div>
          <input
            id="entry-title-input"
            type="text"
            name="title"
            maxLength="120"
            onChange={changeGroupTitle}
            value={title}
            className={`form-control group-title col-11 p-2 inline-block ${
              formErrors.titleValid === false ? 'error-input-bold ' : ' '
            }${title ? 'entry-title-active' : 'entry-title-inactive'}`}
          />
          <div className="check-circle-container col-1 ml-n5 inline-block">
            <AiFillCheckCircle
              size={16}
              color={
                title && formErrors.titleValid !== false
                  ? '#00C29B'
                  : 'lightgray'
              }
            />
          </div>
        </div>
        {checkForValidationErrorsForField(validationErrors, 'title') &&
          formErrors.titleValid === false && (
            <div className="pl-5 pt-1 validation-error">
              {getErrorMessageForField(validationErrors, 'title')}
            </div>
          )}
        {formErrors.titleValid === false &&
          checkForValidationErrorsForField(validationErrors, 'title') ===
            false && (
            <div className="title-required-error pl-5 error-input-bold inline-block">
              <img
                src={errorIcon}
                alt="error icon"
                width={12}
                className="inline-block"
              />
              <div className="inline-block align-middle pl-2">
                {VALIDATION_LABELS.REQUIRED_FIELD}
              </div>
            </div>
          )}
      </div>
      <div className="pt-4 px-5">
        <div className="pb-2 color-dove-gray label-heading">
          {`${ADD_GROUP_MODAL_LABELS.DESCRIPTION_TITLE} (Optional)`}
        </div>
        <textarea
          name="description"
          className="form-control description-text-area box-shadow"
          value={description}
          onChange={changeDescriptionHandler}
        />
      </div>
      <div>
        <div className="pt-4 pl-5 color-dove-gray label-heading">
          {`*${ADD_GROUP_MODAL_LABELS.ADD_EMPLOYEES_TITLE}`}
        </div>
      </div>
      <div className="row pt-4 pl-5 search-margin">
        <div className="col-sm-11 ml-n2">
          <SearchEmployee
            updateSelectedEmployeesHandler={updateSelectedEmployeesHandler}
            searchBoxInputId="search-employee-box-input"
            focus={false}
            formErrors={formErrors}
            selectedEmployees={selectedEmployees}
            className="ml-3"
          />
        </div>
      </div>
      <div>
        <div className="pt-4 pl-5 color-dove-gray label-heading">
          {`${ADD_GROUP_MODAL_LABELS.ASSIGN_TRAINING} (Optional)`}
        </div>
      </div>
      <div className="row pt-4 pl-5 search-margin">
        <div className="col-sm-11 ml-n2">
          <SearchAssignedTraining
            handleOnSelectedTrainingsChanged={handleOnSelectedTrainingsChanged}
            searchBoxInputId="search-training-box-input"
            formErrors={{}}
            selectedTrainings={selectedTrainings}
            className="ml-3"
          />
        </div>
      </div>
      <div>
        <CreateGroupButtons
          formValid={formValid}
          closeFormModal={onCloseHandler}
          submitGroupHandler={editMode ? submitEditGroup : submitGroup}
          editMode={editMode}
        />
      </div>
      <BaseModal hideCloseBtn open={showSuccess} onClose={handleSuccessClose}>
        <SuccessMessage
          text={
            editMode
              ? NOTIFICATION_LABELS.GROUP_HAS_BEEN_EDITED
              : NOTIFICATION_LABELS.GROUP_HAS_BEEN_CREATED
          }
        />
      </BaseModal>
    </div>
  );
}

export default CreateGroupForm;
