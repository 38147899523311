import { isEqual, unionBy } from 'lodash';
import {
  PERSONAL_PATHS_URL,
  PATHS_URL,
  PATH_UPLOAD_FILES_URL,
  ASSIGN_PATH_TO_USERS_URL,
  UNASSIGN_PATH_FROM_USERS_URL,
  DROP_PATH_URL,
} from '../../api/trainingsPortalBackend/config';
import { api } from './api';
import {
  PathResponse,
  PathsResponse,
  PathsSearchParams,
  CreatePathDto,
  EditPathDto,
  PersonalPathsSearchParams,
  PersonalPathsResponse,
  AssignPathToUsersDto,
  UnassignPathFromUsers,
  ChangePathItemStatusRequest,
} from '../../cms/types/pathTypes';
import { transformErrorResponse } from '../../cms/Utils';

export const pathApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaths: build.query<PathsResponse, PathsSearchParams>({
      query: (args) => ({
        url: PATHS_URL,
        params: args,
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCacheData, responseData, { arg }) => {
        if (arg?.pageNumber === 1) return responseData;

        return {
          totalCount: responseData.totalCount,
          entities: unionBy(
            currentCacheData.entities,
            responseData.entities,
            'id',
          ),
        };
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        isEqual(currentArg, previousArg),
    }),
    getPersonalPaths: build.query<
      PersonalPathsResponse,
      PersonalPathsSearchParams
    >({
      query: (args) => ({
        url: PERSONAL_PATHS_URL,
        params: args,
      }),
      providesTags: ['Path'],
    }),
    createPath: build.mutation<any, CreatePathDto>({
      query: (data) => ({
        url: PATHS_URL,
        method: 'POST',
        body: data,
      }),
      transformErrorResponse,
    }),
    uploadFilesToPath: build.mutation<
      any,
      { pathId: number; filesFormData: FormData }
    >({
      query: ({ pathId, filesFormData }) => ({
        url: `${PATH_UPLOAD_FILES_URL}/${pathId}`,
        method: 'POST',
        body: filesFormData,
      }),
      invalidatesTags: ['Path'],
      transformErrorResponse,
    }),
    getPath: build.query<PathResponse, number>({
      query: (pathId) => ({
        url: `${PATHS_URL}/${pathId}`,
      }),
      transformErrorResponse,
      providesTags: ['Path'],
    }),
    getPersonalPath: build.query<PathResponse, number>({
      query: (pathId) => ({
        url: `${PERSONAL_PATHS_URL}/${pathId}`,
      }),
      transformErrorResponse,
      providesTags: ['Path'],
    }),
    deletePath: build.mutation<any, number>({
      query: (pathId) => ({
        url: `${PATHS_URL}/${pathId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Path'],
    }),
    editPath: build.mutation<any, EditPathDto>({
      query: (model) => ({
        url: PATHS_URL,
        method: 'PUT',
        body: model,
      }),
      transformErrorResponse,
      invalidatesTags: ['Path'],
    }),
    assignPathToUsers: build.mutation<any, AssignPathToUsersDto>({
      query: (model) => ({
        url: ASSIGN_PATH_TO_USERS_URL,
        method: 'POST',
        body: model,
      }),
      invalidatesTags: ['Path'],
    }),
    unassignPathFromUsers: build.mutation<any, UnassignPathFromUsers>({
      query: (model) => ({
        url: UNASSIGN_PATH_FROM_USERS_URL,
        method: 'POST',
        body: model,
      }),
      transformErrorResponse,
      invalidatesTags: ['Path'],
    }),
    dropPath: build.mutation<any, number>({
      query: (id) => ({
        url: `${DROP_PATH_URL}/${id}`,
        method: 'PUT',
      }),
      transformErrorResponse,
      invalidatesTags: ['Path'],
    }),
    changePathItemStatus: build.mutation<any, ChangePathItemStatusRequest>({
      query: ({ pathId, model }) => ({
        url: `${PATHS_URL}/${pathId}/itemStatus`,
        method: 'Patch',
        body: model,
      }),
      invalidatesTags: ['Path'],
    }),
  }),
});

export const {
  useGetPathsQuery,
  useGetPersonalPathsQuery,
  useGetPathQuery,
  useLazyGetPathQuery,
  useGetPersonalPathQuery,
  useLazyGetPersonalPathQuery,
  useCreatePathMutation,
  useUploadFilesToPathMutation,
  useDeletePathMutation,
  useEditPathMutation,
  useAssignPathToUsersMutation,
  useUnassignPathFromUsersMutation,
  useDropPathMutation,
  useChangePathItemStatusMutation,
} = pathApi;
