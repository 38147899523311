import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import { ApplicationStatuses, ModalTypes } from '../../../cms/enums';
import { TrainingResponse } from '../../../cms/types/trainingTypes';
import { useAppDispatch } from '../../../rtk/hooks';
import { useApplyForTrainingMutation } from '../../../rtk/api/trainingApi';
import {
  useCompleteTrainingApplicationMutation,
  useDropTrainingApplicationMutation,
  useStartTrainingApplicationMutation,
} from '../../../rtk/api/trainingApplicationApi';
import {
  BUTTON_LABELS,
  FAILED_REQUEST_MESSAGE,
  MODAL_CLOSE_BTNS,
  MODAL_VALUES,
  SUCCESSUL_REQUEST_MESSAGES,
} from '../../../cms';
import { openModal } from '../../../rtk/features/modalsSlice';

type ChangeTrainingStatusButtonsProps = {
  training: TrainingResponse;
};

export const ChangeTrainingStatusButtons = ({
  training,
}: ChangeTrainingStatusButtonsProps) => {
  const dispatch = useAppDispatch();
  const [applyForTraining, { isLoading: isApplyingForTraining }] =
    useApplyForTrainingMutation();
  const [
    startTrainingApplication,
    { isLoading: isStartingTrainingApplication },
  ] = useStartTrainingApplicationMutation();
  const [
    completeTrainingApplication,
    { isLoading: isCompletingTrainingApplication },
  ] = useCompleteTrainingApplicationMutation();
  const [
    dropTrainingApplication,
    { isLoading: isDroppingTrainingApplication },
  ] = useDropTrainingApplicationMutation();

  const [applyConfirmation, setApplyConfirmation] = useState<boolean>(false);

  const applicationId = training.trainingUserId;
  const applicationStatus = training.applicationStatus;
  const isPaid = training.cost > 0;
  const isExpired =
    training?.endDate !== null && dayjs().isAfter(training?.endDate, 'date');

  const showArchived =
    training.isArchived &&
    (applicationStatus === null ||
      [
        ApplicationStatuses.COMPLETED,
        ApplicationStatuses.DROPPED,
        ApplicationStatuses.REJECTED,
      ].includes(applicationStatus));

  const showExpired =
    isExpired &&
    (applicationStatus === null ||
      [
        ApplicationStatuses.COMPLETED,
        ApplicationStatuses.DROPPED,
        ApplicationStatuses.REJECTED,
      ].includes(applicationStatus));

  const showApply =
    applicationStatus === null ||
    [ApplicationStatuses.DROPPED, ApplicationStatuses.REJECTED].includes(
      applicationStatus,
    );

  const showDrop =
    applicationStatus !== null &&
    [
      ApplicationStatuses.PENDING,
      ApplicationStatuses.APPROVED,
      ApplicationStatuses.IN_PROGRESS,
    ].includes(applicationStatus);

  const showStart = applicationStatus === ApplicationStatuses.APPROVED;
  const showComplete = applicationStatus === ApplicationStatuses.IN_PROGRESS;

  const handleOnRequestSuccess = (message: string) => {
    dispatch(
      openModal({
        modalType: ModalTypes.SUCCESS_MESSAGE,
        modalData: message,
        timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
      }),
    );
  };

  const handleOnCompleteRequestSuccess = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.RATE_TRAINING_FORM,
        modalData: {
          trainingId: training.id,
          trainingTitle: training.title,
          currentRating: training.rating,
        },
        closeBtn: MODAL_CLOSE_BTNS.OUTSIDE,
      }),
    );
  };

  const handleOnRequestError = (error: any) => {
    const errorMessage = error?.data?.message ?? FAILED_REQUEST_MESSAGE;

    dispatch(
      openModal({
        modalType: ModalTypes.ERROR_MESSAGE,
        modalData: errorMessage,
        timeout: MODAL_VALUES.MODAL_CLOSE_FIVE_SECONDS_TIMEOUT,
      }),
    );
  };

  const handleOnApply = () => {
    if (isPaid) {
      dispatch(
        openModal({
          modalType: ModalTypes.PAID_TRAINING_NOTE,
          modalData: {
            type: 'Application',
            title: training.title,
            onConfirm: () => setApplyConfirmation(true),
          },
        }),
      );
    }
    else
    {
      applyForTraining(training.id)
        .unwrap()
        .then(() => {
          setApplyConfirmation(false);
          handleOnRequestSuccess(SUCCESSUL_REQUEST_MESSAGES.APPLY_TRAINING);
        })
        .catch(handleOnRequestError);
    }
  };

  useEffect(() => {
    if (applyConfirmation) {
      applyForTraining(training.id)
        .unwrap()
        .then(() => {
          setApplyConfirmation(false);
          handleOnRequestSuccess(SUCCESSUL_REQUEST_MESSAGES.APPLY_TRAINING);
        })
        .catch(handleOnRequestError);
    }
  }, [applyConfirmation]);

  const handleOnStart = () => {
    startTrainingApplication(applicationId)
      .unwrap()
      .then(() =>
        handleOnRequestSuccess(SUCCESSUL_REQUEST_MESSAGES.START_TRAINING),
      )
      .catch(handleOnRequestError);
  };

  const handleOnComplete = () => {
    completeTrainingApplication(applicationId)
      .unwrap()
      .then(() => handleOnCompleteRequestSuccess())
      .catch(handleOnRequestError);
  };

  const handleOnDrop = () => {
    dropTrainingApplication(applicationId)
      .unwrap()
      .then(() =>
        handleOnRequestSuccess(SUCCESSUL_REQUEST_MESSAGES.DROP_TRAINING),
      )
      .catch(handleOnRequestError);
  };

  if (showArchived) {
    return (
      <Button variant="contained" disabled>
        Archived
      </Button>
    );
  }

  if (showExpired) {
    return (
      <Button variant="contained" disabled>
        Expired
      </Button>
    );
  }

  return (
    <>
      {showApply && (
        <Button
          variant="contained"
          className="apply-training-button"
          disabled={isApplyingForTraining}
          onClick={handleOnApply}
        >
          {BUTTON_LABELS.APPLY}
        </Button>
      )}
      {showStart && (
        <Button
          variant="contained"
          className="start-training-button"
          disabled={isStartingTrainingApplication}
          onClick={handleOnStart}
        >
          {BUTTON_LABELS.START}
        </Button>
      )}
      {showDrop && (
        <Button
          variant="outlined"
          color="error"
          className="drop-training-button"
          disabled={isDroppingTrainingApplication}
          onClick={handleOnDrop}
        >
          {BUTTON_LABELS.DROPOUT}
        </Button>
      )}
      {showComplete && (
        <Button
          variant="contained"
          color="success"
          className="complete-training-button"
          disabled={isCompletingTrainingApplication}
          onClick={handleOnComplete}
        >
          {BUTTON_LABELS.COMPLETE}
        </Button>
      )}
    </>
  );
};

export default ChangeTrainingStatusButtons;
