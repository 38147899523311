import { Avatar, Stack, Typography } from '@mui/material';
import { extractInitials } from '../../../../cms/Utils';
import './EmployeeAutocompleteOption.scss';

type EmployeeAutocompleteOptionProps = {
  photoUrl: string | undefined;
  fullName: string;
  renderOptionProps: React.HTMLAttributes<HTMLLIElement>;
};

const EmployeeAutocompleteOption = ({
  photoUrl,
  fullName,
  renderOptionProps,
}: EmployeeAutocompleteOptionProps) => {
  return (
    <Stack
      {...renderOptionProps}
      component="li"
      direction="row"
      spacing="0.75rem"
      className={`${renderOptionProps.className} employee-autocomplete-option`}
    >
      <Avatar className="employee-autocomplete-option-avatar" src={photoUrl}>
        {extractInitials(fullName)}
      </Avatar>
      <Typography className="employee-autocomplete-option-name" noWrap>
        {fullName}
      </Typography>
    </Stack>
  );
};

export default EmployeeAutocompleteOption;
