import {
  FaBookReader,
  FaBuilding,
  FaChalkboardTeacher,
  FaLaptop,
  FaRobot,
  FaUserTie,
} from 'react-icons/fa';
import { MdMessage } from 'react-icons/md';
import { RiPsychotherapyFill } from 'react-icons/ri';
import { SkillTypes } from '../../../cms/enums';

type SkillTypeIconProps = {
  skillType: SkillTypes;
  color: string;
  size: number;
};

const SkillTypeIcon = ({ skillType, color, size }: SkillTypeIconProps) => {
  if (
    skillType === SkillTypes.TECHNICAL ||
    skillType === SkillTypes.TECHNICAL_SKILLS
  )
    return <FaRobot color={color} size={size} />;
  else if (skillType === SkillTypes.SOFT_SKILLS)
    return <MdMessage color={color} size={size} />;
  else if (skillType === SkillTypes.MANAGEMENT_SKILLS)
    return <RiPsychotherapyFill color={color} size={size} />;
  else if (skillType === SkillTypes.CAREER)
    return <FaUserTie color={color} size={size} />;
  else if (skillType === SkillTypes.PERSONAL)
    return <FaBookReader color={color} size={size} />;
  else if (skillType === SkillTypes.INTERNAL)
    return <FaChalkboardTeacher color={color} size={size} />;
  else if (skillType === SkillTypes.DOMAIN)
    return <FaBuilding color={color} size={size} />;

  return <FaLaptop color={color} size={size} />;
};

export default SkillTypeIcon;
