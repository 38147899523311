import React, { useState } from 'react';
import {
  BUTTON_LABELS,
  REJECT_APPLICATION_LABELS,
  SUBMISSIONS_LABELS,
} from '../../../../../cms';
import DemoRequestActionButton from '../DemoRequestActionButton/DemoRequestActionButton';
import {
  DemoRequestApplicationArgs,
  useRejectDemoRequestMutation,
} from '../../../../../rtk/api/demoRequestApi';

export interface RejectDemoRequestRequestModel {
  applicationId: string;
  comment: string;
}

export default function ApproveDemoRequest(props: { id: string }) {
  const [rejectDemoRequest, { isSuccess }] = useRejectDemoRequestMutation();

  const [rejectCommentText, setRejectCommentText] = useState('');

  const changeRejectCommentText = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setRejectCommentText(e.target.value);
  };

  const reject = () => {
    const model: DemoRequestApplicationArgs = {
      applicationId: props.id,
      comment: rejectCommentText,
    };

    rejectDemoRequest(model);
  };

  return (
    <DemoRequestActionButton
      onClick={() => reject}
      text={BUTTON_LABELS.REJECT_BUTTON}
      className="rejected-color"
      textareaText={rejectCommentText}
      changeTextareaHandler={changeRejectCommentText}
      textareaPlaceholderText={
        REJECT_APPLICATION_LABELS.REJECT_COMMENT_PLACEHOLDER_TEXT
      }
      confirmationText={
        SUBMISSIONS_LABELS.REJECT_DEMO_REQUESTS_CONFIRMATION_TEXT
      }
      isConfirmationModalClosed={isSuccess}
    />
  );
}
