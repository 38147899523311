import React from 'react';
import uploadFileIcon from '../../../images/upload-file-icon.png';
// css
import './FileDropZone.scss';

import { FILE_UPLOAD_DROP_ZONE } from '../../../../cms';
import FileDropZoneError from './FileDropZoneError/FileDropZoneError';
import FileDropZoneFiles from './FileDropZoneFiles/FileDropZoneFiles';

interface Props {
  files: any;
  clearFileSelection: () => void;
  fileTypeError: boolean;
  fileSizeError: boolean;
  fileNameAlreadyExistsError: boolean;
  maxFileNumberError: boolean;
  errorMessage: string;
  isImporting: boolean;
  checkForImporting: boolean;
  uploadFileCenterMessage: string;
  maxFileSize: number;
  allowMultiple: boolean;
  disableClearFile: boolean;
}

export default function FileDropZone({
  files,
  clearFileSelection,
  fileTypeError,
  fileSizeError,
  fileNameAlreadyExistsError,
  maxFileNumberError,
  errorMessage,
  isImporting,
  checkForImporting,
  uploadFileCenterMessage,
  maxFileSize,
  allowMultiple,
  disableClearFile,
}: Props) {
  return (
    <div className="file-upload-cotainer">
      {(files.length === 0 && !isImporting) ||
      (errorMessage !== undefined && errorMessage.length > 0) ||
      fileTypeError ||
      fileSizeError ||
      fileNameAlreadyExistsError ||
      maxFileNumberError ? (
        <div>
          <img
            src={uploadFileIcon}
            alt="upload file icon"
            className="upload-file-icon"
          />
          {errorMessage !== undefined && errorMessage.length > 0 ? (
            <FileDropZoneError errorMessage={errorMessage} />
          ) : fileTypeError ? (
            <FileDropZoneError
              errorMessage={FILE_UPLOAD_DROP_ZONE.NOT_SUPPORTED_TYPE}
            />
          ) : fileSizeError ? (
            <FileDropZoneError
              errorMessage={FILE_UPLOAD_DROP_ZONE.MAX_SIZE_ERROR.replace(
                '/maxSize/',
                maxFileSize.toString(),
              )}
            />
          ) : maxFileNumberError ? (
            <FileDropZoneError
              errorMessage={FILE_UPLOAD_DROP_ZONE.MAX_FILE_NUMBER_REACHED}
            />
          ) : fileNameAlreadyExistsError ? (
            <FileDropZoneError
              errorMessage={FILE_UPLOAD_DROP_ZONE.FILE_NAME_ALREADY_EXISTS}
            />
          ) : (
            <div className="file-upload-text">
              {uploadFileCenterMessage.replace(
                '/maxSize/',
                maxFileSize.toString(),
              )}
            </div>
          )}
        </div>
      ) : (
        <FileDropZoneFiles
          files={files}
          checkForImporting={checkForImporting}
          isImporting={isImporting}
          clearFileSelection={clearFileSelection}
          allowMultiple={allowMultiple}
          disableClearFile={disableClearFile}
        />
      )}
    </div>
  );
}
