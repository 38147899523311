import { Typography } from '@mui/material';
import { Label, Pie, PieChart } from 'recharts';
import { DonutChartCenterLabel } from './DonutChartCenterLabel/DonutChartCenterLabel';
import { COLORS } from '../../../cms';
import './DonutChart.scss';

type DonutChartProps = {
  data: any[];
  dataSymbol?: string;
  desc1: string;
  desc2: string;
  className?: string;
};

const CHART_RADIUS = 70;
const CHART_DIAMETER = CHART_RADIUS * 2;

const pieOptions = {
  outerRadius: CHART_RADIUS,
  innerRadius: CHART_RADIUS - 10,
  paddingAngle: 7.5,
  dataKey: 'value',
};

const emptyData = [{ name: 'No Data', value: 1 }];

export const DonutChart = ({
  data,
  desc1,
  desc2,
  dataSymbol = '',
  className = '',
}: DonutChartProps) => {
  const totalValue =
    data?.reduce((total, object) => total + object.value, 0) ?? 0;

  const centerLabel = (
    <Label
      position="center"
      content={
        <DonutChartCenterLabel
          value={`${totalValue}${dataSymbol}`}
          desc1={desc1}
          desc2={desc2}
        />
      }
    />
  );

  return (
    <div className={`donut-chart ${className}`}>
      <PieChart width={CHART_DIAMETER} height={CHART_DIAMETER}>
        {totalValue === 0 && (
          <Pie data={emptyData} fill={COLORS.LIGHT_GRAY} {...pieOptions}>
            {centerLabel}
          </Pie>
        )}

        <Pie data={data} {...pieOptions}>
          {centerLabel}
        </Pie>
      </PieChart>
      <ul className="donut-chart-legend">
        {data.map(({ name, value, fill }) => (
          <li key={`item-${name}`} className="donut-chart-legend-item">
            <div
              className="donut-chart-legend-item-marker"
              style={{
                backgroundColor: fill,
              }}
            />
            <Typography>
              <Typography
                component="span"
                className="donut-chart-legend-item-value donut-chart-legend-item-text"
              >
                {value}
                {dataSymbol}
              </Typography>{' '}
              <Typography
                component="span"
                className="donut-chart-legend-item-name donut-chart-legend-item-text"
              >
                {name}
              </Typography>
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};
