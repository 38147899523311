import { put, take, call } from 'redux-saga/effects';
import { GET_GROUP_REQUEST } from './types';
import { getGroupSuccess, getGroupError } from './actions';
// import { setSectionLoader } from '../../../../../SectionLoader/actions';

/** If detect type: GET_GROUP_REQUEST call getGroup() */
export default function* getGroupSaga(api) {
  while (true) {
    const getGroupRequest = yield take(GET_GROUP_REQUEST);
    if (getGroupRequest.payload) {
      const { groupId } = getGroupRequest.payload;
      yield call(getGroup, api, groupId);
    }
  }
}

/** Get group details */
function* getGroup(api, groupId) {
  let response;
  // yield put(setSectionLoader(true));
  try {
    response = yield call(api.getGroup, groupId);
    yield put(getGroupSuccess(response.data));
    // yield put(setSectionLoader(false));
  } catch (error) {
    yield put(getGroupError(error));
  }
}
