import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Container, Stack } from '@mui/material';
import {
  PathSchema,
  PathSchemaType,
} from '../../cms/validation/pathValidation';
import {
  useCreatePathMutation,
  useGetPathQuery,
  useUploadFilesToPathMutation,
} from '../../rtk/api/pathApi';
import {
  FAILED_REQUEST_MESSAGE,
  MODAL_VALUES,
  PATHS_LABELS,
  ROUTE_LINKS,
} from '../../cms';
import { useAppDispatch } from '../../rtk/hooks';
import { openModal } from '../../rtk/features/modalsSlice';
import {
  CreatePathDto,
  CreatePathEventDto,
  CreatePathItemDto,
  CreatePathTrainingDto,
} from '../../cms/types/pathTypes';
import { ModalTypes, PathItemTypes } from '../../cms/enums';
import { PathForm } from '../../forms';
import {
  convertServerPathToPathFormValues,
  generateFileFormData,
} from '../../cms/Utils';
import { Loader } from '../../common/components';
import { useNavigateBack } from '../../common/hooks/useNavigateBack';

const CreatePathPage = () => {
  const dispatch = useAppDispatch();
  const navigateBack = useNavigateBack();
  const { state } = useLocation();
  const pathId = state?.pathId;
  const { data: path, isFetching: isLoadingFormValues } = useGetPathQuery(
    pathId != null ? pathId : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const [createPath, { error: createPathErrors }] = useCreatePathMutation();
  const [uploadFilesToPath] = useUploadFilesToPathMutation();
  const methods = useForm<PathSchemaType>({
    resolver: zodResolver(PathSchema),
    defaultValues: {
      name: '',
      durationInMonths: 0,
      items: [],
    },
  });

  useEffect(() => {
    if (!path) return;

    convertServerPathToPathFormValues(path).then((formValues) =>
      methods.reset(formValues),
    );
  }, [path]);

  useEffect(() => {
    if (!createPathErrors) return;

    if (Array.isArray(createPathErrors) && createPathErrors.length > 0) {
      createPathErrors.forEach(({ name, message }: any) => {
        methods.setError(name, {
          type: 'server',
          message,
        });
      });
    } else {
      dispatch(
        openModal({
          modalType: ModalTypes.ERROR_MESSAGE,
          modalData: FAILED_REQUEST_MESSAGE,
          timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
        }),
      );
    }
  }, [createPathErrors]);

  const onClose = () => {
    navigateBack({ fallback: ROUTE_LINKS.PATHS });
  };

  const onSubmit = async (values: PathSchemaType) => {
    const trainings: CreatePathTrainingDto[] = values.items
      .filter((i) => i.type === PathItemTypes.TRAINING)
      .map(({ position, data }) => ({
        position: position!,
        trainingId: data.id,
      }));

    const events: CreatePathEventDto[] = values.items
      .filter((i) => i.type === PathItemTypes.EVENT)
      .map(({ position, data }) => ({ position: position!, eventId: data.id }));

    const items: CreatePathItemDto[] = values.items
      .filter((i) =>
        [PathItemTypes.LINK, PathItemTypes.MEETING].includes(i.type),
      )
      .map(({ position, data, type }) => ({
        position: position!,
        type,
        ...data,
      }));

    const createPathDto: CreatePathDto = {
      name: values.name,
      description: values.description,
      durationInMonths: values.durationInMonths,
      type: values.type,
      trainings,
      events,
      items,
    };

    try {
      // Create Path and get path id
      const { id } = await createPath(createPathDto).unwrap();

      const files = values.items.filter(
        (item) => item.type === PathItemTypes.FILE,
      );

      // Upload files to path
      if (files.length > 0) {
        const filesFormData = generateFileFormData(files);

        await uploadFilesToPath({ filesFormData, pathId: id });
      }

      // Show success modal
      dispatch(
        openModal({
          modalType: ModalTypes.SUCCESS_MESSAGE,
          modalData: PATHS_LABELS.CREATED_PATH_TEXT,
          timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
        }),
      );

      // Close create path form
      setTimeout(onClose, MODAL_VALUES.MODAL_CLOSE_TIMEOUT);
    } catch (еrr) {}
  };

  return (
    <Container maxWidth="lg">
      <Stack className="create-path-page" spacing="2rem" padding="2rem">
        {isLoadingFormValues ? (
          <Loader />
        ) : (
          <FormProvider {...methods}>
            <PathForm
              onSubmit={onSubmit}
              onCancel={onClose}
              title="Create New Path"
              submitBtnLabel="Create"
            />
          </FormProvider>
        )}
      </Stack>
    </Container>
  );
};

export default CreatePathPage;
