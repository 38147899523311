import { VALIDATION_ERRORS_HANDLER, RESET_VALIDATION_ERRORS } from './types';

/** Create action to handle validation errors
 * @param {Array} validationErrors - All validation errors that have occured
 */
export function handleValidationErrors(validationErrors) {
  return {
    type: VALIDATION_ERRORS_HANDLER,
    payload: {
      validationErrors,
    },
  };
}

/** Create action to reset validation errors
 */
export function resetValidationErrors() {
  return {
    type: RESET_VALIDATION_ERRORS,
    payload: {},
  };
}
