import { useEffect, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import { Stack, Grid, Button, Typography, Divider } from '@mui/material';
import { AnyAction } from '@reduxjs/toolkit';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { isArray } from 'lodash';
import {
  TRAINING_DETAILS,
  ADD_EVENT_MODAL_LABELS,
  NOTIFICATION_LABELS,
  MODAL_VALUES,
  ROLES,
} from '../../cms';
import './TrainingForm.scss';
import TrainerSection from './TrainerSection/TrainerSection';
import DetailsSection from './DetailsSection/DetailsSection';
import { getGroups } from '../../pages/AdministrationPage/tabs/GroupManagementTab/GetAllGroups/actions';
import { getUserRoles } from '../../common/userRoleRetriever';
import { useAppDispatch, useAppSelector } from '../../rtk/hooks';
import { EditTrainingType } from '../../cms/types';
import {
  useCreateTrainingMutation,
  useEditTrainingMutation,
  useUploadTrainingPdfsMutation,
} from '../../rtk/api/trainingApi';
import {
  ApplicationsLimitSection,
  BaseModal,
  CostSection,
  DatesSection,
  LocationSection,
  MarkdownTextArea,
  TitleInput,
} from '../../common/components';
import UploadPdf from '../EventForm/EventMainForm/UploadPdf/UploadPdf';
import TrainingFormAdminSection from './TrainingFormAdminSection/TrainingFormAdminSection';
import TrainingFormFooter from './TrainingFormFooter/TrainingFormFooter';
import {
  TrainingFormSchema,
  TrainingFormSchemaType,
} from '../../cms/validation/trainingValidation';
import { openModal } from '../../rtk/features/modalsSlice';
import { ModalTypes } from '../../cms/enums';
import TrainingLinkInput from './TrainingLinkInput/TrainingLinkInput';
import FormTrainingTypes from './FormTrainingTypes/FormTrainingTypes';
import PrivateToggle from './PrivateToggle/PrivateToggle';

export type CustomFormEvent = {
  target: {
    name: string;
    value?: string;
    checked?: boolean;
  };
};

export type TrainingFormType = 'Create' | 'Edit' | 'Duplicate';

type TrainingFormProps = {
  trainingData?: EditTrainingType;
  type: TrainingFormType;
  onCloseHandler: () => void;
};

function TrainingForm({
  trainingData,
  type = 'Create',
  onCloseHandler,
}: TrainingFormProps) {
  const dispatch = useAppDispatch();
  const { isUserLoggedIn } = useAppSelector((state) => state.userSession);
  const isAdmin = isUserLoggedIn && getUserRoles().includes(ROLES.ADMIN_ROLE);
  const isManager =
    isUserLoggedIn && getUserRoles().includes(ROLES.MANAGER_ROLE);
  const { creatorId, id: numberId } = (trainingData as EditTrainingType) ?? {};

  const [createTraining, { error: createTrainingErrors }] =
    useCreateTrainingMutation();
  const [editTraining, { error: editTrainingErrors }] =
    useEditTrainingMutation();
  const [uploadPdfs] = useUploadTrainingPdfsMutation();

  const methods = useForm<TrainingFormSchemaType>({
    resolver: zodResolver(TrainingFormSchema),
    defaultValues: {
      title: trainingData?.title ?? '',
      description: trainingData?.description ?? '',
      startDate: trainingData?.startDate,
      endDate: trainingData?.endDate,
      applyBeforeDate: trainingData?.applyBeforeDate,
      url: trainingData?.url ?? '',
      cost: trainingData?.cost ?? 0,
      costOption: (trainingData?.cost ?? 0) > 0 ? 'paid' : 'free',
      trainerFirstName: trainingData?.trainerFirstName ?? '',
      trainerLastName: trainingData?.trainerLastName ?? '',
      trainerJobTitle: trainingData?.trainerJobTitle ?? '',
      trainingLocationAddress: trainingData?.trainingLocationAddress ?? '',
      level: trainingData?.level ?? 'Beginner',
      duration: trainingData?.duration ?? 0,
      lessons: trainingData?.lessons ?? 0,
      type:
        trainingData && 'type' in trainingData ? trainingData.type : undefined,
      location: trainingData?.location,
      isPrivate: trainingData?.isPrivate ?? false,
      applicationsLimitOption:
        (trainingData?.applicationsLimit ?? 0) > 0 ? 'yes' : 'no',
      applicationsLimit: trainingData?.applicationsLimit ?? 0,
    },
  });
  const { getValues } = methods;

  const [files, setFiles] = useState<File[]>([]);
  const [uploadPdfModalOpened, setUploadPdfModalOpened] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [createdId, setCreatedId] = useState(null);

  useEffect(() => {
    if (isAdmin) {
      dispatch(getGroups() as AnyAction);
    }
  }, [isAdmin]);

  useEffect(() => {
    if (isArray(createTrainingErrors)) {
      createTrainingErrors.forEach(({ name, message }: any) => {
        methods.setError(name, {
          type: 'server',
          message,
        });
      });
    }
  }, [createTrainingErrors]);

  useEffect(() => {
    if (isArray(editTrainingErrors)) {
      editTrainingErrors.forEach(({ name, message }: any) => {
        methods.setError(name, {
          type: 'server',
          message,
        });
      });
    }
  }, [editTrainingErrors]);

  useEffect(() => {
    if (successMessage) {
      dispatch(
        openModal({
          modalType: ModalTypes.SUCCESS_MESSAGE,
          modalData: successMessage,
          timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
        }),
      );
      setTimeout(() => {
        onCloseHandler();
      }, MODAL_VALUES.MODAL_CLOSE_TIMEOUT);
    }
  }, [successMessage]);

  const handleClosePdfModal = () => {
    setUploadPdfModalOpened(false);
  };

  const removeFile = (name: string) => {
    setFiles((prev) => prev.filter((f: File) => f.name !== name));
  };

  const onSubmitHandler = async (data: TrainingFormSchemaType) => {
    const { costOption, applicationsLimit, ...other } = data;
    const model = {
      ...other,
      applicationsLimit:
        applicationsLimit && applicationsLimit > 0 ? applicationsLimit : null,
    };

    if (type === 'Edit' && creatorId && numberId) {
      const editModel: EditTrainingType = {
        ...model,
        creatorId,
        id: numberId,
      };

      try {
        await editTraining(editModel).unwrap();

        if (files.length > 0) {
          await uploadPdfs({ files, id: numberId });
        }
        setSuccessMessage(NOTIFICATION_LABELS.TRAINING_HAVE_BEEN_EDITED);
      } catch (error) {}
    } else {
      try {
        const { id } = await createTraining(model).unwrap();

        setCreatedId(id);

        if (files.length > 0) {
          await uploadPdfs({ files, id });
        }

        setSuccessMessage(
          type === 'Duplicate'
            ? NOTIFICATION_LABELS.TRAINING_HAS_BEEN_DUPLICATED
            : NOTIFICATION_LABELS.TRAINING_HAS_BEEN_CREATED,
        );
      } catch (error) {}
    }
  };

  const generateFormTitle = (): string => {
    if (type === 'Edit') return TRAINING_DETAILS.EDIT_TRAINING;

    if (type === 'Duplicate') return TRAINING_DETAILS.DUPLICATE_TRAINING;

    return TRAINING_DETAILS.CREATE_TRAINING;
  };

  const formTitle = generateFormTitle();

  const showConfirmation = getValues('costOption') === 'paid';
  
  const handleCreateConfirmation = () => {
      dispatch(
        openModal({
          modalType: ModalTypes.PAID_TRAINING_NOTE,
          modalData: {
            type: 'Creation',
            title: getValues('title'),
            onConfirm: methods.handleSubmit(onSubmitHandler),
          },
        }),
      );
  };

  return (
    <>
      <FormProvider {...methods}>
        <Stack
          className="create-training-form"
          component="form"
          noValidate
          spacing="2rem"
        >
          <Stack direction="row" spacing="2rem" alignItems="center">
            <Typography className="section-title">{formTitle}</Typography>
            {isAdmin && <PrivateToggle />}
          </Stack>
          <Divider />
          <Grid container spacing="2rem" alignItems="stretch">
            <Grid item xs={12} md={6}>
              <Stack spacing="2rem">
                <TrainingLinkInput />
                <TitleInput />
                <FormTrainingTypes />
                <LocationSection type="Training" />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <MarkdownTextArea label={TRAINING_DETAILS.DESCRIPTION} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatesSection datesRequired={false} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing="0.75rem">
                <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                  Price & Applications limit
                </Typography>
                <Stack spacing="2rem" direction="row" flexWrap="wrap">
                  <CostSection />
                  <ApplicationsLimitSection />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailsSection />
            </Grid>
            <Grid item xs={12} md={6}>
              <TrainerSection />
            </Grid>
            {(isAdmin || isManager) && (
              <Grid item xs={12} md={6}>
                <TrainingFormAdminSection
                  trainingId={createdId}
                  isAdmin={isAdmin}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Stack direction="row" flexWrap="wrap">
                <Button
                  className="event-form-btn-link"
                  startIcon={<UploadFileRoundedIcon />}
                  onClick={() => setUploadPdfModalOpened(true)}
                >
                  {ADD_EVENT_MODAL_LABELS.ATTACH_FILE}
                </Button>
                {files?.map((file) => (
                  <Button
                    color="secondary"
                    key={file.name}
                    className="event-form-btn-link"
                    startIcon={<CancelIcon />}
                    onClick={() => removeFile(file.name)}
                  >
                    {file.name}
                  </Button>
                ))}
              </Stack>
            </Grid>
          </Grid>
          <Divider />
          <TrainingFormFooter
            type={type}
            onClose={onCloseHandler}
            onClick={showConfirmation ? handleCreateConfirmation : methods.handleSubmit(onSubmitHandler)}
          />
        </Stack>
      </FormProvider>

      <BaseModal open={uploadPdfModalOpened} onClose={handleClosePdfModal}>
        <UploadPdf
          handleClosePdfModal={handleClosePdfModal}
          files={files}
          setFiles={setFiles}
        />
      </BaseModal>
    </>
  );
}

export default TrainingForm;
