import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormLabel, Grid, Stack, Typography } from '@mui/material';
import { TRAINING_DETAILS, DATES_LABELS } from '../../../cms/index';
import { CustomDateTimePicker } from '../index';

type DatesSectionProps = {
  datesRequired?: boolean;
};

const DatesSection = ({ datesRequired = false }: DatesSectionProps) => {
  const { control, watch, setValue } = useFormContext();
  const watchStartDate = watch('startDate');

  useEffect(() => {
    if (watchStartDate == null) {
      setValue('endDate', null, { shouldValidate: true });
      setValue('applyBeforeDate', null, { shouldValidate: true });
    }
  }, [watchStartDate]);

  return (
    <Stack spacing="0.75rem">
      <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
        {TRAINING_DETAILS.DATES_TEXT}
      </Typography>
      <Grid container spacing="1rem" className="dates-section">
        <Grid item xs={12} sm="auto" lg={4}>
          <Controller
            name="startDate"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <CustomDateTimePicker
                required={datesRequired}
                label={DATES_LABELS.START_DATE_TEXT}
                value={value}
                onChange={onChange}
                error={Boolean(error?.message)}
                helperText={error?.message}
                disablePast
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm="auto" lg={4}>
          <Controller
            name="endDate"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <CustomDateTimePicker
                required={datesRequired}
                label={DATES_LABELS.END_DATE_TEXT}
                value={value}
                onChange={onChange}
                error={Boolean(error?.message)}
                helperText={error?.message}
                minDate={watchStartDate}
                disablePast
                disabled={watchStartDate == null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm="auto" lg={4}>
          <Controller
            name="applyBeforeDate"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <CustomDateTimePicker
                required={datesRequired}
                label={DATES_LABELS.APPLY_BEFORE_TEXT}
                value={value}
                onChange={onChange}
                error={Boolean(error?.message)}
                helperText={error?.message}
                maxDate={watchStartDate}
                disablePast
                disabled={watchStartDate == null}
              />
            )}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default DatesSection;
