import { useState, useRef } from 'react';
import { PLACEHOLDERS } from '../../cms';
import BaseGroupSearch from '../BaseGroupSearch/BaseGroupSearch';
import ItemsInGroupPreview from '../ItemsInGroupPreview/ItemsInGroupPreview';
import { useGetAllTrainingsQuery } from '../../rtk/api/trainingApi';

function SearchAssignedTraining({
  searchBoxInputId,
  handleOnSelectedTrainingsChanged,
  formErrors,
  selectedTrainings,
  className,
}) {
  const { data } = useGetAllTrainingsQuery({
    pageNumber: 1,
    pageSize: 1000,
  });
  const { trainings = [] } = data || {};
  const selectedTrainingsIds = selectedTrainings.map((t) => Number(t.id));
  const unasignedTrainings = trainings
    .filter((t) => !selectedTrainingsIds.includes(Number(t.id)))
    .sort((a, b) => a.title.localeCompare(b.title));
  const [searching, setSearching] = useState(false);
  const typeaheadRef = useRef(null);

  const handleInputChange = () => {
    setSearching(true);
  };

  const onCloseClick = () => {
    typeaheadRef.current.clear();
    setSearching(false);
  };

  const multipleSelectionHandler = (selections) => {
    handleOnSelectedTrainingsChanged(selections);
  };

  return (
    <>
      <div>
        <BaseGroupSearch
          searching={searching}
          filteredItems={unasignedTrainings}
          typeaheadRef={typeaheadRef}
          handleInputChange={handleInputChange}
          onCloseClick={onCloseClick}
          filterBy={['title']}
          focus={false}
          formErrors={formErrors}
          searchBoxInputId={searchBoxInputId}
          placeholder={PLACEHOLDERS.SEARCH_TRAININGS}
          className={className}
          multiSelectionHandler={multipleSelectionHandler}
          multiSelection={selectedTrainings}
        />
        <div className="mt-2 items-preview">
          <ItemsInGroupPreview items={selectedTrainings} />
        </div>
      </div>
    </>
  );
}

export default SearchAssignedTraining;
