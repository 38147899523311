import aboutUsImage from '../../common/images/about-us.png';
import PolicySection from '../../common/components/PolicySection/PolicySection';
import { ROUTE_LINKS } from '../../cms';

const PrivacyNotice = () => {
  return (
    <div className="privacy-notice-page policy-page">
      <header>
        <img src={aboutUsImage} alt="Privacy Notice" />

        <h1>Privacy Notice</h1>

        <p>
          UpSkill Portal complies with the General Data Protection Regulation
          (“GDPR”) For purposes of this Notice, “UpSkill Portal” includes
          Strypes EOOD and its official affiliated companies. We collect
          information in connection with our products and services as well as
          how our website and applications automatically collects information.
        </p>
      </header>

      <PolicySection heading="1. What This Privacy Notice Covers">
        <p>
          If you are an individual employee or prospective employee of a UpSkill
          portal customer, this Notice does not apply to you. For more
          information on your privacy rights and your employer’s privacy
          practices, please refer to your employer’s privacy notices. Under
          applicable privacy laws, we are a Data Processor under the GDPR and
          your employer is the Data Controller under the GDPR. If you are a
          UpSkill portal customer, please see Section 11 of this Notice for
          information on collection, use, and sharing of Customer Data,
          including Personal Information.
          <br />
          This Notice does not apply to anonymized, de-identified or aggregate
          information if it is not Personal Information.
          <br />
          This Notice describes the rights available to you regarding our use of
          your Personal Information (defined below). Use of Personal Information
          collected through the UpSkill portal Services shall be limited to the
          purposes of providing the Services for which the individual has
          engaged UpSkill portal, as described in this Notice, and otherwise
          with your consent. Some information provided to us that may, either
          alone or when connected with other information to which we may have
          access, individually identify you.
        </p>
      </PolicySection>

      <PolicySection heading="2. What Personal Information We Collect">
        <p>
          Personal Information is information that identifies, relates to,
          describes, references, is capable of being associated with, or could
          reasonably be linked, directly or indirectly, with an individual or
          household. Below are the categories of information, including Personal
          Information that we may have collected or shared for a business
          purpose as permitted by law and depending on the Services you receive:
          <br />
          We collect your name, title, business phone number, company name,
          employer, and email address when you contact us to register for an
          event, request information, or register for a free trial. We also
          document the products or services you purchase from{' '}
          <a href="https://upskill-portal.com/landing">
            https://upskill-portal.com/landing
          </a>
          <br />
          We collect browsing and search history, usage of, and information
          regarding your use of our applications or website using cookies. For
          more information about cookies, please see Section 4 below.
          <br />
          This information may be used to create anonymous data to help us
          better understand customer preferences and needs. We draw inferences
          from the information we collect when you visit our website, use our
          app, or interact with our tools, widgets, or plug-ins.
          <br />
          We collect the city and state location of your device, which may
          include IP-based, GPS-based, Wi-Fi based, or cell-based location
          information. We collect cookies, as managed by you, as described in
          Section 4 of this Notice.
        </p>

        <h4>User Information</h4>
        <p>
          Every customer identifies a user (account owner, primary contact, or
          secondary contact) before using Upskill Portal. We collect your name,
          title, business phone number and email address. We use your
          information to set up the account and for service-related
          communications or to provide you with our newsletter when you
          requested.
        </p>
      </PolicySection>

      <PolicySection heading="3. How Long Do We Keep Your Personal Information">
        <p>
          We retain Personal Information about you necessary to fulfill the
          purpose for which that information was collected and if applicable, in
          accordance with your employer’s contract with us, consistent with
          applicable laws. When we destroy your Personal Information, we do so
          in a way that prevents that information from being restored or
          reconstructed.
          <br />
          Below are the sources from which we may receive your Personal
          Information:
        </p>

        <ul>
          <li>directly from you when you inquire about our Services</li>
          <li>from your device when you access our website</li>
          <li>
            from third parties that assist us in providing relevant Services
          </li>
        </ul>

        <p>
          We may combine Personal Information that you provide us through our
          website with other information we have received from you or your
          employer, whether online or offline, or from other sources such as
          from our service providers.
        </p>
      </PolicySection>

      <PolicySection heading="4. Cookies">
        <p>
          Cookies are small text files that are used to store small pieces of
          information. They are stored on your device when the website is loaded
          on your browser. These cookies help us make the website function
          properly, make it more secure, provide better user experience, and
          understand how the website performs and to analyze what works and
          where it needs improvement.
        </p>

        <h4>How do we use cookies</h4>
        <p>
          As most of the online services, our website uses first-party and
          third-party cookies for several purposes. First-party cookies are
          mostly necessary for the website to function the right way, and they
          do not collect any of your personally identifiable data.
        </p>

        <p>
          The third-party cookies used on our website are mainly for
          understanding how the website performs, how you interact with our
          website, keeping our services secure, providing advertisements that
          are relevant to you, and all in all providing you with a better and
          improved user experience and help speed up your future interactions
          with our website. For more information you can visit{' '}
          <a href={`https://upskillportal.eu${ROUTE_LINKS.COOKIE_POLICY}`}>
            {`https://upskillportal.eu${ROUTE_LINKS.COOKIE_POLICY}`}
          </a>
          .
        </p>
      </PolicySection>

      <PolicySection heading="5. How We Use and Disclose Your Personal Information for Business and Commercial Purposes">
        <p>
          We only disclose your personal information (i) at your express request
          or at the direction of your employer; (ii) to our partner or
          co-sponsor with your consent, and (iii) to our service providers for
          the business purpose(s) described below.
          <br />
          We may use or disclose the Personal Information listed in Section 2 of
          this Notice for the following purposes:
        </p>

        <h5>Business Purposes.</h5>
        <p>
          Upskill Portal uses information, including Personal Information,
          provided by you to provide the Services and for business purposes such
          as:
        </p>

        <ul>
          <li>
            Processing your registration for a training, an event, request for
            information, or a free trial.{' '}
          </li>
          <li>
            Processing and fulfilling orders, billing, implementation, service
            improvement, research, marketing and for other general business
            purposes.
          </li>
          <li>Keeping a record of our transactions and communications.</li>
          <li>
            Conducting audits and reporting related to transactions and
            interactions, including online interactions, you may have with us or
            others on our behalf.
          </li>
          <li>
            Operating and improving our website, performing analytics, and
            improving our Services.
          </li>
          <li>Helping to protect you and us from fraud or economic loss.</li>
          <li>
            Detecting, analyzing, and preventing security incidents, and other
            fraudulent or illegal activity.
          </li>
          <li>
            Identifying, debugging, and repairing errors in our systems or
            websites that impair existing functionality.{' '}
          </li>
        </ul>

        <h5>Consent.</h5>
        <p>
          Upskill Portal may disclose your Personal Information when you provide
          your consent and intentional direction. For example, when you request
          content or register for an event, you consent to receiving marketing
          communications from Upskill Portal and our third-party partner or
          co-sponsor of that content or event. For example, you may consent to
          participate in marketing, product, or customer service research or
          user surveys.{' '}
        </p>

        <h5>Legal Proceedings.</h5>
        <p>
          Upskill Portal will share your information, including Personal
          Information, to respond to investigations, court orders, legal
          process, or to investigate, prevent or act regarding illegal
          activities, suspected fraud, or situations{' '}
        </p>

        <h5>Service Providers.</h5>
        <p>
          Upskill Portal may also share Personal Information with our service
          providers, who help us to provide the Services, such as cloud storage,
          security, application communications, customer support, backup, and
          data analytics.{' '}
        </p>

        <h5>Merger, Acquisition, Sales.</h5>
        <p>
          If Upskill Portal is involved in a merger, acquisition, restructuring
          or sale of all or a portion of its assets, equity or similar
          transaction, Personal Information may be transferred to the acquiring
          person or entity and you will be notified via email and/or a prominent
          notice on our website of any change in ownership or uses of Personal
          Information, as well as any choices you may have regarding Personal
          Information. We will use reasonable efforts to direct the acquiring
          person or entity to use your Personal Information in a manner that is
          consistent with our Privacy Notice.
        </p>
      </PolicySection>

      <PolicySection heading="6. How We Protect Your Information">
        <p>
          The security of your Personal Information is important to us. When you
          enter information in our systems, we encrypt the transmission of that
          information using secure socket layer technology (SSL). The
          installation specifications will be aligned with the client.
          <br />
          Upskill Portal maintains a comprehensive written information security
          program that complies with applicable law and generally accepted
          industry standards. Our program is based on ISO 27001 requirements and
          includes appropriate administrative, technical, and physical
          safeguards, procedures, and practices to protect Personal Information
          submitted to us, both during transmission and once we receive it. No
          method or transmission over the Internet, or method of electronic
          storage, however, is 100% secure. Therefore, we cannot guarantee its
          absolute security.
          <br />
          We recommend that you use a unique password for your Upskill Portal
          account that is not associated with other websites. You should check
          your Upskill Portal account regularly to ensure that your Personal
          Information has not been tampered with or altered. You should only use
          the Services within a secure environment. Any suspicious activity
          regarding your account, including automated messages or calls from
          parties you cannot identify, should be reported to Upskill Portal
          support team using the contact information below. Upskill Portal
          secures your data using state-of-the-art encryption with Azure. Azure
          protects your data both at rest and in transit. Azure secures your
          data using various encryption methods, protocols, and algorithms,
          including double encryption.
        </p>
      </PolicySection>

      <PolicySection heading="7. Your Privacy Choices">
        <p>
          You may make certain choices regarding your Personal Information as
          permitted under applicable privacy laws. You may make these choices
          free of charge except as otherwise permitted under applicable law. We
          may limit our response to your requests as permitted under applicable
          law.
        </p>

        <h5>Right to Rectify or Correct</h5>
        <p>
          You may rectify or correct Personal Information that Upskill Portal
          has collected about you directly or indirectly, including Personal
          Information collected by a service provider or contractor on our
          behalf.
        </p>

        <h5>Right to Request Deletion</h5>
        <p>
          You may request that Upskill Portal delete your Personal Information,
          subject to certain limited exceptions. For example, we may retain an
          archived copy of your records consistent with applicable law, to
          continue to provide Services, or for other legitimate business
          purposes. We will use commercially reasonable efforts to honor your
          requests.
        </p>
      </PolicySection>

      <PolicySection heading="8. Grounds and purposes for processing data">
        <p>
          UpSkill portal collects and processes personal data based on one of
          the grounds below:
        </p>

        <ul className="alpha-list">
          <li>
            If this is necessary for the performance of an agreement to which
            you are a party or for the performance of precontractual acts at
            your request;{' '}
          </li>
          <li>If this is necessary to comply with a legal obligation;</li>
          <li>
            If this is necessary to promote our legitimate interests or the
            interests of a third party;{' '}
          </li>
          <li>With your consent.</li>
        </ul>

        <p>
          When UpSkill portal processes your personal data based on your
          consent, UpSkill portal will request this consent separately. You can
          withdraw your consent at any time.
        </p>

        <p>
          When UpSkill portal collects your personal data, these data are
          collected for one or more of the following purposes. We may collect
          your personal data for our business operations, sending out news
          items, to improve our services, to invite you to our events, to
          process questions left on our website, or to improve the performance
          of our website.
        </p>
      </PolicySection>
      <PolicySection heading="9. International Transfers">
        <p>
          If personal data is to be transferred outside the European Economic
          Area, we will only do so if necessary and to the extent permitted by
          law.
        </p>
      </PolicySection>

      <PolicySection heading="10. Rights of data subjects">
        <p>
          You have the following rights with regard to the processing of your
          personal data by UpSkill portal:{' '}
        </p>

        <ul>
          <li>
            Right to access: you have the right to inspect the personal data we
            process concerning you.
          </li>
          <li>
            Right to rectification: you have the right to have the personal data
            we process concerning you corrected or supplemented if the personal
            data is incorrect or incomplete.
          </li>
          <li>
            Right to withdraw your consent: you may simply withdraw your consent
            at any time.
          </li>
          <li>
            Right to object: you may object to the processing of your personal
            data.
          </li>
          <li>
            Right to erasure: you may request us to erase your personal data.
          </li>
          <li>
            Right to data portability: if it is technically possible, you have
            the right to have the personal data we process concerning you
            transferred to a third party.
          </li>
          <li>
            Right to restriction of processing: in some cases, you can request
            to restrict the processing of your personal data, whether
            temporarily or otherwise, which means that we will process fewer
            data concerning you.
          </li>
        </ul>

        <p>
          To prevent that we provide data to an incorrect person, we may ask for
          additional information to verify your identity.{' '}
        </p>

        <p>
          We will assess your request in accordance with the General Data
          Protection Regulation (GDPR). In principle, we will inform you whether
          we can comply with your request within one month after receiving that
          request. This term may be extended by two months in specific cases,
          for example in the event of a complex request. We will inform you
          about such an extension within one month after having received your
          request.
        </p>
      </PolicySection>
      <PolicySection heading="11. UpSkill portal Customer Data">
        <p>
          As discussed in Section 2 above, this Notice does not apply to
          individual employees or prospective employees of UpSkill portal
          customers. Such individuals should access the customer or employer’s
          privacy notice.
          <br />
          Customer Data is collected by UpSkill portal as a Data Processor
          and/or Service Provider. UpSkill portal may also collect customer
          Data, including Personal Information included in the Data, under the
          direction of its customers and has no direct relationship with the
          individuals whose Personal Information it processes. UpSkill portal
          may collect Personal Information that identifies, relates to,
          describes, references, or is reasonably capable of being associated
          with the customer including location data. UpSkill portal may collect
          only strictly necessary or required cookies within the UpSkill portal
          Application and website.
        </p>

        <h4>How We Use and Disclose Customer Data</h4>
        <p>
          UpSkill portal will not disclose customer Data except as compelled by
          applicable law or as expressly authorized by the customer in the Terms
          of Service or in writing and will only retain and use the Data for the
          purpose of providing the Customer with the Service or to comply with
          applicable laws, including:
        </p>

        <ul>
          <li>
            <h6>Legal purposes</h6>
            <p>
              UpSkill portal will share your information, including Personal
              Information required by law or an order of a court of competent
              jurisdiction to disclose customer Data, UpSkill portal will
              promptly notify the customer of this requirement, if permitted by
              the court or applicable law, so that the customer may seek a
              protective order or other appropriate relief.
            </p>
          </li>
          <li>
            <h6>Service Providers</h6>
            <p>
              UpSkill portal may also share customer Data, including Personal
              Information included in the Data, with our service providers, who
              help us to provide the Services, such as cloud storage, security,
              application communications, customer support, backup, data
              analytics. We will ensure that those service providers are
              obligated to protect customer Data under contract terms which are
              no less protective than those set out in this Privacy Notice.{' '}
            </p>
          </li>
          <li>
            <h6>Mergers, Acquisitions, Sales</h6>
            <p>
              If UpSkill portal is involved in a merger, acquisition,
              restructuring or sale of all or a portion of its assets, equity or
              similar transaction, your Data, including Personal Information
              included in the Data, may be transferred to the acquiring person
              or entity and the customer will be notified via email and/or a
              prominent notice on our website of any change in ownership or uses
              of customer Data, as well as any choices the customer may have
              regarding their Data. We will use reasonable efforts to direct the
              acquiring person or entity to use customer Personal Information in
              a manner that is consistent with our Privacy Notice.
            </p>
          </li>
        </ul>

        <h5>Privacy Rights Requests</h5>
        <p>
          Customers are responsible for processing privacy requests from their
          prospective employees, employees, or independent contractors who have
          data in the Upskill Portal Service. If we receive the request, we will
          forward the request to the customer to process according to applicable
          privacy laws.
        </p>

        <h5>Privacy Concerns and Complaints</h5>
        <p>
          UpSkill portal will forward any concerns or complaints from your
          employees to you.
        </p>
      </PolicySection>
      <PolicySection heading="12. Changes to Our Privacy Notice">
        <p>
          {' '}
          We reserve the right to amend this Notice at our discretion and at any
          time. We will do so by updating this Notice. Amended terms take effect
          upon being incorporated into this Notice, and your continued use of
          the website or association with your employer following the posting of
          any changes constitutes acceptance of any updated terms. If the
          changes will materially affect the way we use your Personal
          Information in connection with Services that we have already
          collected, we will notify you or your employer.
        </p>
      </PolicySection>
    </div>
  );
};
export default PrivacyNotice;
