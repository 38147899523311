import ErrorIcon from '@mui/icons-material/Error';
import './ErrorMessage.scss';
import { COLORS } from '../../../cms';

type ErrorMessageProps = {
  text: string;
};

const ErrorMessage = ({ text }: ErrorMessageProps) => (
  <div className="error-message">
    <ErrorIcon
      sx={{
        fontSize: '1.5rem',
        color: COLORS.ERROR,
      }}
      className="error-message-icon"
    />
    <p className="error-message-text">{text}</p>
  </div>
);

export default ErrorMessage;
