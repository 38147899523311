import {
  StyledToggleButtonGroup,
  StyledToggleButton,
  SkillTypeIcon,
} from '../index';
import { COLORS, EVENT_TYPES } from '../../../cms';
import { SkillTypes } from '../../../cms/enums';

type EventTypesProps = {
  values: SkillTypes[];
  onChange: (newValues: SkillTypes[]) => void;
};

const EventTypes = ({ values, onChange }: EventTypesProps) => {
  return (
    <StyledToggleButtonGroup
      value={values}
      onChange={(_, newValues: SkillTypes[]) => onChange(newValues)}
      aria-label="event types"
    >
      {EVENT_TYPES.map(({ id, name }) => (
        <StyledToggleButton className="skill-type" key={id} value={id}>
          <SkillTypeIcon
            skillType={id}
            size={16}
            color={
              values.find((i) => i === id) ? COLORS.WHITE : COLORS.SECONDARY
            }
          />
          {name}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

export default EventTypes;
