import { useSearchParams } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import { EventShortResponse } from '../../../../../../../cms/types/eventTypes';
import {
  getEventDatePeriod,
  getEventTimePeriod,
} from '../../../../../../../cms/Utils';
import './EventsSelectItem.scss';

type EventsSelectItemProps = {
  event: EventShortResponse;
  isSelected: boolean;
  onClick: (event: EventShortResponse) => void;
};

const EventsSelectItem = ({
  event,
  isSelected,
  onClick,
}: EventsSelectItemProps) => {
  const [, setSearchParams] = useSearchParams();
  const { id, title, agenda, location, cost, startDate, endDate } = event;
  const description = `${location} | ${
    cost > 0 ? 'Paid' : 'Free'
  } | ${getEventDatePeriod(startDate, endDate)}`;
  const description2 = getEventTimePeriod(agenda);

  const handleOnTitleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setSearchParams((prev) => {
      prev.set('eventId', id.toString());
      return prev;
    });
  };

  return (
    <Stack
      onClick={() => onClick(event)}
      direction="row"
      spacing="1rem"
      className={`events-select-item${
        isSelected ? ' events-select-item-selected' : ''
      }`}
    >
      <Stack className="events-select-item-icon-container">
        <EventIcon className="events-select-item-icon" />
      </Stack>
      <Stack className="events-select-item-data">
        <Typography
          noWrap
          className="events-select-item-data-title"
          onClick={handleOnTitleClick}
        >
          {title}
        </Typography>
        <Typography className="events-select-item-data-sub-title">
          {description}
        </Typography>
        <Typography className="events-select-item-data-sub-title">
          {description2}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default EventsSelectItem;
