import React from 'react';
// css
import './FileDropZoneError.scss';

import { FILE_UPLOAD_DROP_ZONE } from '../../../../../cms';
import errorIcon from '../../../../images/error-24px.svg';

interface Props {
  errorMessage: string;
}

export default function FileDropZoneError({ errorMessage }: Props) {
  return (
    <div className="upload-file-error-container">
      <img
        src={errorIcon}
        alt="error icon"
        width={13}
        className="inline-block mt-n1"
      />
      <span className="pl-1">{FILE_UPLOAD_DROP_ZONE.UPLOAD_FAILED}</span>
      <br />
      <span className="pl-1">{errorMessage}</span>
    </div>
  );
}
