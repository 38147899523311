import {
  ARCHIVE_EMPLOYEE_REQUEST,
  ARCHIVE_EMPLOYEE_REQUEST_ERROR,
  ARCHIVE_EMPLOYEE_REQUEST_SUCCESS,
} from './types';

/** Action - Archive an employee

* @return {Object} Return object
*/
export function archiveEmployees() {
  return {
    type: ARCHIVE_EMPLOYEE_REQUEST,
  };
}

/** On success return archived employee id
 * @param {string} isSuccessful - if get successful
 * @return {any} Return object
 */
export function archiveEmployeesSuccess(isSuccessful: boolean) {
  return {
    type: ARCHIVE_EMPLOYEE_REQUEST_SUCCESS,
    payload: {
      isSuccessful,
    },
  };
}

/** Handle error
 * @param {unknown} errorMessage - Error message
 */
export function archiveEmployeesError(errorMessage: unknown) {
  return {
    type: ARCHIVE_EMPLOYEE_REQUEST_ERROR,
    payload: errorMessage,
  };
}
