import { put, take, call } from 'redux-saga/effects';
import { REMOVE_MEMBER_FROM_GROUP_REQUEST } from './types';
import { removeMemberSuccess } from './actions';
import { handleValidationErrors } from '../../../../../../../../common/validation/actions';

/** If detect type: REMOVE_MEMBER_FROM_GROUP_REQUEST call removeMember */
export default function* removeMemberSaga(api) {
  while (true) {
    const removeMemberRequest = yield take(REMOVE_MEMBER_FROM_GROUP_REQUEST);
    if (removeMemberRequest.payload) {
      const { removeMemberModel } = removeMemberRequest.payload;
      yield call(removeMember, api, removeMemberModel);
    }
  }
}

/** Remove member */
function* removeMember(api, removeMemberModel) {
  try {
    yield call(api.removeMember, removeMemberModel);

    const isSuccessful = true;
    yield put(removeMemberSuccess(isSuccessful));
  } catch (error) {
    yield put(handleValidationErrors(error.response.data));
  }
}
