import { useEffect, useState } from 'react';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import './TrainingsSelect.scss';
import {
  InfiniteScrollList,
  SearchField,
  TrainingTypes,
} from '../../../../../../common/components';
import { useAppDispatch, useAppSelector } from '../../../../../../rtk/hooks';
import { useDebounce } from '../../../../../../common/hooks/useDebounce';
import { useGetAllTrainingsQuery } from '../../../../../../rtk/api/trainingApi';
import {
  setTrainingsTypes,
  incrementTrainingsPageNumber,
  resetTrainingsPagination,
  setTrainingsSearchText,
} from '../../../../../../rtk/features/trainingsPaginationSlice';
import {
  BUTTON_LABELS,
  NOT_FOUND_MESSAGES,
  PLACEHOLDERS,
} from '../../../../../../cms';
import { SkillTypes } from '../../../../../../cms/enums';
import { TrainingShortResponse } from '../../../../../../cms/types/trainingTypes';
import TrainingsSelectCard from './TrainingsSelectCard/TrainingsSelectCard';

type TrainingsSelectProps = {
  title: string;
  submitBtnText: string;
  isLoading?: boolean;
  trainingIdsToHide?: number[];
  onSubmit: (trainings: TrainingShortResponse[]) => void;
};

const TrainingsSelect = ({
  title,
  submitBtnText,
  isLoading,
  trainingIdsToHide = [],
  onSubmit,
}: TrainingsSelectProps) => {
  const dispatch = useAppDispatch();
  const trainingsPagination = useAppSelector(
    (state) => state.trainingsPagination,
  );
  const debouncedSearchValue = useDebounce(
    trainingsPagination.searchText.trim().toLowerCase(),
    300,
  );
  const { data: trainingsData, isFetching } = useGetAllTrainingsQuery(
    {
      ...trainingsPagination,
      searchText: debouncedSearchValue,
      types: trainingsPagination.types.join(','),
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { trainings = [], totalcount = 0 } = trainingsData || {};
  const availableTrainings = trainings.filter(
    (t) => !trainingIdsToHide.includes(t.id),
  );
  const hiddenTrainingsCount = trainings.length - availableTrainings.length;
  const totalAvailableTrainings = totalcount - hiddenTrainingsCount;
  const [selectedTrainings, setSelectedTrainings] = useState<
    TrainingShortResponse[]
  >([]);

  useEffect(() => {
    return () => {
      dispatch(resetTrainingsPagination());
    };
  }, []);

  const fetchData = () => {
    dispatch(incrementTrainingsPageNumber());
  };

  const handleOnTypesChange = (newTypes: SkillTypes[]) => {
    dispatch(setTrainingsTypes(newTypes));
  };

  const handleOnSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTrainingsSearchText(e.target.value));
  };

  const handleOnTrainingClick = (training: TrainingShortResponse) => {
    if (selectedTrainings.findIndex((t) => t.id === training.id) > -1) {
      setSelectedTrainings((prev) => prev.filter((t) => t.id !== training.id));
    } else {
      setSelectedTrainings((prev) => [...prev, training]);
    }
  };

  return (
    <Stack className="trainings-select" spacing="1.5rem">
      <Typography className="trainings-select-title">
        {title}{' '}
        {selectedTrainings.length > 0 &&
          `(Selected: ${selectedTrainings.length})`}
      </Typography>
      <Stack spacing="1rem" padding="0 2rem">
        <SearchField
          placeholder={PLACEHOLDERS.SEARCH_TRAININGS}
          value={trainingsPagination.searchText}
          onChange={handleOnSearchTextChange}
          className="trainings-select-search"
          sx={{
            width: '100%',
          }}
        />
        <TrainingTypes
          values={trainingsPagination.types}
          onChange={handleOnTypesChange}
        />
      </Stack>
      <Stack className="trainings-select-list-container">
        <InfiniteScrollList
          className="trainings-select-list"
          emptyMessage={NOT_FOUND_MESSAGES.TRAININGS}
          fetchData={fetchData}
          isFetching={isFetching}
          currentCount={availableTrainings.length}
          totalCount={totalAvailableTrainings}
        >
          {availableTrainings.map((training) => (
            <TrainingsSelectCard
              key={training.id}
              training={training}
              isSelected={
                selectedTrainings.findIndex((t) => t.id === training.id) > -1
              }
              onClick={handleOnTrainingClick}
            />
          ))}
        </InfiniteScrollList>
        <Divider className="trainings-select-list-divider" />
      </Stack>
      <Stack spacing="1rem" className="trainings-select-footer">
        <Button variant="outlined" onClick={() => onSubmit([])}>
          {BUTTON_LABELS.CANCEL}
        </Button>
        <LoadingButton
          className="trainings-select-submit-btn"
          disabled={selectedTrainings.length === 0}
          variant="contained"
          onClick={() => onSubmit(selectedTrainings)}
          loading={isLoading}
          startIcon={<AddCircleIcon />}
          loadingPosition="start"
        >
          {submitBtnText}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default TrainingsSelect;
