import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import {
  SpeakerSchema,
  SpeakerSchemaType,
} from '../../cms/validation/eventValidation';
import { ADD_EVENT_MODAL_LABELS } from '../../cms';
import { CustomTextField, ImageDropzone } from '../../common/components';
import { SpeakerResponse } from '../../cms/types/eventTypes';

type SpeakerFormProps = {
  speakerToEdit: SpeakerResponse | null;
  onCancelEdit: () => void;
  onSubmit: (data: SpeakerSchemaType) => void;
};

const SpeakerForm = ({
  speakerToEdit,
  onCancelEdit,
  onSubmit,
}: SpeakerFormProps) => {
  const methods = useForm<SpeakerSchemaType>({
    resolver: zodResolver(SpeakerSchema),
    defaultValues: {
      index: -1,
      firstName: '',
      lastName: '',
      jobTitle: '',
      company: '',
      photoUrl: null,
    },
  });

  useEffect(() => {
    if (speakerToEdit != null) {
      methods.reset(speakerToEdit, { keepDefaultValues: true });
    } else {
      methods.reset();
    }
  }, [speakerToEdit]);

  const handleOnSubmit = (data: SpeakerSchemaType) => {
    onSubmit(data);

    if (data.index === -1) methods.reset();
  };

  return (
    <Stack
      component="form"
      noValidate
      spacing="2rem"
      onSubmit={methods.handleSubmit(handleOnSubmit)}
    >
      <Typography className="section-title">
        {speakerToEdit
          ? ADD_EVENT_MODAL_LABELS.EDIT_SPEAKER
          : ADD_EVENT_MODAL_LABELS.ADD_SPEAKER}
      </Typography>
      <Grid container spacing="1rem">
        <Grid item xs={12} md={6}>
          <Controller
            name="firstName"
            control={methods.control}
            render={({
              field: { name, onBlur, value, onChange, ref },
              fieldState: { error },
            }) => (
              <CustomTextField
                color="secondary"
                required
                fullWidth
                label="First Name"
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                error={Boolean(error)}
                helperText={error?.message}
                inputProps={{
                  className: 'speaker-first-name-input',
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="lastName"
            control={methods.control}
            render={({
              field: { name, onBlur, value, onChange, ref },
              fieldState: { error },
            }) => (
              <CustomTextField
                color="secondary"
                required
                fullWidth
                label="Last Name"
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                error={Boolean(error)}
                helperText={error?.message}
                inputProps={{
                  className: 'speaker-last-name-input',
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="jobTitle"
            control={methods.control}
            render={({
              field: { name, onBlur, value, onChange, ref },
              fieldState: { error },
            }) => (
              <CustomTextField
                color="secondary"
                fullWidth
                label="Job Title"
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                error={Boolean(error)}
                helperText={error?.message}
                inputProps={{
                  className: 'speaker-job-title-input',
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="company"
            control={methods.control}
            render={({
              field: { name, onBlur, value, onChange, ref },
              fieldState: { error },
            }) => (
              <CustomTextField
                color="secondary"
                fullWidth
                label="Company"
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                error={Boolean(error)}
                helperText={error?.message}
                inputProps={{
                  className: 'speaker-company-input',
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Controller
        name="photoUrl"
        control={methods.control}
        render={({ field: { value, onChange } }) => (
          <ImageDropzone image={value} onImageChange={onChange} />
        )}
      />
      <Stack
        direction="row"
        flexWrap="wrap"
        gap="1rem"
        sx={{ marginLeft: 'auto' }}
      >
        {speakerToEdit && (
          <Button
            variant="outlined"
            className="speaker-cancel-btn"
            onClick={onCancelEdit}
          >
            {ADD_EVENT_MODAL_LABELS.CANCEL}
          </Button>
        )}
        <Button
          type="submit"
          variant="outlined"
          className="speaker-submit-btn"
          sx={{
            marginLeft: 'auto',
          }}
        >
          {speakerToEdit
            ? ADD_EVENT_MODAL_LABELS.SAVE_CHANGES
            : ADD_EVENT_MODAL_LABELS.ADD_SPEAKER}
        </Button>
      </Stack>
    </Stack>
  );
};

export default SpeakerForm;
