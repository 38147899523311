import { FormLabel, Stack } from '@mui/material';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import './MarkdownTextArea.scss';
import { Controller, useFormContext } from 'react-hook-form';

type MarkdownTextAreaProps = {
  label: string;
};

const MarkdownTextArea = ({ label }: MarkdownTextAreaProps) => {
  const { control } = useFormContext();

  return (
    <Stack height="100%">
      <FormLabel sx={{ fontSize: 12 }}>{label}</FormLabel>
      <Controller
        name="description"
        control={control}
        render={({ field: { value, onChange, onBlur, ref } }) => (
          <MDEditor
            ref={ref}
            onBlur={onBlur}
            value={value}
            preview="edit"
            highlightEnable={false}
            onChange={onChange}
            className="description-markdown"
            data-color-mode="light"
            previewOptions={{
              rehypePlugins: [[rehypeSanitize]],
            }}
            commandsFilter={(cmd) => (cmd.name === 'image' ? false : cmd)}
          />
        )}
      />
    </Stack>
  );
};

export default MarkdownTextArea;
