import ArchiveIcon from '@mui/icons-material/Archive';
import { Button } from '@mui/material';
import {
  BUTTON_LABELS,
  ENVIRONMENT_NAMES,
  ROLES,
} from '../../../../../../../cms';
import config from '../../../../../../../config';
import { getUserRoles } from '../../../../../../../common/userRoleRetriever';

type ArchiveEmployeesButtonProps = {
  setShowArchiveEmployeesConfirmation: Function;
};

const ArchiveEmployeesButton = ({
  setShowArchiveEmployeesConfirmation,
}: ArchiveEmployeesButtonProps) => {
  const onClickHandler = () => {
    setShowArchiveEmployeesConfirmation(true);
  };

  return (
    <Button
      variant="outlined"
      className="archive-employees-btn"
      disabled={
        config.ENVIRONMENT === ENVIRONMENT_NAMES.TRIAL &&
        !getUserRoles().includes(ROLES.SUPER_ADMIN_ROLE)
      }
      sx={{
        width: { xs: '100%', sm: 'auto' },
      }}
      onClick={onClickHandler}
      startIcon={<ArchiveIcon />}
    >
      {BUTTON_LABELS.ARCHIVE_EMPLOYEES_BUTTON}
    </Button>
  );
};

export default ArchiveEmployeesButton;
