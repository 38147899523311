import React from 'react';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { createRoot } from 'react-dom/client';
import './index.css';
import 'react-toggle/style.css';
import 'rc-time-picker/assets/index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './common/global-styles.scss';
import App from './components/App/App';

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

const app = document.getElementById('root');
const root = createRoot(app);

root.render(<App />);

window.React = React;
window.ReactDOM = ReactDOM;
