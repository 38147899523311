import { UPDATE_EMPLOYEE_SEARCH_CRITERIA } from '../components/MyGroup/MyTeam/MyEmployees/EmployeeSearch/types';
import { UPDATE_PROFESSIONS_SUCCESS } from '../components/MyGroup/MyTeam/MyEmployees/Professions/types';
import {
  GET_MY_TEAM_INFO_REQUEST_SUCCESS,
  GET_MY_TEAM_INFO_REQUEST_ERROR,
} from '../components/MyGroup/MyTeam/types';
/** Store states
 * @constant {Object} */
export const initialState = {
  professions: [],
  employees: [],
  searchCriteria: '',
};

/** Paths reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function myTeamReducer(state = initialState, action) {
  switch (action.type) {
    /** Action Creator - Update search criteria
     * it will receive a string with the search criteria
     */
    case UPDATE_EMPLOYEE_SEARCH_CRITERIA:
      return {
        ...state,
        searchCriteria: action.payload.searchCriteria,
      };
    case UPDATE_PROFESSIONS_SUCCESS:
      return {
        ...state,
        professions: action.payload.professions,
      };
    case GET_MY_TEAM_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        professions: action.payload.isInitialLoad
          ? action.payload.professions
          : state.professions,
        employees: action.payload.employees,
      };
    case GET_MY_TEAM_INFO_REQUEST_ERROR:
      return {
        ...state,
        professions: [],
        employees: [],
        searchCriteria: '',
      };
    default:
      return state;
  }
}

export default myTeamReducer;
