import { useEffect } from 'react';
import { Container, Stack } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { CustomTabs } from '../../common/components';
import { MY_GROUP_LABELS, ROUTE_LINKS } from '../../cms';
import { TabState } from '../../cms/types';
import { useGetMyTeamApplicationsQuery } from '../../rtk/api/adminApi';
import { ApplicationStatuses } from '../../cms/enums';

const MyGroupPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data: applications } = useGetMyTeamApplicationsQuery({
    search: '',
    type: 'All',
    status: ApplicationStatuses.PENDING,
  });
  const pendingCountElement = ` (${applications?.length})`;

  useEffect(() => {
    if (
      !pathname.includes(ROUTE_LINKS.MY_EMPLOYEES) &&
      !pathname.includes(ROUTE_LINKS.REQUESTS)
    )
      navigate(ROUTE_LINKS.MY_EMPLOYEES, { replace: true });
  }, [pathname]);

  const tabs: TabState[] = [
    {
      label: MY_GROUP_LABELS.MY_EMPLOYEES,
      link: ROUTE_LINKS.MY_EMPLOYEES,
    },
    {
      label: `${MY_GROUP_LABELS.REQUESTS}${
        applications ? pendingCountElement : ''
      }`,
      link: ROUTE_LINKS.REQUESTS,
    },
  ];

  if (pathname === ROUTE_LINKS.MY_GROUP) return null;

  return (
    <Container maxWidth="lg">
      <Stack className="my-group" padding={{ xs: '1rem', sm: '2rem' }}>
        <CustomTabs tabs={tabs} divider value={pathname} />
        <Outlet />
      </Stack>
    </Container>
  );
};

export default MyGroupPage;
