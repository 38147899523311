import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { adminApi } from '../api/adminApi';

type TraininigApplicationsPaginationState = {
  pageNumber: number;
  pageSize: number;
  search: string;
  refetch: number;
};

const initialState: TraininigApplicationsPaginationState = {
  pageNumber: 1,
  pageSize: 20,
  search: '',
  refetch: 0,
};

const trainingApplicationsPaginationSlice = createSlice({
  name: 'trainingApplicationsPagination',
  initialState,
  reducers: {
    incrementTrainingApplicationsPageNumber: (state) => {
      state.pageNumber++;
    },
    setTrainingApplicationsSearch: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      return {
        ...state,
        pageNumber: 1,
        search: payload,
      };
    },
    resetTrainingApplicationsPagination: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(adminApi.endpoints.removeUserFromTraining.matchFulfilled),
      (state) => {
        return {
          ...state,
          pageNumber: 1,
          refetch: state.refetch + 1,
        };
      },
    );
  },
});

export const {
  incrementTrainingApplicationsPageNumber,
  setTrainingApplicationsSearch,
  resetTrainingApplicationsPagination,
} = trainingApplicationsPaginationSlice.actions;
export default trainingApplicationsPaginationSlice.reducer;
