import { Toolbar, Drawer, List, ListItem } from '@mui/material';
import './AppNotificationsDrawer.scss';
import { useAppDispatch, useAppSelector } from '../../../rtk/hooks';
import { DRAWER_TYPES } from '../../../cms';
import { closeDrawer } from '../../../rtk/features/drawerSlice';
import { useGetNotificationsQuery } from '../../../rtk/api/notificationApi';
import Notification from '../Notification/Notification';
import { NotificationType } from '../../../cms/types';

const AppNotificationsDrawer = () => {
  const dispatch = useAppDispatch();
  const { data: notifications } = useGetNotificationsQuery();
  const { openedDrawer } = useAppSelector((state) => state.drawer);

  const handleCloseDrawer = () => {
    dispatch(closeDrawer());
  };

  return (
    <Drawer
      sx={{
        width: '100%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '100%',
          boxSizing: 'border-box',
        },
      }}
      variant="temporary"
      open={openedDrawer === DRAWER_TYPES.NOTIFICATIONS}
      onClose={handleCloseDrawer}
      anchor="top"
    >
      <Toolbar className="notification-drawer-toolbar" />
      <List className="notification-drawer-list" disablePadding>
        <ListItem divider className="notification-drawer-list-title">
          Notifications
        </ListItem>
        {notifications?.map((n: NotificationType, index: number) => (
          <ListItem
            key={n.rowKey}
            divider={notifications.length !== index + 1}
            className="notification-drawer-list-item"
          >
            <Notification notification={n} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default AppNotificationsDrawer;
