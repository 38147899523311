import { NotificationType } from '../../cms/types';
import { api } from './api';

export type MarkNotificationAsReadParams = {
  partitionKey?: string;
  rowKey?: string;
};

export const notificationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getNotifications: build.query<NotificationType[], void>({
      query: () => `/notification`,
      providesTags: ['Notifications'],
      forceRefetch: () => true,
    }),
    markNotificationAsRead: build.mutation<any, MarkNotificationAsReadParams>({
      query: (notification) => ({
        url: `/notification/update`,
        method: 'POST',
        body: notification,
      }),
      invalidatesTags: ['Notifications'],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useLazyGetNotificationsQuery,
  useMarkNotificationAsReadMutation,
} = notificationApi;
