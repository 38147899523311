import { useState } from 'react';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { COLORS } from '../../../cms';
import { MenuOptions } from '../../../cms/types';

type DotMenuProps = {
  options: MenuOptions[];
  btnClassName?: string;
  menuClassName?: string;
};

const DotMenu = ({ options, btnClassName, menuClassName }: DotMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const handleOnMoreBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOnMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <IconButton
        id="menu-actions-btn"
        className={btnClassName}
        aria-label="actions"
        aria-controls={menuOpen ? 'menu' : undefined}
        aria-expanded={menuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOnMoreBtnClick}
      >
        <MoreVertIcon sx={{ fontSize: 18, color: COLORS.TEXT }} />
      </IconButton>
      <Menu
        id="dot-menu-actions-menu"
        className={menuClassName}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        MenuListProps={{
          'aria-labelledby': 'menu-actions-btn',
          className: 'menu-actions-menu-list',
        }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleOnMenuClose}
      >
        {options.map(
          ({ label, icon, show = true, action, className }) =>
            show && (
              <MenuItem
                id={label.toLowerCase()}
                className={`menu-option${className ? ` ${className}` : ''}`}
                key={label}
                onClick={() => {
                  action();
                  handleOnMenuClose();
                }}
              >
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText primaryTypographyProps={{ fontSize: 14 }}>
                  {label}
                </ListItemText>
              </MenuItem>
            ),
        )}
      </Menu>
    </Box>
  );
};

export default DotMenu;
