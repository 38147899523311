import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FaHandHoldingUsd } from 'react-icons/fa';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { PRICE_LABELS } from '../../../cms';
import { EventSchemaType } from '../../../cms/validation/eventValidation';
import { CustomTextField } from '..';

const CostSection = () => {
  const { control, setValue, watch, trigger } =
    useFormContext<EventSchemaType>();
  const watchCostOption = watch('costOption');
  const watchCost = watch('cost');

  useEffect(() => {
    if (watchCostOption === 'free') {
      setValue('cost', 0);
      trigger('cost');
    }
  }, [watchCostOption]);

  useEffect(() => {
    if (watchCost > 0) {
      setValue('costOption', 'paid');
    }
  }, [watchCost]);

  return (
    <Stack className="cost-section" direction="row" columnGap="1.25rem">
      <Controller
        name="costOption"
        control={control}
        render={({ field: { value, onChange } }) => (
          <FormControl>
            <FormLabel
              id="cost-option-radio-buttons-group-label"
              sx={{ fontSize: 12, margin: 0 }}
              color="secondary"
            >
              Price
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="cost-option-radio-buttons-group-label"
              name="cost-option-radio-buttons-group"
              value={value}
              onChange={onChange}
            >
              <FormControlLabel
                value="free"
                control={
                  <Radio
                    color="secondary"
                    size="small"
                    sx={{ fontSize: 18 }}
                    className="free-radio-btn"
                  />
                }
                label={<span style={{ fontSize: 14 }}>Free</span>}
              />
              <FormControlLabel
                value="paid"
                control={
                  <Radio
                    color="secondary"
                    size="small"
                    sx={{ fontSize: 18 }}
                    className="paid-radio-btn"
                  />
                }
                label={<span style={{ fontSize: 14 }}>Paid</span>}
              />
            </RadioGroup>
          </FormControl>
        )}
      />
      <Controller
        name="cost"
        control={control}
        render={({
          field: { name, value, onChange, onBlur, ref },
          fieldState: { error },
        }) => (
          <CustomTextField
            required={watchCostOption === 'paid'}
            color="secondary"
            type="number"
            label="Price (in euro)"
            placeholder={PRICE_LABELS.PRICE_TEXT}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            inputRef={ref}
            error={Boolean(error)}
            helperText={error?.message}
            disabled={watchCostOption !== 'paid'}
            inputProps={{
              className: 'cost-input',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaHandHoldingUsd />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Stack>
  );
};

export default CostSection;
