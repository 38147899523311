import { Stack, Typography } from '@mui/material';
import './TimeFrameSection.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { PathSchemaType } from '../../../../cms/validation/pathValidation';
import { CustomTextField } from '../../../../common/components';

const TimeFrameSection = () => {
  const { control } = useFormContext<PathSchemaType>();

  return (
    <Stack
      className="time-frame-section"
      spacing="1.5rem"
      alignItems="flex-start"
    >
      <Typography className="time-frame-section-title">
        2. Set Time Frame (Optional)
      </Typography>
      <Controller
        name="durationInMonths"
        control={control}
        render={({
          field: { name, value, onChange, onBlur, ref },
          fieldState: { error },
        }) => (
          <CustomTextField
            className="path-duration-text-field"
            label="Time Frame (Months)"
            color="secondary"
            type="number"
            name={name}
            value={value}
            onChange={onChange}
            inputRef={ref}
            onBlur={onBlur}
            error={Boolean(error)}
            helperText={error?.message}
            inputProps={{
              className: 'path-duration-input',
            }}
          />
        )}
      />
    </Stack>
  );
};

export default TimeFrameSection;
