import { Stack, Typography, Divider, Button, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { BUTTON_LABELS, ROUTE_LINKS } from '../../../cms';
import { inheritFontStyle } from '../../../cms/Utils';
import { useAppSelector } from '../../../rtk/hooks';

type PaidTrainingNoteModalProps = {
  type: 'Creation' | 'Application';
  trainingTitle: string;
  onConfirm: () => void;
  onClose: () => void;
};

const PaidTrainingNoteModal = ({
  type,
  trainingTitle,
  onConfirm,
  onClose,
}: PaidTrainingNoteModalProps) => {
  const { firstName, lastName } = useAppSelector((state) => state.userSession);
  const fullName = `${firstName} ${lastName}`;

  const message = type === 'Creation' ? 'created' : 'applied for';

  return (
    <Stack width="500px" maxWidth="100%">
      <Stack gap="2rem" padding="2rem 1.6rem" fontSize="14px">
        <Typography sx={{ ...inheritFontStyle }}>Dear {fullName},</Typography>

        <Typography sx={{ ...inheritFontStyle }}>
          Thank you for your interest in our courses on the Upskill Portal!
        </Typography>

        <Typography sx={{ ...inheritFontStyle }}>
          We would like to inform you that the training you have {message},
          <b>{` "${trainingTitle}" `}</b> is not covered under our business
          account.
          <br />
          You can find another similar course on the topic at our business page{' '}
          <a href="https://strypes.udemy.com" target="_blank">
            strypes.udemy.com
          </a>{' '}
          , if you don't have credentials, please contact Liliya Zlatanova (
          <a href="mailto:liliya.zlatanova@ict.eu">liliya.zlatanova@ict.eu</a>).
        </Typography>

        <Stack
          flexDirection="row"
          justifyContent="flex-end"
          marginTop="2rem"
          gap={'1rem'}
        >
          <Button variant="outlined" onClick={onClose}>
            {BUTTON_LABELS.DROP_IT}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            {type === 'Application'
              ? BUTTON_LABELS.APPLY_ANYWAY
              : BUTTON_LABELS.CREATE_ANYWAY}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PaidTrainingNoteModal;
