import { useState } from 'react';
import { Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { BUTTON_LABELS } from '../../../../../../cms/index';
import './CreateGroupButton.scss';
import CreateGroupForm from './CreateGroupForm/CreateGroupForm';
import { BaseModal } from '../../../../../../common/components';

function CreateGroupButton() {
  const [showCreateGroupForm, setShowCreateGroupForm] = useState(false);
  const handleCreateGroupModalHideAttempt = () => setShowCreateGroupForm(false);
  const createGroup = () => setShowCreateGroupForm(true);

  return (
    <>
      <Button
        variant="contained"
        className="create-group-btn"
        onClick={createGroup}
        startIcon={<AddCircleIcon />}
        sx={{ width: { xs: '100%', sm: 'auto' } }}
      >
        {BUTTON_LABELS.CREATE_GROUP}
      </Button>
      <BaseModal
        showCloseBtnOutside
        open={showCreateGroupForm}
        onClose={handleCreateGroupModalHideAttempt}
      >
        <CreateGroupForm
          groupFormData={{}}
          onCloseHandler={handleCreateGroupModalHideAttempt}
        />
      </BaseModal>
    </>
  );
}

export default CreateGroupButton;
