import { useState } from 'react';
import UserImportModalContent from './UserImportModalContent/UserImportModalContent';
import UserImportSteps from './UserImportSteps/UserImportSteps';
import './UserImport.scss';

interface Props {
  setImportUsersModalOpened: Function;
}

export default function UserImport({ setImportUsersModalOpened }: Props) {
  const [showUserImportCsv, setShowUserImportCsv] = useState(false);

  const handleCloseModal = () => {
    setImportUsersModalOpened(false);
    setShowUserImportCsv(false);
  };

  return (
    <div className="import-users-csv-container">
      {showUserImportCsv ? (
        <UserImportModalContent handleCloseUserImportModal={handleCloseModal} />
      ) : (
        <UserImportSteps setShowUserImportCsv={setShowUserImportCsv} />
      )}
    </div>
  );
}
