import { useEffect } from 'react';
import {
  Button,
  Divider,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import dayjs from 'dayjs';
import { GiReceiveMoney } from 'react-icons/gi';
import { BiCalendarEvent, BiCalendarAlt } from 'react-icons/bi';
import { MdLocationOn } from 'react-icons/md';
import './EventDetails.scss';
import {
  TRAINING_DETAIL_LABELS,
  DATE_FORMATS,
  EVENT_DETAIL_LABELS,
  COLORS,
} from '../../cms';
import EventDetailsOverview from '../DetailsOverview/EventDetailsOverview/EventDetailsOverview';
import SpeakersDetails from './SpeakersDetails/SpeakersDetails';
import AgendaDetails from './AgendaDetails/AgendaDetails';
import EventDetailsHeader from './EventDetailsHeader/EventDetailsHeader';
import participantsIcon from '../../common/images/participants-icon.png';
import { useGetEventQuery } from '../../rtk/api/eventApi';
import { SectionLoader } from '../../common/components';
import EventDetailsItem from './EventDetailsItem/EventDetailsItem';

type EventDetailsProps = {
  eventId: number;
  onClose: () => void;
};

export default function EventDetails({ onClose, eventId }: EventDetailsProps) {
  const theme = useTheme();
  const isScreenXS = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: event, isError } = useGetEventQuery(eventId, {
    refetchOnMountOrArgChange: true,
  });

  const eventLocation =
    (event?.eventLocation?.length ?? 0) > 0
      ? event!.eventLocation
      : TRAINING_DETAIL_LABELS.NOT_AVAILABLE;

  const eventUrl =
    event?.url.substring(0, 3) === 'www' ? `//${event?.url}` : event?.url;

  const eventDetailsItems = event && [
    {
      name: TRAINING_DETAIL_LABELS.PRICE,
      icon: <GiReceiveMoney size={24} color={COLORS.SECONDARY} />,
      value: `${event.cost}€`,
    },
    {
      name: TRAINING_DETAIL_LABELS.DATES,
      icon: <BiCalendarEvent size={24} color={COLORS.SECONDARY} />,
      value:
        event.startDate && event.endDate
          ? `${dayjs(event.startDate).format(DATE_FORMATS.NORMAL)} - 
        ${dayjs(event.endDate).format(DATE_FORMATS.NORMAL)}`
          : TRAINING_DETAIL_LABELS.SELF_PACED,
    },
    {
      name: TRAINING_DETAIL_LABELS.APPLY_BEFORE,
      icon: <BiCalendarAlt size={24} color={COLORS.SECONDARY} />,
      value: event.applyBeforeDate
        ? dayjs(new Date(event.applyBeforeDate)).format(DATE_FORMATS.NORMAL)
        : TRAINING_DETAIL_LABELS.NO_DEADLINE,
    },
    {
      name: TRAINING_DETAIL_LABELS.LOCATION,
      icon: <MdLocationOn size={24} color={COLORS.SECONDARY} />,
      value: event.location === 'Online' ? event.location : eventLocation,
    },
    ...(event.applicationsLimit
      ? [
          {
            name: TRAINING_DETAIL_LABELS.PARTICIPANTS,
            icon: <img src={participantsIcon} alt="Participants" width={24} />,
            value: `${event.activeApplications} (of ${event.applicationsLimit} available)`,
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (isError) onClose();
  }, [isError]);

  if (!event) return <SectionLoader />;

  return (
    <Stack
      direction="column"
      gap="1.5rem"
      sx={{
        padding: '2rem',
      }}
      className="event-details"
    >
      <EventDetailsHeader event={event} />
      <Divider />
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        flexWrap="wrap"
        divider={!isScreenXS && <Divider orientation="vertical" flexItem />}
        gap="1.5rem"
      >
        {eventDetailsItems?.map((item) => (
          <EventDetailsItem key={item.name} item={item} />
        ))}
      </Stack>
      <Stack direction="row" flexWrap="wrap">
        {event.url?.length > 0 && (
          <Button
            component="a"
            target="_blank"
            color="secondary"
            startIcon={<LaunchIcon />}
            href={eventUrl}
            className="event-details-btn-link"
          >
            {EVENT_DETAIL_LABELS.PREVIEW_EVENT}
          </Button>
        )}
      </Stack>
      <Grid
        container
        spacing="2rem"
        className="event-details-sections"
        flex={1}
      >
        <Grid item xs={12} md={5}>
          <AgendaDetails
            agenda={event.agenda}
            className="event-details-section"
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <EventDetailsOverview
            description={event.description}
            className="event-details-section"
          />
        </Grid>
        <Grid item xs={12}>
          <SpeakersDetails speakers={event.speakers} />
        </Grid>
      </Grid>
    </Stack>
  );
}
