import { Chip, SxProps, Theme } from '@mui/material';
import { ApplicationStatuses } from '../../../cms/enums';
import { APPLICATION_STATUSES } from '../../../cms';

type ApplicationStatusChipProps = {
  status: ApplicationStatuses;
  className?: string;
};

const ApplicationStatusChip = ({
  status,
  className,
}: ApplicationStatusChipProps) => {
  const label =
    APPLICATION_STATUSES.find((item) => item.value === status)?.label ?? status;
  let sx: SxProps<Theme>;

  switch (status) {
    case ApplicationStatuses.IN_PROGRESS:
      sx = { backgroundColor: '#F4F3FF', color: '#4B1FB8' };
      break;
    case ApplicationStatuses.COMPLETED:
      sx = { backgroundColor: '#E1FCEF', color: '#13804A' };
      break;
    case ApplicationStatuses.DROPPED:
      sx = { backgroundColor: '#FAF0F3', color: '#CC0100' };
      break;
    case ApplicationStatuses.PENDING:
      sx = { backgroundColor: '#FEF1C6', color: '#B54707' };
      break;
    case ApplicationStatuses.REJECTED:
      sx = { backgroundColor: '#FAF0F3', color: '#CC0100' };
      break;
    case ApplicationStatuses.APPROVED:
      sx = { backgroundColor: '#D2E9FF', color: '#2D3282' };
      break;
    default:
      sx = { backgroundColor: '#D2E9FF', color: '#2D3282' };
      break;
  }

  return (
    <Chip
      label={label}
      className={className}
      sx={{
        ...sx,
        borderRadius: '1rem',
        padding: '0.35rem 1rem',
        fontSize: '14px',
        height: 'auto',
        fontWeight: '500',
        '& .MuiChip-label': {
          paddingLeft: '0',
          paddingRight: '0',
        },
      }}
    />
  );
};

export default ApplicationStatusChip;
