import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { eventApi } from '../api/eventApi';
import { SkillTypes } from '../../cms/enums';

export type EventsPaginationState = {
  pageNumber: number;
  pageSize: number;
  types: SkillTypes[];
  searchText: string;
  refetch: number;
};

const initialState: EventsPaginationState = {
  pageNumber: 1,
  pageSize: 18,
  searchText: '',
  types: [],
  refetch: 0,
};

const eventsPaginationSlice = createSlice({
  name: 'eventsPagination',
  initialState,
  reducers: {
    incrementEventsPageNumber: (state: EventsPaginationState) => {
      state.pageNumber++;
    },
    setEventsTypes: (
      state: EventsPaginationState,
      { payload }: PayloadAction<SkillTypes[]>,
    ) => {
      return {
        ...state,
        pageNumber: 1,
        types: payload,
      };
    },
    setEventsSearchText: (
      state: EventsPaginationState,
      { payload }: PayloadAction<string>,
    ) => {
      return {
        ...state,
        pageNumber: 1,
        searchText: payload,
      };
    },
    resetEventsPagination: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        eventApi.endpoints.createEvent.matchFulfilled,
        eventApi.endpoints.deleteEvent.matchFulfilled,
        eventApi.endpoints.editEvent.matchFulfilled,
        eventApi.endpoints.uploadEventPdfs.matchFulfilled,
      ),
      (state: EventsPaginationState) => {
        return {
          ...state,
          pageNumber: 1,
          refetch: state.refetch + 1,
        };
      },
    );
  },
});

export const {
  incrementEventsPageNumber,
  setEventsTypes,
  setEventsSearchText,
  resetEventsPagination,
} = eventsPaginationSlice.actions;
export default eventsPaginationSlice.reducer;
