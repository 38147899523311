import { useEffect, useState } from 'react';
import {
  HubConnectionBuilder,
  DefaultHttpClient,
  LogLevel,
} from '@microsoft/signalr';
import { NOTIFICATIONS_HUB } from '../../api/trainingsPortalBackend/config';
import { useAppSelector } from '../../rtk/hooks';
import { useLazyGetNotificationsQuery } from '../../rtk/api/notificationApi';

const NotificationClient = () => {
  const { accessToken, userId } = useAppSelector((state) => state.userSession);
  const [updateNotifications] = useLazyGetNotificationsQuery();
  const [connection, setConnection] = useState(null);

  class CustomHttpClient extends DefaultHttpClient {
    send(request) {
      if (accessToken != null) {
        request.headers = { ...request.headers, Authorization: accessToken };
        return super.send(request);
      }
    }
  }

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withUrl(NOTIFICATIONS_HUB, { httpClient: new CustomHttpClient() })
      .build();
    setConnection(connect);
  }, [accessToken]);

  useEffect(() => {
    try {
      if (connection) {
        connection
          .start()
          .then(() => {
            connection.on('notification', () => {
              updateNotifications(userId);
            });
          })
          .catch(() => {});
      }
    } catch {}
  }, [connection]);

  return <></>;
};

export default NotificationClient;
