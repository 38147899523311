import { ZodTypeAny, z } from 'zod';
import dayjs from 'dayjs';
import {
  APPLICATIONS_LIMITED_OPTIONS,
  COST_OPTIONS,
  TRAINING_LEVELS,
  VALIDATION_MESSAGES,
} from '..';
import { LOCATION_TYPES, SkillTypes } from '../enums';
import { sanitizeData } from '../Utils';

const zeroAsRequired = (pipe: ZodTypeAny) =>
  z.coerce
    .number()
    .refine((value) => value !== 0, { message: VALIDATION_MESSAGES.REQUIRED })
    .pipe(pipe);

export const TrainingFormSchema = z
  .object({
    title: z.string().min(1, {
      message: VALIDATION_MESSAGES.REQUIRED,
    }),
    description: z.string().optional(),
    url: z
      .string()
      .url({ message: VALIDATION_MESSAGES.VALID_LINK })
      .or(z.literal('')),
    costOption: z.enum(COST_OPTIONS),
    cost: z.coerce.number(),
    trainerFirstName: z.string().optional(),
    trainerLastName: z.string().optional(),
    trainerJobTitle: z.string().optional(),
    trainingLocationAddress: z.string(),
    duration: zeroAsRequired(z.number().int().positive()),
    level: z.enum(TRAINING_LEVELS),
    lessons: z.coerce.number().int().nonnegative().optional(),
    type: z.nativeEnum(SkillTypes),
    location: z.enum(LOCATION_TYPES),
    startDate: z.coerce.date().nullish(),
    endDate: z.coerce.date().nullish(),
    applyBeforeDate: z.coerce.date().nullish(),
    isPrivate: z.boolean().optional(),
    applicationsLimitOption: z.enum(APPLICATIONS_LIMITED_OPTIONS),
    applicationsLimit: z.coerce.number().int().nullable(),
  })
  .transform((data) => {
    return {
      ...data,
      description: sanitizeData(data.description ?? ''),
    };
  })
  .refine(
    ({ startDate, endDate }) => {
      if (startDate && !endDate) return false;

      return true;
    },
    {
      message: VALIDATION_MESSAGES.REQUIRED,
      path: ['endDate'],
    },
  )
  .refine(
    ({ startDate, applyBeforeDate }) => {
      if (startDate && !applyBeforeDate) return false;

      return true;
    },
    {
      message: VALIDATION_MESSAGES.REQUIRED,
      path: ['applyBeforeDate'],
    },
  )
  .refine(
    ({ startDate, applyBeforeDate }) => {
      if (
        startDate &&
        applyBeforeDate &&
        dayjs(applyBeforeDate).isAfter(startDate, 'minute')
      )
        return false;

      return true;
    },
    {
      message: VALIDATION_MESSAGES.BEFORE_OR_EQUAL_TO_START_DATE,
      path: ['applyBeforeDate'],
    },
  )
  .refine(
    ({ startDate, endDate }) => {
      if (startDate && endDate && dayjs(endDate).isBefore(startDate, 'minute'))
        return false;

      return true;
    },
    {
      message: VALIDATION_MESSAGES.AFTER_OR_EQUAL_TO_START_DATE,
      path: ['endDate'],
    },
  )
  .refine(
    ({ costOption, cost }) => {
      if (costOption === 'paid' && cost <= 0) return false;

      return true;
    },
    {
      message: VALIDATION_MESSAGES.POSITIVE_NUMBER,
      path: ['cost'],
    },
  )
  .refine(
    ({ applicationsLimitOption, applicationsLimit }) => {
      if (
        applicationsLimitOption === 'yes' &&
        applicationsLimit !== null &&
        applicationsLimit <= 0
      )
        return false;

      return true;
    },
    {
      message: VALIDATION_MESSAGES.POSITIVE_NUMBER,
      path: ['applicationsLimit'],
    },
  )
  .refine(
    ({ location, trainingLocationAddress }) => {
      if (location !== 'Online' && !trainingLocationAddress) return false;

      return true;
    },
    {
      message: VALIDATION_MESSAGES.REQUIRED,
      path: ['trainingLocationAddress'],
    },
  );

export type TrainingFormSchemaType = z.infer<typeof TrainingFormSchema>;
