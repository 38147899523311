import { useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import GroupTrainingCard from './GroupTrainingCard/GroupTrainingCard';
import { ADD_GROUP_MODAL_LABELS } from '../../../../../../../../cms';
import AddTrainingsToGroup from '../AddTrainingsToGroup/AddTrainingsToGroup';
import './GroupTrainings.scss';
import { BaseModal } from '../../../../../../../../common/components';

export default function GroupTrainings({ group }) {
  const [addTrainingsModalVisible, setAddTrainingsModalVisible] =
    useState(false);

  const openAddTrainingsModal = () => {
    setAddTrainingsModalVisible(true);
  };

  const closeAddTrainingsModal = () => {
    setAddTrainingsModalVisible(false);
  };

  return (
    <>
      <Stack direction="row" spacing="1rem" alignItems="center" flexWrap="wrap">
        <Typography className="group-trainings-count">
          Assigned Trainings: {group.trainings.length}
        </Typography>
        <Button
          className="add-training-to-group"
          onClick={openAddTrainingsModal}
          startIcon={<AddCircleIcon />}
        >
          {ADD_GROUP_MODAL_LABELS.ASSIGN_TRAINING}
        </Button>
      </Stack>
      {group.trainings.length > 0 && (
        <div className="w-100">
          <div className="d-flex all-group-trianings-container">
            {group.trainings.map((groupTraining) => {
              return (
                <div key={groupTraining.id}>
                  <GroupTrainingCard
                    groupTraining={groupTraining}
                    groupId={group.id}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}

      <BaseModal
        open={addTrainingsModalVisible}
        onClose={closeAddTrainingsModal}
      >
        <AddTrainingsToGroup onClose={closeAddTrainingsModal} group={group} />
      </BaseModal>
    </>
  );
}
