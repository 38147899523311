import { Avatar, IconButton, Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { extractInitials } from '../../../../cms/Utils';
import './SpeakerItem2.scss';
import { SpeakerResponse } from '../../../../cms/types/eventTypes';
import { SpeakerSchemaType } from '../../../../cms/validation/eventValidation';

type SpeakerItem2Props = {
  speaker: SpeakerResponse;
  speakerIndex: number;
  onStartEdit: (speaker: SpeakerSchemaType) => void;
  onDelete: (index: number) => void;
};

const SpeakerItem2 = ({
  speaker,
  speakerIndex,
  onStartEdit,
  onDelete,
}: SpeakerItem2Props) => {
  const fullName = `${speaker.firstName} ${speaker.lastName}`;

  return (
    <Stack
      direction="row"
      className="speaker-item-2"
      gap="1rem"
      alignItems="center"
    >
      <Avatar
        className="speaker-avatar-2"
        src={`data:image/png;base64, ${speaker.photoUrl}`}
        alt="Speaker"
        variant="circular"
      >
        {extractInitials(fullName)}
      </Avatar>
      <Stack direction="column" sx={{ flex: 1 }}>
        <Typography title={fullName} className="speaker-name-2" noWrap>
          {fullName}
        </Typography>
        {speaker.jobTitle && speaker.jobTitle?.length > 0 && (
          <Typography
            title={speaker.jobTitle}
            className="speaker-job-title-2"
            noWrap
          >
            {speaker.jobTitle}
          </Typography>
        )}
        {speaker.company && speaker.company?.length > 0 && (
          <Typography
            title={speaker.company}
            className="speaker-company-2"
            noWrap
          >
            ({speaker.company})
          </Typography>
        )}
      </Stack>
      <Stack direction="row">
        <IconButton
          aria-label="Edit"
          color="secondary"
          className="speaker-item-edit-btn"
          onClick={() => onStartEdit({ ...speaker, index: speakerIndex })}
        >
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton
          aria-label="Delete"
          color="error"
          className="speaker-item-delete-btn"
          onClick={() => onDelete(speakerIndex)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default SpeakerItem2;
