import { put, take, call } from 'redux-saga/effects';
import { REMOVE_TRAINING_FROM_GROUP_REQUEST } from './types';
import { removeTrainingSuccess } from './actions';
import { handleValidationErrors } from '../../../../../../../../common/validation/actions';

/** If detect type: REMOVE_TRAINING_FROM_GROUP_REQUEST call removeTraining */
export default function* removeTrainingSaga(api) {
  while (true) {
    const removeTrainingRequest = yield take(
      REMOVE_TRAINING_FROM_GROUP_REQUEST,
    );
    if (removeTrainingRequest.payload) {
      const { removeTrainingModel } = removeTrainingRequest.payload;
      yield call(removeTraining, api, removeTrainingModel);
    }
  }
}

/** Remove training */
function* removeTraining(api, removeTrainingModel) {
  try {
    yield call(api.removeTraining, removeTrainingModel);

    const isSuccessful = true;
    yield put(removeTrainingSuccess(isSuccessful));
  } catch (error) {
    yield put(handleValidationErrors(error.response.data));
  }
}
