import { useFormContext } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import GeneralInformationSection from './sections/GeneralInformationSection/GeneralInformationSection';
import TimeFrameSection from './sections/TimeFrameSection/TimeFrameSection';
import ContentSection from './sections/ContentSection/ContentSection';
import { BUTTON_LABELS } from '../../cms';
import { PathSchemaType } from '../../cms/validation/pathValidation';
import './PathForm.scss';

type PathFormProps = {
  onCancel: () => void;
  onSubmit: (values: PathSchemaType) => void;
  title: string;
  submitBtnLabel: string;
  isEditMode?: boolean;
};

const PathForm = ({
  onSubmit,
  onCancel,
  title,
  submitBtnLabel,
  isEditMode = false,
}: PathFormProps) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext<PathSchemaType>();

  return (
    <Stack
      className="path-form"
      spacing="2rem"
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing="1rem">
        <Stack direction="row" spacing="1rem" className="path-form-header">
          <Tooltip title="Go back">
            <IconButton onClick={onCancel}>
              <ArrowBackIosNewIcon className="path-form-header-btn" />
            </IconButton>
          </Tooltip>
          <Typography className="path-form-header-title">{title}</Typography>
        </Stack>
        <Divider />
      </Stack>
      <GeneralInformationSection />
      <TimeFrameSection />
      <ContentSection />

      <Stack spacing="1.5rem">
        <Divider />
        <Stack
          direction="row"
          flexWrap="wrap"
          spacing="1rem"
          justifyContent="flex-end"
        >
          <Button
            variant="outlined"
            onClick={onCancel}
            className="path-form-cancel-btn"
          >
            {BUTTON_LABELS.CANCEL}
          </Button>
          <LoadingButton
            loading={isSubmitting}
            startIcon={isEditMode ? <EditIcon /> : <AddCircleIcon />}
            loadingPosition="start"
            variant="contained"
            type="submit"
            className="path-form-create-btn"
          >
            {submitBtnLabel}
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PathForm;
