import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Box,
  Button,
  Container,
  Divider,
  Fab,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import './PathsPage.scss';
import {
  Loader,
  PathCard,
  PathCategories,
  SearchField,
} from '../../common/components';
import { useGetPathsQuery } from '../../rtk/api/pathApi';
import { useAppDispatch, useAppSelector } from '../../rtk/hooks';
import { useDebounce } from '../../common/hooks/useDebounce';
import {
  incrementPathsPageNumber,
  resetPathsPagination,
  setPathsSearchText,
  setPathsTypes,
} from '../../rtk/features/pathsPagincationSlice';
import { NOT_FOUND_MESSAGES, ROUTE_LINKS } from '../../cms';
import { useNavigateBack } from '../../common/hooks/useNavigateBack';

const PathsPage = () => {
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const [searchParams] = useSearchParams();
  const employeeId = searchParams.get('employeeId');
  const employeeName = searchParams.get('employeeName');
  const dispatch = useAppDispatch();
  const pathsPagination = useAppSelector((state) => state.pathsPagination);
  const debouncedSearchValue = useDebounce(
    pathsPagination.searchText.trim().toLowerCase(),
    300,
  );
  const { data, isLoading } = useGetPathsQuery(
    {
      ...pathsPagination,
      types: pathsPagination.types.join(','),
      searchText: debouncedSearchValue,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { entities: paths = [], totalCount = 0 } = data || {};
  const hasMore =
    totalCount - pathsPagination.pageNumber * pathsPagination.pageSize > 0;
  const isAssignPath = !!employeeId && !!employeeName;

  useEffect(() => {
    return () => {
      dispatch(resetPathsPagination());
    };
  }, []);

  const handleOnTypesChange = (newTypes: any) => {
    dispatch(setPathsTypes(newTypes));
  };

  const handleOnSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPathsSearchText(e.target.value));
  };

  const fetchData = () => {
    dispatch(incrementPathsPageNumber());
  };

  const handleOnCreatePathBtnClick = () => {
    navigate(ROUTE_LINKS.CREATE_PATH);
  };

  const handleOnBackBtnClick = () => {
    navigateBack({ fallback: ROUTE_LINKS.MY_EMPLOYEES });
  };

  return (
    <Container maxWidth="lg">
      <Stack
        className="paths-page"
        spacing="2rem"
        padding={{ xs: '1rem', md: '2rem' }}
      >
        <Stack spacing="1rem">
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
          >
            {isAssignPath && (
              <Stack direction="row" alignItems="center" spacing="1rem">
                <IconButton onClick={handleOnBackBtnClick}>
                  <ArrowBackIosNewIcon className="path-details-page-header-btn" />
                </IconButton>
                <Typography className="paths-page-title">
                  {`Assign Path To ${employeeName}`}
                </Typography>
              </Stack>
            )}
            {!isAssignPath && (
              <>
                <Typography className="paths-page-title">Paths</Typography>

                <Button
                  variant="contained"
                  className="paths-page-create-btn"
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'flex',
                    },
                  }}
                  onClick={handleOnCreatePathBtnClick}
                >
                  Create Path
                </Button>
                <Fab
                  size="large"
                  color="primary"
                  aria-label="Create Path"
                  className="paths-page-create-fab"
                  sx={{
                    display: {
                      xs: 'flex',
                      sm: 'none',
                    },
                  }}
                  onClick={handleOnCreatePathBtnClick}
                >
                  <AddIcon />
                </Fab>
              </>
            )}
          </Stack>
          <Divider />
        </Stack>
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="space-between"
          spacing="1rem"
        >
          <SearchField
            value={pathsPagination.searchText}
            onChange={handleOnSearchTextChange}
            placeholder="Search paths..."
            sx={{
              width: { xs: '100%', sm: 300 },
            }}
          />
          <PathCategories
            value={pathsPagination.types}
            onChange={handleOnTypesChange}
          />
        </Stack>
        {isLoading && <Loader />}
        {!isLoading && (
          <Box className="paths-list">
            {paths.length === 0 ? (
              <Typography className="paths-list-not-found">
                {NOT_FOUND_MESSAGES.PATHS}
              </Typography>
            ) : (
              <InfiniteScroll
                className="paths-list-infinite-scroll"
                dataLength={paths.length}
                next={fetchData}
                hasMore={hasMore}
                loader={<Loader />}
                scrollableTarget="scrollableDiv"
              >
                {paths.map((path) => (
                  <PathCard key={path.id} path={path} />
                ))}
              </InfiniteScroll>
            )}
          </Box>
        )}
      </Stack>
    </Container>
  );
};

export default PathsPage;
