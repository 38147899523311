import { Dispatch, SetStateAction } from 'react';
import ScrollList from '../ScrollList/ScrollList';
import { EmployeeCard } from '../index';
import { PathAssignee } from '../../../cms/types/pathTypes';
import './EmployeeList.scss';
import { Employee } from '../../../cms/types';

type EmployeeListprops = {
  employees: PathAssignee[] | Employee[];
  emptyMessage: string;
  className?: string;
  selectedEmployeeIds?: number[];
  setSelectedEmployeeIds?: Dispatch<SetStateAction<number[]>>;
  onEmployeeRemove?: (employeeId: number) => void;
  disabled?: boolean;
  emptyMessageAlignment?: 'left' | 'center';
};

const EmployeeList = ({
  employees,
  selectedEmployeeIds,
  setSelectedEmployeeIds,
  emptyMessage,
  className,
  onEmployeeRemove,
  disabled = false,
  emptyMessageAlignment,
}: EmployeeListprops) => {
  const handleOnEmployeeCheckedChange = (employeeId: number) => {
    setSelectedEmployeeIds!((prev) => {
      if (prev.includes(employeeId))
        return prev.filter((eId) => eId !== employeeId);

      return [...prev, employeeId];
    });
  };

  return (
    <ScrollList
      count={employees.length}
      emptyMessage={emptyMessage}
      className={`employee-list${className ? ` ${className}` : ''}`}
      emptyMessageAlignment={emptyMessageAlignment}
    >
      {employees.map((employee, index) => (
        <EmployeeCard
          key={employee.id}
          employee={employee}
          isLast={index + 1 === employees.length}
          onRemove={onEmployeeRemove}
          isChecked={selectedEmployeeIds?.includes(employee.id)}
          onCheckedChange={handleOnEmployeeCheckedChange}
          disabled={disabled}
        />
      ))}
    </ScrollList>
  );
};

export default EmployeeList;
