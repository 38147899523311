import { NavLink } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './DashboardSection.scss';
import { DashboardSectionProps } from '../types';
import { COLORS, GO_TO_SECTION_TEXT } from '../../../cms';

const DashboardSection = ({ section, classNames }: DashboardSectionProps) => {
  const { title, link, component, linkState } = section;

  return (
    <div className={classNames}>
      <div className="dashboard-section-header">
        <h4>{title}</h4>
        {link && (
          <NavLink
            to={link}
            className="dashboard-section-link-btn"
            state={linkState}
          >
            {GO_TO_SECTION_TEXT}
            <ArrowForwardIosIcon
              sx={{
                fontSize: 12,
                color: COLORS.TEXT,
              }}
            />
          </NavLink>
        )}
      </div>
      <hr className="dashboard-section-header-divider" />
      <div className="dashboard-section-content">{component}</div>
    </div>
  );
};

export default DashboardSection;
