import { useState } from 'react';
import { Stack } from '@mui/material';
import { CustomTabs } from '../index';
import { TabState } from '../../../cms/types';

type CustomTabsWithOutletProps = {
  tabs: TabState[];
  divider?: boolean;
  initialTabIndex?: number;
  className?: string;
};

const CustomTabsWithOutlet = ({
  tabs,
  divider,
  initialTabIndex = 0,
  className = '',
}: CustomTabsWithOutletProps) => {
  const [tabIndex, setTabIndex] = useState(initialTabIndex);

  const handleTabChange = (
    _: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    setTabIndex(newValue);
  };
  return (
    <Stack spacing="1rem">
      <CustomTabs
        value={tabIndex}
        onChange={handleTabChange}
        divider={divider}
        tabs={tabs}
        className={className}
      />
      {{ ...tabs.map(({ element }) => element) }[tabIndex]}
    </Stack>
  );
};

export default CustomTabsWithOutlet;
