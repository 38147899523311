import {
  DEACTIVATE_EMPLOYEE_REQUEST,
  DEACTIVATE_EMPLOYEE_REQUEST_ERROR,
  DEACTIVATE_EMPLOYEE_REQUEST_SUCCESS,
} from './types';

/** Action - Deactivate an employee

* @param {Number[]} ids - Employee id to deactivate
* @return {Object} Return object
*/
export function deactivateEmployees(ids: number[]) {
  return {
    type: DEACTIVATE_EMPLOYEE_REQUEST,
    payload: {
      ids,
    },
  };
}

/** On success return deactivated employee id
 * @param {string} isSuccessful - if get successful
 * @param {Number} deactivatedEmployeeId - deactivated employee id
 * @return {any} Return object
 */
export function deactivateEmployeesSuccess(
  isSuccessful: boolean,
  deactivatedEmployeeIds: number[],
) {
  return {
    type: DEACTIVATE_EMPLOYEE_REQUEST_SUCCESS,
    payload: {
      isSuccessful,
      deactivatedEmployeeIds,
    },
  };
}

/** Handle error
 * @param {unknown} errorMessage - Error message
 */
export function deactivateEmployeesError(errorMessage: unknown) {
  return {
    type: DEACTIVATE_EMPLOYEE_REQUEST_ERROR,
    payload: errorMessage,
  };
}
