import dayjs from 'dayjs';
import { IconButton, Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import './AgendaEventItem.scss';
import { AgendaEventItemResponse } from '../../../../cms/types/eventTypes';
import { DATE_FORMATS } from '../../../../cms';
import { AgendaEventItemSchemaType } from '../../../../cms/validation/eventValidation';

type AgendaEventItemProps = {
  item: AgendaEventItemResponse;
  itemIndex: number;
  showActions: boolean;
  onStartEdit?: (item: AgendaEventItemSchemaType) => void;
  onDelete?: (item: AgendaEventItemSchemaType) => void;
};

const AgendaEventItem = ({
  item,
  itemIndex,
  showActions,
  onStartEdit = () => {},
  onDelete = () => {},
}: AgendaEventItemProps) => {
  const { startTime, endTime, agendaTopic } = item;

  return (
    <Stack direction="row" gap="1rem" alignItems="flex-start">
      <Typography className="agenda-item-time">
        {dayjs(startTime).format(DATE_FORMATS.HOUR_MINUTES)} -{' '}
        {dayjs(endTime).format(DATE_FORMATS.HOUR_MINUTES)}
      </Typography>
      <Typography noWrap sx={{ flex: 1 }} className="agenda-item-topic">
        {agendaTopic}
      </Typography>
      {showActions && (
        <Stack direction="row">
          <IconButton
            aria-label="Edit"
            color="secondary"
            className="agenda-item-edit-btn"
            onClick={() => onStartEdit({ ...item, index: itemIndex })}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="Delete"
            className="agenda-item-delete-btn"
            onClick={() => onDelete({ ...item, index: itemIndex })}
            color="error"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};

export default AgendaEventItem;
