import { useState, useEffect } from 'react';
import './InternalLogin.scss';
import config from '../../config';
import { MODAL_VALUES } from '../../cms';
import MicrosoftLoginButton from '../MicrosoftLoginButton/MicrosoftLoginButton';
import GoogleLoginButton from '../GoogleLoginButton/GoogleLoginButton';
import { prepareErrorText } from '../../common/validation/helpers';
import { resetValidationErrors } from '../../common/validation/actions';
import { resetReduxStore } from '../../rtk/rootReducer';
import { BaseModal, ErrorMessage } from '../../common/components';
import { useAppDispatch, useAppSelector } from '../../rtk/hooks';

const InternalLogin = () => {
  const dispatch = useAppDispatch();
  const { validationErrors, haveBeenReset } = useAppSelector(
    (state) => state.validationErrors,
  );
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState(false);

  useEffect(() => {
    if (validationErrors.hasOwnProperty('message')) {
      setErrorText(prepareErrorText(validationErrors));
      setShowError(true);
      setTimeout(() => {
        handleErrorClose();
      }, MODAL_VALUES.MODAL_CLOSE_FIVE_SECONDS_TIMEOUT);
      // called to reset the user session
      dispatch(resetReduxStore());
    }
  }, [validationErrors, haveBeenReset]);

  const handleErrorClose = () => {
    setShowError(false);
    dispatch(resetValidationErrors());
  };

  return (
    <div className="internal-login-form">
      {config.GOOGLE_CLIENT_ID.length > 0 && <GoogleLoginButton />}
      {config.MICROSOFT_CLIENT_ID.length > 0 && <MicrosoftLoginButton />}
      <BaseModal hideCloseBtn open={showError} onClose={handleErrorClose}>
        <ErrorMessage text={errorText} />
      </BaseModal>
    </div>
  );
};

export default InternalLogin;
