import { useEffect, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PieChart, Pie, Cell } from 'recharts';
import { COST_REPORT_LABELS } from '../../../../cms';
import './CostReportTableRow.scss';

export default function CostReportTableFooter(props: {
  trainingsCostReport: any;
  eventsCostReport: any;
  total: any;
  currency: any;
}) {
  const { trainingsCostReport } = props;
  const { eventsCostReport } = props;
  const { total } = props;
  const { currency } = props;
  const [open, setOpen] = useState(false);
  const COLORS = ['#00aac2', '#DA76B4', '#F5C040', '#569B6D'];
  const pieData = [
    {
      name: 'TechnicalSkills',
      value: trainingsCostReport?.costPerType?.TechnicalSkills,
    },
    {
      name: 'ManagementSkills',
      value: trainingsCostReport?.costPerType?.ManagementSkills,
    },
    {
      name: 'SoftSkills',
      value: trainingsCostReport?.costPerType?.SoftSkills,
    },
    {
      name: 'Other',
      value: trainingsCostReport?.costPerType?.Other,
    },
  ];

  const calcPercentage = (totalValue: number, partialValue: number) => {
    if (
      partialValue === 0 ||
      partialValue === null ||
      partialValue === undefined
    )
      return 0;
    const percent = (partialValue / totalValue) * 100;
    return Math.round(percent);
  };

  useEffect(() => {
    if (trainingsCostReport?.costPerType.Other === null)
      trainingsCostReport.costPerType.Other = 0;
  }, []);

  return (
    <>
      <TableRow
        className="cost-report-table-footer"
        sx={{
          '& > *': {
            borderBottom: 'unset',
          },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <div className="cost-report-text">All Departments</div>
        </TableCell>
        <TableCell>
          <div className="cost-report-text">{trainingsCostReport?.total}</div>
        </TableCell>
        <TableCell>
          <div className="cost-report-text">{eventsCostReport?.total}</div>
        </TableCell>
        <TableCell>
          <div className="cost-report-text">{total}</div>
        </TableCell>
      </TableRow>
      <TableRow className="cost-report-table-footer">
        <TableCell />
        <TableCell />
        <TableCell>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="column">
              {COST_REPORT_LABELS.TRAINING_COST_DETAIL}
            </div>
            <div className="cost-report-training-cost">
              <ul>
                <li className="bullet-color-techical">
                  <div className="cost-name">Techincal Skills: </div>
                  <div className="cost-value">
                    {calcPercentage(
                      trainingsCostReport?.total,
                      trainingsCostReport?.costPerType.TechnicalSkills,
                    )}
                    %
                  </div>
                </li>
                <li className="bullet-color-management">
                  <div className="cost-name">Managment Skills: </div>
                  <div className="cost-value">
                    {calcPercentage(
                      trainingsCostReport?.total,
                      trainingsCostReport?.costPerType.ManagementSkills,
                    )}
                    %
                  </div>
                </li>
                <li className="bullet-color-soft">
                  <div className="cost-name">Soft Skills: </div>
                  <div className="cost-value">
                    {calcPercentage(
                      trainingsCostReport?.total,
                      trainingsCostReport?.costPerType.SoftSkills,
                    )}
                    %
                  </div>
                </li>
                <li className="bullet-color-other">
                  <div className="cost-name">Other: </div>
                  <div className="cost-value">
                    {calcPercentage(
                      trainingsCostReport?.total,
                      trainingsCostReport?.costPerType.Other,
                    )}
                    %
                  </div>
                </li>
              </ul>
            </div>
            <div className="cost-report-chart">
              <PieChart width={80} height={80}>
                <Pie
                  data={pieData}
                  color="#000000"
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={40}
                  fill="#8884d8"
                >
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
          </Collapse>
        </TableCell>
        <TableCell>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              <div className="column">
                {COST_REPORT_LABELS.EVENT_COST_DETAIL}
              </div>
              <div>
                <div>
                  Techincal Skills: {eventsCostReport?.costPerType.Technical}{' '}
                  {currency}
                </div>
                <div>
                  Carrer: {eventsCostReport?.costPerType.Career} {currency}
                </div>
                <div>
                  Other: {eventsCostReport?.costPerType.Other} {currency}
                </div>
              </div>
            </div>
          </Collapse>
        </TableCell>
        <TableCell>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              <div className="column">
                {COST_REPORT_LABELS.TOTAL_COST_DETAIL}
              </div>
              <div>
                <div>
                  Trainings: {trainingsCostReport?.total} {currency}
                </div>
                <div>
                  Events: {eventsCostReport?.total} {currency}
                </div>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
