import { CgClose } from 'react-icons/cg';
import { Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import { VALIDATION_LABELS } from '../../cms';
import errorIcon from '../../common/images/error-24px.svg';
import accountIcon from '../../common/images/account-circle.png';
import managementSkillsImage from '../../common/images/management-skill-logo.png';
import softSkillsImage from '../../common/images/soft-skill-logo.png';
import techSkillsImage from '../../common/images/technical-skill-logo.png';
import otherImage from '../../common/images/other-small-logo.png';
import searchIconDark from '../../common/images/search-icon-dark.png';
import './BaseGroupSearch.scss';

function BaseGroupSearch({
  searching,
  filteredItems,
  typeaheadRef,
  handleInputChange,
  onCloseClick,
  filterBy,
  focus,
  formErrors,
  searchBoxInputId,
  placeholder,
  className,
  multiSelectionHandler,
  multiSelection,
}) {
  return (
    <div>
      <div className="row">
        <div className="col-1">
          {multiSelection?.length === 0 && (
            <img
              src={searchIconDark}
              alt="search icon pr-2"
              width={15}
              className={
                formErrors.employeesValid === false
                  ? `base-search-box-input-search base-search-box-input-search-required ${className}`
                  : `base-search-box-input-search ${className}`
              }
            />
          )}
        </div>
        <div
          className={
            formErrors.employeesValid === false
              ? 'col-12 item-search-bar-required'
              : 'col-12 item-search-bar'
          }
        >
          <Typeahead
            filterBy={filterBy}
            autoFocus={Boolean(focus)}
            className=""
            id=""
            labelKey={(option) =>
              `${option.fullName ? option.fullName : option.title}`
            }
            multiple
            options={filteredItems}
            inputProps={{
              id: searchBoxInputId,
              className: `search-box-input rounded-lg px-4 ${
                formErrors.employeesValid === false
                  ? 'search-input-required'
                  : 'search-item-input-bg'
              }`,
            }}
            onChange={multiSelectionHandler}
            onInputChange={handleInputChange}
            placeholder={placeholder}
            ref={typeaheadRef}
            selected={multiSelection}
            renderMenu={(results, menuProps) => (
              <Menu {...menuProps}>
                {results.map((result, index) => (
                  <MenuItem key={index} option={result} position={index}>
                    <div className="row">
                      <div className="pr-2">
                        {result.type ? (
                          {
                            ManagementSkills: (
                              <img
                                src={managementSkillsImage}
                                width={30}
                                height={30}
                                alt="ManagementSkills"
                                className="type-icon-white-border"
                              />
                            ),
                            SoftSkills: (
                              <img
                                src={softSkillsImage}
                                width={30}
                                height={30}
                                alt="SoftSkills"
                                className="type-icon-white-border"
                              />
                            ),
                            TechnicalSkills: (
                              <img
                                src={techSkillsImage}
                                width={30}
                                height={30}
                                alt="TechnicalSkills"
                                className="type-icon-white-border"
                              />
                            ),
                            Other: (
                              <img
                                src={otherImage}
                                width={30}
                                height={30}
                                alt="Other"
                                className="type-icon-white-border"
                              />
                            ),
                          }[result.type]
                        ) : (
                          <img
                            className={
                              result.photoUrl
                                ? 'account-icon'
                                : 'account-icon-not-available'
                            }
                            width={33}
                            src={
                              result.photoUrl ? result.photoUrl : accountIcon
                            }
                            alt="account icon"
                          />
                        )}
                      </div>
                      <div
                        className="pt-1 text-truncate w-75"
                        id="item-fullname"
                      >
                        {result.fullName !== undefined
                          ? result.fullName
                          : result.title}
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </Menu>
            )}
          >
            {searching ? (
              <CgClose
                className="search-box-input-close"
                onClick={() => onCloseClick()}
              />
            ) : null}
          </Typeahead>
        </div>
      </div>
      {formErrors.employeesValid === false ? (
        <div className="item-required-error pl-2 pt-2 error-input-bold">
          <img
            src={errorIcon}
            alt="search icon"
            width={12}
            className="inline-block"
          />
          <div className="inline-block align-middle pl-2">
            {VALIDATION_LABELS.REQUIRED_FIELD}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default BaseGroupSearch;
