import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import { PathDetailsPageOutletContext } from '../../../../cms/types/pathTypes';
import {
  EmployeeList,
  BaseModal,
  EmployeesSelect,
  SearchField,
} from '../../../../common/components';
import { useDebounce } from '../../../../common/hooks/useDebounce';
import {
  FAILED_REQUEST_MESSAGE,
  MODAL_CLOSE_BTNS,
  MODAL_VALUES,
  NOT_FOUND_MESSAGES,
  PATHS_LABELS,
  PLACEHOLDERS,
} from '../../../../cms';
import './AssigneesTab.scss';
import {
  useAssignPathToUsersMutation,
  useUnassignPathFromUsersMutation,
} from '../../../../rtk/api/pathApi';
import { useAppDispatch } from '../../../../rtk/hooks';
import { openModal } from '../../../../rtk/features/modalsSlice';
import { ModalTypes } from '../../../../cms/enums';

const AssigneesTab = () => {
  const { pathId, assignees } =
    useOutletContext<PathDetailsPageOutletContext>();
  const dispatch = useAppDispatch();
  const [assignPathToUsers, { isLoading: isAssigningPathToUsers }] =
    useAssignPathToUsersMutation();
  const [unnassugnPathFromUsers, { isLoading: isUnassigningPathFromUsers }] =
    useUnassignPathFromUsersMutation();
  const [filteredAssignees, setFilteredAssignees] = useState(assignees);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText.trim().toLowerCase());
  const [employeesSelectModalOpen, setEmployeesSelectModalOpen] =
    useState(false);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<number[]>([]);

  useEffect(() => {
    setFilteredAssignees(
      assignees.filter((a) =>
        a.fullName.toLowerCase().includes(debouncedSearchText),
      ),
    );
  }, [debouncedSearchText, assignees]);

  const handelOnEmployeesSelectModalOpen = () => {
    setEmployeesSelectModalOpen(true);
  };

  const handelOnEmployeesSelectModalClose = () => {
    setEmployeesSelectModalOpen(false);
  };

  const handleOnEmployeesSelectSubmit = (employeeIds: number[]) => {
    assignPathToUsers({
      pathId,
      userIds: employeeIds,
    })
      .unwrap()
      .then(() => {
        handelOnEmployeesSelectModalClose();
      })
      .catch(() => {
        dispatch(
          openModal({
            modalType: ModalTypes.ERROR_MESSAGE,
            modalData: FAILED_REQUEST_MESSAGE,
            timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
          }),
        );
      });
  };

  const handleOnEmployeeRemove = (employeeId: number) => {
    handleOnEmployeesRemove([employeeId]);
  };

  const handleOnEmployeesRemove = (
    employeeIds: number[] = selectedEmployeeIds,
  ) => {
    const text =
      employeeIds.length === 1
        ? PATHS_LABELS.SINGLE_UNASSIGN_CONFIRMATION_TEXT
        : PATHS_LABELS.MULTIPLE_UNASSIGN_CONFIRMATION_TEXT;

    dispatch(
      openModal({
        modalType: ModalTypes.CONFIRM_ACTION,
        closeBtn: MODAL_CLOSE_BTNS.OUTSIDE,
        modalData: {
          text,
          onConfirm: () => handleOnEmployeesUnassign(employeeIds),
        },
      }),
    );
  };

  const handleOnEmployeesUnassign = (employeeIds: number[]) => {
    unnassugnPathFromUsers({
      pathId,
      userIds: employeeIds,
    })
      .unwrap()
      .then(() => {
        setSelectedEmployeeIds([]);
      })
      .catch(() => {
        dispatch(
          openModal({
            modalType: ModalTypes.ERROR_MESSAGE,
            modalData: FAILED_REQUEST_MESSAGE,
            timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
          }),
        );
      });
  };

  return (
    <Stack spacing="2rem" marginTop="1rem">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing="1rem"
        flexWrap="wrap"
      >
        <SearchField
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder={PLACEHOLDERS.SEARCH_EMPLOYEES}
          className="assignees-tab-search"
          sx={{
            width: { xs: '100%', sm: 300 },
          }}
        />
        <Button
          variant="outlined"
          className="assignees-tab-remove-assignees-btn"
          startIcon={<GroupRemoveIcon />}
          disabled={selectedEmployeeIds.length === 0}
          sx={{
            width: { xs: '100%', sm: 'auto' },
          }}
          onClick={() => handleOnEmployeesRemove()}
        >
          Remove Selected
        </Button>
        <Button
          variant="contained"
          className="assignees-tab-add-btn"
          startIcon={<GroupAddIcon />}
          sx={{
            width: { xs: '100%', sm: 'auto' },
          }}
          onClick={handelOnEmployeesSelectModalOpen}
        >
          Assign Employees
        </Button>
        <BaseModal
          open={employeesSelectModalOpen}
          onClose={handelOnEmployeesSelectModalClose}
          showCloseBtnOutside
        >
          <EmployeesSelect
            title="Assign Employees"
            onSubmit={handleOnEmployeesSelectSubmit}
            isSubmitting={isAssigningPathToUsers}
            employeeIdsToHide={assignees.map((a) => a.id)}
          />
        </BaseModal>
      </Stack>
      <EmployeeList
        employees={filteredAssignees}
        selectedEmployeeIds={selectedEmployeeIds}
        setSelectedEmployeeIds={setSelectedEmployeeIds}
        onEmployeeRemove={handleOnEmployeeRemove}
        emptyMessage={NOT_FOUND_MESSAGES.PATH_ASSIGNEES}
        emptyMessageAlignment="left"
        className={filteredAssignees.length > 0 ? 'assignees-tab-list' : ''}
        disabled={isUnassigningPathFromUsers}
      />
    </Stack>
  );
};

export default AssigneesTab;
