import { RiErrorWarningFill } from 'react-icons/ri';
import { VALIDATION_LABELS, BUTTON_LABELS } from '../../../../../../../../cms';
import './CreateGroupButtons.scss';

export default function CreateGroupButtons({
  formValid,
  closeFormModal,
  submitGroupHandler,
  editMode,
}) {
  return (
    <div className="pt-3 d-flex justify-content-end mb-3">
      {!formValid ? (
        <div className="validation-error-container col-12 pt-4 text-center">
          <RiErrorWarningFill size={20} />
          &nbsp;
          {VALIDATION_LABELS.PLEASE_FILL_TEXT}
        </div>
      ) : (
        <div className="float-right pr-3">
          <div className="row">
            <div className="col-auto mr-n3">
              <button
                className="cancel-create-group-button px-3"
                onClick={() => closeFormModal()}
              >
                {BUTTON_LABELS.CANCEL.toUpperCase()}
              </button>
            </div>
            <div className="col-auto pr-5">
              <button
                className="create-gorup-button px-4"
                onClick={submitGroupHandler}
              >
                {!editMode
                  ? BUTTON_LABELS.CREATE_GROUP.toUpperCase()
                  : BUTTON_LABELS.EDIT_GROUP.toUpperCase()}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
