import { AddEmployeeToMyTeamModel } from './AddEmployeeModal';
import {
  ADD_EMPLOYEE_TO_MY_TEAM_REQUEST,
  ADD_EMPLOYEE_TO_MY_TEAM_REQUEST_SUCCESS,
  ADD_EMPLOYEE_TO_MY_TEAM_REQUEST_ERROR,
} from './types';

interface ErrorResponse {
  response: Response;
}

interface Response {
  data: Data;
}

interface Data {
  message: string;
}

/** Action - Send add employee to my team request

* @param {AddEmployeeToMyTeamModel} model - Add employee to my team model
* @return {Object} Return object
*/
export function addEmployeeToMyTeam(model: AddEmployeeToMyTeamModel) {
  return {
    type: ADD_EMPLOYEE_TO_MY_TEAM_REQUEST,
    payload: {
      model,
    },
  };
}

/** On successful adding an employee to my team
 * @param {string} isSuccessful - if get successful
 * @return {any} Return object
 */
export function addEmployeeToMyTeamSuccess(isSuccessful: boolean) {
  return {
    type: ADD_EMPLOYEE_TO_MY_TEAM_REQUEST_SUCCESS,
    payload: {
      isSuccessful,
    },
  };
}

/** Handle error
 * @param {unknown} error - Error response
 */
export function addEmployeeToMyTeamError(error: ErrorResponse) {
  return {
    type: ADD_EMPLOYEE_TO_MY_TEAM_REQUEST_ERROR,
    payload: error.response.data.message,
  };
}
