import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { AxiosRequestConfig } from 'axios';
import axiosInstance from '../../api/trainingsPortalBackend/axiosInstance';

const generateApiErrorResponse = (err: any) => {
  return {
    error: {
      status: err.response?.status,
      data: err.response?.data || err.message,
    },
  };
};

const generateApiSuccessResponse = (data: any) => {
  return {
    data,
  };
};

type AxiosArgs = {
  url: string;
  method?: AxiosRequestConfig['method'];
  body?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  headers?: AxiosRequestConfig['headers'];
};

const axiosRequest = async (args: string | AxiosArgs) => {
  const justUrl = typeof args === 'string' ? args : null;

  if (justUrl) return axiosInstance(justUrl);

  const { url, method = 'GET', body, params, headers } = args as AxiosArgs;

  return axiosInstance(url, {
    method,
    data: body,
    params,
    headers,
  });
};

const axiosBaseQuery: BaseQueryFn<
  string | AxiosArgs,
  unknown,
  unknown
> = async (args) => {
  try {
    const response = await axiosRequest(args);
    return generateApiSuccessResponse(response.data);
  } catch (err) {
    return generateApiErrorResponse(err);
  }
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery,
  endpoints: () => ({}),
  tagTypes: [
    'Event',
    'Notifications',
    'Training',
    'DemoRequest',
    'Path',
    'MyTeamEmployees',
    'MyTeamApplications',
    'Employee',
    'Application',
    'TrainingApplication',
    'EventApplication',
  ],
});
