import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import {
  Grid,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import demoRequestPhoto from '../../common/images/login_image.png';
import { COMPANY_SIZES, DEMO_REQUEST_LABELS } from '../../cms';
import './RequestDemoForm.scss';
import UpskillPortalLogo from '../../components/UpskillPortalLogo/UpskillPortalLogo';
import { CustomTextField, Disclaimer } from '../../common/components';
import { addSmallStyle } from '../../cms/Utils';
import { useCreateDemoRequestMutation } from '../../rtk/api/demoRequestApi';
import { DataLayerEvents } from '../../cms/enums';
import { useDataLayerEvent } from '../../common/hooks/useDataLayerEvent';
import {
  RequestDemoFormSchema,
  RequestDemoFormSchemaType,
} from '../../cms/validation/demoRequestValidation';

const RequestDemoForm = () => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
    setError,
  } = useForm<RequestDemoFormSchemaType>({
    resolver: zodResolver(RequestDemoFormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      companySize: '1-10 people',
      workEmail: '',
      roleInCompany: '',
      phoneNumber: '',
    },
  });
  const serverError = errors.root?.serverError.message;
  const sendDataLayerEvent = useDataLayerEvent();
  const [createDemoRequest, { isSuccess }] = useCreateDemoRequestMutation();
  const theme = useTheme();
  const isScreenSM = useMediaQuery(theme.breakpoints.down('md'));

  const onSubmit = async (values: RequestDemoFormSchemaType) => {
    await createDemoRequest(values)
      .unwrap()
      .then(() => {
        sendDataLayerEvent({
          event: DataLayerEvents.SUBMIT_DEMO_REQUEST_FORM,
        });
      })
      .catch((err: any) => {
        const data = err.data;
        if (
          Array.isArray(data) &&
          data.length > 0 &&
          Array.isArray(data[0].value) &&
          data[0].value.length > 0
        ) {
          setError('root.serverError', {
            message: data[0].value[0].errorMessage,
          });
        }
      });
  };

  return (
    <Grid container className="request-demo-form-container">
      <Grid item xs={12} md={6}>
        <img
          src={demoRequestPhoto}
          alt="Demo Request"
          className={`request-demo-form-image${addSmallStyle(isScreenSM)}`}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack
          className={`request-demo-form-content${addSmallStyle(isScreenSM)}`}
        >
          <UpskillPortalLogo />
          {isSuccess ? (
            <Stack flex={1} justifyContent="center">
              <Typography
                component="h2"
                className="request-demo-form-success-message-title"
              >
                Thank You.
              </Typography>
              <Typography className="request-demo-form-success-message-text">
                Your submission has been sent successfully and we will contact
                you soon with further instructions.
              </Typography>
            </Stack>
          ) : (
            <>
              <Typography component="h2" className="request-demo-form-title">
                Request Free Trial
              </Typography>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="request-demo-form"
                noValidate
              >
                <Stack className="request-demo-form-btn-group">
                  <Controller
                    control={control}
                    name="firstName"
                    render={({
                      field: { name, value, onChange, onBlur, ref },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        required
                        label={DEMO_REQUEST_LABELS.FIRSTNAME}
                        name={name}
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        onBlur={onBlur}
                        error={Boolean(error)}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="lastName"
                    render={({
                      field: { name, value, onChange, onBlur, ref },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        required
                        label={DEMO_REQUEST_LABELS.LASTNAME}
                        name={name}
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        onBlur={onBlur}
                        error={Boolean(error)}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Stack>
                <Controller
                  control={control}
                  name="companyName"
                  render={({
                    field: { name, value, onChange, onBlur, ref },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      required
                      fullWidth
                      label={DEMO_REQUEST_LABELS.COMPANY_NAME}
                      name={name}
                      value={value}
                      onChange={onChange}
                      inputRef={ref}
                      onBlur={onBlur}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="companySize"
                  render={({
                    field: { name, value, onChange, onBlur, ref },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      required
                      fullWidth
                      label={DEMO_REQUEST_LABELS.COMPANY_SIZE}
                      name={name}
                      value={value}
                      onChange={onChange}
                      inputRef={ref}
                      onBlur={onBlur}
                      error={Boolean(error)}
                      helperText={error?.message}
                      select
                    >
                      {COMPANY_SIZES.map((cs) => (
                        <MenuItem key={cs} value={cs}>
                          {cs}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  )}
                />
                <Controller
                  control={control}
                  name="workEmail"
                  render={({
                    field: { name, value, onChange, onBlur, ref },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      required
                      fullWidth
                      label={DEMO_REQUEST_LABELS.WORK_EMAIL}
                      name={name}
                      value={value}
                      onChange={onChange}
                      inputRef={ref}
                      onBlur={onBlur}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="roleInCompany"
                  render={({
                    field: { name, value, onChange, onBlur, ref },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      required
                      fullWidth
                      label={DEMO_REQUEST_LABELS.ROLE_IN_COMPANY}
                      name={name}
                      value={value}
                      onChange={onChange}
                      inputRef={ref}
                      onBlur={onBlur}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({
                    field: { name, value, onChange, onBlur, ref },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      fullWidth
                      label={DEMO_REQUEST_LABELS.PHONE_NUMBER}
                      name={name}
                      value={value}
                      onChange={onChange}
                      inputRef={ref}
                      onBlur={onBlur}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
                {serverError && (
                  <Typography className="request-demo-form-server-error">
                    {serverError}
                  </Typography>
                )}
                <LoadingButton
                  variant="contained"
                  className="request-demo-form-btn"
                  type="submit"
                  loading={isSubmitting}
                  loadingPosition="center"
                >
                  Submit Request
                </LoadingButton>
              </form>
              <Disclaimer
                description="By requesting this demo"
                className="request-demo-form-disclaimer"
              />
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default RequestDemoForm;
