import { useState } from 'react';
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import './FileForm.scss';
import {
  PathFileSchema,
  PathFileSchemaType,
  PathItemSchemaType,
} from '../../../../../../cms/validation/pathValidation';
import { CustomTextField } from '../../../../../../common/components';
import {
  ALLOWED_PATH_FILE_FORMATS,
  BUTTON_LABELS,
  FILE_UPLOAD_DROP_ZONE,
  PATH_FILE_DESCRIPTION_LENGTH,
  PATH_FILE_LABELS,
  PATH_FILE_UPLOAD_HELPER_TEXT,
} from '../../../../../../cms';
import { PathItemTypes } from '../../../../../../cms/enums';
import { getFileNameFromFileUrl } from '../../../../../../cms/Utils';

type FileFormProps = {
  initialValues: PathItemSchemaType | null;
  onClose: (newItems: PathItemSchemaType[]) => void;
};

const FileForm = ({ initialValues, onClose }: FileFormProps) => {
  const { handleSubmit, control, reset } = useForm<PathFileSchemaType>({
    resolver: zodResolver(PathFileSchema),
    defaultValues: {
      isCreate: !initialValues,
      name: initialValues?.data.name ?? '',
      description: initialValues?.data.description ?? '',
    },
  });
  const initialFileName =
    initialValues?.data?.file?.name ||
    getFileNameFromFileUrl(initialValues?.data.url);
  const [fileName, setFileName] = useState(
    initialValues ? initialFileName : '',
  );

  const formTitle = initialValues
    ? PATH_FILE_LABELS.EDIT_FILE
    : PATH_FILE_LABELS.ADD_FILE;
  const buttonTitle = initialValues
    ? BUTTON_LABELS.SAVE_CHANGES
    : PATH_FILE_LABELS.ADD_FILE;

  const handleOnSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files.length === 1) {
      return files[0];
    }

    return undefined;
  };

  const onSubmit = (data: PathFileSchemaType) => {
    onClose([
      {
        type: PathItemTypes.FILE,
        data: initialValues
          ? {
              ...data,
              id: !data.file ? initialValues.data?.id : null,
              url: !data.file ? initialValues.data?.url : null,
              file: data.file || initialValues.data?.file,
            }
          : data,
        displayData: {
          title: data.name,
          subTitle: data.description,
        },
      },
    ]);
  };

  const handleClearFileInput = () => {
    setFileName('');
    reset({
      isCreate: true,
    });
  };

  return (
    <Stack className="file-form-container" spacing="1.5rem">
      <Typography className="file-form-title">{formTitle}</Typography>
      <Stack component="form" noValidate className="file-form" spacing="1.5rem">
        <Controller
          name="name"
          control={control}
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <CustomTextField
              label="Name"
              color="secondary"
              required
              fullWidth
              name={name}
              value={value}
              onChange={onChange}
              inputRef={ref}
              onBlur={onBlur}
              error={Boolean(error)}
              helperText={error?.message}
              inputProps={{
                className: 'file-name-input',
              }}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <CustomTextField
              label="Description"
              color="secondary"
              fullWidth
              name={name}
              value={value}
              onChange={onChange}
              inputRef={ref}
              onBlur={onBlur}
              error={Boolean(error)}
              helperText={
                error?.message ??
                `${value?.length ?? 0} / ${PATH_FILE_DESCRIPTION_LENGTH}`
              }
              multiline
              rows={6}
              inputProps={{
                className: 'file-description-input',
                maxLength: PATH_FILE_DESCRIPTION_LENGTH,
              }}
            />
          )}
        />

        <Controller
          name="file"
          control={control}
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <CustomTextField
              label="Upload File"
              color="secondary"
              required
              fullWidth
              value={value?.name}
              onBlur={onBlur}
              error={Boolean(error)}
              placeholder={
                fileName || FILE_UPLOAD_DROP_ZONE.FILE_UPLOAD_PLACEHOLDER
              }
              helperText={error?.message ?? PATH_FILE_UPLOAD_HELPER_TEXT}
              inputProps={{
                className: 'file-upload-input',
                maxLength: PATH_FILE_DESCRIPTION_LENGTH,
              }}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <input
                    type="file"
                    accept={ALLOWED_PATH_FILE_FORMATS.join(',')}
                    ref={ref}
                    onChange={(event) => onChange(handleOnSelectFile(event))}
                    tabIndex={-1}
                    className="file-upload-hidden-input"
                    name={name}
                  />
                ),
                endAdornment: (
                  <>
                    {fileName && (
                      <button className="file-upload-input-replace-btn">
                        Replace
                      </button>
                    )}
                    {(value || fileName) && (
                      <IconButton
                        onClick={() => {
                          onChange(undefined);
                          handleClearFileInput();
                        }}
                      >
                        <ClearIcon className="file-upload-input-clear-btn-icon" />
                      </IconButton>
                    )}
                  </>
                ),
              }}
            />
          )}
        />
      </Stack>
      <Divider />
      <Stack spacing="1rem" className="file-form-footer">
        <Button variant="outlined" onClick={() => onClose([])}>
          {BUTTON_LABELS.CANCEL}
        </Button>
        <Button variant="contained" onClick={() => handleSubmit(onSubmit)()}>
          {buttonTitle}
        </Button>
      </Stack>
    </Stack>
  );
};

export default FileForm;
