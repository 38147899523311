import { z } from 'zod';
import { COMPANY_SIZES, VALIDATION_MESSAGES } from '..';

export const RequestDemoFormSchema = z.object({
  firstName: z.string().min(1, {
    message: VALIDATION_MESSAGES.REQUIRED,
  }),
  lastName: z.string().min(1, {
    message: VALIDATION_MESSAGES.REQUIRED,
  }),
  companyName: z.string().min(1, {
    message: VALIDATION_MESSAGES.REQUIRED,
  }),
  companySize: z.enum(COMPANY_SIZES),
  workEmail: z
    .string()
    .min(1, {
      message: VALIDATION_MESSAGES.REQUIRED,
    })
    .email({
      message: VALIDATION_MESSAGES.VALID_EMAIL,
    }),
  roleInCompany: z.string().min(1, {
    message: VALIDATION_MESSAGES.REQUIRED,
  }),
  phoneNumber: z.string(),
});

export type RequestDemoFormSchemaType = z.infer<typeof RequestDemoFormSchema>;
