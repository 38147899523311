import { NavLink } from 'react-router-dom';
import { Box } from '@mui/material';
import './AppNavbarLinks.scss';
import { NavbarLink } from '../../../cms/types';
import { useAppDispatch } from '../../../rtk/hooks';
import { closeDrawer } from '../../../rtk/features/drawerSlice';

type AppNavbarLinksProps = {
  links: NavbarLink[];
};

const AppNavbarLinks = ({ links }: AppNavbarLinksProps) => {
  const dispatch = useAppDispatch();

  const handleCloseAppDrawer = () => {
    dispatch(closeDrawer());
  };

  return (
    <Box
      component="nav"
      className="navbar-links"
      sx={{
        display: { xs: 'none', lg: 'flex' },
      }}
    >
      {links.map(({ name, link, hide }) => {
        if (hide) return null;

        return (
          <NavLink
            key={name}
            to={link}
            className="navbar-links-link"
            onClick={handleCloseAppDrawer}
          >
            {name}
          </NavLink>
        );
      })}
    </Box>
  );
};

export default AppNavbarLinks;
