import {
  ASSIGN_TRAININGS_TO_GROUP_REQUEST,
  ASSIGN_TRAININGS_TO_GROUP_REQUEST_SUCCESS,
} from './types';

/** Action - assign trainings to group.
 * @param {string} assignGroupTrainingsModel - The assign trainings to group model
 * @return {Object} Return object
 */

export function assignGroupTrainings(
  assignGroupTrainingsModel,
  lastAssignTrainingsToGroupModel,
) {
  return {
    type: ASSIGN_TRAININGS_TO_GROUP_REQUEST,
    payload: {
      assignGroupTrainingsModel,
      lastAssignTrainingsToGroupModel,
    },
  };
}

/** On successful assign trainings to group
 * @param {string} isSuccessful - if assign trainings to group went alright
 * @return {any} Return object
 */
export function assignGroupTrainingsSuccess(isSuccessful) {
  return {
    type: ASSIGN_TRAININGS_TO_GROUP_REQUEST_SUCCESS,
    payload: {
      isSuccessful,
    },
  };
}
