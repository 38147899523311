import { ReactElement } from 'react';
import { Stack, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { LiaSpinnerSolid } from 'react-icons/lia';
import { PATH_STATUSES } from '../../../../cms';
import { PathStatuses } from '../../../../cms/enums';
import './PathStatusBox.scss';

type PathStatusBoxProps = {
  status: PathStatuses;
};

const ICON_SIZE = 24;

const PathStatusBox = ({ status }: PathStatusBoxProps) => {
  const generatePathStatusIcon = () => {
    let iconBackgroundColor: string;
    let icon: ReactElement;

    switch (status) {
      case PathStatuses.IN_PROGRESS:
        iconBackgroundColor = '#E5EBFF';
        icon = <LiaSpinnerSolid color="#652D63" size={ICON_SIZE} />;
        break;
      case PathStatuses.COMPLETED:
        iconBackgroundColor = '#CDEBCA';
        icon = (
          <CheckIcon
            sx={{
              color: '#027B15',
              fontSize: ICON_SIZE,
            }}
          />
        );
        break;
      case PathStatuses.DROPPED:
        iconBackgroundColor = '#FFEBEB';
        icon = (
          <ClearIcon
            sx={{
              color: '#C70000',
              fontSize: ICON_SIZE,
            }}
          />
        );
        break;
      default:
        iconBackgroundColor = '#FAFAFA';
        icon = (
          <CheckIcon
            sx={{
              color: '#A7A7A7',
              fontSize: ICON_SIZE,
            }}
          />
        );
        break;
    }

    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: iconBackgroundColor,
          borderRadius: '50%',
          width: 42,
          height: 42,
        }}
      >
        {icon}
      </Stack>
    );
  };
  const icon = generatePathStatusIcon();

  return (
    <Stack direction="row" spacing="1rem" className="path-status-box">
      {icon}
      <Stack>
        <Typography className="path-status-box-title">Status:</Typography>
        <Typography className="path-status-box-text">
          {PATH_STATUSES.find((s) => s.value === status)?.label ?? status}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PathStatusBox;
