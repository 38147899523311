import { ReactElement } from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { LiaSpinnerSolid } from 'react-icons/lia';
import { PathItemStatuses } from '../../../../cms/enums';
import { PATH_ITEM_STATUSES } from '../../../../cms';
import './PathItemStatusBox.scss';

type PathItemStatusBoxProps = {
  status: PathItemStatuses;
};

const ICON_SIZE = 20;

const PathItemStatusBox = ({ status }: PathItemStatusBoxProps) => {
  const statusLabel =
    PATH_ITEM_STATUSES.find((s) => s.value === status)?.label ?? status;
  let iconBackgroundColor: string;
  let icon: ReactElement;

  switch (status) {
    case PathItemStatuses.IN_PROGRESS:
      iconBackgroundColor = '#E5EBFF';
      icon = (
        // MUI Tooltip doesn't work with React-Icons so we wrap it in a div
        <Stack alignItems="center" justifyContent="center">
          <LiaSpinnerSolid color="#652D63" size={ICON_SIZE} />
        </Stack>
      );
      break;
    case PathItemStatuses.COMPLETED:
      iconBackgroundColor = '#CDEBCA';
      icon = (
        <CheckIcon
          sx={{
            color: '#027B15',
            fontSize: ICON_SIZE,
          }}
        />
      );
      break;
    case PathItemStatuses.DROPPED:
      iconBackgroundColor = '#FFEBEB';
      icon = (
        <ClearIcon
          sx={{
            color: '#C70000',
            fontSize: ICON_SIZE,
          }}
        />
      );
      break;
    default:
      iconBackgroundColor = '#FAFAFA';
      icon = (
        <CheckIcon
          sx={{
            color: '#A7A7A7',
            fontSize: ICON_SIZE,
          }}
        />
      );
      break;
  }

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      className="path-item-status-box"
      sx={{
        backgroundColor: iconBackgroundColor,
      }}
    >
      <Tooltip
        title={
          <Stack>
            <Typography className="path-item-status-box-tooltip-title">
              Status:
            </Typography>
            <Typography className="path-item-status-box-tooltip-text">
              {statusLabel}
            </Typography>
          </Stack>
        }
      >
        {icon}
      </Tooltip>
    </Stack>
  );
};

export default PathItemStatusBox;
