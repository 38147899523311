export type Pricing = {
  title: string;
  price?: number;
  pricePerUser?: number;
  plan?: string;
  buttonText: string;
  pricingOption: PricingOption;
  onClick?: () => void;
};

export enum PricingOption {
  Tier1,
  Tier2,
  Tier3,
  Tier4,
}
