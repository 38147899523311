import { useState, useEffect } from 'react';
import './AssignTrainingSection.scss';
import SearchEmployee from '../../../components/SearchEmployee/SearchEmployee';
import { useAssignTrainingsToEmployeesMutation } from '../../../rtk/api/trainingApi';
import { Employee } from '../../../cms/types';

type AssignTrainingSectionProps = {
  trainingId: number | null;
};

const AssignTrainingSection = ({ trainingId }: AssignTrainingSectionProps) => {
  const [assignTrainings] = useAssignTrainingsToEmployeesMutation();

  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);

  const updateSelectedEmployeesHandler = (employees: Employee[]) =>
    setSelectedEmployees(employees);

  useEffect(() => {
    if (trainingId) {
      const model = {
        trainingIds: [trainingId],
        userIds: selectedEmployees.map((employee) => employee.id),
      };
      assignTrainings(model);
    }
  }, [trainingId]);

  return (
    <SearchEmployee
      updateSelectedEmployeesHandler={updateSelectedEmployeesHandler}
      searchBoxInputId="search-employee-box-input"
      focus={false}
      formErrors={[]}
      selectedEmployees={selectedEmployees}
      className="ml-3"
    />
  );
};

export default AssignTrainingSection;
