import { GoogleLogin } from '@react-oauth/google';
import { LOGIN_PROVIDERS } from '../../cms';
import { useLoginMutation } from '../../rtk/api/userApi';

export default function GoogleLoginButton() {
  const [login] = useLoginMutation();
  const responseGoogle = (googleUser: any) => {
    login({
      token: googleUser.credential,
      provider: LOGIN_PROVIDERS.GOOGLE,
    });
  };
  return (
    <GoogleLogin
      onSuccess={responseGoogle}
      onError={() => responseGoogle}
      shape="pill"
      size="medium"
      text="signin_with"
      width="250px"
    />
  );
}
