import { Button, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {
  BUTTON_LABELS,
  EVENT_FORM_TYPES,
  ADD_EVENT_MODAL_LABELS,
} from '../../../cms';
import { EventSchemaType } from '../../../cms/validation/eventValidation';

type EventFormFooterProps = {
  type: number;
  onClose: () => void;
  showAgendaSpeakersForm: boolean;
  setShowAgendaSpeakersForm: (show: boolean) => void;
};

const EventFormFooter = ({
  type,
  onClose,
  showAgendaSpeakersForm,
  setShowAgendaSpeakersForm,
}: EventFormFooterProps) => {
  const {
    formState: { isSubmitting },
  } = useFormContext<EventSchemaType>();

  const generateBtnText = (): string => {
    if (type === EVENT_FORM_TYPES.CREATE) return 'Create';
    else if (type === EVENT_FORM_TYPES.EDIT) return BUTTON_LABELS.SAVE_CHANGES;

    return BUTTON_LABELS.DUPLICATE_EVENT;
  };

  const btnText = generateBtnText();

  return (
    <Stack direction="row" flexWrap="wrap" gap="1rem">
      {showAgendaSpeakersForm && (
        <Button
          className="show-general-details-btn"
          startIcon={<KeyboardDoubleArrowLeftIcon />}
          onClick={() => setShowAgendaSpeakersForm(false)}
        >
          {ADD_EVENT_MODAL_LABELS.GENERAL_DETAILS}
        </Button>
      )}

      <Stack direction="row" flexWrap="wrap" gap="1rem" marginLeft="auto">
        <Button
          variant="outlined"
          onClick={onClose}
          className="event-close-btn"
        >
          {BUTTON_LABELS.CANCEL}
        </Button>

        <LoadingButton
          loading={isSubmitting}
          loadingPosition="center"
          variant="contained"
          type="submit"
          className="event-submit-btn"
        >
          {btnText}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default EventFormFooter;
