import { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmployees } from '../../../../../components/SearchEmployee/actions';
import { EMPLOYEES_MANAGEMENT_LABELS } from '../../../../../cms';
import EmployeesTableRow from './EmployeesTableRow/EmployeesTableRow';
import EmployeesTableHeader from './EmployeesTableHeader/EmployeesTableHeader';
import './EmployeesTable.scss';
import { deactivateEmployees } from './DeactiveateEmployee/actions';
import { archiveEmployees } from './ArchiveEmployee/actions';

export interface AllEmployeesProps {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  managerEmail: string;
  managerId: number;
  managerExternalId: string;
  manager: string;
  managerJobTitle: string;
  managerPhotoUrl: string;
  departmentId: number;
  department: string;
  jobTitle: string;
  fullName: string;
  photo: string;
  resignedOn: string;
  isDisabled: boolean;
}

interface AllEmployeesState {
  allEmployees: AllEmployeesProps[];
  editSuccess: boolean;
  archiveSuccess: boolean;
  deactivateSuccess: boolean;
  deactivatedEmployeesIds: number[];
}

interface RootState {
  allEmployees: AllEmployeesState;
}

const EmployeesTable = () => {
  const dispatch = useDispatch<Dispatch<any>>();

  const [
    showArchiveEmployeesConfirmation,
    setShowArchiveEmployeesConfirmation,
  ] = useState(false);
  const [archiveEmployeesActive, setArchiveEmployeesActive] = useState(false);
  const {
    allEmployees,
    archiveSuccess,
    deactivateSuccess,
    deactivatedEmployeesIds,
    editSuccess,
  } = useSelector((state: RootState) => state.allEmployees);
  const [allEmployeesList, setAllEmployeesList] = useState(allEmployees);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const rowsPerPage = 12;

  useEffect(() => {
    loadEmployees();
  }, []);

  useEffect(() => {
    loadEmployees();
  }, [page]);

  useEffect(() => {
    if (page === 1) setAllEmployeesList(allEmployees);
    else setAllEmployeesList(allEmployeesList.concat(allEmployees));

    if (allEmployees.length === rowsPerPage) setHasMore(true);
    else setHasMore(false);
  }, [allEmployees]);

  useEffect(() => {
    if (editSuccess) {
      setAllEmployeesList([]);
      const model = {
        pageNumber: 1,
        pageSize: rowsPerPage,
      };
      dispatch(getAllEmployees(model));
      setPage(1);
    }
  }, [editSuccess]);

  useEffect(() => {
    if (deactivateSuccess) {
      const updatedEmployeesList = allEmployeesList.map((item, i) => {
        if (deactivatedEmployeesIds.includes(item.id)) {
          const updatedItem = item;
          updatedItem.isDisabled = true;
          updatedItem.resignedOn = new Date().toString();
          return updatedItem;
        }
        return item;
      });
      setAllEmployeesList(updatedEmployeesList);
    }
  }, [deactivateSuccess]);

  useEffect(() => {
    if (archiveSuccess) {
      setShowArchiveEmployeesConfirmation(false);
      setArchiveEmployeesActive(false);
      if (page === 1) loadEmployees();
      else setPage(1);
    }
  }, [archiveSuccess]);

  const loadEmployees = () => {
    const model = {
      pageNumber: page,
      pageSize: rowsPerPage,
    };
    dispatch(getAllEmployees(model));
  };

  const fetchMoreData = () => {
    setPage(page + 1);
  };

  const handleArchiveEmployees = () => {
    dispatch(archiveEmployees());
  };

  const handleDeactivateEmployees = (employeeId: number) => {
    const Ids = [employeeId];
    dispatch(deactivateEmployees(Ids));
  };

  return (
    <Stack spacing="2rem">
      <EmployeesTableHeader
        archiveEmployeesHandler={handleArchiveEmployees}
        setShowArchiveEmployeesConfirmation={
          setShowArchiveEmployeesConfirmation
        }
        showArchiveEmployeesConfirmation={showArchiveEmployeesConfirmation}
        setArchiveEmployeesActive={setArchiveEmployeesActive}
        archiveEmployeesActive={archiveEmployeesActive}
      />
      <TableContainer component={Paper} id="employees-table">
        <InfiniteScroll
          dataLength={allEmployeesList.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader=""
          scrollableTarget="scrollableDiv"
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell className="column" align="left">
                  {EMPLOYEES_MANAGEMENT_LABELS.FULL_NAME}
                </TableCell>
                <TableCell className="column" align="left">
                  {EMPLOYEES_MANAGEMENT_LABELS.EMAIL}
                </TableCell>
                <TableCell className="column" align="left">
                  {EMPLOYEES_MANAGEMENT_LABELS.RESIGNED_ON}
                </TableCell>
                <TableCell className="column" align="left">
                  {EMPLOYEES_MANAGEMENT_LABELS.STATUS}
                </TableCell>
                <TableCell className="action-column" />
              </TableRow>
            </TableHead>
            <TableBody>
              {allEmployeesList.map((row) => (
                <EmployeesTableRow
                  key={row.id}
                  row={row}
                  handleDeactivateEmployees={handleDeactivateEmployees}
                />
              ))}
            </TableBody>
          </Table>
        </InfiniteScroll>
      </TableContainer>
    </Stack>
  );
};

export default EmployeesTable;
