import { put, take, call } from 'redux-saga/effects';
import { GET_ALL_PROFESSIONS_REQUEST } from './types';
import { getAllProfessionsError, getAllProfessionsSuccess } from './actions';

/** If detect type: GET_ALL_PROFFESSIONS_REQUEST call getAllProfessions() */
export default function* getAllProfessionsSaga(api: any): any {
  while (true) {
    const getAllProfessionsRequest = yield take(GET_ALL_PROFESSIONS_REQUEST);
    yield call(getAllProfessions, api);
  }
}

/** get all professions */
function* getAllProfessions(api: any): any {
  let response;
  try {
    response = yield call(api.getAllProfessions);
    const allProfessions = response.data;
    yield put(getAllProfessionsSuccess(allProfessions));
  } catch (error) {
    yield put(getAllProfessionsError(error));
  }
}
