import { FormControlLabel, Switch, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { TRAINING_DETAILS } from '../../../cms';

import './PrivateToggle.scss';

const PrivateToggle = () => {
  const { control } = useFormContext();

  return (
    <Controller
      name="isPrivate"
      control={control}
      rules={{ required: true }}
      render={({ field: { value, onChange, onBlur, ref } }) => (
        <FormControlLabel
          control={
            <Switch
              checked={value}
              color="secondary"
              inputRef={ref}
              onBlur={onBlur}
              onChange={onChange}
            />
          }
          label={
            <Typography className="private-toggle-label">
              {TRAINING_DETAILS.MARK_AS_PRIVATE}
            </Typography>
          }
        />
      )}
    />
  );
};

export default PrivateToggle;
