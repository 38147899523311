import {
  ADD_MEMBERS_TO_GROUP_REQUEST,
  ADD_MEMBERS_TO_GROUP_REQUEST_SUCCESS,
} from './types';

/** Action - add members to group.
 * @param {string} addMembersModel - The add members to group model
 * @return {Object} Return object
 */

export function addMembers(addMembersModel, lastAddMembersToGroupModel) {
  return {
    type: ADD_MEMBERS_TO_GROUP_REQUEST,
    payload: {
      addMembersModel,
      lastAddMembersToGroupModel,
    },
  };
}

/** On successful add members to group
 * @param {string} isSuccessful - if add members to group went alright
 * @return {any} Return object
 */
export function addMembersSuccess(isSuccessful) {
  return {
    type: ADD_MEMBERS_TO_GROUP_REQUEST_SUCCESS,
    payload: {
      isSuccessful,
    },
  };
}
