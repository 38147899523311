import { checkForValidationErrorsForField } from '../../../../../../../common/validation/helpers';

export function validateCreateGroupFormField(
  fieldName,
  value,
  formErrors,
  fluentValidationErrors,
) {
  const fieldValidationErrors = formErrors;
  switch (fieldName) {
    case 'title':
      fieldValidationErrors.titleValid =
        value.length > 0 &&
        !checkForValidationErrorsForField(fluentValidationErrors, fieldName);
      break;
    case 'employees':
      fieldValidationErrors.employeesValid =
        value.length > 0 &&
        !checkForValidationErrorsForField(fluentValidationErrors, fieldName);
      break;
    default:
      break;
  }

  return fieldValidationErrors;
}

export function checkFormFieldsForErrors(formErrors) {
  const formHasErrors = Object.values(formErrors).some((x) => x === false);

  return formHasErrors;
}
