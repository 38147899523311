import { TextFieldProps } from '@mui/material';
import './FilterSelect.scss';
import CustomTextField from '../CustomTextField/CustomTextField';

const FilterSelect = ({ className, ...rest }: TextFieldProps) => (
  <CustomTextField
    {...rest}
    select
    className={`filter-select${className ? ` ${className}` : ''}`}
  />
);

export default FilterSelect;
