import DashboardSection from './DashboardSection/DashboardSection';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import { ROUTE_LINKS, SCREEN_SIZES } from '../../cms';
import { addSmallStyle } from '../../cms/Utils';
import ProfileSection from './Profile/ProfileSection';
import TrainingsSection from './Trainings/TrainingsSection';
import EventsSection from './Events/EventsSection';
import './DashboardPage.scss';

const sections = [
  {
    name: 'profile',
    title: 'My Profile',
    component: <ProfileSection />,
  },
  {
    name: 'trainings',
    title: 'My Trainings',
    link: ROUTE_LINKS.MY_TRAININGS_AND_EVENTS,
    component: <TrainingsSection />,
  },
  {
    name: 'events',
    title: 'My Events',
    link: ROUTE_LINKS.MY_TRAININGS_AND_EVENTS,
    component: <EventsSection />,
  },
];

const DashboardPage = () => {
  const isScreenSmall = useMediaQuery(SCREEN_SIZES.SMALL);

  return (
    <div className="dashboard-page">
      <div
        className={`dashboard-page-container${addSmallStyle(isScreenSmall)}`}
      >
        <DashboardSection
          key={sections[0].name}
          section={sections[0]}
          classNames={`dashboard-section-profile${addSmallStyle(
            isScreenSmall,
          )}`}
        />

        <div className="dashboard-sections-container">
          {sections.slice(1).map((section) => (
            <DashboardSection
              key={section.name}
              classNames="dashboard-section"
              section={section}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
