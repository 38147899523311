import { useSearchParams } from 'react-router-dom';
import { Box, Chip, Stack, Typography } from '@mui/material';
import eventCardGenericImage from '../../images/event-card_visual.png';
import './Event.scss';
import { APPLICATION_STATUSES, COLORS } from '../../../cms/index';
import { EventShortResponse } from '../../../cms/types/eventTypes';
import {
  getApplicationsStatusColor,
  getEventDatePeriod,
  getEventTimePeriod,
} from '../../../cms/Utils';
import { SkillTypeIcon } from '../index';
import { ApplicationStatuses } from '../../../cms/enums';

type EventProps = {
  event: EventShortResponse;
  applicationStatus?: ApplicationStatuses;
};

const Event = ({ event, applicationStatus }: EventProps) => {
  const [, setSearchParams] = useSearchParams();
  const { id, startDate, endDate, type, title, cost, agenda } = event;
  const eventId = event.eventId && event.eventId > 0 ? event.eventId : id;
  const statusColor =
    applicationStatus && getApplicationsStatusColor(applicationStatus);

  const eventDatePeriod = getEventDatePeriod(startDate, endDate);
  const eventTimePeriod = getEventTimePeriod(agenda);

  const handleOnClick = () => {
    setSearchParams((prev) => {
      prev.set('eventId', eventId.toString());
      return prev;
    });
  };

  return (
    <Box className="event-card-outer">
      {applicationStatus && (
        <Typography
          className="event-application-status"
          sx={{ backgroundColor: statusColor }}
        >
          {
            Object.values(APPLICATION_STATUSES).find(
              (s) => s.value === applicationStatus,
            )?.label
          }
        </Typography>
      )}

      <Box onClick={handleOnClick} className="event-card">
        <Box className="event-card-header">
          <img
            className="event-card-image"
            alt="Event"
            src={eventCardGenericImage}
          />
          <Box className="event-card-label">
            <SkillTypeIcon skillType={type} color={COLORS.WHITE} size={18} />
            <Typography className="event-card-duration">
              {eventDatePeriod}
            </Typography>
          </Box>
        </Box>
        <Box className="event-card-content">
          <Stack className="event-card-content-text" gap="0.5rem">
            <Typography noWrap className="event-card-title">
              {title}
            </Typography>
            <Typography noWrap className="event-card-hours">
              {eventTimePeriod}
            </Typography>
          </Stack>

          {cost > 0 ? (
            <Chip
              label="Paid"
              color="primary"
              className="event-card-price"
              size="small"
            />
          ) : (
            <Chip
              label="Free"
              color="success"
              className="event-card-price"
              size="small"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Event;
