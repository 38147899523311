import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware, { Saga } from 'redux-saga';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';
import rootSaga from '../sagas/rootSaga';
import { api } from './api/api';
import { migrations } from './migrations';

const whitelist = ['userSession'];

const persistConfig = {
  key: 'root',
  storage,
  whitelist,
  // Increment by 1 and create a migration when you change the whitelist
  version: 0,
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, api.middleware];

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
});

const persistor = persistStore(store);
sagaMiddleware.run(rootSaga as Saga);

export type AppDispatch = typeof store.dispatch;

export { store, persistor };
