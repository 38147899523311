import { isEqual } from 'lodash';
import {
  COMPLETE_EVENT_URL,
  DROP_EVENT_URL,
  GET_ALL_EVENT_APPLICATIONS_URL,
  START_EVENT_URL,
} from '../../api/trainingsPortalBackend/config';
import {
  EventApplication,
  GetEventApplicationsForExportParams,
  GetEventApplicationsParams,
} from '../../cms/types/eventApplicationTypes';
import { api } from './api';

export const eventApplicationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEventApplications: build.query<
      EventApplication[],
      GetEventApplicationsParams
    >({
      query: (params) => ({
        url: GET_ALL_EVENT_APPLICATIONS_URL,
        params,
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentData, responseData, { arg }) => {
        if (arg?.pageNumber === 1) {
          return responseData;
        }

        currentData.push(...responseData);
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        isEqual(currentArg, previousArg),
    }),
    getEventApplicationsForExport: build.query<
      EventApplication[],
      GetEventApplicationsForExportParams
    >({
      query: (params) => ({
        url: GET_ALL_EVENT_APPLICATIONS_URL,
        params,
      }),
    }),
    startEventApplication: build.mutation<any, number>({
      query: (applicationId) => ({
        url: `${START_EVENT_URL}/${applicationId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error) =>
        error ? [] : ['Event', 'EventApplication', 'Application', 'Path'],
    }),
    completeEventApplication: build.mutation<any, number>({
      query: (applicationId) => ({
        url: `${COMPLETE_EVENT_URL}/${applicationId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error) =>
        error ? [] : ['Event', 'EventApplication', 'Application', 'Path'],
    }),
    dropEventApplication: build.mutation<any, number>({
      query: (applicationId) => ({
        url: `${DROP_EVENT_URL}/${applicationId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error) =>
        error ? [] : ['Event', 'EventApplication', 'Application', 'Path'],
    }),
  }),
});

export const {
  useGetEventApplicationsQuery,
  useLazyGetEventApplicationsForExportQuery,
  useStartEventApplicationMutation,
  useCompleteEventApplicationMutation,
  useDropEventApplicationMutation,
} = eventApplicationApi;
