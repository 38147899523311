import { useState } from 'react';
import { Button } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  BUTTON_LABELS,
  ENVIRONMENT_NAMES,
  ROLES,
} from '../../../../../../../cms';
import UserImport from '../UserImport/UserImport';
import { BaseModal } from '../../../../../../../common/components';
import config from '../../../../../../../config';
import { getUserRoles } from '../../../../../../../common/userRoleRetriever';
import './ImportUsersButton.scss';

const ImportUsersButton = () => {
  const [importUsersModalOpened, setImportUsersModalOpened] = useState(false);
  const openImportUserModal = () => setImportUsersModalOpened(true);

  return (
    <>
      <Button
        variant="contained"
        startIcon={<UploadFileIcon />}
        className="import-employees-btn"
        disabled={
          config.ENVIRONMENT === ENVIRONMENT_NAMES.TRIAL &&
          !getUserRoles().includes(ROLES.SUPER_ADMIN_ROLE)
        }
        sx={{
          width: { xs: '100%', sm: 'auto' },
        }}
        onClick={openImportUserModal}
      >
        {BUTTON_LABELS.IMPORT_EMPLOYEES_DATA.toUpperCase()}
      </Button>
      <BaseModal
        open={importUsersModalOpened}
        onClose={() => setImportUsersModalOpened(false)}
      >
        <UserImport setImportUsersModalOpened={setImportUsersModalOpened} />
      </BaseModal>
    </>
  );
};

export default ImportUsersButton;
