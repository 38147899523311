import { EditEmployeeRequestModel } from './EditEmployee';
import {
  EDIT_EMPLOYEE_REQUEST,
  EDIT_EMPLOYEE_REQUEST_ERROR,
  EDIT_EMPLOYEE_REQUEST_SUCCESS,
} from './types';

/** Action - edit employee.
 * @return {Object} Return object
 */
export function editEmployee(model: EditEmployeeRequestModel) {
  return {
    type: EDIT_EMPLOYEE_REQUEST,
    payload: {
      model,
    },
  };
}

/** On successful edit employee
 * @param {Array} allDepartments
 * @return {any} Return object
 */
export function editEmployeeSuccess(isSuccessfull: boolean) {
  return {
    type: EDIT_EMPLOYEE_REQUEST_SUCCESS,
    payload: {
      isSuccessfull,
    },
  };
}

/** Handle error
 * @param {string} errorMessage - Error message
 */
export function editEmployeeError(errorMessage: any) {
  return {
    type: EDIT_EMPLOYEE_REQUEST_ERROR,
    payload: errorMessage,
  };
}
