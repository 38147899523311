import {
  EXTERNAL_LOGIN_SERVICE_URL,
  LOGIN_SERVICE_URL,
  LOGOUT_SERVICE_URL,
  UPDATE_IMAGE_URL,
} from '../../api/trainingsPortalBackend/config';
import { api } from './api';

type LoginParams = {
  token: string;
  provider: string;
};

type ExternalLoginParams = {
  email: string;
  password: string;
};

export type UpdateImageParams = {
  rawImage: string;
}

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<any, LoginParams>({
      query: (loginModel) => ({
        url: LOGIN_SERVICE_URL,
        method: 'POST',
        body: loginModel,
      }),
    }),
    externalLogin: build.mutation<any, ExternalLoginParams>({
      query: (externalLoginModel) => ({
        url: EXTERNAL_LOGIN_SERVICE_URL,
        method: 'POST',
        body: externalLoginModel,
      }),
    }),
    logout: build.mutation<any, void>({
      query: () => ({
        url: LOGOUT_SERVICE_URL,
        method: 'POST',
      }),
    }),
    updateImage: build.mutation<any, UpdateImageParams>({
      query: (updateImageModel) => ({
        url: UPDATE_IMAGE_URL,
        method: 'POST',
        body: updateImageModel
      }),
    }),
  }),
});

export const { useLoginMutation, useExternalLoginMutation, useLogoutMutation, useUpdateImageMutation } =
  userApi;
