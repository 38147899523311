import { put, take, call } from 'redux-saga/effects';
import { ASSIGN_TRAININGS_TO_GROUP_REQUEST } from './types';
import { assignGroupTrainingsSuccess } from './actions';
import { handleValidationErrors } from '../../../../../../../../common/validation/actions';

/** If detect type: ASSIGN_TRAININGS_TO_GROUP_REQUEST call assignGroupTrainings */
export default function* assignGroupTrainingsSaga(api) {
  while (true) {
    const assignGroupTrainingsRequest = yield take(
      ASSIGN_TRAININGS_TO_GROUP_REQUEST,
    );
    if (assignGroupTrainingsRequest.payload) {
      const { assignGroupTrainingsModel } = assignGroupTrainingsRequest.payload;
      yield call(assignGroupTrainings, api, assignGroupTrainingsModel);
    }
  }
}

/** Assign Group Trainings */
function* assignGroupTrainings(api, assignGroupTrainingsModel) {
  try {
    yield call(api.assignGroupTrainings, assignGroupTrainingsModel);

    const isSuccessful = true;
    yield put(assignGroupTrainingsSuccess(isSuccessful));
  } catch (error) {
    yield put(handleValidationErrors(error.response.data));
  }
}
