import { IconButton, Stack, Tooltip } from '@mui/material';
import { MenuOptions } from '../../../cms/types';

type InlineIconMenuProps = {
  options: MenuOptions[];
};

const InlineIconMenu = ({ options }: InlineIconMenuProps) => {
  return (
    <Stack direction="row" className="menu-actions-menu-list">
      {options.map(({ show = true, label, icon, action, className }) =>
        show ? (
          <Tooltip key={label} title={label}>
            <IconButton
              className={`menu-option${className ? ` ${className}` : ''}`}
              onClick={action}
            >
              {icon}
            </IconButton>
          </Tooltip>
        ) : null,
      )}
    </Stack>
  );
};

export default InlineIconMenu;
