import dayjs from 'dayjs';
import { isEqual, unionBy } from 'lodash';
import {
  APPLY_FOR_EVENT_URL,
  ASSIGN_EVENT_URL,
  EVENT_UPLOAD_PDF_URL,
  GET_EVENTS_URL,
  GET_MY_EVENTS_URL,
} from '../../api/trainingsPortalBackend/config';
import { api } from './api';
import { DATE_FORMATS } from '../../cms';
import {
  AssignEventDto,
  EventResponse,
  EventSearchParams,
  EventUploadPdfsModel,
  EventsResponse,
  MyEventsResponse,
} from '../../cms/types/eventTypes';
import { transformErrorResponse } from '../../cms/Utils';

const transformEventShortResponse = (event: any) => ({
  ...event,
  startDate: new Date(event.startDate),
  endDate: new Date(event.endDate),
  agenda: event.agenda.map((agendaItem: any) => ({
    ...agendaItem,
    date: new Date(agendaItem.date),
    eventItems: agendaItem.eventItems.map((eventItem: any) => ({
      ...eventItem,
      date: new Date(agendaItem.date),
      startTime: dayjs(eventItem.startTime, DATE_FORMATS.HOUR_MINUTES).toDate(),
      endTime: dayjs(eventItem.endTime, DATE_FORMATS.HOUR_MINUTES).toDate(),
    })),
  })),
});

const transformEventResponse = (event: any) => ({
  ...event,
  startDate: new Date(event.startDate),
  endDate: new Date(event.endDate),
  applyBeforeDate: new Date(event.applyBeforeDate),
  agenda: event.agenda.map((agendaItem: any) => ({
    ...agendaItem,
    date: new Date(agendaItem.date),
    eventItems: agendaItem.eventItems.map((eventItem: any) => ({
      ...eventItem,
      date: new Date(agendaItem.date),
      startTime: dayjs(eventItem.startTime, DATE_FORMATS.HOUR_MINUTES).toDate(),
      endTime: dayjs(eventItem.endTime, DATE_FORMATS.HOUR_MINUTES).toDate(),
    })),
  })),
});

export const eventApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMyEvents: build.query<MyEventsResponse, void>({
      query: () => ({
        url: `${GET_MY_EVENTS_URL}`,
      }),
      forceRefetch: () => true,
      providesTags: ['EventApplication'],
    }),
    getEvents: build.query<EventsResponse, EventSearchParams>({
      query: (args) => ({
        url: GET_EVENTS_URL,
        params: args,
      }),
      transformResponse: (response: any) => {
        return {
          ...response,
          events: response.events.map((event: any) =>
            transformEventShortResponse(event),
          ),
        };
      },
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCacheData, responseData, { arg }) => {
        if (arg?.pageNumber === 1) return responseData;

        return {
          totalCount: responseData.totalCount,
          // Combine both arrays and skip duplicates
          events: unionBy(currentCacheData.events, responseData.events, 'id'),
        };
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        isEqual(currentArg, previousArg),
    }),
    getEvent: build.query<EventResponse, number>({
      query: (eventId) => `${GET_EVENTS_URL}/${eventId}`,
      transformResponse: (event: any) => transformEventResponse(event),
      providesTags: ['Event'],
    }),
    createEvent: build.mutation<any, any>({
      query: (eventModel) => ({
        url: GET_EVENTS_URL,
        method: 'POST',
        body: eventModel,
      }),
      transformErrorResponse,
    }),
    uploadEventPdfs: build.mutation<any, EventUploadPdfsModel>({
      query: (model) => {
        const formData = new FormData();
        model.files.forEach((file: any, index: number) => {
          formData.append(`file${index}`, file);
        });

        return {
          url: `${EVENT_UPLOAD_PDF_URL}/${model.id}`,
          method: 'POST',
          headers: {
            'content-type': 'multipart/form-data',
          },
          body: formData,
        };
      },
      invalidatesTags: ['Event'],
    }),
    editEvent: build.mutation<any, any>({
      query: (model) => ({
        url: GET_EVENTS_URL,
        method: 'PUT',
        body: model,
      }),
      invalidatesTags: ['Event', 'EventApplication', 'Application'],
      transformErrorResponse,
    }),
    deleteEvent: build.mutation<any, number>({
      query: (eventId) => ({
        url: `${GET_EVENTS_URL}/${eventId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Event', 'EventApplication', 'Application'],
    }),

    assignEvent: build.mutation<any, AssignEventDto>({
      query: (assignEventDto) => ({
        url: ASSIGN_EVENT_URL,
        method: 'POST',
        body: assignEventDto,
      }),
      invalidatesTags: ['Event', 'EventApplication'],
    }),
    applyForEvent: build.mutation<any, number>({
      query: (eventId) => ({
        url: APPLY_FOR_EVENT_URL,
        method: 'POST',
        body: {
          eventId,
        },
      }),
      invalidatesTags: (result, error) =>
        error ? [] : ['Event', 'EventApplication', 'Application'],
    }),
  }),
});

export const {
  useGetMyEventsQuery,
  useGetEventsQuery,
  useLazyGetEventQuery,
  useGetEventQuery,
  useCreateEventMutation,
  useUploadEventPdfsMutation,
  useEditEventMutation,
  useDeleteEventMutation,
  useAssignEventMutation,
  useApplyForEventMutation,
} = eventApi;
