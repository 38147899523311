import { useEffect, useState } from 'react';
import {
  Toolbar,
  Drawer,
  Collapse,
  ListItemButton,
  Avatar,
  List,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavbarLink } from '../../../cms/types';
import './AppDrawer.scss';
import { useAppDispatch, useAppSelector } from '../../../rtk/hooks';
import { extractInitials } from '../../../cms/Utils';
import { closeDrawer } from '../../../rtk/features/drawerSlice';
import { DRAWER_TYPES } from '../../../cms';
import { useLogoutMutation } from '../../../rtk/api/userApi';
import { resetReduxStore } from '../../../rtk/rootReducer';

type AppDrawerProps = {
  links: NavbarLink[];
};

const AppDrawer = ({ links }: AppDrawerProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [logout] = useLogoutMutation();
  const { openedDrawer } = useAppSelector((state) => state.drawer);
  const { firstName, lastName, photoUrl } = useAppSelector(
    (state) => state.userSession,
  );
  const fullName = `${firstName} ${lastName}`;
  const [linksOpened, setLinksOpened] = useState(true);
  const open = openedDrawer === DRAWER_TYPES.APP;

  useEffect(() => {
    if (open) setLinksOpened(true);
  }, [open]);

  const handleLogout = () => {
    logout().then(() => dispatch(resetReduxStore()));
  };

  const handleCloseDrawer = () => {
    dispatch(closeDrawer());
  };

  const handleToggleLinks = () => {
    setLinksOpened((prev) => !prev);
  };

  const handleOnLinkClick = (link: string) => {
    navigate(link);
    handleCloseDrawer();
  };

  return (
    <Drawer
      sx={{
        width: '100%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '100%',
          boxSizing: 'border-box',
        },
      }}
      variant="temporary"
      open={open}
      onClose={handleCloseDrawer}
      anchor="top"
    >
      <Toolbar className="drawer-toolbar" />
      <List className="drawer-list" disablePadding>
        <ListItemButton
          className="drawer-profile-btn"
          onClick={handleToggleLinks}
        >
          <Avatar
            alt="User avatar"
            className="drawer-profile-btn-avatar"
            src={photoUrl}
          >
            {extractInitials(fullName)}
          </Avatar>

          {fullName}
          {linksOpened ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </ListItemButton>
        <Collapse
          in={!linksOpened}
          timeout={300}
          unmountOnExit
          sx={{ width: '100%' }}
        >
          <List className="drawer-profile-menu" disablePadding>
            <ListItemButton
              className="drawer-profile-menu-item"
              onClick={handleLogout}
            >
              <LogoutIcon sx={{ fontSize: 16 }} /> Log out
            </ListItemButton>
          </List>
        </Collapse>
        <Collapse
          in={linksOpened}
          timeout={300}
          unmountOnExit
          sx={{ width: '100%' }}
        >
          <List component="nav" className="drawer-links-menu" disablePadding>
            {links.map(({ link, name, hide }) => {
              if (hide) return null;

              return (
                <ListItemButton
                  onClick={() => handleOnLinkClick(link)}
                  key={name}
                  className={`drawer-links-menu-link${
                    location.pathname === link ? ' active' : ''
                  }`}
                >
                  {name}
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      </List>
    </Drawer>
  );
};

export default AppDrawer;
