import { useState } from 'react';
import { useDispatch } from 'react-redux';
import accountIcon from '../../../../../../../../common/images/avatar.png';
import {
  BUTTON_LABELS,
  GROUP_MANAGEMENT_LABELS,
  MODAL_VALUES,
} from '../../../../../../../../cms';
import './GroupMemberCard.scss';
import { removeMember } from '../RemoveMemberFromGroup/actions';
import {
  BaseModal,
  ConfirmAction,
  ErrorMessage,
} from '../../../../../../../../common/components';

export default function GroupMemberCard({ groupmembers, groupId }) {
  const dispatch = useDispatch();

  const [isConfirmDeleteModalOpened, setIsConfirmDeleteModalOpened] =
    useState(false);
  const [isErrorModalOpened, setIsErrorModalOpened] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState('');

  const closeDeleteConfirmationModal = () => {
    setIsConfirmDeleteModalOpened(false);
    setMemberToRemove(undefined);
  };

  const handleErrorClose = () => {
    setIsErrorModalOpened(false);
    setMemberToRemove(undefined);
  };

  const openDeleteConfirmationModal = (memberToRemove) => {
    if (groupmembers.length > 1) {
      setIsConfirmDeleteModalOpened(true);
      setMemberToRemove(memberToRemove);
    } else {
      setIsErrorModalOpened(true);
      setTimeout(() => {
        handleErrorClose();
      }, MODAL_VALUES.MODAL_CLOSE_TEN_SECONDS_TIMEOUT);
    }
  };

  const removeMemberFromGroup = () => {
    const model = {
      userExternalId: memberToRemove,
      groupExternalId: groupId,
    };

    dispatch(removeMember(model));
    closeDeleteConfirmationModal();
  };

  return (
    <>
      {groupmembers.length > 0 && (
        <div className="w-100">
          <div className="d-flex all-group-members-container">
            {groupmembers.map((groupmember, index) => (
              <div
                className="mt-4 mr-3 pb-1 d-flex justify-content-center group-member-container"
                key={groupmember.externalId}
              >
                <div>
                  <button
                    onClick={() =>
                      openDeleteConfirmationModal(groupmember.externalId)
                    }
                    type="button"
                    className="close remove-member"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="group-member-img-container text-center mb-2 mt-6 pt-4">
                    <img
                      className={`${
                        groupmember.photoUrl ? 'account-icon' : ''
                      }`}
                      width="75px"
                      src={
                        groupmember.photoUrl
                          ? groupmember.photoUrl
                          : accountIcon
                      }
                      alt="account icon"
                    />
                  </div>
                  <div
                    title={`${groupmember.firstName} ${groupmember.lastName}`}
                    className="col-12 group-member-names text-center mb-1 font-family-bold"
                  >
                    {groupmember.firstName} {groupmember.lastName}
                  </div>
                  <div
                    title={groupmember.jobTitle}
                    className="group-member-job-title text-center"
                  >
                    {groupmember.jobTitle}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <BaseModal
        open={isConfirmDeleteModalOpened}
        onClose={closeDeleteConfirmationModal}
        showCloseBtnOutside
      >
        <ConfirmAction
          text={GROUP_MANAGEMENT_LABELS.REMOVE_MEMBER}
          btnText={BUTTON_LABELS.DELETE_BUTTON}
          onConfirm={removeMemberFromGroup}
          onClose={closeDeleteConfirmationModal}
        />
      </BaseModal>
      <BaseModal
        hideCloseBtn
        open={isErrorModalOpened}
        onClose={handleErrorClose}
      >
        <ErrorMessage text={GROUP_MANAGEMENT_LABELS.REMOVE_MEMBER_FORBIDDEN} />
      </BaseModal>
    </>
  );
}
