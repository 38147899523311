import { ENVIRONMENT_NAMES } from './cms';

const config = window.backEndConfig;

// Switch to true when you want to connect to tche test API
const useTestAPI = false;
// Switch to true when you want to see the Trial version
const isTrial = false;

if (useTestAPI && config.ENVIRONMENT !== ENVIRONMENT_NAMES.PRODUCTION) {
  config.API_PREFIX =
    'https://tp-str-trainingportalapi-t.azurewebsites.net/api';
  config.HUB_PREFIX =
    'https://tp-str-trainingportalapi-t.azurewebsites.net/hubs';
}

if (isTrial && config.ENVIRONMENT !== ENVIRONMENT_NAMES.PRODUCTION) {
  config.ENVIRONMENT = ENVIRONMENT_NAMES.TRIAL;
}

export default config;
