import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Box,
  Button,
  Container,
  Fab,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import {
  BUTTON_LABELS,
  EVENT_FORM_TYPES,
  MODAL_CLOSE_BTNS,
  NOT_FOUND_MESSAGES,
  PLACEHOLDERS,
} from '../../cms';
import './EventsPage.scss';
import { useGetEventsQuery } from '../../rtk/api/eventApi';
import {
  Event,
  EventTypes,
  Loader,
  SearchField,
} from '../../common/components';
import { useAppDispatch, useAppSelector } from '../../rtk/hooks';
import {
  incrementEventsPageNumber,
  resetEventsPagination,
  setEventsSearchText,
  setEventsTypes,
} from '../../rtk/features/eventsPaginationSlice';
import { openModal } from '../../rtk/features/modalsSlice';
import { useDebounce } from '../../common/hooks/useDebounce';
import { ModalTypes, SkillTypes } from '../../cms/enums';

const EventsPage = () => {
  const dispatch = useAppDispatch();
  const eventsPagination = useAppSelector((state) => state.eventsPagination);
  const debouncedSearchValue = useDebounce(
    eventsPagination.searchText.trim().toLowerCase(),
    300,
  );
  const { data: eventsData, isLoading } = useGetEventsQuery(
    {
      ...eventsPagination,
      searchText: debouncedSearchValue,
      types: eventsPagination.types.join(','),
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { events = [], totalCount = 0 } = eventsData || {};
  const hasMoreEvents: boolean =
    totalCount - eventsPagination.pageNumber * eventsPagination.pageSize > 0;

  useEffect(() => {
    return () => {
      dispatch(resetEventsPagination());
    };
  }, []);

  const handleOnTypesChange = (newTypes: SkillTypes[]) => {
    dispatch(setEventsTypes(newTypes));
  };

  const handleOnSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEventsSearchText(e.target.value));
  };

  const fetchMoreData = () => {
    dispatch(incrementEventsPageNumber());
  };

  const handleOnCreateEventBtnClick = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.EVENT_FORM,
        modalData: {
          type: EVENT_FORM_TYPES.CREATE,
        },
        closeBtn: MODAL_CLOSE_BTNS.OUTSIDE,
      }),
    );
  };

  return (
    <Container className="events-page" maxWidth="xl">
      <Stack
        direction="row"
        className="events-page-header"
        spacing={{ xs: 2, sm: 4 }}
        alignItems="center"
        flexWrap="wrap"
        useFlexGap
      >
        <SearchField
          placeholder={PLACEHOLDERS.SEARCH_EVENTS}
          value={eventsPagination.searchText}
          onChange={handleOnSearchTextChange}
          className="events-page-search"
          sx={{
            width: { xs: '100%', sm: 300 },
          }}
        />
        <EventTypes
          values={eventsPagination.types}
          onChange={handleOnTypesChange}
        />
        <Button
          startIcon={<AddCircleIcon />}
          variant="contained"
          className="create-event-btn"
          sx={{
            display: {
              xs: 'none',
              sm: 'flex',
            },
          }}
          onClick={handleOnCreateEventBtnClick}
        >
          {BUTTON_LABELS.NEW_EVENT}
        </Button>
        <Fab
          size="large"
          color="primary"
          aria-label="Add Event"
          sx={{
            position: 'absolute',
            bottom: '4rem',
            right: '1.5rem',
            display: {
              xs: 'flex',
              sm: 'none',
            },
          }}
          onClick={handleOnCreateEventBtnClick}
        >
          <AddIcon />
        </Fab>
      </Stack>
      {isLoading && (
        <div className="events-container-loading">
          <Loader />
        </div>
      )}
      {!isLoading && (
        <Box className="events-container">
          {events.length === 0 ? (
            <Typography className="events-page-not-found">
              {NOT_FOUND_MESSAGES.EVENTS}
            </Typography>
          ) : (
            <InfiniteScroll
              className="events-page-infinite-scroll"
              dataLength={events.length}
              next={fetchMoreData}
              hasMore={hasMoreEvents}
              loader={<Loader />}
              scrollableTarget="scrollableDiv"
            >
              <Grid container spacing={4}>
                {events.map((event) => (
                  <Grid
                    item
                    key={event.id}
                    title={event.title}
                    xs={12}
                    sm={4}
                    md={3}
                    lg={2.4}
                    xl={2}
                  >
                    <Event event={event} />
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          )}
          <span id="end-of-events" />
        </Box>
      )}
    </Container>
  );
};

export default EventsPage;
