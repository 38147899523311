import {
  APPROVE_EVENT_APPLICATION_URL,
  PERSONAL_APPLICATIONS_URL,
  REJECT_EVENT_APPLICATION_URL,
} from '../../api/trainingsPortalBackend/config';
import {
  ApplicationStatuses,
  MyTrainingsAndEventsTypes,
} from '../../cms/enums';
import { api } from './api';

export type PersonalApplication = {
  id: number;
  entryId: number;
  title: string;
  pathId: number | null;
  comment: string;
  statusDate: Date;
  type: MyTrainingsAndEventsTypes;
  status: ApplicationStatuses;
  isEntityArchived: boolean;
  isEntityExpired: boolean;
};

export type PersonalApplicationParams = {
  userId: number;
  search: string;
  type: string;
  status: string;
};

export type EventApplicationActionRequestModel = {
  comment: string;
  applicationId: string;
};

export const applicationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPersonalApplications: build.query<
      PersonalApplication[],
      PersonalApplicationParams
    >({
      query: (params) => ({ url: PERSONAL_APPLICATIONS_URL, params }),
      providesTags: ['Application'],
    }),
    approveEventApplication: build.mutation<
      any,
      EventApplicationActionRequestModel
    >({
      query: (model) => ({
        url: APPROVE_EVENT_APPLICATION_URL,
        method: 'POST',
        body: model,
      }),
      invalidatesTags: ['MyTeamApplications'],
    }),
    rejectEventApplication: build.mutation<
      any,
      EventApplicationActionRequestModel
    >({
      query: (model) => ({
        url: REJECT_EVENT_APPLICATION_URL,
        method: 'POST',
        body: model,
      }),
      invalidatesTags: ['MyTeamApplications'],
    }),
  }),
});

export const {
  useGetPersonalApplicationsQuery,
  useApproveEventApplicationMutation,
  useRejectEventApplicationMutation,
} = applicationApi;
