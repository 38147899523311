import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type UrlParams = {
  eventId: number | undefined;
  trainingId: number | undefined;
};

export const useUrlParams = () => {
  const { search } = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  const params: any = Object.fromEntries(urlSearchParams);

  return params as UrlParams;
};
