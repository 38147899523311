import { useState } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../rtk/hooks';
import { extractInitials } from '../../../cms/Utils';
import './AppNavbarProfile.scss';
import { useLogoutMutation } from '../../../rtk/api/userApi';
import { resetReduxStore } from '../../../rtk/rootReducer';

const menuProps = {
  elevation: 0,
  sx: {
    minWidth: '10rem',
    maxWidth: '100%',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 0.5,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 16,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

const AppNavbarProfile = () => {
  const dispatch = useAppDispatch();
  const [logout] = useLogoutMutation();
  const { firstName, lastName, photoUrl } = useAppSelector(
    (state) => state.userSession,
  );
  const fullName = `${firstName} ${lastName}`;
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = anchorElement ? true : false;

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElement(null);
  };

  return (
    <Box
      className="navbar-profile"
      sx={{
        display: { xs: 'none', lg: 'flex' },
      }}
    >
      <Tooltip title="Open Profile Menu">
        <IconButton
          className="navbar-profile-btn"
          onClick={handleOpenMenu}
          aria-controls={open ? 'navbar-profile-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar
            alt="User avatar"
            src={photoUrl}
            className="navbar-profile-avatar"
          >
            {extractInitials(fullName)}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorElement}
        id="navbar-profile-menu"
        className="navbar-profile-menu"
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{ disablePadding: true }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        slotProps={{
          paper: menuProps,
        }}
      >
        <MenuItem divider className="navbar-profile-menu-name">
          {fullName}
        </MenuItem>
        <MenuItem
          onClick={() => logout().then(() => dispatch(resetReduxStore()))}
          className="navbar-profile-menu-logout"
        >
          <LogoutIcon sx={{ fontSize: 16 }} /> Log out
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default AppNavbarProfile;
