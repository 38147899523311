import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LOGIN_LABELS } from '../../cms';
import { useExternalLoginMutation } from '../../rtk/api/userApi';
import {
  LoginSchema,
  LoginSchemaType,
} from '../../cms/validation/loginValidation';
import { useAppDispatch } from '../../rtk/hooks';
import { closeModal } from '../../rtk/features/modalsSlice';
import { CustomTextField } from '../../common/components';
import { ModalTypes } from '../../cms/enums';
import './ExternalLogin.scss';

const ExternalLogin = () => {
  const dispatch = useAppDispatch();
  const [externalLogin, { error: loginError, isSuccess }] =
    useExternalLoginMutation();

  const {
    handleSubmit,
    setError,
    control,
    formState: { errors, isSubmitting },
  } = useForm<LoginSchemaType>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  useEffect(() => {
    if (loginError) {
      setError('root.serverError', {
        type: 'server',
        message: (loginError as any).data.message,
      });
    }
  }, [loginError]);

  const onSubmit = async (data: LoginSchemaType) => {
    await externalLogin({
      email: data.username,
      password: data.password,
    });
  };

  useEffect(() => {
    if (isSuccess) dispatch(closeModal(ModalTypes.LOGIN_FORM));
  }, [isSuccess]);

  return (
    <form
      className="external-login-form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Controller
        name="username"
        control={control}
        render={({
          field: { name, value, onChange, onBlur, ref },
          fieldState: { error },
        }) => (
          <CustomTextField
            className="username-input-container"
            required
            fullWidth
            label={LOGIN_LABELS.USERNAME}
            name={name}
            value={value}
            onChange={onChange}
            inputRef={ref}
            onBlur={onBlur}
            error={Boolean(error)}
            helperText={error?.message}
            inputProps={{
              className: 'external-login-form-username',
            }}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        render={({
          field: { name, value, onChange, onBlur, ref },
          fieldState: { error },
        }) => (
          <CustomTextField
            className="password-input-container"
            required
            fullWidth
            label={LOGIN_LABELS.PASSWORD}
            name={name}
            value={value}
            type="password"
            onChange={onChange}
            inputRef={ref}
            onBlur={onBlur}
            error={Boolean(error)}
            helperText={error?.message}
            inputProps={{
              className: 'external-login-form-password',
            }}
          />
        )}
      />
      <Typography className="login-error-container">
        {errors.root?.serverError.message}
      </Typography>

      <LoadingButton
        loading={isSubmitting}
        loadingPosition="center"
        startIcon={null}
        type="submit"
        variant="contained"
        className="external-login-btn"
      >
        Login
      </LoadingButton>
    </form>
  );
};

export default ExternalLogin;
