import { Button, Divider, Stack, Typography } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import './MeetingForm.scss';
import {
  PathMeetingSchemaType,
  PathMeetingSchema,
  PathItemSchemaType,
} from '../../../../../../cms/validation/pathValidation';
import {
  CustomDateTimePicker,
  CustomTextField,
} from '../../../../../../common/components';
import {
  BUTTON_LABELS,
  DATE_FORMATS,
  PATH_MEETING_DESCRIPTION_LENGTH,
  PATH_MEETING_LABELS,
} from '../../../../../../cms';
import { convertServerPathMeetingToClientPathMeeting } from '../../../../../../cms/Utils';

type MeetingFormProps = {
  initialValues?: PathItemSchemaType | null;
  onClose: (newItems: PathItemSchemaType[]) => void;
};

const MeetingForm = ({ initialValues, onClose }: MeetingFormProps) => {
  const { handleSubmit, control } = useForm<PathMeetingSchemaType>({
    resolver: zodResolver(PathMeetingSchema),
    defaultValues: {
      name: initialValues?.data.name ?? '',
      description: initialValues?.data.description ?? '',
      startDateTime: initialValues?.data.startDateTime,
    },
  });

  const formTitle = initialValues
    ? PATH_MEETING_LABELS.EDIT_MEETING
    : PATH_MEETING_LABELS.ADD_MEETING;
  const buttonTitle = initialValues
    ? BUTTON_LABELS.SAVE_CHANGES
    : PATH_MEETING_LABELS.ADD_MEETING;

  const onSubmit = (meeting: PathMeetingSchemaType) => {
    onClose([convertServerPathMeetingToClientPathMeeting(meeting)]);
  };

  return (
    <Stack className="meeting-form-container" spacing="1.5rem">
      <Typography className="meeting-form-title"> {formTitle}</Typography>
      <Stack
        component="form"
        noValidate
        className="meeting-form"
        spacing="1.5rem"
      >
        <Controller
          name="name"
          control={control}
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <CustomTextField
              label="Topic"
              color="secondary"
              required
              fullWidth
              name={name}
              value={value}
              onChange={onChange}
              inputRef={ref}
              onBlur={onBlur}
              error={Boolean(error)}
              helperText={error?.message}
              inputProps={{
                className: 'meeting-topic-input',
              }}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <CustomTextField
              label="Description"
              color="secondary"
              fullWidth
              name={name}
              value={value}
              onChange={onChange}
              inputRef={ref}
              onBlur={onBlur}
              error={Boolean(error)}
              helperText={
                error?.message ??
                `${value?.length ?? 0} / ${PATH_MEETING_DESCRIPTION_LENGTH}`
              }
              multiline
              rows={6}
              inputProps={{
                className: 'meeting-description-input',
                maxLength: PATH_MEETING_DESCRIPTION_LENGTH,
              }}
            />
          )}
        />

        <Controller
          name="startDateTime"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <CustomDateTimePicker
              required
              label="Date and Time"
              value={value}
              onChange={onChange}
              error={Boolean(error?.message)}
              helperText={error?.message}
              disablePast
              views={['year', 'month', 'day', 'hours', 'minutes']}
              format={DATE_FORMATS.DATE_TIME}
            />
          )}
        />
      </Stack>
      <Divider />
      <Stack spacing="1rem" className="meeting-form-footer">
        <Button variant="outlined" onClick={() => onClose([])}>
          {BUTTON_LABELS.CANCEL}
        </Button>
        <Button variant="contained" onClick={() => handleSubmit(onSubmit)()}>
          {buttonTitle}
        </Button>
      </Stack>
    </Stack>
  );
};

export default MeetingForm;
