import { PropsWithChildren } from 'react';
import { Stack, Typography } from '@mui/material';
import { Loader } from '../index';
import './ScrollList.scss';

type ScrollListProps = {
  className?: string;
  emptyMessage: string;
  count: number;
  isFetching?: boolean;
  emptyMessageAlignment?: 'left' | 'center';
};

const ScrollList = ({
  className,
  emptyMessage,
  count,
  isFetching = false,
  children,
  emptyMessageAlignment = 'center',
}: PropsWithChildren<ScrollListProps>) => {
  if (isFetching) return <Loader />;

  if (count === 0 && !isFetching)
    return (
      <Typography
        className={className}
        sx={{
          fontSize: '14px',
          textAlign: emptyMessageAlignment,
          padding: emptyMessageAlignment === 'left' ? 'none' : '5rem 0',
        }}
      >
        {emptyMessage}
      </Typography>
    );

  return (
    <Stack spacing="1.25rem" className={`scroll-list ${className}`}>
      {children}
    </Stack>
  );
};

export default ScrollList;
