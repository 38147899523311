import {
  GET_ALL_PROFESSIONS_REQUEST,
  GET_ALL_PROFESSIONS_REQUEST_ERROR,
  GET_ALL_PROFESSIONS_REQUEST_SUCCESS,
} from './types';

/** Action - get all professions in the system.
 * @return {Object} Return object
 */
export function getAllProfessions() {
  return {
    type: GET_ALL_PROFESSIONS_REQUEST,
  };
}

/** On successful get of all professions
 * @param {Array} allProfessions
 * @return {any} Return object
 */
export function getAllProfessionsSuccess(allProfessions: any) {
  return {
    type: GET_ALL_PROFESSIONS_REQUEST_SUCCESS,
    payload: {
      allProfessions,
    },
  };
}

/** Handle error
 * @param {string} errorMessage - Error message
 */
export function getAllProfessionsError(errorMessage: any) {
  return {
    type: GET_ALL_PROFESSIONS_REQUEST_ERROR,
    payload: errorMessage,
  };
}
