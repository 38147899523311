import dayjs, { Dayjs } from 'dayjs';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { COLORS } from '../../../../cms';

type HighlightedDayProps = PickersDayProps<Dayjs> & {
  highlightedDates: Dayjs[];
};

const HighlightedDay = (props: HighlightedDayProps) => {
  const { highlightedDates, day, outsideCurrentMonth, ...other } = props;
  const date: Dayjs = dayjs(day);
  const isHighlighted =
    !outsideCurrentMonth &&
    highlightedDates.findIndex((hDate) => date.isSame(hDate, 'day')) >= 0;

  return (
    <PickersDay
      sx={
        isHighlighted
          ? {
              backgroundColor: COLORS.SECONDARY,
              color: `${COLORS.WHITE} !important`,
              borderRadius: '6px',
              '&:hover': {
                color: `${COLORS.SECONDARY} !important`,
              },
            }
          : {}
      }
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      disableTouchRipple
      className={isHighlighted ? 'highlighted-day' : ''}
    />
  );
};

export default HighlightedDay;
