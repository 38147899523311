import { useState } from 'react';
import dayjs from 'dayjs';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import { Grid } from '@mui/material';
import AgendaForm from '../AgendaForm/AgendaForm';
import AgendaDetails from '../../components/EventDetails/AgendaDetails/AgendaDetails';
import './AgendaSpeakersForm.scss';
import SpeakersDetails from '../../components/EventDetails/SpeakersDetails/SpeakersDetails';
import SpeakerForm from '../SpeakerForm/SpeakerForm';
import {
  AgendaEventItemSchemaType,
  AgendaItemSchemaType,
  EventSchemaType,
  SpeakerSchemaType,
} from '../../cms/validation/eventValidation';
import { AgendaItemResponse } from '../../cms/types/eventTypes';

type AgendaSpeakersFormProps = {
  eventMethods: UseFormReturn<EventSchemaType>;
};

const AgendaSpeakersForm = ({ eventMethods }: AgendaSpeakersFormProps) => {
  const [agendaEventItemToEdit, setAgendaEventItemToEdit] =
    useState<AgendaEventItemSchemaType | null>(null);
  const [speakerToEdit, setSpeakerToEdit] = useState<SpeakerSchemaType | null>(
    null,
  );
  const agenda = eventMethods.watch('agenda');
  const speakers = eventMethods.watch('speakers');
  const minDate = eventMethods.watch('startDate');
  const maxDate = eventMethods.watch('endDate');
  const agendaMethods = useFieldArray({
    control: eventMethods.control,
    name: 'agenda',
  });
  const speakersMethods = useFieldArray({
    control: eventMethods.control,
    name: 'speakers',
  });

  const onAgendaStartEdit = (data: AgendaEventItemSchemaType) => {
    let newAgenda = cloneDeep(agenda);
    const agendaItemIndex = newAgenda.findIndex((ai: AgendaItemSchemaType) =>
      dayjs(ai.date).isSame(data.date, 'date'),
    );

    // Remove topic ftom agenda
    if (newAgenda[agendaItemIndex].eventItems.length === 1) {
      newAgenda = newAgenda.filter((ai, index) => index !== agendaItemIndex);
    } else {
      newAgenda[agendaItemIndex].eventItems = newAgenda[
        agendaItemIndex
      ].eventItems.filter((ei, index) => index !== data.index);
    }

    // Add canceled topic back
    if (agendaEventItemToEdit) {
      const canceledAgendaItemIndex = newAgenda.findIndex(
        (ai: AgendaItemSchemaType) =>
          dayjs(ai.date).isSame(agendaEventItemToEdit.date, 'date'),
      );

      if (canceledAgendaItemIndex === -1) {
        newAgenda.push({
          date: agendaEventItemToEdit.date!,
          eventItems: [agendaEventItemToEdit],
        });
      } else {
        newAgenda[canceledAgendaItemIndex].eventItems.push(
          agendaEventItemToEdit,
        );
      }
    }

    // Update agenda
    agendaMethods.replace(newAgenda);

    setAgendaEventItemToEdit(data);
  };

  const onAgendaCancelEdit = () => {
    onAgendaSubmit(agendaEventItemToEdit!);
    setAgendaEventItemToEdit(null);
  };

  const onAgendaSubmit = (data: AgendaEventItemSchemaType) => {
    const agendaItemIndex = agenda.findIndex((ai: AgendaItemSchemaType) =>
      dayjs(ai.date).isSame(data.date, 'date'),
    );

    if (agendaItemIndex === -1) {
      // Create new date with topic
      agendaMethods.append({
        date: data.date!,
        eventItems: [data],
      });
    } else {
      agendaMethods.update(agendaItemIndex, {
        // Create topic on existing date
        date: data.date!,
        eventItems: [...agenda[agendaItemIndex].eventItems, data],
      });
    }
  };

  const handleOnAgendaSubmit = (data: AgendaEventItemSchemaType) => {
    onAgendaSubmit(data);

    if (agendaEventItemToEdit) setAgendaEventItemToEdit(null);
  };

  const onAgendaDelete = (data: AgendaEventItemSchemaType) => {
    const agendaItemIndex = agenda.findIndex((ai) =>
      dayjs(ai.date).isSame(data.date, 'date'),
    );

    if (agenda[agendaItemIndex].eventItems.length === 1) {
      // Remove the date with the topic if the topic was the last one
      agendaMethods.remove(agendaItemIndex);
    } else {
      // Remove the topic from the date
      agendaMethods.update(agendaItemIndex, {
        date: data.date!,
        eventItems: [
          ...agenda[agendaItemIndex].eventItems.filter(
            (ei, index) => index !== data.index,
          ),
        ],
      });
    }
  };

  const onSpeakerStartEdit = (speaker: SpeakerSchemaType) => {
    let newSpeakers = cloneDeep(speakers);

    // Remove speaker
    newSpeakers = newSpeakers.filter((s, index) => index !== speaker.index);

    // Add canceled speaker back
    if (speakerToEdit) {
      newSpeakers.unshift(speakerToEdit);
    }

    // Update speakers
    speakersMethods.replace(newSpeakers);

    setSpeakerToEdit(speaker);
  };

  const onSpeakerCancelEdit = () => {
    speakersMethods.prepend(speakerToEdit!);
    setSpeakerToEdit(null);
  };

  const onSpeakerSubmit = (data: SpeakerSchemaType) => {
    speakersMethods.prepend(data);

    if (speakerToEdit) setSpeakerToEdit(null);
  };

  const onSpeakerDelete = (index: number) => {
    speakersMethods.remove(index);
  };

  return (
    <Grid
      container
      className="agenda-speakers-form"
      rowSpacing="2rem"
      columnSpacing="3rem"
    >
      <Grid item xs={12} md={6} order={{ xs: 1 }}>
        <AgendaForm
          agendaEventItemToEdit={agendaEventItemToEdit}
          minDate={minDate}
          maxDate={maxDate}
          onSubmit={handleOnAgendaSubmit}
          onCancelEdit={onAgendaCancelEdit}
        />
      </Grid>
      <Grid item xs={12} md={6} order={{ xs: 2, md: 3 }}>
        <AgendaDetails
          agenda={agenda as AgendaItemResponse[]}
          useImagePlaceholder={false}
          className="agenda-speakers-form-agenda"
          showActions
          onStartEdit={onAgendaStartEdit}
          onDelete={onAgendaDelete}
        />
      </Grid>
      <Grid item xs={12} md={6} order={{ xs: 3, md: 2 }}>
        <SpeakerForm
          speakerToEdit={speakerToEdit}
          onCancelEdit={onSpeakerCancelEdit}
          onSubmit={onSpeakerSubmit}
        />
      </Grid>
      <Grid item xs={12} md={6} order={{ xs: 4 }}>
        <SpeakersDetails
          speakers={speakers}
          useImagePlaceholder={false}
          column
          className="agenda-speakers-form-speakers"
          onStartEdit={onSpeakerStartEdit}
          onDelete={onSpeakerDelete}
        />
      </Grid>
    </Grid>
  );
};

export default AgendaSpeakersForm;
