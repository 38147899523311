
export function validateUrl(value) {
  // eslint-disable-next-line
  var regexp =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
  const result = regexp.test(value);

  return result;
}

export function validateEmailAddress(value) {
  const regexp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const result = regexp.test(value);

  return result;
}
