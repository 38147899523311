import { Button, Divider, Stack, Typography } from '@mui/material';
import { BUTTON_LABELS } from '../../../cms';
import './ConfirmAction.scss';

type ConfirmActionProps = {
  text: string;
  btnText: string;
  onConfirm: () => void;
  onClose: () => void;
};

const ConfirmAction = ({
  text,
  btnText,
  onConfirm,
  onClose,
}: ConfirmActionProps) => (
  <Stack className="confirm-action">
    <Typography className="confirm-action-title">Confirmation</Typography>
    <Divider flexItem />
    <Typography className="confirm-action-text">{text}</Typography>
    <Stack className="confirm-action-btns">
      <Button
        variant="outlined"
        color="primary"
        onClick={onClose}
        className="cancel-action-btn"
      >
        {BUTTON_LABELS.NO}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          onConfirm();
          onClose();
        }}
        className="confirm-action-btn confirm-action-btn-delete"
      >
        {btnText ?? BUTTON_LABELS.YES}
      </Button>
    </Stack>
  </Stack>
);

export default ConfirmAction;
