import { useSearchParams } from 'react-router-dom';
import { Rating, Stack, Typography } from '@mui/material';
import TrainingIcon from '@mui/icons-material/School';
import { TrainingShortResponse } from '../../../../../../../cms/types/trainingTypes';
import './TrainingsSelectCard.scss';

type TrainingsSelectCardProps = {
  training: TrainingShortResponse;
  isSelected?: boolean;
  onClick?: (training: TrainingShortResponse) => void;
};

const TrainingsSelectCard = ({
  training,
  isSelected = false,
  onClick,
}: TrainingsSelectCardProps) => {
  const [, setSearchParams] = useSearchParams();
  const { id, title, rating, location, cost, vendor } = training;
  const subTitle = `${location} | ${cost > 0 ? 'Paid' : 'Free'} | ${vendor}`;

  const titleElement = (
    <Typography
      onClick={(e) => {
        e.stopPropagation();
        handleCardTypeClick();
      }}
      noWrap
      className="trainings-select-card-data-title"
    >
      {title}
    </Typography>
  );

  const handleCardTypeClick = () => {
    setSearchParams((prev) => {
      prev.set('trainingId', id.toString());
      return prev;
    });
  };

  const handleOnClick = () => {
    if (onClick) onClick(training);
  };

  return (
    <Stack
      onClick={handleOnClick}
      direction="row"
      spacing="1rem"
      className={`trainings-select-card ${
        isSelected ? 'trainings-select-card-selected' : ''
      }`}
    >
      <Stack className="trainings-select-card-icon-container">
        <TrainingIcon className="trainings-select-card-icon" />
      </Stack>
      <Stack className="trainings-select-card-data">
        {titleElement}
        <Typography className="trainings-select-card-data-sub-title">
          {subTitle}
        </Typography>
        {rating != null && (
          <Rating
            value={rating}
            readOnly
            className="trainings-select-card-data-rating"
          />
        )}
      </Stack>
    </Stack>
  );
};

export default TrainingsSelectCard;
