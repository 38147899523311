/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Link, Stack, Typography } from '@mui/material';
import { UpcomingEvent } from '../../../../cms/types';
import './UpcomingEventItem.scss';
import { DATE_FORMATS } from '../../../../cms';

type UpcomingEventItemProps = {
  event: UpcomingEvent;
};

const UpcomingEventItem = ({ event }: UpcomingEventItemProps) => {
  const [, setSearchParams] = useSearchParams();
  const { id, startDate, title } = event;

  const handleOnClick = () => {
    setSearchParams((prev) => {
      prev.set('eventId', id.toString());
      return prev;
    });
  };

  return (
    <Stack direction="row" spacing="1rem">
      <Stack className="upcoming-event-date-container">
        <Typography className="upcoming-event-date-day">
          {dayjs(startDate).format(DATE_FORMATS.SHORT_DAY)}
        </Typography>
        <Typography className="upcoming-event-date-month">
          {dayjs(startDate).format(DATE_FORMATS.SHORT_MONTH)}
        </Typography>
      </Stack>
      <Stack alignItems="flex-start">
        <Typography className="upcoming-event-title" noWrap>
          {title}
        </Typography>
        <Link
          component="button"
          variant="body2"
          onClick={handleOnClick}
          className="upcoming-event-view-btn"
        >
          View Event
        </Link>
      </Stack>
    </Stack>
  );
};

export default UpcomingEventItem;
