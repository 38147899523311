import axiosInstance from './axiosInstance';
import { IMPORT_USERS_CSV_URL } from './config';

export const api = {
  async importUsersCsv(csvImport) {
    return axiosInstance.post(IMPORT_USERS_CSV_URL, csvImport, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  },
};
