import { put, take, call } from 'redux-saga/effects';
import { ADD_MEMBERS_TO_GROUP_REQUEST } from './types';
import { addMembersSuccess } from './actions';
import { handleValidationErrors } from '../../../../../../../../common/validation/actions';

/** If detect type: ADD_MEMBERS_TO_GROUP_REQUEST call addMembers() */
export default function* addMembersSaga(api) {
  while (true) {
    const addMembersRequest = yield take(ADD_MEMBERS_TO_GROUP_REQUEST);
    if (addMembersRequest.payload) {
      const { addMembersModel } = addMembersRequest.payload;
      yield call(addMembers, api, addMembersModel);
    }
  }
}

/** Add members */
function* addMembers(api, addMembersModel) {
  try {
    yield call(api.addMembers, addMembersModel);

    const isSuccessful = true;
    yield put(addMembersSuccess(isSuccessful));
  } catch (error) {
    yield put(handleValidationErrors(error.response.data));
  }
}
