import { useParams } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import { LiaSpinnerSolid } from 'react-icons/lia';
import { Stack, SxProps, Theme } from '@mui/material';
import { COLORS, MENU_ACTIONS } from '../../../../cms';
import {
  PathItemTypes,
  PathItemStatuses,
  ApplicationStatusesNumbers,
  PathItemStatusType,
} from '../../../../cms/enums';
import PathItemStatusBox from '../PathItemStatusBox/PathItemStatusBox';
import DotMenu from '../../DotMenu/DotMenu';
import { MenuOptions } from '../../../../cms/types';
import { useChangePathItemStatusMutation } from '../../../../rtk/api/pathApi';

type PathItemStatusMenuProps = {
  status: PathItemStatuses;
  type: PathItemTypes;
  id: number;
};

const menuOptionsIconSx: SxProps<Theme> = {
  fontSize: 16,
  color: COLORS.TEXT,
};

const PathItemStatusMenu = ({ status, type, id }: PathItemStatusMenuProps) => {
  const { id: pathIdString } = useParams();
  const pathId: number = pathIdString ? +pathIdString : -1;
  const [changeItemStatus] = useChangePathItemStatusMutation();

  const getPathItemStatusType = () => {
    if (type === PathItemTypes.TRAINING) return PathItemStatusType.TRAININIG;

    if (type === PathItemTypes.EVENT) return PathItemStatusType.EVENT;

    return PathItemStatusType.ITEM;
  };

  const pathItemStatusType = getPathItemStatusType();

  const handleMarkInProgress = () => {
    changeItemStatus({
      pathId,
      model: {
        id,
        status: ApplicationStatusesNumbers.IN_PROGRESS,
        type: pathItemStatusType,
      },
    });
  };

  const handleMarkAsCompleted = () => {
    changeItemStatus({
      pathId,
      model: {
        id,
        status: ApplicationStatusesNumbers.COMPLETED,
        type: pathItemStatusType,
      },
    });
  };

  const handleMarkAsDropped = () => {
    changeItemStatus({
      pathId,
      model: {
        id,
        status: ApplicationStatusesNumbers.DROPPED,
        type: pathItemStatusType,
      },
    });
  };

  const options: MenuOptions[] = [
    {
      show:
        status === PathItemStatuses.NOT_STARTED ||
        status === PathItemStatuses.APPROVED,
      label: MENU_ACTIONS.MARK_IN_PROGRESS,
      icon: <LiaSpinnerSolid size={16} color={COLORS.TEXT} />,
      action: handleMarkInProgress,
    },
    {
      show: status === PathItemStatuses.IN_PROGRESS,
      label: MENU_ACTIONS.MARK_AS_DONE,
      icon: <CheckIcon sx={menuOptionsIconSx} />,
      action: handleMarkAsCompleted,
    },
    {
      show:
        status === PathItemStatuses.APPROVED ||
        status === PathItemStatuses.IN_PROGRESS,
      label: MENU_ACTIONS.MARK_AS_DROPPED,
      icon: <RemoveIcon sx={menuOptionsIconSx} />,
      action: handleMarkAsDropped,
    },
  ];

  const showMenu = options.some((option) => option.show);

  return (
    <Stack
      spacing="0.5rem"
      sx={{
        flexDirection: { xs: 'column-reverse', sm: 'row' },
        justifyContent: 'flex-end',
      }}
    >
      {status && <PathItemStatusBox status={status} />}
      {showMenu && <DotMenu options={options} />}
    </Stack>
  );
};

export default PathItemStatusMenu;
