import {
  StyledToggleButtonGroup,
  StyledToggleButton,
  SkillTypeIcon,
} from '../index';
import { COLORS, NEW_PATH_TYPES } from '../../../cms';
import { SkillTypes } from '../../../cms/enums';

type PathCategoriesProps = {
  value: SkillTypes | SkillTypes[];
  onChange: (newValue: SkillTypes | SkillTypes[]) => void;
  error?: boolean;
  exclusive?: boolean;
};

const PathCategories = ({
  value,
  onChange,
  error = false,
  exclusive = false,
}: PathCategoriesProps) => {
  const chooseCategoryIconColor = (category: SkillTypes) => {
    if (error) return COLORS.ERROR;

    let isSelected;

    if (Array.isArray(value)) {
      isSelected = value.find((v) => v === category);
    } else {
      isSelected = value === category;
    }

    return isSelected ? COLORS.WHITE : COLORS.SECONDARY;
  };

  return (
    <StyledToggleButtonGroup
      value={value}
      onChange={(_, newValues: SkillTypes[]) => onChange(newValues)}
      aria-label="Path categories"
      exclusive={exclusive}
    >
      {NEW_PATH_TYPES.map(({ id, name }) => (
        <StyledToggleButton
          key={id}
          value={id}
          className={`path-category ${error ? 'toggle-btn-error' : ''}`}
        >
          <SkillTypeIcon
            skillType={id}
            size={16}
            color={chooseCategoryIconColor(id)}
          />
          {name}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

export default PathCategories;
