/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ThreeDots } from 'react-loader-spinner';
import { GROUP_MANAGEMENT_LABELS, COLORS } from '../../../../../cms';
import GroupAccordion from './GroupAccordeon/GroupAccordion';
import { getAllGroups } from './actions';
import { deleteGroup } from './GroupAccordeon/GroupDetails/DeleteGroup/actions';
import CreateGroupButton from './CreateGroupButton/CreateGroupButton';
import DeleteMultipleGroupsButton from './DeleteMultipleGroupsButton/DeleteMultipleGroupsButton';
import './GroupsOverview.scss';

export default function GroupsOverview() {
  const dispatch = useDispatch();

  const {
    groups,
    createSuccess,
    editSuccess,
    deleteSuccess,
    lastEditedGroup,
    addMembersSuccess,
    lastAddMembersToGroup,
    assignGroupTrainingsSuccess,
    lastAssignTrainingsToGroup,
    removeMemberSuccess,
    lastRemovedMemberfromGroup,
    removeTrainingSuccess,
    lastRemovedTrainingfromGroup,
  } = useSelector((state) => state.groups);
  const [userGroups, setUserGroups] = useState(groups);
  const [openedGroupId, setOpenedGroupId] = useState();
  const [lastModifiedGroupId, setLastModifiedGroupId] = useState();
  const [page, setPage] = useState(1);
  const pageSize = 20;
  const [hasMore, setHasMore] = useState(true);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);

  const onOpenHandler = (groupId) => {
    setOpenedGroupId(groupId);
  };

  const onModifyHandler = (groupId) => {
    setLastModifiedGroupId(groupId);
  };

  const fetchMoreData = () => {
    setPage(page + 1);
  };

  const loadGroups = () => {
    const model = {
      pageNumber: page,
      pageSize,
    };
    dispatch(getAllGroups(model));
  };

  const addSelectedGroupIdsHandler = (groupId) => {
    setSelectedGroupIds((selectedGroupIds) => [...selectedGroupIds, groupId]);
  };

  const removeSelectedGroupIdsHandler = (groupId) => {
    if (selectedGroupIds.length > 0) {
      setSelectedGroupIds(
        selectedGroupIds.filter(
          (selectedGroupId) => selectedGroupId !== groupId,
        ),
      );
    }
  };

  const deleteMultipleGroups = () => {
    dispatch(deleteGroup(selectedGroupIds));
    setSelectedGroupIds([]);
  };

  useEffect(() => {
    loadGroups();
  }, []);

  useEffect(() => {
    loadGroups();
  }, [page]);

  useEffect(() => {
    if (createSuccess) {
      setUserGroups([]);
      setOpenedGroupId(undefined);
      setPage(1);
      if (page == 1) {
        loadGroups();
      }
    }
  }, [createSuccess]);

  useEffect(() => {
    if (page === 1) setUserGroups(groups);
    else setUserGroups(userGroups.concat(groups));

    if (groups.length === pageSize) setHasMore(true);
    else setHasMore(false);
  }, [groups]);

  useEffect(() => {
    if (editSuccess) {
      let index = userGroups.findIndex((g) => g.id === lastEditedGroup.id);
      userGroups[index] = lastEditedGroup;
    }
  }, [editSuccess]);

  useEffect(() => {
    if (addMembersSuccess) {
      let index = userGroups.findIndex(
        (g) => g.id === lastAddMembersToGroup.id,
      );
      userGroups[index] = lastAddMembersToGroup;
    }
  }, [addMembersSuccess]);

  useEffect(() => {
    if (assignGroupTrainingsSuccess) {
      let index = userGroups.findIndex(
        (g) => g.id === lastAssignTrainingsToGroup.id,
      );
      userGroups[index] = lastAssignTrainingsToGroup;
    }
  }, [assignGroupTrainingsSuccess]);

  useEffect(() => {
    if (removeMemberSuccess) {
      let index = userGroups.findIndex(
        (g) => g.id === lastRemovedMemberfromGroup.groupExternalId,
      );
      let newGroup = userGroups[index];
      newGroup = {
        ...newGroup,
        members: newGroup.members.filter(
          (member) =>
            member.externalId != lastRemovedMemberfromGroup.userExternalId,
        ),
      };
      userGroups[index] = newGroup;
    }
  }, [removeMemberSuccess]);

  useEffect(() => {
    if (removeTrainingSuccess) {
      let index = userGroups.findIndex(
        (g) => g.id === lastRemovedTrainingfromGroup.groupExternalId,
      );
      let newGroup = userGroups[index];
      newGroup = {
        ...newGroup,
        assignedTrainings: newGroup.assignedTrainings.filter(
          (training) => training.id != lastRemovedTrainingfromGroup.trainingId,
        ),
      };
      userGroups[index] = newGroup;
    }
  }, [removeTrainingSuccess]);

  useEffect(() => {
    if (deleteSuccess && page == 1) {
      const model = {
        pageNumber: page,
        pageSize: pageSize,
      };
      dispatch(getAllGroups(model));
    } else {
      setPage(1);
    }
    setOpenedGroupId(undefined);
  }, [deleteSuccess]);

  return (
    <Stack spacing="2rem" className="groups-overview">
      <Stack
        direction="row"
        className="groups-overview-header"
        flexWrap="wrap"
        spacing="1rem"
        alignItems="center"
      >
        <Typography className="groups-overview-title">
          {GROUP_MANAGEMENT_LABELS.GROUPS_TEXT}
        </Typography>
        <Stack
          direction="row"
          spacing="1rem"
          flexWrap="wrap"
          className="groups-overview-btns"
        >
          <DeleteMultipleGroupsButton
            selectedGroupsCount={selectedGroupIds.length}
            deleteMultipleGroups={deleteMultipleGroups}
          />
          <CreateGroupButton disabled={false} />
        </Stack>
      </Stack>
      <Stack className="groups-overview-list">
        <InfiniteScroll
          dataLength={userGroups.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <ThreeDots
              color={COLORS.PRIMARY}
              className="ml-3"
              height={80}
              width={80}
            />
          }
          scrollableTarget="scrollableDiv"
        >
          {userGroups.map((group) => {
            return (
              <GroupAccordion
                key={group.id}
                group={group}
                openedGroupId={openedGroupId}
                onOpenHandler={onOpenHandler}
                onModifyHandler={onModifyHandler}
                addSelectedGroupIdsHandler={addSelectedGroupIdsHandler}
                removeSelectedGroupIdsHandler={removeSelectedGroupIdsHandler}
              />
            );
          })}
        </InfiniteScroll>
        <span id="end-of-groups" />
      </Stack>
    </Stack>
  );
}
