import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MODAL_CLOSE_BTNS } from '../../cms';
import { ModalState } from '../../cms/types';
import { ModalTypes } from '../../cms/enums';

type ModalsState = {
  modals: ModalState[];
  lastClosedModal: ModalState | null;
};

const initialState: ModalsState = {
  modals: [],
  lastClosedModal: null,
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: (state: ModalsState, { payload }: PayloadAction<ModalState>) => {
      const indexOfModal = state.modals.findIndex(
        (m) => m.modalType === payload.modalType,
      );

      if (indexOfModal === -1) {
        // Add modal
        state.modals.push({
          timeout: 0,
          closeBtn: MODAL_CLOSE_BTNS.HIDDEN,
          ...payload,
        });
      } else {
        // Replace prev instance of modal
        return {
          lastClosedModal:
            state.modals.find((m) => m.modalType === payload.modalData) ?? null,
          modals: [
            ...state.modals.filter((m) => m.modalType !== payload.modalType),
            {
              timeout: 0,
              closeBtn: MODAL_CLOSE_BTNS.HIDDEN,
              ...payload,
            },
          ],
        };
      }
    },
    closeModal: (
      state: ModalsState,
      { payload }: PayloadAction<ModalTypes>,
    ) => {
      const modalToClose =
        state.modals.find((m) => m.modalType === payload) ?? null;

      return {
        lastClosedModal: modalToClose,
        modals: state.modals.filter((m) => m.modalType !== payload),
      };
    },
  },
});

export const { openModal, closeModal } = modalsSlice.actions;
export default modalsSlice.reducer;
