import { useEffect, useState } from 'react';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import './EventsSelect.scss';
import {
  EventTypes,
  InfiniteScrollList,
  SearchField,
} from '../../../../../../common/components';
import { useAppDispatch, useAppSelector } from '../../../../../../rtk/hooks';
import { useDebounce } from '../../../../../../common/hooks/useDebounce';
import {
  BUTTON_LABELS,
  NOT_FOUND_MESSAGES,
  PLACEHOLDERS,
} from '../../../../../../cms';
import { SkillTypes } from '../../../../../../cms/enums';
import { useGetEventsQuery } from '../../../../../../rtk/api/eventApi';
import {
  incrementEventsPageNumber,
  resetEventsPagination,
  setEventsSearchText,
  setEventsTypes,
} from '../../../../../../rtk/features/eventsPaginationSlice';
import { EventShortResponse } from '../../../../../../cms/types/eventTypes';
import EventsSelectItem from './EventsSelectItem/EventsSelectItem';

type EventsSelectProps = {
  title: string;
  submitBtnText: string;
  isSubmitting?: boolean;
  onSubmit: (events: EventShortResponse[]) => void;
  eventIdsToHide?: number[];
  isSingleSelect?: boolean;
};

const EventsSelect = ({
  title,
  submitBtnText,
  isSubmitting = false,
  onSubmit,
  eventIdsToHide = [],
  isSingleSelect = false,
}: EventsSelectProps) => {
  const dispatch = useAppDispatch();
  const eventsPagination = useAppSelector((state) => state.eventsPagination);
  const debouncedSearchValue = useDebounce(
    eventsPagination.searchText.trim().toLowerCase(),
    300,
  );
  const { data, isFetching } = useGetEventsQuery(
    {
      ...eventsPagination,
      searchText: debouncedSearchValue,
      types: eventsPagination.types.join(','),
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { events = [], totalCount = 0 } = data || {};
  const availableEvents = events.filter((e) => !eventIdsToHide.includes(e.id));
  const hiddenEventsCount = events.length - availableEvents.length;
  const totalAvailableEvents = totalCount - hiddenEventsCount;
  const [selectedEvents, setSelectedEvents] = useState<EventShortResponse[]>(
    [],
  );

  useEffect(() => {
    return () => {
      dispatch(resetEventsPagination());
    };
  }, []);

  const fetchData = () => {
    dispatch(incrementEventsPageNumber());
  };

  const handleOnTypesChange = (newTypes: SkillTypes[]) => {
    dispatch(setEventsTypes(newTypes));
  };

  const handleOnSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEventsSearchText(e.target.value));
  };

  const handleOnEventClick = (event: EventShortResponse) => {
    if (isSingleSelect) {
      if (selectedEvents.findIndex((e) => e.id === event.id) > -1) {
        setSelectedEvents([]);
      } else {
        setSelectedEvents([event]);
      }

      return;
    }

    if (selectedEvents.findIndex((e) => e.id === event.id) > -1) {
      setSelectedEvents((prev) => prev.filter((e) => e.id !== event.id));
    } else {
      setSelectedEvents((prev) => [...prev, event]);
    }
  };

  return (
    <Stack className="events-select" spacing="1.5rem">
      <Typography className="events-select-title">
        {title}{' '}
        {!isSingleSelect &&
          selectedEvents.length > 0 &&
          `(Selected: ${selectedEvents.length})`}
      </Typography>
      <Stack spacing="1rem" padding="0 2rem">
        <SearchField
          placeholder={PLACEHOLDERS.SEARCH_EVENTS}
          value={eventsPagination.searchText}
          onChange={handleOnSearchTextChange}
          className="events-select-search"
          sx={{
            width: '100%',
          }}
        />
        <EventTypes
          values={eventsPagination.types}
          onChange={handleOnTypesChange}
        />
      </Stack>
      <Stack className="events-select-list-container">
        <InfiniteScrollList
          className="events-select-list"
          emptyMessage={NOT_FOUND_MESSAGES.EVENTS}
          fetchData={fetchData}
          isFetching={isFetching}
          currentCount={availableEvents.length}
          totalCount={totalAvailableEvents}
        >
          {availableEvents.map((event) => (
            <EventsSelectItem
              key={event.id}
              event={event}
              isSelected={
                selectedEvents.findIndex((e) => e.id === event.id) > -1
              }
              onClick={handleOnEventClick}
            />
          ))}
        </InfiniteScrollList>
        <Divider className="events-select-list-divider" />
      </Stack>
      <Stack spacing="1rem" className="events-select-footer">
        <Button
          variant="outlined"
          onClick={() => onSubmit([])}
          className="events-select-cancel-btn"
        >
          {BUTTON_LABELS.CANCEL}
        </Button>
        <LoadingButton
          disabled={selectedEvents.length === 0}
          variant="contained"
          onClick={() => onSubmit(selectedEvents)}
          loading={isSubmitting}
          startIcon={<AddCircleIcon />}
          loadingPosition="start"
          className="events-select-submit-btn"
        >
          {submitBtnText}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default EventsSelect;
