import './TrainingDetailsReviews.scss';
import { Stack } from '@mui/material';
import TrainingDetailsComment from '../TrainingDetailsComment/TrainingDetailsComment';

export type TrainingComment = {
  id: number;
  comment: string;
  user: string;
  rating: number;
  userPhoto: string;
};

type TrainingDetailsReviewsProps = {
  comments: TrainingComment[];
};

const TrainingDetailsReviews = ({ comments }: TrainingDetailsReviewsProps) => (
  <Stack component="ol" direction="column" gap="1rem">
    {comments.map((comment: TrainingComment) => (
      <TrainingDetailsComment key={comment.id} comment={comment} />
    ))}
  </Stack>
);

export default TrainingDetailsReviews;
