import { put, take, call } from 'redux-saga/effects';
import { ADD_EMPLOYEE_TO_MY_TEAM_REQUEST } from './types';
import {
  addEmployeeToMyTeamSuccess,
  addEmployeeToMyTeamError,
} from './actions';
import { STATUS_CODES } from '../../../../../cms/index';
import { AddEmployeeToMyTeamModel } from './AddEmployeeModal';

/** If detect type: ADD_EMPLOYEE_TO_MY_TEAM_REQUEST call addEmployeeToMyTeam() */
export default function* addEmployeeToMyTeamSaga(api: any): any {
  while (true) {
    const addEmployeeToMyTeamRequest = yield take(
      ADD_EMPLOYEE_TO_MY_TEAM_REQUEST,
    );
    if (addEmployeeToMyTeamRequest.payload) {
      const { model } = addEmployeeToMyTeamRequest.payload;
      yield call(addEmployeeToMyTeam, api, model);
    }
  }
}

/** Add employee to my team */
function* addEmployeeToMyTeam(api: any, model: AddEmployeeToMyTeamModel): any {
  let response;
  try {
    response = yield call(api.addEmployeeToMyTeam, model);

    if (response.status === STATUS_CODES.OK) {
      const isSuccessful = true;
      yield put(addEmployeeToMyTeamSuccess(isSuccessful));
    }
  } catch (error: any) {
    yield put(addEmployeeToMyTeamError(error));
  }
}
