import {
  CostReportForCompanyData,
  GetAllCostReportRequestModel,
} from './ReportsTab';
import {
  GET_ALL_COST_REPORTS_REQUEST,
  GET_ALL_COST_REPORTS_REQUEST_SUCCESS,
  GET_ALL_COST_REPORTS_REQUEST_ERROR,
} from './types';

/** Action - get all cost reports

* @param {GetAllCostReportRequestModel} getAllCostReportRequestModel - event applications request model
* @return {Object} Return object
*/
export function getAllCostReports(
  getAllCostReportRequestModel: GetAllCostReportRequestModel,
) {
  return {
    type: GET_ALL_COST_REPORTS_REQUEST,
    payload: {
      getAllCostReportRequestModel,
    },
  };
}

/** On successful get all cost reports
 * @param {string} isSuccessful - if get successful
 * @return {any} Return object
 */
export function getAllCostReportsSuccess(
  isSuccessful: boolean,
  costReportForCompanyData: CostReportForCompanyData[],
) {
  return {
    type: GET_ALL_COST_REPORTS_REQUEST_SUCCESS,
    payload: {
      isSuccessful,
      costReportForCompanyData,
    },
  };
}

/** Handle error
 * @param {unknown} errorMessage - Error message
 */
export function getAllCostReportsError(errorMessage: unknown) {
  return {
    type: GET_ALL_COST_REPORTS_REQUEST_ERROR,
    payload: errorMessage,
  };
}
