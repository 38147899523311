import { useRef } from 'react';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Badge,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import './AppNavbarNotifications.scss';
import { DRAWER_TYPES } from '../../../cms';
import { useAppDispatch, useAppSelector } from '../../../rtk/hooks';
import { useGetNotificationsQuery } from '../../../rtk/api/notificationApi';
import Notification from '../Notification/Notification';
import { closeDrawer, toggleDrawer } from '../../../rtk/features/drawerSlice';
import { NotificationType } from '../../../cms/types';

const menuProps = {
  elevation: 0,
  sx: {
    width: 500,
    maxWidth: '100%',
    maxHeight: '60%',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  },
};

const AppNavbarNotifications = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isScreenLG = useMediaQuery(theme.breakpoints.up('lg'));
  const { openedDrawer } = useAppSelector((state) => state.drawer);
  const { data: notifications } = useGetNotificationsQuery();
  const anchorElement = useRef(null);
  const open = openedDrawer === DRAWER_TYPES.NOTIFICATIONS;

  const handleToggleMenu = () => {
    dispatch(toggleDrawer(DRAWER_TYPES.NOTIFICATIONS));
  };

  const handleCloseMenu = () => {
    dispatch(closeDrawer());
  };

  return (
    <Box className="navbar-notifications">
      <Tooltip title="Open Notifications">
        <IconButton
          ref={anchorElement}
          className="navbar-notifications-btn"
          onClick={handleToggleMenu}
          aria-controls={open ? 'navbar-notifications-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Badge
            badgeContent={notifications?.length}
            color="primary"
            overlap="circular"
          >
            <NotificationsIcon sx={{ fontSize: 30, color: 'white' }} />
          </Badge>
        </IconButton>
      </Tooltip>
      {isScreenLG && (
        <Menu
          id="navbar-notifications-menu"
          open={open}
          anchorEl={anchorElement?.current}
          className="navbar-notifications-menu"
          onClose={handleCloseMenu}
          MenuListProps={{ disablePadding: true }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          slotProps={{
            paper: menuProps,
          }}
        >
          <MenuItem divider className="navbar-notifications-title">
            Notifications
          </MenuItem>
          {notifications?.map((n: NotificationType, index: number) => (
            <MenuItem
              disableRipple
              className="navbar-notifications-item"
              key={n.rowKey}
              divider={notifications.length !== index + 1}
            >
              <Notification notification={n} />
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  );
};

export default AppNavbarNotifications;
