import { SCREEN_SIZES } from '../../../cms';

import { addSmallStyle } from '../../../cms/Utils';
import useMediaQuery from '../../../common/hooks/useMediaQuery';
import { Pricing } from '../types';
import './PricingCard.scss';

type PricingCardProps = {
  item: Pricing;
  onClick: (pricing: Pricing) => void;
};

const PricingCard = ({ item, onClick }: PricingCardProps) => {
  const { title, price, pricePerUser, plan, buttonText } = item;

  const isScreenSmall = useMediaQuery(SCREEN_SIZES.SMALL);

  return (
    <div className={`pricing-card${addSmallStyle(isScreenSmall)}`}>
      <h3 className="pricing-card-title">{title}</h3>
      <div className="pricing-card-body">
        {price == null ? (
          <p className="pricing-card-personalized-offer">Get Personal Offer</p>
        ) : (
          <>
            <p className="pricing-card-price-container">
              <span className="pricing-card-price">{price}€</span>
              <span className="pricing-card-plan"> / {plan}</span>
            </p>
            <p className="pricing-card-plan">
              ({pricePerUser?.toFixed(2)}€ per user)
            </p>
          </>
        )}
      </div>
      <button
        type="button"
        className="pricing-card-request-button"
        onClick={() => onClick(item)}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default PricingCard;
