import { put, take, call } from 'redux-saga/effects';
import { DEACTIVATE_EMPLOYEE_REQUEST } from './types';
import {
  deactivateEmployeesError,
  deactivateEmployeesSuccess,
} from './actions';
import { STATUS_CODES } from '../../../../../../cms/index';

/** If detect type:  DEACTIVATE_EMPLOYEE_REQUEST call deactivateEmployees() */
export default function* deactivateEmployeesSaga(api: any): any {
  while (true) {
    const deactivateEmployeesRequest = yield take(DEACTIVATE_EMPLOYEE_REQUEST);
    if (deactivateEmployeesRequest.payload) {
      yield call(
        deactivateEmployees,
        api,
        deactivateEmployeesRequest.payload.ids,
      );
    }
  }
}
/** Deactivate employee */
function* deactivateEmployees(api: any, employeeIds: number[]): any {
  let response;
  try {
    response = yield call(api.deactivateEmployees, employeeIds);
    if (response.status === STATUS_CODES.OK) {
      const isSuccessful: boolean = true;
      yield put(deactivateEmployeesSuccess(isSuccessful, employeeIds));
    }
  } catch (error) {
    yield put(deactivateEmployeesError(error));
  }
}
