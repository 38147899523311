import { put, call, take } from 'redux-saga/effects';
import { archiveEmployeesError, archiveEmployeesSuccess } from './actions';
import { STATUS_CODES } from '../../../../../../cms/index';
import { ARCHIVE_EMPLOYEE_REQUEST } from './types';

/** If detect type:  ARCHIVE_EMPLOYEE_REQUEST call archieveEmployee() */
export default function* archiveEmployeesSaga(api: any): any {
  while (true) {
    yield take(ARCHIVE_EMPLOYEE_REQUEST);
    yield call(archiveEmployees, api);
  }
}

/** Archieve employee */
function* archiveEmployees(api: any): any {
  let response;
  try {
    response = yield call(api.archiveEmployees);
    if (response.status === STATUS_CODES.OK) {
      const isSuccessful: boolean = true;
      yield put(archiveEmployeesSuccess(isSuccessful));
    }
  } catch (error) {
    yield put(archiveEmployeesError(error));
  }
}
