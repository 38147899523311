import { FormLabel, Stack } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { COLORS, EVENT_TYPES } from '../../../../cms';
import { EventSchemaType } from '../../../../cms/validation/eventValidation';
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
  SkillTypeIcon,
} from '../../../../common/components';

const FormEventTypes = () => {
  const { control, watch } = useFormContext<EventSchemaType>();

  return (
    <Stack>
      <FormLabel required sx={{ fontSize: 12 }}>
        Event Type
      </FormLabel>
      <Controller
        name="type"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <StyledToggleButtonGroup
              value={value}
              onChange={onChange}
              exclusive
              aria-label="event type"
            >
              {EVENT_TYPES.map(({ id, name }) => (
                <StyledToggleButton
                  key={id}
                  value={id}
                  className={`skill-type ${error ? 'toggle-btn-error' : ''}`}
                >
                  <SkillTypeIcon
                    skillType={id}
                    size={16}
                    color={
                      error
                        ? COLORS.ERROR
                        : watch('type') === id
                        ? COLORS.WHITE
                        : COLORS.SECONDARY
                    }
                  />
                  {name}
                </StyledToggleButton>
              ))}
            </StyledToggleButtonGroup>
          );
        }}
      />
    </Stack>
  );
};

export default FormEventTypes;
