import {
  GET_ALL_EMPLOYEES_REQUEST,
  GET_ALL_EMPLOYEES_REQUEST_ERROR,
  GET_ALL_EMPLOYEES_REQUEST_SUCCESS,
  GET_SINGLE_EMPLOYEE_REQUEST,
  GET_SINGLE_EMPLOYEE_REQUEST_SUCCESS,
  GET_SINGLE_EMPLOYEE_REQUEST_ERROR,
} from './types';

/** Action - get all employees in the system.
 * @return {Object} Return object
 */
export function getAllEmployees(model = {}) {
  return {
    type: GET_ALL_EMPLOYEES_REQUEST,
    payload: {
      model,
    },
  };
}

export function getSingleEmployee(employeeId) {
  return {
    type: GET_SINGLE_EMPLOYEE_REQUEST,
    payload: {
      employeeId,
    },
  };
}

/** On successful get of all employees
 * @param {Array} allEemployees - if assignment went alright assign all applications
 * @return {any} Return object
 */
export function getAllEmployeesSuccess(allEmployees) {
  return {
    type: GET_ALL_EMPLOYEES_REQUEST_SUCCESS,
    payload: {
      allEmployees,
    },
  };
}

export function getSingleEmployeeSuccess(employeeData) {
  return {
    type: GET_SINGLE_EMPLOYEE_REQUEST_SUCCESS,
    payload: {
      employeeData,
    },
  };
}

/** Handle error
 * @param {string} errorMessage - Error message
 */
export function getAllEmployeesError(errorMessage) {
  return {
    type: GET_ALL_EMPLOYEES_REQUEST_ERROR,
    payload: errorMessage,
  };
}

export function getSingleEmployeeError(errorMessage) {
  return {
    type: GET_SINGLE_EMPLOYEE_REQUEST_ERROR,
    payload: errorMessage,
  };
}
