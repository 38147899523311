import { put, take, call } from 'redux-saga/effects';
import { GET_MY_TEAM_INFO_REQUEST } from './types.js';
import { getMyTeamInfoSuccess, getMyTeamInfoError } from './actions.js';

/** If detect type: GET_MY_TEAM_INFO_REQUEST call getMyTeam() */
export default function* getMyTeamInfoSaga(api) {
  while (true) {
    const getMyTeamInfoRequest = yield take(GET_MY_TEAM_INFO_REQUEST);
    if (getMyTeamInfoRequest.payload) {
      const { isInitialLoad } = getMyTeamInfoRequest.payload;
      yield call(getMyTeam, api, isInitialLoad);
    }
  }
}

/** Get my team info */
function* getMyTeam(api, isInitialLoad) {
  let response;
  try {
    response = yield call(api.getMyTeamInfo);
    const { employees, professions, applications } = response.data;
    yield put(
      getMyTeamInfoSuccess(employees, professions, applications, isInitialLoad),
    );
  } catch (error) {
    yield put(getMyTeamInfoError(error));
  }
}
