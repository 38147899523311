import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { pathApi } from '../api/pathApi';
import { SkillTypes } from '../../cms/enums';

export type PathsPaginationState = {
  pageNumber: number;
  pageSize: number;
  types: SkillTypes[];
  searchText: string;
  refetch: number;
};

const initialState: PathsPaginationState = {
  pageNumber: 1,
  pageSize: 10,
  searchText: '',
  types: [],
  refetch: 0,
};

const pathsPaginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    incrementPathsPageNumber: (state: PathsPaginationState) => {
      state.pageNumber++;
    },
    setPathsTypes: (
      state: PathsPaginationState,
      { payload }: PayloadAction<SkillTypes[]>,
    ) => {
      return {
        ...state,
        pageNumber: 1,
        types: payload,
      };
    },
    setPathsSearchText: (
      state: PathsPaginationState,
      { payload }: PayloadAction<string>,
    ) => {
      return {
        ...state,
        pageNumber: 1,
        searchText: payload,
      };
    },
    resetPathsPagination: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        pathApi.endpoints.createPath.matchFulfilled,
        pathApi.endpoints.deletePath.matchFulfilled,
      ),
      (state: PathsPaginationState) => {
        return {
          ...state,
          pageNumber: 1,
          refetch: state.refetch + 1,
        };
      },
    );
  },
});

export const {
  setPathsTypes,
  setPathsSearchText,
  incrementPathsPageNumber,
  resetPathsPagination,
} = pathsPaginationSlice.actions;
export default pathsPaginationSlice.reducer;
