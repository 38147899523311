import { FC } from 'react';
import { Divider } from '@mui/material';
import { USERS_IMPORT, USERS_IMPORT_MODAL } from '../../../../../../../../cms';
import './UserImportSteps.scss';

interface UserImportStepsProps {
  setShowUserImportCsv: Function;
}

const UserImportSteps: FC<UserImportStepsProps> = (props) => {
  const handleModalContent = () => {
    props.setShowUserImportCsv(true);
  };

  return (
    <div className="user-import-steps">
      <h6 className="user-import-steps-header">
        {USERS_IMPORT_MODAL.IMPORT_CSV_MODAL_HEADLINE}
      </h6>
      <Divider flexItem />
      <div className="user-import-steps-content">
        <p className="user-import-steps-content-title">
          {USERS_IMPORT.USERS_IMPORT_HEADLINE}
        </p>
        <div>
          <p className="user-import-steps-content-step">
            <span className="user-import-step">{USERS_IMPORT.STEP_1}</span>
            &nbsp;{USERS_IMPORT.STEP_1_CONTENT}&nbsp;
            <span className="download-template-link">
              <a
                href={require('../../../../../../../../resources/Employees Template.csv')}
                download="Employees Template"
              >
                Employees Template.CSV
              </a>
            </span>
          </p>
          <p className="user-import-steps-content-step">
            <span className="user-import-step">{USERS_IMPORT.STEP_2}</span>
            &nbsp;{USERS_IMPORT.STEP_2_CONTENT}&nbsp;
          </p>
          <p className="user-import-steps-content-step">
            <span className="user-import-step">{USERS_IMPORT.STEP_3}</span>
            &nbsp;{USERS_IMPORT.STEP_3_CONTENT}&nbsp;
            <button
              type="button"
              className="user-import-csv-btn inline-block"
              onClick={handleModalContent}
            >
              {USERS_IMPORT.IMPORT_FILE_BUTTON}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserImportSteps;
