import { SCREEN_SIZES } from '../../../cms';
import { addSmallStyle } from '../../../cms/Utils';
import useMediaQuery from '../../../common/hooks/useMediaQuery';
import './FeatureCard.scss';

type Feature = {
  title: string;
  description: string;
  image: any;
};

type FeatureCardProps = {
  item: Feature;
};

const FeatureCard = ({ item }: FeatureCardProps) => {
  const { title, description, image } = item;
  const isScreenSmall = useMediaQuery(SCREEN_SIZES.SMALL);

  return (
    <div className={`feature-card${addSmallStyle(isScreenSmall)}`}>
      <img src={image} alt="Feature Card" className="feature-card-image" />
      <h3 className="feature-card-title">{title}</h3>
      <p className="feature-card-description">{description}</p>
    </div>
  );
};

export default FeatureCard;
