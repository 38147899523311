import { useState } from 'react';
import './HomeSection.scss';
import { Stack, Typography } from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import homeSectionImage from '../../common/images/user-guide-image.png';
import { LandingPageSections, SCREEN_SIZES } from '../../cms/index';
import useMediaQuery from '../../common/hooks/useMediaQuery';

type HomeSectionProps = {
  openRequestDemoModal: () => void;
};

const HomeSection = ({ openRequestDemoModal }: HomeSectionProps) => {
  const isScreenExtraSmall = useMediaQuery(SCREEN_SIZES.EXTRA_SMALL);
  const isScreenSmall = useMediaQuery(SCREEN_SIZES.SMALL);
  const isScreenMedium = useMediaQuery(SCREEN_SIZES.MEDIUM);
  const [showVideo, setShowVideo] = useState(false);

  const subText = (
    <div className="home-section-sub-content">
      <p>
        One space for your workforce to upskill and track their own career path
        goals.
      </p>
      <button
        className="landing-page-home-button-request"
        onClick={openRequestDemoModal}
      >
        REQUEST FREE TRIAL ENVIRONMENT
      </button>
    </div>
  );

  return (
    <section id={LandingPageSections.HOME} className="home-section-container">
      <div className="home-section-inner-container">
        <div className="home-section-content">
          <h1>
            Training and
            <br />
            Development
            <br />
            Platform
          </h1>

          {!isScreenSmall && !isScreenMedium && subText}
        </div>

        {showVideo && (
          <div className="home-section-video">
            <div className="iframe-container">
              <iframe
                allow="autoplay"
                title="Upskill Portal video"
                src="https://www.youtube.com/embed/u3dJ9ghbJm4?autoplay=1"
              />
            </div>
          </div>
        )}

        {!showVideo && (
          <div className="home-section-image">
            <img src={homeSectionImage} alt="Upskill Portal Video Thumbnail" />
            <Stack className="play-video">
              <PlayCircleFilledIcon
                className="play-video-icon"
                onClick={() => setShowVideo(true)}
                sx={{
                  fontSize: isScreenExtraSmall ? '2.5rem' : '4rem',
                }}
              />
              <Typography
                className="play-video-text"
                component="span"
                sx={{
                  fontSize: isScreenExtraSmall ? '0.8rem' : '1.2rem',
                }}
              >
                PLAY VIDEO
              </Typography>
            </Stack>
          </div>
        )}
      </div>
      {isScreenMedium && subText}
    </section>
  );
};

export default HomeSection;
