/* eslint-disable */
import axios, { AxiosRequestConfig, isAxiosError } from 'axios';
import config from '../../config';
import { sanitizeData } from '../../cms/Utils';
import { store } from '../../rtk/store';
import { REFRESH_TOKEN_URL } from './config';

const axiosInstance = axios.create({
  baseURL: config.API_PREFIX,
});

let refreshPromise: Promise<any> | null = null;
const clearRefreshPromise = () => (refreshPromise = null);

async function refreshToken() {
  try {
    const { accessToken, refreshToken } = store.getState().userSession;

    const response = await axiosInstance(REFRESH_TOKEN_URL, {
      method: 'POST',
      data: { accessToken, refreshToken },
    });

    return {
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
    };
  } catch (err) {
    return null;
  }
}

axiosInstance.interceptors.request.use((request) => {
  const { accessToken } = store.getState().userSession;

  if (accessToken)
    request.headers.set('Authorization', `Bearer ${accessToken}`);

  return request;
});

axiosInstance.interceptors.response.use(
  async (response) => {
    response.data = sanitizeData(response.data);
    return response;
  },
  async (error) => {
    if (error.response?.status === 401 && isAxiosError(error)) {
      if (!refreshPromise) {
        refreshPromise = refreshToken().finally(clearRefreshPromise);
      }

      // All requests await the same refresh promise
      const tokens = await refreshPromise;

      if (tokens) {
        store.dispatch({
          type: 'userSession/refreshUserSession',
          payload: tokens,
        });

        // Re-try request
        return axiosInstance(error.config as AxiosRequestConfig);
      }

      // Sign out
      store.dispatch({ type: 'store/reset' });
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
