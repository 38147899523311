import {
  StyledToggleButtonGroup,
  StyledToggleButton,
  SkillTypeIcon,
} from '../index';
import { COLORS, TRAINING_TYPES } from '../../../cms';
import { SkillTypes } from '../../../cms/enums';

type TrainingTypesProps = {
  values: SkillTypes[];
  onChange: (newValues: SkillTypes[]) => void;
};

const TrainingTypes = ({ values, onChange }: TrainingTypesProps) => {
  return (
    <StyledToggleButtonGroup
      value={values}
      onChange={(_, newValues: SkillTypes[]) => onChange(newValues)}
      aria-label="training types"
    >
      {TRAINING_TYPES.map(({ type, name }) => (
        <StyledToggleButton key={type} value={type} className="skill-type">
          <SkillTypeIcon
            skillType={type}
            size={16}
            color={
              values.find((i) => i === type) ? COLORS.WHITE : COLORS.SECONDARY
            }
          />
          {name}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

export default TrainingTypes;
