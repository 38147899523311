import { put, take, call } from 'redux-saga/effects';
import { SEND_NOTIFICATIONS_FOR_ASSIGNED_TRAININGS_REQUEST } from './types';
import { sendNotificationsForAssignedTrainingsSuccess } from './actions';
import { handleValidationErrors } from '../../../../../../common/validation/actions';

/** If detect type: SEND_NOTIFICATIONS_FOR_ASSIGNED_TRAININGS_REQUEST call sendNotificationsForAssignedTrainings() */
export default function* sendNotificationsForAssignedTrainingsSaga(api) {
  while (true) {
    const sendNotificationsForAssignedTrainingsRequest = yield take(
      SEND_NOTIFICATIONS_FOR_ASSIGNED_TRAININGS_REQUEST,
    );
    if (sendNotificationsForAssignedTrainingsRequest.payload) {
      const sendNotificationsForAssignedTrainingsModel =
        sendNotificationsForAssignedTrainingsRequest.payload
          .sendNotificationsModel;
      yield call(
        sendNotificationsForAssignedTrainings,
        api,
        sendNotificationsForAssignedTrainingsModel,
      );
    }
  }
}

/** Send notifications for assigned trainings */
function* sendNotificationsForAssignedTrainings(
  api,
  sendNotificationsForAssignedTrainingsModel,
) {
  try {
    yield call(
      api.sendNotificationsForAssignedTrainings,
      sendNotificationsForAssignedTrainingsModel,
    );

    const isSuccessful = true;
    yield put(sendNotificationsForAssignedTrainingsSuccess(isSuccessful));
  } catch (error) {
    yield put(handleValidationErrors(error.response.data));
  }
}
