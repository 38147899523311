import { FC } from 'react';
import { Stack } from '@mui/material';
import {
  ARCHIVE_EMPLOYEES_MODAL_LABELS,
  BUTTON_LABELS,
  EMPLOYEES_MANAGEMENT_LABELS,
} from '../../../../../../cms';
import ArchiveEmployeesButton from './ArchiveEmployeesButton/ArchiveEmployeesButton';
import './EmployeesTableHeader.scss';
import ImportUsersButton from './ImportUsersButton/ImportUsersButton';
import { BaseModal, ConfirmAction } from '../../../../../../common/components';

interface EmployeesTableHeaderProps {
  archiveEmployeesHandler: Function;
  setShowArchiveEmployeesConfirmation: Function;
  showArchiveEmployeesConfirmation: boolean;
  setArchiveEmployeesActive: Function;
  archiveEmployeesActive: boolean;
}

const EmployeesTableHeader: FC<EmployeesTableHeaderProps> = (props) => {
  const closeArchiveConfirmationModal = () => {
    props.setShowArchiveEmployeesConfirmation(false);
    props.setArchiveEmployeesActive(false);
  };
  const onClickHandler = () => {
    props.archiveEmployeesHandler();
    props.setArchiveEmployeesActive(true);
  };

  return (
    <>
      <Stack className="employee-table-header" spacing="2rem">
        <h4 className="employees-table-header-title">
          {EMPLOYEES_MANAGEMENT_LABELS.OVERVIEW_EMPLOYEES}
        </h4>
        <Stack className="employee-table-header-btns" spacing="1rem">
          <ArchiveEmployeesButton
            setShowArchiveEmployeesConfirmation={
              props.setShowArchiveEmployeesConfirmation
            }
          />
          <ImportUsersButton />
        </Stack>
      </Stack>
      <BaseModal
        open={props.showArchiveEmployeesConfirmation}
        onClose={closeArchiveConfirmationModal}
        showCloseBtnOutside
      >
        <ConfirmAction
          onClose={closeArchiveConfirmationModal}
          onConfirm={onClickHandler}
          text={
            ARCHIVE_EMPLOYEES_MODAL_LABELS.ARCHIVE_EMPLOYEE_CONFIRMATION_LABEL
          }
          btnText={BUTTON_LABELS.ARCHIVE_BUTTON}
        />
      </BaseModal>
    </>
  );
};

export default EmployeesTableHeader;
