import { Avatar, AvatarGroup, Stack } from '@mui/material';
import accountIcon from '../../common/images/account-circle.png';
import managementSkillsImage from '../../common/images/management-skill-logo.png';
import softSkillsImage from '../../common/images/soft-skill-logo.png';
import techSkillsImage from '../../common/images/technical-skill-logo.png';
import otherImage from '../../common/images/other-small-logo.png';
import { SkillTypes } from '../../cms/enums';
import './ItemsInGroupPreview.scss';

const ItemsInGroupPreview = ({ items, max }) => {
  const getImageSrc = (item) => {
    if (item.type) {
      switch (item.type) {
        case SkillTypes.MANAGEMENT_SKILLS:
          return managementSkillsImage;
        case SkillTypes.SOFT_SKILLS:
          return softSkillsImage;
        case SkillTypes.TECHNICAL_SKILLS:
          return techSkillsImage;
        default:
          return otherImage;
      }
    }

    return item.photoUrl ?? accountIcon;
  };

  return (
    <Stack direction="row">
      <AvatarGroup max={max} className="items-preview">
        {items.map((item, index) => (
          <Avatar key={index} src={getImageSrc(item)}>
            ??
          </Avatar>
        ))}
      </AvatarGroup>
    </Stack>
  );
};

export default ItemsInGroupPreview;
