import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { Calendar } from '../../../common/components/Calendar/Calendar';
import { addSmallStyle } from '../../../cms/Utils';
import useMediaQuery from '../../../common/hooks/useMediaQuery';
import { ROUTE_LINKS, SCREEN_SIZES } from '../../../cms';
import { useGetMyEventsQuery } from '../../../rtk/api/eventApi';
import { Loader } from '../../../common/components';
import UpcomingEventItem from './UpcomingEventItem/UpcomingEventItem';
import { UpcomingEvent } from '../../../cms/types';
import { ApplicationStatuses } from '../../../cms/enums';
import './EventsSection.scss';

const allowedLatestStatuses = [
  ApplicationStatuses.PENDING,
  ApplicationStatuses.APPROVED,
  ApplicationStatuses.IN_PROGRESS,
];

const allowedHighlightedStatuses = [
  ApplicationStatuses.PENDING,
  ApplicationStatuses.APPROVED,
  ApplicationStatuses.IN_PROGRESS,
  ApplicationStatuses.COMPLETED,
];

const EventsSection = () => {
  const { data, isLoading } = useGetMyEventsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const myEventApplications = data?.applications;
  const isScreenSmall = useMediaQuery(SCREEN_SIZES.SMALL);
  const [latestEvents, setLatestEvents] = useState<UpcomingEvent[]>([]);
  const [highlightedDates, setHighligthedDates] = useState<Date[]>([]);

  useEffect(() => {
    if (!myEventApplications) return;

    // Update highlighted events
    setHighligthedDates(
      myEventApplications
        .filter((a) => allowedHighlightedStatuses.includes(a.status))
        .map((a) => a.startDate),
    );

    // Update latest events
    setLatestEvents(
      myEventApplications
        .filter(
          (a) =>
            !dayjs().isAfter(a.startDate, 'date') &&
            allowedLatestStatuses.includes(a.status),
        )
        .sort((a, b) =>
          dayjs(a.startDate).isAfter(dayjs(b.startDate)) ? 1 : -1,
        )
        .slice(0, 4)
        .map((a) => ({
          id: a.eventId,
          startDate: a.startDate,
          title: a.title,
        })),
    );
  }, [myEventApplications]);

  if (isLoading) return <Loader />;

  return (
    <div className="events-section">
      {latestEvents.length === 0 ? (
        <div className="events-section-no-data">
          <p className="events-section-no-data-text">
            No upcoming events to show.
            <br />
            Go to the{' '}
            <Link
              to={ROUTE_LINKS.EVENTS}
              className="events-section-no-data-link"
            >
              Events
            </Link>{' '}
            page and apply for the event you are interested in.
          </p>
        </div>
      ) : (
        <div className="events-section-data">
          <Grid container spacing="2rem">
            {latestEvents.map((event) => (
              <Grid item xs={12} sm={6} key={event.id}>
                <UpcomingEventItem event={event} />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      <Calendar
        highlightedDates={highlightedDates}
        classNames={`events-section-calendar${addSmallStyle(isScreenSmall)}`}
      />
    </div>
  );
};

export default EventsSection;
