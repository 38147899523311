import './CookieTable.scss';

const CookieTable = () => {
  return (
    <div className="table-wrapper">
      <table className="cookie-table">
        <tbody>
          <tr>
            <td>Cookie</td>
            <td>Duration</td>
            <td>Description</td>
          </tr>
          <tr>
            <td>ARRAffinity/ARRAffinitySameSite</td>
            <td>When your session ends</td>
            <td>
              This cookie is set by websites run on the Windows Azure cloud
              platform. It is used for load balancing to make sure the visitor
              page requests are routed to the same server in any browsing
              session
            </td>
          </tr>
          <tr>
            <td>g_state</td>
            <td>180 days</td>
            <td>
              Stores the timestamp for the purpose of honoring the cool off
              period and dismiss count for Google's one tap prompt feature.
            </td>
          </tr>
          <tr>
            <td>_ga</td>
            <td>2 years</td>
            <td>
              This cookie is a Google Analytics persistent cookie which is used
              to distinguish unique users.{' '}
            </td>
          </tr>
          <tr>
            <td>_gat</td>
            <td>1 minute</td>
            <td>
              This cookie is used to throttle request rate. These are third
              party cookies that are placed on your device to allow us to use
              the Google Analytics service. These cookies are used to collect
              information about how visitors use our website. We use this
              information to compile reports and to help us improve the website.
            </td>
          </tr>
          <tr>
            <td>_gid</td>
            <td>1 day</td>
            <td>
              This cookie is installed by Google Analytics. The cookie is used
              to store information of how visitors use a website and helps in
              creating an analytics report of how the website is doing. The data
              collected including the number visitors, the source where they
              have come from, and the pages visited in an anonymous form.
            </td>
          </tr>
          <tr>
            <td>CONSENT</td>
            <td>17 years</td>
            <td>Google cookie consent tracker </td>
          </tr>
          <tr>
            <td>__Host-GAPS</td>
            <td>2 Years</td>
            <td>
              This cookie name is associated with Google. It is set by Google to
              identify the user and is used in support of the Google Identity
              application
            </td>
          </tr>
          <tr>
            <td>__Host-3PLSID</td>
            <td>2 Years</td>
            <td>
              These cookies are used to collect information about how visitors
              use our site. We use the information to compile reports and to
              help us improve the site. The cookies collect information in an
              anonymous form, including the number of visitors to the site,
              where visitors have come to the site from and the pages they
              visited.
            </td>
          </tr>
          <tr>
            <td>__Host-1PLSID</td>
            <td>2 years</td>
            <td>
              Google Accounts cookie used for logging in to our website with
              your Google account.
            </td>
          </tr>
          <tr>
            <td>LSID</td>
            <td>2 Years</td>
            <td>
              Google Accounts cookie used for logging in to our website with
              your Google account.
            </td>
          </tr>
          <tr>
            <td>SMSV</td>
            <td>10 Years</td>
            <td>
              Stores user preferences and information each time they visit web
              pages containing Google services
            </td>
          </tr>
          <tr>
            <td>ACCOUNT_CHOOSER</td>
            <td>2 Years</td>
            <td>
              Google Accounts cookie used for logging in to our website with
              your Google account.
            </td>
          </tr>
          <tr>
            <td>OTZ</td>
            <td>2 years</td>
            <td>
              Stores user preferences and information each time they visit web
              pages containing Google services
            </td>
          </tr>
          <tr>
            <td>_ga_FR4ZD20FWJ</td>
            <td>1 year</td>
            <td>Used by Google Analytics to store and count pageviews.</td>
          </tr>
          <tr>
            <td>_gid</td>
            <td>1 day</td>
            <td>
              Google Analytics unique user identifier to provide insights on our
              website.
            </td>
          </tr>
          <tr>
            <td>__Secure-3PSIDCC</td>
            <td>2 Year</td>
            <td>
              Used by for targeting purposes to build a profile of the website
              visitor’s interests in order to show relevant & personalised
              Google advertising
            </td>
          </tr>
          <tr>
            <td>__Secure-1PSIDCC</td>
            <td>1 Year</td>
            <td>Cookie required to use website options and services </td>
          </tr>
          <tr>
            <td>SIDCC</td>
            <td>1 Year</td>
            <td>Google ads targeting and optimization.</td>
          </tr>
          <tr>
            <td>__Secure-1PSID</td>
            <td>2 years</td>
            <td>
              Google Accounts cookie used for logging in to our website with
              your Google account.
            </td>
          </tr>
          <tr>
            <td>__Secure-3PAPISID</td>
            <td>2 Years</td>
            <td>
              Used by for targeting purposes to build a profile of the website
              visitor’s interests in order to show relevant & personalised
              Google advertising
            </td>
          </tr>
          <tr>
            <td>SAPISID</td>
            <td>2 years</td>
            <td>
              Download certain Google Tools and save certain preferences, for
              example the number of search results per page or activation of the
              SafeSearch Filter. Adjusts the ads that appear in Google Search.
            </td>
          </tr>
          <tr>
            <td>APISID</td>
            <td>2 Years</td>
            <td>
              Google Accounts cookie used for logging in to our website with
              your Google account.
            </td>
          </tr>
          <tr>
            <td>SSID</td>
            <td>2 Years</td>
            <td>Google ads targeting and optimization.</td>
          </tr>
          <tr>
            <td>HSID</td>
            <td>2 Years</td>
            <td>
              Google Accounts cookie used for logging in to our website with
              your Google account.
            </td>
          </tr>
          <tr>
            <td>__Secure-3PSID</td>
            <td>2 Years</td>
            <td>
              Used by for targeting purposes to build a profile of the website
              visitor’s interests in order to show relevant & personalised
              Google advertising
            </td>
          </tr>
          <tr>
            <td>SID</td>
            <td>2 Years</td>
            <td>Google ads targeting and optimization.</td>
          </tr>
          <tr>
            <td>__Secure-1PAPISID</td>
            <td>2 years</td>
            <td>
              Google Accounts cookie used for logging in to our website with
              your Google account.
            </td>
          </tr>
          <tr>
            <td>NID</td>
            <td>183 Days</td>
            <td>Google ads targeting and optimization.</td>
          </tr>
          <tr>
            <td>__Secure-ENID</td>
            <td>13 months</td>
            <td>
              Used by Google to prevent fraudulent login attempts. This also
              contains a Google user ID which can be used for statistics and
              marketing purposes following a successful login.
            </td>
          </tr>
          <tr>
            <td>1P_JAR</td>
            <td>30 Days</td>
            <td>Google ads targeting and optimization.</td>
          </tr>
          <tr>
            <td>AEC</td>
            <td>6 months</td>
            <td>
              Google Accounts cookie used for logging in to our website with
              your Google account.
            </td>
          </tr>
          <tr>
            <td>__Secure-1PSIDTS</td>
            <td>1 year</td>
            <td>
              Google cookie that collects information about your interactions
              with Google services and ads. It contains a unique identifier.
            </td>
          </tr>
          <tr>
            <td>__Secure-3PSIDTS</td>
            <td>2 years</td>
            <td>
              Google cookie that collects information about your interactions
              with Google services and ads. It is used to measure advertising
              effectiveness and deliver personalized content based on your
              interests. The cookie contains a unique identifier
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CookieTable;
