import { useEffect, useState } from 'react';
import { MenuItem, Stack } from '@mui/material';
import { useDebounce } from '../../../../../common/hooks/useDebounce';
import { FilterSelect, SearchField } from '../../../../../common/components';
import { DEMO_REQUEST_STATUSES, SUBMISSIONS_LABELS } from '../../../../../cms';
import DeleteMultipleDemoRequestsButton from './DeleteMultipleDemoRequestsButton/DeleteMultipleDemoRequestsButton';
import DemoRequestsDateRange from './DemoRequestsDateRange/DemoRequestsDateRange';

interface Props {
  onSearch: Function;
  selected: string[];
  deleteMultipleDemoRequests: Function;
  onFromDateChange: Function;
  onToDateChange: Function;
  onStatusFilterChange: Function;
}

export default function DemoRequestsTableHeader({
  onSearch,
  selected,
  deleteMultipleDemoRequests,
  onFromDateChange,
  onToDateChange,
  onStatusFilterChange,
}: Props) {
  const [status, setStatus] = useState('All');
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText.trim().toLowerCase());

  useEffect(() => {
    if (status) onStatusFilterChange(status);
  }, [status]);

  useEffect(() => {
    if (debouncedSearchText) onSearch(debouncedSearchText);
  }, [debouncedSearchText]);

  return (
    <Stack className="demo-requests-header" spacing="1rem">
      <DemoRequestsDateRange
        onFromDateChange={onFromDateChange}
        onToDateChange={onToDateChange}
      />
      <Stack
        direction="row"
        spacing="1rem"
        flexWrap="wrap"
        alignItems="flex-end"
      >
        <SearchField
          placeholder={SUBMISSIONS_LABELS.SUBMISSIONS_SEARCH_PLACEHOLDER}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          sx={{
            width: { xs: '100%', sm: 400 },
          }}
          className="submissions-search"
        />
        <FilterSelect
          label="Select Status"
          onChange={(e) => setStatus(e.target.value)}
          value={status}
          sx={{
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          {DEMO_REQUEST_STATUSES.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </FilterSelect>
        <DeleteMultipleDemoRequestsButton
          selectedDemoRequestsCount={selected.length}
          deleteMultipleDemoRequests={deleteMultipleDemoRequests}
        />
      </Stack>
    </Stack>
  );
}
