import {
  UPDATE_EMPLOYEE_SEARCH_CRITERIA,
  UPDATE_EMPLOYEE_SEARCH_CRITERIA_SUCCESS,
} from './types';

/** Action to update the search criteria
 * @param {array} searchCriteria - the new values for the search criteria
 * @return {Object} Return object
 */
export function updateSearchCriteria(searchCriteria) {
  return {
    type: UPDATE_EMPLOYEE_SEARCH_CRITERIA,
    payload: {
      searchCriteria,
    },
  };
}

/** On successfully update search criteria .
 * @param {array} searchCriteria - the new values for the search criteria
 * @return {any}
 */
export function updateSearchCriteriaSuccess(searchCriteria) {
  return {
    type: UPDATE_EMPLOYEE_SEARCH_CRITERIA_SUCCESS,
    payload: {
      searchCriteria,
    },
  };
}
