import { put, take, call } from 'redux-saga/effects';
import { GET_ALL_GROUPS_REQUEST } from './types.js';
import { getAllGroupsSuccess, getAllGroupsError } from './actions.js';

/** If detect type: GET_ALL_GROUPS_REQUEST call getGroups() */
export default function* getAllGroupsSaga(api) {
  while (true) {
    const getAllGroupsRequest = yield take(GET_ALL_GROUPS_REQUEST);
    if (getAllGroupsRequest.payload) {
      const { requestModel } = getAllGroupsRequest.payload;
      yield call(getGroups, api, requestModel);
    }
  }
}

/** Get groups */
function* getGroups(api, requestModel) {
  let response;
  try {
    response = yield call(api.getAll, requestModel);

    const groups = response.data;
    yield put(getAllGroupsSuccess(groups));
  } catch (error) {
    yield put(getAllGroupsError(error));
  }
}
