import {
  GET_ALL_COST_REPORTS_REQUEST_ERROR,
  GET_ALL_COST_REPORTS_REQUEST_SUCCESS,
} from '../pages/AdministrationPage/tabs/ReportsTab/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  costReportForCompanyData: null,
  getReportForCompanyDataSuccess: false,
  getReportForCompanyDataErrorMessage: null,
};

/** Statistics reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function statisticsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_COST_REPORTS_REQUEST_SUCCESS:
      return {
        ...state,
        costReportForCompanyData: action.payload.costReportForCompanyData,
        getReportForCompanyDataSuccess: action.payload.isSuccessful,
      };
    case GET_ALL_COST_REPORTS_REQUEST_ERROR:
      return {
        ...state,
        getReportForCompanyDataErrorMessage: action.payload.errorMessage,
      };
    default:
      return {
        ...state,
        getReportForCompanyDataSuccess: false,
        getReportForCompanyDataErrorMessage: null,
      };
  }
}

export default statisticsReducer;
