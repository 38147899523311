import {
  GET_MY_TEAM_INFO_REQUEST,
  GET_MY_TEAM_INFO_REQUEST_SUCCESS,
  GET_MY_TEAM_INFO_REQUEST_ERROR,
} from './types';

/** Action for get my team info.
 * @param {boolean} isInitialLoad - Check if it is the initial load of the request
 * @return {Object} Return object
 */
export function getMyTeamInfo(isInitialLoad) {
  return {
    type: GET_MY_TEAM_INFO_REQUEST,
    payload: {
      isInitialLoad
    },
  };
}

/** On successfully get my team info set data in store .
 * @param {string} employees - list of employees of the current user (manager)
 * @param {string} professions - list of professions
 * @param {string} applications - applications of the employees of the current user (manager)
 * @param {boolean} isInitialLoad - Check if it is the initial load of the request
 */
export function getMyTeamInfoSuccess(
  employees,
  professions,
  applications,
  isInitialLoad,
) {
  return {
    type: GET_MY_TEAM_INFO_REQUEST_SUCCESS,
    payload: {
      employees,
      professions,
      applications,
      isInitialLoad,
    },
  };
}

/** Handle error
 * @param {string} errorMessage - Error message
 */
export function getMyTeamInfoError(errorMessage) {
  return {
    type: GET_MY_TEAM_INFO_REQUEST_ERROR,
    payload: errorMessage,
  };
}
