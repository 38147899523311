import SolutionCard from './SolutionCard/SolutionCard';
import productMeetingImage from '../../common/images/landing-page-product-meeting.png';
import SolutionRoleCard from './SolutionRoleCard/SolutionRoleCard';
import {
  LandingPageSections,
  SCREEN_SIZES,
  SOLUTION_SECTION_ITEMS,
  SOLUTION_SECTION_ROLES,
} from '../../cms/index';

import './SolutionSection.scss';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import { addSmallStyle } from '../../cms/Utils';

const SolutionSection = () => {
  const isScreenSmall = useMediaQuery(SCREEN_SIZES.SMALL);

  return (
    <section
      id={LandingPageSections.SOLUTION}
      className={`solution-section-container${addSmallStyle(isScreenSmall)}`}
    >
      <h2 className="solution-section-title">
        Build up your people’s skills and track their career paths in a single
        holistic environment
      </h2>
      <div
        className={`solution-section-items-container${addSmallStyle(
          isScreenSmall,
        )}`}
      >
        {SOLUTION_SECTION_ITEMS.map((item) => (
          <SolutionCard key={item.title} item={item} />
        ))}
      </div>

      <div
        className={`solution-section-roles-section${addSmallStyle(
          isScreenSmall,
        )}`}
      >
        <img
          src={productMeetingImage}
          alt="Product Meeting"
          className={`solution-section-roles-section-image${addSmallStyle(
            isScreenSmall,
          )}`}
        />
        <div className="solution-section-roles-container">
          <div className="solution-section-roles-container-title">
            <SolutionCard
              item={{
                title:
                  'Manage multiple roles to fit your organisation’s needs.',
              }}
            />
          </div>
          <div className="solution-section-roles-items-container">
            {SOLUTION_SECTION_ROLES.map((item) => (
              <SolutionRoleCard key={item.title} item={item} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SolutionSection;
