import { Link as RouterLink } from 'react-router-dom';
import { Stack, Avatar, Box, Typography, Link } from '@mui/material';
import { inheritFontStyle } from '../../../../cms/Utils';
import { ROUTE_LINKS } from '../../../../cms';

type EmployeeBioProps = {
  name: string;
  photoUrl: string;
  id?: number;
  learningHub?: boolean;
};

const EmpployeeBio = ({
  name,
  photoUrl,
  id,
  learningHub,
}: EmployeeBioProps) => {
  return (
    <Stack flexDirection="row" alignItems="center" spacing="1rem">
      <Avatar
        alt="Employee"
        className="employee-avatar"
        src={photoUrl ?? ''}
        variant="circular"
      />
      <Box>
        <Typography
          sx={{
            ...inheritFontStyle,
            fontWeight: 'bold',
          }}
          className="employee-name"
        >
          {name}
        </Typography>
        {learningHub && (
          <Link
            sx={{ ...inheritFontStyle, whiteSpace: 'nowrap' }}
            component={RouterLink}
            to={`${ROUTE_LINKS.MY_TRAININGS_AND_EVENTS}?employeeId=${id}&employeeName=${name}`}
            className="employee-learning-hub-btn"
          >
            Learning Hub
          </Link>
        )}
      </Box>
    </Stack>
  );
};

export default EmpployeeBio;
