export const GET_ALL_EMPLOYEES_REQUEST = 'GET_ALL_EMPLOYEES_REQUEST';
export const GET_SINGLE_EMPLOYEE_REQUEST = 'GET_SINGLE_EMPLOYEE_REQUEST';
export const GET_ALL_EMPLOYEES_REQUEST_ERROR =
  'GET_ALL_EMPLOYEES_REQUEST_ERROR';
export const GET_ALL_EMPLOYEES_REQUEST_SUCCESS =
  'GET_ALL_EMPLOYEES_REQUEST_SUCCESS';
export const GET_SINGLE_EMPLOYEE_REQUEST_ERROR =
  'GET_ALL_EMPLOYEES_REQUEST_ERROR';
export const GET_SINGLE_EMPLOYEE_REQUEST_SUCCESS =
  'GET_SINGLE_EMPLOYEE_REQUEST_SUCCESS';
