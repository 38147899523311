import { Stack, Box, useMediaQuery, useTheme } from '@mui/material';
import { Column } from '../ResponsiveTable';
import { COLORS } from '../../../../cms';

type TableDetailsProps<T> = {
  column: Column<T>;
  row: T;
};

const TableDetails = <T,>({ column, row }: TableDetailsProps<T>) => {
  const theme = useTheme();
  const isScreenMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      gap="1rem"
      alignItems="center"
      sx={{
        fontSize: '0.875rem',
      }}
    >
      <Box
        sx={{
          fontWeight: 'bold',
          color: !isScreenMd ? COLORS.SECONDARY : 'inherit',
        }}
      >
        {column.heading}:
      </Box>
      {column.render ? (
        <Box>{column.render(row)}</Box>
      ) : (
        <Box>{row[column.key as keyof typeof row] as string}</Box>
      )}
    </Stack>
  );
};

export default TableDetails;
