import {
  GET_ALL_GROUPS_REQUEST,
  GET_ALL_GROUPS_REQUEST_SUCCESS,
  GET_ALL_GROUPS_REQUEST_ERROR,
} from './types';

/** Action to get all groups
 * @return {Object} Return object
 */
export function getAllGroups(requestModel) {
  return {
    type: GET_ALL_GROUPS_REQUEST,
    payload: {
      requestModel,
    },
  };
}

/** On successfully get all groups.
 * @param {string} groups - all groups
 * @return {any} groups
 */
export function getAllGroupsSuccess(groups) {
  return {
    type: GET_ALL_GROUPS_REQUEST_SUCCESS,
    payload: {
      groups,
    },
  };
}

/** Handle error
 * @param {string} errorMessage - Error message
 */
export function getAllGroupsError(errorMessage) {
  return {
    type: GET_ALL_GROUPS_REQUEST_ERROR,
    payload: errorMessage,
  };
}
