import { useEffect, useRef, useState } from 'react';
import { Button, Divider, Stack, Typography } from '@mui/material';
import {
  BUTTON_LABELS,
  FILE_UPLOAD_DROP_ZONE,
  UPLOAD_PDF_MODAL,
} from '../../../../cms';
import DragAndDropFiles from '../../../../common/components/DragAndDropFiles/DragAndDropFiles';
import './UploadPdf.scss';

interface UploadPdfModalProps {
  handleClosePdfModal: () => void;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const UploadPdf = ({
  files,
  setFiles,
  handleClosePdfModal,
}: UploadPdfModalProps) => {
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [fileNameAlreadyExistsError, setFileNameAlreadyExistsError] =
    useState(false);
  const [maxFileNumberError, setMaxFileNumberError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [maxFileNumber] = useState(5);
  const [errorResponseMessage, setErrorResponseMessage] = useState<string>('');

  useEffect(() => {
    setLocalFiles(files);
  }, [files]);

  const clearFileSelection = () => setLocalFiles([]);

  const attachFiles = () => {
    setFiles(localFiles);
    handleClosePdfModal();
  };

  const handleChange = (file: any) => {
    setErrorResponseMessage('');
    const fileNames: string[] = [];

    localFiles.map((file: any) => fileNames.push(file.name));
    if (!fileNames.includes(file[0].name)) {
      if (localFiles.length >= maxFileNumber) {
        setMaxFileNumberError(true);
      } else {
        setMaxFileNumberError(false);
        setFileNameAlreadyExistsError(false);
        const joined = localFiles.concat(file[0]);
        setLocalFiles(joined);
      }
    } else {
      setFileNameAlreadyExistsError(true);
    }
  };

  const onFileChange = (data: any) => {
    const fileNames: string[] = [];
    localFiles.map((file: any) => fileNames.push(file.name));

    if (data.target.files[0].type !== 'application/pdf') setFileTypeError(true);
    else if (!fileNames.includes(data.target.files[0].name)) {
      if (localFiles.length >= maxFileNumber) {
        setMaxFileNumberError(true);
      } else {
        setMaxFileNumberError(false);
        setFileNameAlreadyExistsError(false);
        const joined = localFiles.concat([data.target.files[0]]);
        setLocalFiles(joined);
      }
    } else {
      setFileNameAlreadyExistsError(true);
    }
  };

  return (
    <Stack className="pdf-upload">
      <Stack>
        <Typography className="pdf-upload-title">
          {UPLOAD_PDF_MODAL.UPLOAD_PDF_HEADLINE}
        </Typography>
        <Divider />
      </Stack>
      <Stack gap="1.5rem" alignItems="center" padding="2rem">
        <DragAndDropFiles
          handleChange={handleChange}
          files={localFiles}
          clearFileSelection={clearFileSelection}
          fileTypeError={fileTypeError}
          fileSizeError={fileSizeError}
          fileNameAlreadyExistsError={fileNameAlreadyExistsError}
          maxFileNumberError={maxFileNumberError}
          setFileTypeError={setFileTypeError}
          setFileSizeError={setFileSizeError}
          errorMessage={errorResponseMessage}
          isImporting={false}
          checkForImporting={false}
          uploadFileCenterMessage={
            FILE_UPLOAD_DROP_ZONE.FILE_UPLOAD_CENTER_MESSAGE
          }
          supportedFileTypes={['PDF']}
          maxSize={3}
          allowMultiple
          disableClearFile
        />
        <Typography>OR</Typography>

        <Stack
          direction="row"
          gap="1rem"
          flexWrap="wrap"
          justifyContent="center"
        >
          <Button
            variant="contained"
            className="browse-file-btn"
            onClick={() => inputFile.current!.click()}
          >
            {BUTTON_LABELS.BROWSE}
          </Button>
          <input
            type="file"
            accept="application/pdf"
            defaultValue={[]}
            onChange={onFileChange}
            id="file"
            ref={inputFile}
            style={{ display: 'none' }}
          />
          {localFiles.length > 0 && (
            <Button
              variant="contained"
              className="browse-file-btn"
              onClick={attachFiles}
            >
              {BUTTON_LABELS.ATTACH}
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UploadPdf;
