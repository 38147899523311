import { useEffect, useState, useRef } from 'react';
import { uniqueId } from 'lodash';
import { getAllEmployees } from './actions';
import { PLACEHOLDERS, ROLES } from '../../cms';
import BaseGroupSearch from '../BaseGroupSearch/BaseGroupSearch';
import ItemsInGroupPreview from '../ItemsInGroupPreview/ItemsInGroupPreview';
import { getMyTeamInfo } from '../MyGroup/MyTeam/actions';
import { getUserRoles } from '../../common/userRoleRetriever';
import { useAppDispatch, useAppSelector } from '../../rtk/hooks';

function SearchEmployee({
  searchBoxInputId,
  focus,
  updateSelectedEmployeesHandler,
  formErrors,
  selectedEmployees,
  className = '',
}) {
  const dispatch = useAppDispatch();
  const { isUserLoggedIn } = useAppSelector((state) => state.userSession);
  const isAdmin = isUserLoggedIn && getUserRoles().includes(ROLES.ADMIN_ROLE);
  const isManager =
    isUserLoggedIn && getUserRoles().includes(ROLES.MANAGER_ROLE);
  const { allEmployees } = useAppSelector((state) => state.allEmployees);
  const { employees } = useAppSelector((state) => state.myTeam);
  const [searching, setSearching] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState(
    isManager && !isAdmin ? employees : allEmployees,
  );

  const typeaheadRef = useRef(null);

  useEffect(() => {
    if (isAdmin) {
      dispatch(getAllEmployees());
    } else if (isManager && !isAdmin) {
      const isInitialLoad = true;
      dispatch(getMyTeamInfo(isInitialLoad));
    }
  }, [isAdmin, isManager]);

  useEffect(() => {
    if (isAdmin) {
      setFilteredEmployees(allEmployees);
    }
  }, [allEmployees]);

  useEffect(() => {
    if (isManager && !isAdmin) {
      setFilteredEmployees(employees);
    }
  }, [employees]);

  const handleInputChange = (input, e) => {
    setSearching(true);
  };

  const onCloseClick = () => {
    typeaheadRef.current.clear();
    setSearching(false);
  };

  const multipleSelectionHandler = (selections) => {
    const newSelections = selections.map((selection) =>
      selection.id ? selection : { ...selection, id: uniqueId() },
    );
    updateSelectedEmployeesHandler(newSelections);
  };

  useEffect(() => {
    if (isAdmin) {
      if (selectedEmployees.length > 0)
        setFilteredEmployees(
          allEmployees.filter(
            (item) =>
              !selectedEmployees.some(
                (employee) => employee.externalId === item.externalId,
              ),
          ),
        );
      else setFilteredEmployees(allEmployees);
    }
  }, [selectedEmployees, allEmployees]);

  useEffect(() => {
    if (isManager && !isAdmin) {
      if (selectedEmployees.length > 0)
        setFilteredEmployees(
          employees.filter(
            (item) =>
              !selectedEmployees.some(
                (employee) => employee.externalId === item.externalId,
              ),
          ),
        );
      else setFilteredEmployees(employees);
    }
  }, [selectedEmployees, employees]);

  return (
    <div>
      <BaseGroupSearch
        searching={searching}
        filteredItems={filteredEmployees.sort((a, b) =>
          a.fullName.localeCompare(b.fullName),
        )}
        typeaheadRef={typeaheadRef}
        handleInputChange={handleInputChange}
        onCloseClick={onCloseClick}
        filterBy={['fullName', 'jobTitle', 'department']}
        focus={focus}
        formErrors={formErrors}
        searchBoxInputId={searchBoxInputId}
        placeholder={PLACEHOLDERS.EMPLOYEE_SEARCH}
        className={className}
        multiSelectionHandler={multipleSelectionHandler}
        multiSelection={selectedEmployees}
      />
      <div className="mt-2 items-preview">
        <ItemsInGroupPreview items={selectedEmployees} />
      </div>
    </div>
  );
}

export default SearchEmployee;
