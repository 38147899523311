import { put, take, call } from 'redux-saga/effects';
import { GET_GROUPS_REQUEST } from './types.js';
import { getGroupsError, getGroupsSuccess } from './actions.js';

/** If detect type: GET_GROUPS_REQUEST call getGroups() */
export default function* getGroupsSaga(api) {
  while (true) {
    const getGroupsRequest = yield take(GET_GROUPS_REQUEST);
    if (getGroupsRequest) {
      yield call(getGroups, api);
    }
  }
}

/** get groups */
function* getGroups(api) {
  let response;
  try {
    response = yield call(api.getGroups);
    const groups = response.data;
    yield put(getGroupsSuccess(groups));
  } catch (error) {
    yield put(getGroupsError(error));
  }
}
