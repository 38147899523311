import {
  REMOVE_TRAINING_FROM_GROUP_REQUEST,
  REMOVE_TRAINING_FROM_GROUP_REQUEST_SUCCESS,
} from './types';

/** Action - remove training from group.
 * @param {string} removeTrainingModel - The remove training from group model
 * @return {Object} Return object
 */

export function removeTraining(removeTrainingModel) {
  return {
    type: REMOVE_TRAINING_FROM_GROUP_REQUEST,
    payload: {
      removeTrainingModel,
    },
  };
}

/** On successful remove training from group
 * @param {string} isSuccessful - if remove training from group went alright
 * @return {any} Return object
 */
export function removeTrainingSuccess(isSuccessful) {
  return {
    type: REMOVE_TRAINING_FROM_GROUP_REQUEST_SUCCESS,
    payload: {
      isSuccessful,
    },
  };
}
