import jwt_decode from 'jwt-decode';
import { store } from '../rtk/store';

interface JWTToken {
  role: string[];
}

export function getUserRoles(): string[] {
  try {
    const accessToken = store.getState()?.userSession?.accessToken;
    if (accessToken) {
      const payload: JWTToken = jwt_decode(accessToken);
      if (payload?.role)
        if (Array.isArray(payload.role)) return payload.role;
        else return [payload.role];

      return [];
    }
  } catch (_) {}

  return [];
}
