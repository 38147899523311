import { styled } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import HighlightedDay from './HighlightedDay/HighlightedDay';
import { COLORS } from '../../../cms';

const SIZES = {
  CALENDAR_TITLE_TEXT: 16,
  DATE_SLOT_WIDTH: 24,
  DATE_SLOT_HEIGHT: 24,
  DATE_SLOT_TEXT: 12,
  DATE_SLOT_RADIUS: 6,
};

export const StyledDateCalendar = styled(DateCalendar)(() => ({
  '.MuiPickersCalendarHeader-root': {
    marginTop: 0,
    marginBottom: 2,
  },
  '.MuiDayCalendar-slideTransition': {
    minHeight: '160px !important',
  },
  '.MuiPickersArrowSwitcher-button': {
    color: COLORS.TEXT,
    fontSize: SIZES.CALENDAR_TITLE_TEXT,
  },
  '.MuiPickersCalendarHeader-label': {
    fontSize: SIZES.CALENDAR_TITLE_TEXT,
    color: COLORS.TEXT,
    fontWeight: 'normal',
  },
  '.MuiDayCalendar-weekDayLabel': {
    fontWeight: 'bold',
    color: COLORS.TEXT,
    fontSize: SIZES.DATE_SLOT_TEXT,
    width: SIZES.DATE_SLOT_WIDTH,
    height: SIZES.DATE_SLOT_HEIGHT,
  },
  '.MuiPickersDay-root': {
    fontSize: SIZES.DATE_SLOT_TEXT,
    width: SIZES.DATE_SLOT_WIDTH,
    height: SIZES.DATE_SLOT_HEIGHT,
    color: COLORS.TEXT,
    '&:hover': {
      borderRadius: SIZES.DATE_SLOT_RADIUS,
      backgroundColor: COLORS.LIGHT_BLUE,
      color: COLORS.SECONDARY,
    },
  },
  '.Mui-selected': {
    borderRadius: SIZES.DATE_SLOT_RADIUS,
    backgroundColor: `${COLORS.LIGHT_BLUE} !important`,
    color: `${COLORS.SECONDARY} !important`,
    fontWeight: 'normal !important',
  },
  '.MuiPickersDay-root:not(.Mui-selected)': {
    border: 'none',
  },
  '.MuiPickersDay-today': {
    color: COLORS.SECONDARY,
    textDecoration: 'underline',
  },
  '.MuiPickersDay-dayOutsideMonth': {
    color: COLORS.DARK_SILVER,
  },
}));

type CalendarProps = {
  highlightedDates: Date[];
  classNames?: string;
};

export function Calendar({ highlightedDates, classNames }: CalendarProps) {
  return (
    <StyledDateCalendar
      sx={{
        width: 250,
        height: 'auto',
      }}
      className={classNames}
      views={['day']}
      showDaysOutsideCurrentMonth
      slots={{
        day: HighlightedDay as any,
      }}
      slotProps={{
        day: {
          highlightedDates,
        } as any,
      }}
    />
  );
}
