import { FC, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { BUTTON_LABELS } from '../../../../../cms';
import { BaseModal } from '../../../../../common/components';
import './DemoRequestActionButton.scss';

interface DemoRequestActionButtonProps {
  onClick: Function;
  text: string;
  className: string;
  textareaText: string;
  changeTextareaHandler: any;
  textareaPlaceholderText: string;
  confirmationText: string;
  isConfirmationModalClosed: boolean;
}

const DemoRequestActionButton: FC<DemoRequestActionButtonProps> = (props) => {
  const [isConfirmDeleteModalOpened, setIsConfirmDeleteModalOpened] =
    useState(false);

  const closeDeleteConfirmationModal = () => {
    setIsConfirmDeleteModalOpened(false);
  };

  const openDeleteConfirmationModal = () => {
    setIsConfirmDeleteModalOpened(true);
  };

  useEffect(() => {
    if (props.isConfirmationModalClosed === true) {
      closeDeleteConfirmationModal();
    }
  }, [props.isConfirmationModalClosed]);

  return (
    <>
      <button
        className={`border-0 bg-white mt-1 ${props.className}`}
        onClick={openDeleteConfirmationModal}
      >
        <u>{props.text}</u>
      </button>

      <BaseModal
        hideCloseBtn
        open={isConfirmDeleteModalOpened}
        onClose={closeDeleteConfirmationModal}
      >
        <div className="demo-request-action-modal">
          <p className="success-text text-center">{props.confirmationText}</p>

          <textarea
            className="form-control portal-textarea"
            placeholder={props.textareaPlaceholderText}
            onChange={props.changeTextareaHandler}
            value={props.textareaText}
          />
          <div className="demo-request-action-modal-btns">
            <Button
              variant="outlined"
              color="secondary"
              onClick={closeDeleteConfirmationModal}
              className="demo-request-action-modal-btns-btn"
            >
              {BUTTON_LABELS.CANCEL}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={props.onClick()}
              className="demo-request-action-modal-btns-btn"
            >
              {props.text}
            </Button>
          </div>
        </div>
      </BaseModal>
    </>
  );
};

export default DemoRequestActionButton;
