import { put, take, call } from 'redux-saga/effects';
import { UPDATE_EMPLOYEE_SEARCH_CRITERIA } from './types.js';
import { updateSearchCriteriaSuccess } from './actions.js';

/** If detect type: UPDATE_EMPLOYEE_SEARCH_CRITERIA call updateSearchCriteria() */
export default function* updateSearchCriteriaSaga() {
  while (true) {
    const updateSearchCriteriaRequest = yield take(
      UPDATE_EMPLOYEE_SEARCH_CRITERIA,
    );
    if (updateSearchCriteriaRequest.payload) {
      const { searchCriteria } = updateSearchCriteriaRequest.payload;
      yield call(updateSearchCriteria, searchCriteria);
    }
  }
}

/** Update training path */
function* updateSearchCriteria(searchCriteria) {
  yield put(updateSearchCriteriaSuccess(searchCriteria));
}
