import { put, take, call } from 'redux-saga/effects';
import { EDIT_EMPLOYEE_REQUEST } from './types';
import { editEmployeeError, editEmployeeSuccess } from './actions';
import { EditEmployeeRequestModel } from './EditEmployee';

/** If detect type: EDIT_EMPLOYEE_REQUEST call editEmployee() */
export default function* editEmployeeSaga(api: any): any {
  while (true) {
    const editEmployeeRequest = yield take(EDIT_EMPLOYEE_REQUEST);
    const { model } = editEmployeeRequest.payload;
    yield call(editEmployee, api, model);
  }
}

/** edit employee */
function* editEmployee(api: any, model: EditEmployeeRequestModel): any {
  let response;
  try {
    response = yield call(api.editEmployee, model);
    const isSuccessfull = true;
    yield put(editEmployeeSuccess(isSuccessfull));
  } catch (error) {
    yield put(editEmployeeError(error));
  }
}
