import { all } from 'redux-saga/effects';
import getMyTeamInfoSaga from '../components/MyGroup/MyTeam/saga';
import updateProfessionsSaga from '../components/MyGroup/MyTeam/MyEmployees/Professions/saga';
import applicationCacheSaga from '../common/components/CacheBust/saga';
import updateSearchCriteriaSaga from '../components/MyGroup/MyTeam/MyEmployees/EmployeeSearch/saga';
// API
import { api as statisticsApi } from '../api/trainingsPortalBackend/statisticsApi';
import { api as adminApi } from '../api/trainingsPortalBackend/adminApi';
import { api as employeesApi } from '../api/trainingsPortalBackend/employeesApi';
import { api as groupsApi } from '../api/trainingsPortalBackend/groupsApi';
import { api as userImportApi } from '../api/trainingsPortalBackend/userImportApi';
import {
  getAllEmployeesSaga,
  getSingleEmployeeSaga,
} from '../components/SearchEmployee/saga';
import createGroupSaga from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/CreateGroupButton/CreateGroupForm/saga';
import editGroupSaga from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/GroupAccordeon/GroupDetails/EditGroup/saga';
import sendNotificationsForAssignedTrainingsSaga from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/SendNotificationsForAssignedTrainings/saga';
import addMembersSaga from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/GroupAccordeon/GroupDetails/AddMembersToGroup/saga';
import assignGroupTrainingsSaga from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/GroupAccordeon/GroupDetails/AddTrainingsToGroup/saga';
import removeMemberSaga from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/GroupAccordeon/GroupDetails/RemoveMemberFromGroup/saga';
import removeTrainingSaga from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/GroupAccordeon/GroupDetails/RemoveTrainingFromGroup/saga';
import getAllGroupsSaga from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/saga';
import getGroupsSaga from '../pages/AdministrationPage/tabs/GroupManagementTab/GetAllGroups/saga';
import getGroupSaga from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/GroupAccordeon/GroupDetails/saga';
import deleteGroupSaga from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/GroupAccordeon/GroupDetails/DeleteGroup/saga';
import importUsersCsvSaga from '../pages/AdministrationPage/tabs/EmployeeManagementTab/EmployeesTable/EmployeesTableHeader/UserImport/UserImportModalContent/saga';
import archiveEmployeesSaga from '../pages/AdministrationPage/tabs/EmployeeManagementTab/EmployeesTable/ArchiveEmployee/saga';
import deactivateEmployeesSaga from '../pages/AdministrationPage/tabs/EmployeeManagementTab/EmployeesTable/DeactiveateEmployee/saga';
import getAllCostReportsSaga from '../pages/AdministrationPage/tabs/ReportsTab/saga';
import addEmployeeToMyTeamSaga from '../components/MyGroup/MyTeam/MyEmployees/AddEmployeeModal/saga';
import removeEmployeeFromMyTeamSaga from '../components/MyGroup/MyTeam/MyEmployees/Employees/saga';
import getAllManagersSaga from '../pages/AdministrationPage/tabs/EmployeeManagementTab/EmployeesTable/EditEmployee/GetAllManagers/saga';
import getAllProfessionsSaga from '../pages/AdministrationPage/tabs/EmployeeManagementTab/EmployeesTable/EditEmployee/GetAllProfessions/saga';
import getAllDepartmentsSaga from '../pages/AdministrationPage/tabs/EmployeeManagementTab/EmployeesTable/EditEmployee/GetAllDepartments/saga';
import editEmployeeSaga from '../pages/AdministrationPage/tabs/EmployeeManagementTab/EmployeesTable/EditEmployee/saga';

/** Root saga.
 * @return {Object} - return store
 */
function* rootSaga() {
  const envVariablesPassed = process.env;
  const doUseMocks = envVariablesPassed.REACT_APP_USE_MOCKS === 'true';
  if (doUseMocks) {
    yield all([]);
  } else {
    yield all([
      updateProfessionsSaga(),
      getMyTeamInfoSaga(adminApi),
      updateSearchCriteriaSaga(),
      applicationCacheSaga(),
      getAllEmployeesSaga(employeesApi),
      getSingleEmployeeSaga(employeesApi),
      createGroupSaga(groupsApi),
      editGroupSaga(groupsApi),
      sendNotificationsForAssignedTrainingsSaga(groupsApi),
      addMembersSaga(groupsApi),
      assignGroupTrainingsSaga(groupsApi),
      removeMemberSaga(groupsApi),
      removeTrainingSaga(groupsApi),
      getAllGroupsSaga(groupsApi),
      getGroupsSaga(groupsApi),
      getGroupSaga(groupsApi),
      deleteGroupSaga(groupsApi),
      importUsersCsvSaga(userImportApi),
      archiveEmployeesSaga(adminApi),
      deactivateEmployeesSaga(adminApi),
      getAllManagersSaga(adminApi),
      getAllProfessionsSaga(adminApi),
      getAllDepartmentsSaga(adminApi),
      editEmployeeSaga(adminApi),
      getAllCostReportsSaga(statisticsApi),
      addEmployeeToMyTeamSaga(employeesApi),
      removeEmployeeFromMyTeamSaga(employeesApi),
    ]);
  }
}

export default rootSaga;
