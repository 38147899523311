import { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useSelector } from 'react-redux';
import {
  BUTTON_LABELS,
  GROUP_MANAGEMENT_LABELS,
  MODAL_VALUES,
  NOTIFICATION_LABELS,
} from '../../../../../../cms/index';
import {
  BaseModal,
  ConfirmAction,
  SuccessMessage,
} from '../../../../../../common/components';

function DeleteMultipleGroupsButton({
  selectedGroupsCount,
  deleteMultipleGroups,
}) {
  const { deleteSuccess } = useSelector((state) => state.groups);
  const [isConfirmDeleteModalOpened, setIsConfirmDeleteModalOpened] =
    useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (deleteSuccess) {
      setShowSuccess(true);
      setTimeout(() => {
        handleSuccessClose();
      }, MODAL_VALUES.MODAL_CLOSE_TIMEOUT);
    }
  }, [deleteSuccess]);

  const closeDeleteConfirmationModal = () => {
    setIsConfirmDeleteModalOpened(false);
  };

  const openDeleteConfirmationModal = () => {
    setIsConfirmDeleteModalOpened(true);
  };

  const deleteSelectedGroups = () => {
    deleteMultipleGroups();
    closeDeleteConfirmationModal();
  };

  const handleSuccessClose = () => {
    setShowSuccess(false);
  };

  return (
    <>
      <Button
        className={
          selectedGroupsCount > 0
            ? 'delete-multiple-group-btn'
            : 'disabled-delete-multiple-group-btn'
        }
        disabled={!(selectedGroupsCount > 0)}
        onClick={openDeleteConfirmationModal}
        variant="outlined"
        color="error"
        startIcon={<DeleteForeverIcon />}
        sx={{ width: { xs: '100%', sm: 'auto' } }}
      >
        {BUTTON_LABELS.DELETE_MULTIPLE_GROUPS.toUpperCase()}
      </Button>
      <BaseModal
        open={isConfirmDeleteModalOpened}
        onClose={closeDeleteConfirmationModal}
        showCloseBtnOutside
      >
        <ConfirmAction
          text={
            GROUP_MANAGEMENT_LABELS.DELETE_MULTIPLE_GROUPS_CONFIRMATION_TEXT
          }
          btnText={BUTTON_LABELS.DELETE_BUTTON}
          onConfirm={deleteSelectedGroups}
          onClose={closeDeleteConfirmationModal}
        />
      </BaseModal>
      <BaseModal hideCloseBtn open={showSuccess} onClose={handleSuccessClose}>
        <SuccessMessage text={NOTIFICATION_LABELS.DELETE_MULTIPLE_GROUPS} />
      </BaseModal>
    </>
  );
}

export default DeleteMultipleGroupsButton;
