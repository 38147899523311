import { useEffect } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';
import { useDropzone } from 'react-dropzone';
import { ADD_EVENT_MODAL_LABELS, COLORS, TRAINER_DETAILS } from '../../../cms';
import './ImageDropzone.scss';

type DropzoneProps = {
  image: string | null;
  onImageChange: (photo: string | null) => void;
};

const ImageDropzone = ({ image, onImageChange }: DropzoneProps) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  useEffect(() => {
    if (acceptedFiles.length) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Less than 200KB
        if (acceptedFiles[0].size <= 200000)
          onImageChange((reader?.result as string)?.split(',')[1]);
      };

      reader.readAsDataURL(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  const removeImage = () => {
    onImageChange(null);
  };

  return (
    <Stack flexDirection="row" spacing="1rem" alignItems="center">
      <Box {...getRootProps({ className: 'image-dropzone' })}>
        <input {...getInputProps()} />
        <Stack direction="row" spacing="1rem" alignItems="center">
          {image ? (
            <img
              className="uploaded-img"
              src={`data:image/png;base64, ${image}`}
              alt="Uploaded"
            />
          ) : (
            <Box
              sx={{
                border: `1px dashed ${COLORS.DARK_SILVER}`,
                borderRadius: '50%',
                padding: '0.6rem',
              }}
            >
              <UploadIcon
                sx={{
                  fontSize: '2rem',
                  color: COLORS.SECONDARY,
                }}
              />
            </Box>
          )}
          <Stack>
            <Typography
              sx={{
                fontSize: 14,
              }}
            >
              {TRAINER_DETAILS.SPEAKER_PHOTO}
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
              }}
            >
              <Typography
                component="span"
                sx={{
                  fontSize: 14,
                  color: COLORS.PRIMARY,
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                }}
              >
                {ADD_EVENT_MODAL_LABELS.UPLOAD}
              </Typography>
              &nbsp;{ADD_EVENT_MODAL_LABELS.DRAG_DROP_HERE}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                color: COLORS.DARK_SILVER,
              }}
            >
              {ADD_EVENT_MODAL_LABELS.JPG_PNG}
            </Typography>
          </Stack>
        </Stack>
      </Box>
      {image && (
        <IconButton className="image-dropzone-remove-btn" onClick={removeImage}>
          <CloseIcon className="image-dropzone-remove-btn-icon" />
        </IconButton>
      )}
    </Stack>
  );
};

export default ImageDropzone;
