import { useState } from 'react';
import './LoginForm.scss';
import config from '../../config';
import { ENVIRONMENT_NAMES, SCREEN_SIZES } from '../../cms/index';
import InternalLogin from '../../components/InternalLogin/InternalLogin';
import ExternalLogin from '../../components/ExternalLogin/ExternalLogin';
import loginImage from '../../common/images/login_image.png';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import { LOGIN_METHODS } from '../../cms/enums';
import { addSmallStyle } from '../../cms/Utils';
import { Disclaimer } from '../../common/components';

const LoginForm = () => {
  const [shownLoginMethod, setShownLoginMethod] = useState(
    LOGIN_METHODS.INTERNAL,
  );
  const isTestEnvironment = config.ENVIRONMENT === ENVIRONMENT_NAMES.TEST;
  const isTrialEnvironment = config.ENVIRONMENT === ENVIRONMENT_NAMES.TRIAL;
  const isScreenSmall = useMediaQuery(SCREEN_SIZES.SMALL);
  const shouldShowInternal =
    (config.GOOGLE_CLIENT_ID.length > 0 ||
      config.MICROSOFT_CLIENT_ID.length > 0) &&
    config.ENVIRONMENT !== ENVIRONMENT_NAMES.TRIAL &&
    shownLoginMethod === 'internal';

  return (
    <div className={`login-form-main-container${addSmallStyle(isScreenSmall)}`}>
      <img
        src={loginImage}
        alt="Login"
        className={`login-form-image${addSmallStyle(isScreenSmall)}`}
      />
      <div className={`login-form-content${addSmallStyle(isScreenSmall)}`}>
        <div className={`upskillportal${addSmallStyle(isScreenSmall)}`} />
        {shouldShowInternal ? <InternalLogin /> : <ExternalLogin />}

        {isTrialEnvironment && <Disclaimer description="By clicking Login" />}

        {isTestEnvironment && shownLoginMethod === LOGIN_METHODS.INTERNAL && (
          <button
            onClick={() => setShownLoginMethod(LOGIN_METHODS.EXTERNAL)}
            className="login-form-switch-btn"
          >
            Login with Username and Password
          </button>
        )}

        {isTestEnvironment && shownLoginMethod === LOGIN_METHODS.EXTERNAL && (
          <button
            onClick={() => setShownLoginMethod(LOGIN_METHODS.INTERNAL)}
            className="login-form-switch-btn"
          >
            Login with External Provider
          </button>
        )}
      </div>
    </div>
  );
};

export default LoginForm;
