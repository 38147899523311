import { useEffect } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  EVENT_APPLICATIONS_LABELS,
  EXPORTS,
  TRAINING_APPLICATIONS_LABELS,
} from '../../../../cms';
import TrainingApplicationTableRow from './TrainingApplicationsTableRow';
import {
  useGetTrainingApplicationsQuery,
  useLazyGetTrainingApplicationsForExportQuery,
} from '../../../../rtk/api/trainingApplicationApi';
import './TrainingApplicationsTab.scss';
import { SearchField } from '../../../../common/components';
import { useDebounce } from '../../../../common/hooks/useDebounce';
import {
  convertToCsv,
  downloadFile,
  formatTrainingApplicationsForExport,
} from '../../../../cms/Utils';
import { TrainingApplication } from '../../../../cms/types/trainingApplicationTypes';
import { useRemoveUserFromTrainingMutation } from '../../../../rtk/api/adminApi';
import { useAppDispatch, useAppSelector } from '../../../../rtk/hooks';
import {
  incrementTrainingApplicationsPageNumber,
  resetTrainingApplicationsPagination,
  setTrainingApplicationsSearch,
} from '../../../../rtk/features/trainingApplicationsPaginationSlice';

export default function TrainingApplicationsTab() {
  const dispatch = useAppDispatch();
  const { search, pageNumber, pageSize, refetch } = useAppSelector(
    (state) => state.trainingApplicationsPagination,
  );
  const debouncedSearchText = useDebounce(search.trim().toLowerCase());
  const [removeUserFromTraining] = useRemoveUserFromTrainingMutation();
  const { data } = useGetTrainingApplicationsQuery(
    {
      pageNumber,
      pageSize,
      search: debouncedSearchText,
      refetch,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const trainingApplications = data ?? [];
  const [getTrainingApplicationsForExport, { isLoading }] =
    useLazyGetTrainingApplicationsForExportQuery();

  const hasMore = pageNumber * pageSize === trainingApplications.length;

  useEffect(() => {
    return () => {
      dispatch(resetTrainingApplicationsPagination());
    };
  }, []);

  const handleOnSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTrainingApplicationsSearch(e.target.value));
  };

  const fetchMoreData = () => {
    dispatch(incrementTrainingApplicationsPageNumber());
  };

  const handleRemoveUser = (trainingId: number, userId: number) => {
    removeUserFromTraining({
      trainingId,
      userId,
    });
  };

  const onExport = () => {
    getTrainingApplicationsForExport({
      search: debouncedSearchText,
    })
      .unwrap()
      .then(downloadCsvFile)
      .catch(() => {});
  };

  const downloadCsvFile = (
    trainingApplicationsForExport: TrainingApplication[],
  ) => {
    const foratedTrainingApplicationsForExport =
      formatTrainingApplicationsForExport(trainingApplicationsForExport);
    const csvData = convertToCsv(foratedTrainingApplicationsForExport);
    downloadFile(csvData, 'training-applications.csv', 'text/csv');
  };

  return (
    <Stack spacing="2rem" className="training-applications">
      <Stack direction="row" spacing="1rem" flexWrap="wrap">
        <SearchField
          placeholder="Search by Title or Email"
          value={search}
          onChange={handleOnSearchTextChange}
          sx={{
            width: { xs: '100%', sm: 300 },
          }}
          className="training-applications-search"
        />
        <LoadingButton
          variant="contained"
          onClick={onExport}
          className="training-applications-export-btn"
          sx={{
            width: { xs: '100%', sm: 'auto' },
          }}
          startIcon={<FileDownloadIcon />}
          loading={isLoading}
          loadingPosition="start"
        >
          {EXPORTS.EXPORT_TO_CSV}
        </LoadingButton>
      </Stack>
      <TableContainer component={Paper}>
        <InfiniteScroll
          dataLength={trainingApplications?.length ?? 0}
          next={fetchMoreData}
          hasMore={hasMore!}
          loader=""
          scrollableTarget="scrollableDiv"
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell className="column" align="left">
                  {TRAINING_APPLICATIONS_LABELS.TITLE}
                </TableCell>
                <TableCell className="column" align="left">
                  {TRAINING_APPLICATIONS_LABELS.USER}
                </TableCell>
                <TableCell className="column" align="left">
                  {TRAINING_APPLICATIONS_LABELS.EMAIL}
                </TableCell>
                <TableCell className="column" align="left">
                  {TRAINING_APPLICATIONS_LABELS.MANAGER}
                </TableCell>
                <TableCell className="column" align="left">
                  {TRAINING_APPLICATIONS_LABELS.LAST_UPDATED}
                </TableCell>
                <TableCell className="column" align="left">
                  {TRAINING_APPLICATIONS_LABELS.TYPE}
                </TableCell>
                <TableCell className="column" align="left">
                  {TRAINING_APPLICATIONS_LABELS.STATUS}
                </TableCell>
                <TableCell className="column" align="left">
                  {EVENT_APPLICATIONS_LABELS.REMOVE_LABEL}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trainingApplications?.map((row) => (
                <TrainingApplicationTableRow
                  key={row.applicationId.toString()}
                  row={row}
                  removeUser={handleRemoveUser}
                />
              ))}
            </TableBody>
          </Table>
        </InfiniteScroll>
      </TableContainer>
    </Stack>
  );
}
