import { PricingOption } from '../../components/PricingSection/types';
import { api } from './api';

export type PaymentRequestModel = {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  roleInCompany: string;
  pricingOption: PricingOption;
  agreed: boolean;
};

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    requestPayment: build.mutation<any, PaymentRequestModel>({
      query: (body) => ({
        url: `/DemoRequest/paymentRequest`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useRequestPaymentMutation } = extendedApi;
