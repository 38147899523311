import './FullPagePreloader.scss';
import { ThreeDots } from 'react-loader-spinner';
import { COLORS } from '../../cms';

const FullPagePreloader = () => (
  <div className="full-page-preloader-container">
    <ThreeDots color={COLORS.PRIMARY} height={150} width={150} />
  </div>
);

export default FullPagePreloader;
