import { Button, Divider, Stack, Typography } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import './LinkForm.scss';
import {
  PathItemSchemaType,
  PathLinkSchema,
  PathLinkSchemaType,
} from '../../../../../../cms/validation/pathValidation';
import { CustomTextField } from '../../../../../../common/components';
import {
  BUTTON_LABELS,
  PATH_LINK_DESCRIPTION_LENGTH,
  PATH_LINK_LABELS,
} from '../../../../../../cms';
import { convertServerPathLinkToClientPathLink } from '../../../../../../cms/Utils';

type LinkFormProps = {
  initialValues: PathItemSchemaType | null;
  onClose: (newItems: PathItemSchemaType[]) => void;
};

const LinkForm = ({ initialValues, onClose }: LinkFormProps) => {
  const { handleSubmit, control } = useForm<PathLinkSchemaType>({
    resolver: zodResolver(PathLinkSchema),
    defaultValues: {
      url: initialValues?.data.url ?? '',
      name: initialValues?.data.name ?? '',
      description: initialValues?.data?.description ?? '',
    },
  });

  const formTitle = initialValues
    ? PATH_LINK_LABELS.EDIT_LINK
    : PATH_LINK_LABELS.ADD_LINK;
  const buttonTitle = initialValues
    ? BUTTON_LABELS.SAVE_CHANGES
    : PATH_LINK_LABELS.ADD_LINK;

  const onSubmit = (link: PathLinkSchemaType) => {
    onClose([convertServerPathLinkToClientPathLink(link)]);
  };

  return (
    <Stack className="link-form-container" spacing="1.5rem">
      <Typography className="link-form-title">{formTitle}</Typography>
      <Stack component="form" noValidate className="link-form" spacing="1.5rem">
        <Controller
          name="url"
          control={control}
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <CustomTextField
              label="URL"
              color="secondary"
              required
              fullWidth
              name={name}
              value={value}
              onChange={onChange}
              inputRef={ref}
              onBlur={onBlur}
              error={Boolean(error)}
              helperText={error?.message}
              inputProps={{
                className: 'link-url-input',
              }}
            />
          )}
        />
        <Controller
          name="name"
          control={control}
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <CustomTextField
              label="Name"
              color="secondary"
              required
              fullWidth
              name={name}
              value={value}
              onChange={onChange}
              inputRef={ref}
              onBlur={onBlur}
              error={Boolean(error)}
              helperText={error?.message}
              inputProps={{
                className: 'link-name-input',
              }}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <CustomTextField
              label="Description"
              color="secondary"
              fullWidth
              name={name}
              value={value}
              onChange={onChange}
              inputRef={ref}
              onBlur={onBlur}
              error={Boolean(error)}
              helperText={
                error?.message ??
                `${value?.length ?? 0} / ${PATH_LINK_DESCRIPTION_LENGTH}`
              }
              multiline
              rows={6}
              inputProps={{
                className: 'link-description-input',
                maxLength: PATH_LINK_DESCRIPTION_LENGTH,
              }}
            />
          )}
        />
      </Stack>
      <Divider />
      <Stack spacing="1rem" className="link-form-footer">
        <Button variant="outlined" onClick={() => onClose([])}>
          {BUTTON_LABELS.CANCEL}
        </Button>
        <Button variant="contained" onClick={() => handleSubmit(onSubmit)()}>
          {buttonTitle}
        </Button>
      </Stack>
    </Stack>
  );
};

export default LinkForm;
