import './SolutionCard.scss';
import checkImage from '../../../common/images/product-card-check.svg';

type Solution = {
  title: string;
  description?: string;
};

type SolutionCardProps = {
  item: Solution;
};

const SolutionCard = ({ item }: SolutionCardProps) => {
  const { title, description } = item;

  return (
    <div className="solution-card-container">
      <img src={checkImage} alt="Solution" />
      <div className="solution-card-data-container">
        <h3 className="solution-card-title">{title}</h3>
        <p className="solution-card-description">{description}</p>
      </div>
    </div>
  );
};

export default SolutionCard;
