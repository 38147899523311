import { useState, useEffect } from 'react';
import { Divider, Button } from '@mui/material';
import './AddTrainingsToGroup.scss';
import SearchAssignedTraining from '../../../../../../../../components/SearchAssignedTraining/SearchAssignedTraining';
import {
  BUTTON_LABELS,
  MODAL_VALUES,
  NOTIFICATION_LABELS,
} from '../../../../../../../../cms';
import { getAllGroups } from '../../../actions';
import { sendNotificationsForAssignedTrainings } from '../../../SendNotificationsForAssignedTrainings/actions';
import { assignGroupTrainings } from './actions';
import {
  BaseModal,
  SuccessMessage,
} from '../../../../../../../../common/components';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../rtk/hooks';

export default function AddTrainingsToGroup({ onClose, group }) {
  const dispatch = useAppDispatch();
  const { assignGroupTrainingsSuccess } = useAppSelector(
    (state) => state.groups,
  );

  const initialTrainings = group.trainings;
  const [selectedTrainings, setSelectedTrainings] = useState(group.trainings);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (assignGroupTrainingsSuccess) {
      setShowSuccess(true);
      setTimeout(() => {
        handleSuccessClose();
      }, MODAL_VALUES.MODAL_CLOSE_TIMEOUT);

      const finalTrainingsToSendSendNotifications = selectedTrainings.filter(
        ({ id: id1 }) => !initialTrainings.some(({ id: id2 }) => id1 === id2),
      );

      const notificationsModel = {
        groupId: group.id,
        memberIds: [],
        trainingIds: finalTrainingsToSendSendNotifications.map((m) => m.id),
      };

      dispatch(sendNotificationsForAssignedTrainings(notificationsModel));
    }
  }, [assignGroupTrainingsSuccess]);

  const handleSuccessClose = () => {
    dispatch(getAllGroups());
    onClose();
  };

  const handleOnSelectedTrainingsChanged = (trainings) =>
    setSelectedTrainings(trainings);

  const submitAddTrainingsToGroup = () => {
    const trainingsExternalIds = selectedTrainings.map((e) => e.id);

    const model = {
      groupIds: [group.id],
      trainingIds: trainingsExternalIds,
    };

    const lastAssignTrainingsToGroupModel = {
      id: group.id,
      title: group.title,
      description: group.description,
      members: group.members,
      assignedTrainings: selectedTrainings,
    };

    dispatch(assignGroupTrainings(model, lastAssignTrainingsToGroupModel));
  };

  return (
    <div className="add-trainings-to-group">
      <h6 className="add-trainings-to-group-header">Assign Trainings</h6>
      <Divider flexItem />
      <div className="add-trainings-to-group-content">
        <SearchAssignedTraining
          handleOnSelectedTrainingsChanged={handleOnSelectedTrainingsChanged}
          searchBoxInputId="search-training-box-input"
          formErrors={{}}
          selectedTrainings={selectedTrainings}
        />
      </div>
      <Divider flexItem />
      <div className="add-trainings-to-group-btns">
        <Button variant="outlined" onClick={onClose}>
          {BUTTON_LABELS.CANCEL}
        </Button>
        <Button
          className="add-trainings-to-group-save-changes"
          variant="contained"
          onClick={submitAddTrainingsToGroup}
        >
          {BUTTON_LABELS.SAVE_CHANGES}
        </Button>
      </div>
      <BaseModal hideCloseBtn open={showSuccess} onClose={handleSuccessClose}>
        <SuccessMessage text={NOTIFICATION_LABELS.ASSIGN_TRAININGS_TO_GROUP} />
      </BaseModal>
    </div>
  );
}
