import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import demoRequestPhoto from '../../common/images/login_image.png';
import { SCREEN_SIZES } from '../../cms';
import './PaymentForm.scss';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import { addSmallStyle } from '../../cms/Utils';
import UpskillPortalLogo from '../../components/UpskillPortalLogo/UpskillPortalLogo';
import { CustomTextField, FormCheckbox } from '../../common/components';
import { Pricing } from '../../components/PricingSection/types';
import { useRequestPaymentMutation } from '../../rtk/api/paymentApi';

type PaymentFormProps = {
  pricing: Pricing;
  onClose: () => void;
};

const PaymentForm = ({ pricing, onClose }: PaymentFormProps) => {
  const isScreenSmall = useMediaQuery(SCREEN_SIZES.SMALL);
  const { price, plan, title, pricePerUser, pricingOption } = pricing ?? {};
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    roleInCompany: '',
    agreed: false,
    pricingOption: pricingOption,
  });
  const [formValidation, setFormValidation] = useState({
    firstName: true,
    lastName: true,
    email: true,
    companyName: true,
    roleInCompany: true,
    agreed: true,
  });

  const [requestPayment, isSuccess] = useRequestPaymentMutation();

  const onChangeFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.name === 'agreed' ? !formValues.agreed : e.target.value,
    }));
    setFormValidation((prev) => ({
      ...prev,
      [e.target.name]: true,
    }));
  };

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    validateForm();
    requestPayment(formValues);
  };

  const validateForm = () => {
    Object.entries(formValues).forEach(([key, val]) => {
      let isValid = false;
      if (key === 'agreed') isValid = val === true;
      else isValid = String(val)?.length > 0;

      setFormValidation((prev) => ({
        ...prev,
        [key]: isValid,
      }));
    });
  };

  useEffect(() => {
    if (isSuccess.isSuccess) onClose();
  }, [isSuccess]);

  return (
    <div className={`payment-form${addSmallStyle(isScreenSmall)}`}>
      <img
        src={demoRequestPhoto}
        alt="Payment Form"
        className={`payment-form-image${addSmallStyle(isScreenSmall)}`}
      />
      <form
        className={`payment-form-container${addSmallStyle(isScreenSmall)}`}
        onSubmit={onSubmit}
        noValidate
      >
        <UpskillPortalLogo className="payment-form-container-logo" />
        <div className="payment-form-container-header">
          <h3 className="payment-form-container-header-title">Payment for:</h3>
          <p
            className="payment-form-container-header-sub-title"
            style={{
              opacity: pricing ? 1 : 0,
            }}
          >
            <span>{price}€</span> / {plan} ({pricePerUser?.toFixed(2)}€ per
            user): {title}
          </p>
        </div>

        <fieldset className="payment-form-container-full-name">
          <CustomTextField
            label="First Name"
            name="firstName"
            onChange={onChangeFormValue}
            value={formValues.firstName}
            error={!formValidation.firstName}
            required
          />

          <CustomTextField
            label="Last Name"
            name="lastName"
            onChange={onChangeFormValue}
            value={formValues.lastName}
            error={!formValidation.lastName}
            required
          />
        </fieldset>

        <CustomTextField
          label="Email Address"
          name="email"
          onChange={onChangeFormValue}
          value={formValues.email}
          error={!formValidation.email}
          required
        />

        <CustomTextField
          label="Company Name"
          name="companyName"
          onChange={onChangeFormValue}
          value={formValues.companyName}
          error={!formValidation.companyName}
          required
        />

        <CustomTextField
          label="Role in the Company"
          name="roleInCompany"
          onChange={onChangeFormValue}
          value={formValues.roleInCompany}
          error={!formValidation.roleInCompany}
          required
        />

        <p className="payment-form-container-pre-submit-info">
          By submitting this form, you will receive an email containing a link
          to access your environment, along with a payment link. Please, ensure
          that the payment is completed within the next 2 days.
        </p>

        <Button
          type="submit"
          variant="contained"
          className="payment-form-container-submit-btn"
          disabled={formValues.agreed === false}
        >
          Submit
        </Button>
        <FormCheckbox
          checked={formValues.agreed}
          onChange={onChangeFormValue}
          name="agreed"
          isValid={formValidation.agreed}
          isRequired
        >
          I have read and agree to the{' '}
          <a href="#">SLA (Service-Level Agreement)</a> and{' '}
          <a href="#">Terms of Use</a>.
        </FormCheckbox>
      </form>
    </div>
  );
};

export default PaymentForm;
