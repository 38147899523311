import { useEffect, useState } from 'react';
import { Button, Divider } from '@mui/material';
import {
  BUTTON_LABELS,
  FAILED_REQUEST_MESSAGE,
  MODAL_VALUES,
  NOTIFICATION_LABELS,
} from '../../cms';
import './AssignTraining.scss';
import SearchEmployee from '../SearchEmployee/SearchEmployee';
import {
  BaseModal,
  ErrorMessage,
  SuccessMessage,
} from '../../common/components';
import { useAssignTrainingsToEmployeesMutation } from '../../rtk/api/trainingApi';
import { Employee } from '../../cms/types';

interface AssignTrainingProps {
  trainingId: number;
}

const AssignTraining = ({ trainingId }: AssignTrainingProps) => {
  const [assignTrainings, result] = useAssignTrainingsToEmployeesMutation();
  const { isError, isSuccess } = result;

  const [showAssignTrainingModal, setShowAssignTrainingModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleSuccessClose = () => {
    setShowSuccess(false);
    setShowAssignTrainingModal(false);
  };
  const handleErrorClose = () => setShowError(false);

  const onCloseHandler = () => {
    setShowAssignTrainingModal(false);
  };

  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);

  const updateSelectedEmployeesHandler = (employees: Employee[]) =>
    setSelectedEmployees(employees);

  const handleAssignTraining = () => {
    const model = {
      trainingIds: [trainingId],
      userIds: selectedEmployees.map((e) => e.id),
    };

    if (model.userIds.length > 0) assignTrainings(model);
  };

  useEffect(() => {
    if (isSuccess) {
      setShowSuccess(true);
      setTimeout(() => {
        handleSuccessClose();
      }, MODAL_VALUES.MODAL_CLOSE_TIMEOUT);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) setShowError(true);
  }, [isError]);

  return (
    <>
      <Button
        variant="contained"
        className="assign-training-button"
        onClick={() => setShowAssignTrainingModal(true)}
      >
        {BUTTON_LABELS.ASSIGN_TRAINING}
      </Button>
      <BaseModal open={showAssignTrainingModal} onClose={onCloseHandler}>
        <div className="assign-training">
          <h6 className="assign-training-header">
            {BUTTON_LABELS.ASSIGN_TRAINING}
          </h6>
          <Divider flexItem />
          <div className="assign-training-content">
            <SearchEmployee
              updateSelectedEmployeesHandler={updateSelectedEmployeesHandler}
              searchBoxInputId="search-employee-box-input"
              focus={false}
              formErrors={[]}
              selectedEmployees={selectedEmployees}
              className=""
            />
          </div>
        </div>
        <Divider flexItem />
        <div className="assign-training-footer">
          <Button variant="outlined" color="secondary" onClick={onCloseHandler}>
            {BUTTON_LABELS.CANCEL}
          </Button>
          <Button onClick={handleAssignTraining} variant="contained">
            {BUTTON_LABELS.ASSIGN_TRAINING}
          </Button>
        </div>
      </BaseModal>
      <BaseModal hideCloseBtn open={showSuccess} onClose={handleSuccessClose}>
        <SuccessMessage
          text={NOTIFICATION_LABELS.TRAININGS_HAVE_BEEN_ASSIGNED}
        />
      </BaseModal>
      <BaseModal hideCloseBtn open={showError} onClose={handleErrorClose}>
        <ErrorMessage text={FAILED_REQUEST_MESSAGE} />
      </BaseModal>
    </>
  );
};

export default AssignTraining;
