import { useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Link, MenuItem, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import {
  APPLICATION_STATUSES,
  DATE_FORMATS,
  MY_TRAININGS_AND_EVENTS_TYPES,
  ROUTE_LINKS,
} from '../../../cms';
import {
  MyTrainingsAndEventsTypes,
  ApplicationStatuses,
} from '../../../cms/enums';
import ResponsiveTable from '../../../common/components/ResponsiveTable/ResponsiveTable';
import {
  ApplicationStatusChip,
  FilterSelect,
  SearchField,
} from '../../../common/components';
import {
  PersonalApplication,
  useGetPersonalApplicationsQuery,
} from '../../../rtk/api/applicationApi';
import { useDebounce } from '../../../common/hooks/useDebounce';
import { inheritFontStyle } from '../../../cms/Utils';
import ChangeApplicationStatusMenu from './ChangeApplicationStatusMenu/ChangeApplicationStatusMenu';
import { useAppSelector } from '../../../rtk/hooks';
import './MyTrainingsAndEventsTab.scss';

const MyTrainingsAndEventsTab = () => {
  const { id } = useAppSelector((state) => state.userSession);
  const [searchParams, setSearchParams] = useSearchParams();
  const employeeId = searchParams.get('employeeId');
  const [searchText, setSearchText] = useState('');
  const [status, setStatus] = useState<ApplicationStatuses>(
    ApplicationStatuses.ALL,
  );
  const [type, setType] = useState<MyTrainingsAndEventsTypes>(
    MyTrainingsAndEventsTypes.ALL,
  );
  const debouncedSearchText = useDebounce(searchText.trim().toLowerCase());
  const { data = [], isFetching } = useGetPersonalApplicationsQuery(
    {
      userId: employeeId ? Number(employeeId) : id!,
      search: debouncedSearchText,
      type: type === MyTrainingsAndEventsTypes.ALL ? '' : type,
      status: status === ApplicationStatuses.ALL ? '' : status,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.value as ApplicationStatuses);
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setType(e.target.value as MyTrainingsAndEventsTypes);
  };

  const handleTitleClick = (
    cardType: MyTrainingsAndEventsTypes,
    id: number,
  ) => {
    setSearchParams((prev) => {
      prev.set(
        cardType === MyTrainingsAndEventsTypes.TRAINING
          ? 'trainingId'
          : 'eventId',
        id.toString(),
      );
      return prev;
    });
  };

  return (
    <Stack spacing="2rem" className="my-trainings-and-events">
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="flex-end"
        spacing="1rem"
      >
        <SearchField
          value={searchText}
          onChange={handleSearchTextChange}
          placeholder="Search trainings and events..."
          sx={{
            width: { xs: '100%', sm: 300 },
            marginRight: 'auto',
          }}
          className="my-trainings-and-events-search"
        />

        <FilterSelect
          label="Type"
          onChange={handleTypeChange}
          value={type}
          sx={{
            width: { xs: '100%', sm: 'auto' },
          }}
          className="my-trainings-and-events-type-select"
        >
          <MenuItem
            key="All"
            value="All"
            className="my-trainings-and-events-type-select-option"
          >
            All
          </MenuItem>
          {MY_TRAININGS_AND_EVENTS_TYPES.map((s) => (
            <MenuItem
              key={s.value}
              value={s.value}
              className="my-trainings-and-events-type-select-option"
            >
              {s.value}
            </MenuItem>
          ))}
        </FilterSelect>

        <FilterSelect
          label="Status"
          onChange={handleStatusChange}
          value={status}
          sx={{
            width: { xs: '100%', sm: 'auto' },
          }}
          className="my-trainings-and-events-status-select"
        >
          <MenuItem
            key="All"
            value="All"
            className="my-trainings-and-events-status-select-option"
          >
            All
          </MenuItem>
          {APPLICATION_STATUSES.map((s) => (
            <MenuItem
              key={s.value}
              value={s.value}
              className="my-trainings-and-events-status-select-option"
            >
              {s.label}
            </MenuItem>
          ))}
        </FilterSelect>
      </Stack>
      <ResponsiveTable<PersonalApplication>
        data={data}
        isLoading={isFetching}
        columns={[
          {
            key: 'title',
            heading: 'Title',
            primary: true,
            width: '50%',
            render: (item: PersonalApplication) => (
              <Typography
                sx={{
                  ...inheritFontStyle,
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  wordBreak: 'break-all',
                  ':hover': {
                    textDecoration: 'underline',
                  },
                }}
                onClick={() => handleTitleClick(item.type, item.entryId)}
                className="application-title"
              >
                {item.title}
              </Typography>
            ),
          },
          {
            key: 'type',
            heading: 'Type',
            hide: true,
            primary: true,
            width: '10%',
            render: (item: PersonalApplication) => (
              <Typography className="application-type">{item.type}</Typography>
            ),
          },
          {
            key: 'pathId',
            heading: 'Path Connection',
            hide: true,
            primary: true,
            width: '15%',
            render: (item: PersonalApplication) =>
              item.pathId ? (
                <Link
                  sx={{ ...inheritFontStyle, whiteSpace: 'nowrap' }}
                  component={RouterLink}
                  to={`${ROUTE_LINKS.PERSONAL_PATHS}/${item.pathId}`}
                  className="application-path-link"
                >
                  Open Path
                </Link>
              ) : (
                <p>-</p>
              ),
          },
          {
            key: 'statusDate',
            heading: 'Status Date',
            width: '10%',
            hide: true,
            primary: true,
            render: (item) => (
              <Typography
                sx={{ ...inheritFontStyle, whiteSpace: 'nowrap' }}
                className="application-status-date"
              >
                {dayjs(new Date(item.statusDate)).format(DATE_FORMATS.NORMAL)}
              </Typography>
            ),
          },
          {
            key: 'status',
            heading: 'Status',
            width: '10%',
            hide: true,
            primary: true,
            render: (item: PersonalApplication) => (
              <ApplicationStatusChip
                status={item.status}
                className="application-status"
              />
            ),
          },
          {
            key: 'id',
            primary: true,
            align: 'right',
            render: (application: PersonalApplication) => (
              <ChangeApplicationStatusMenu application={application} />
            ),
          },
          {
            key: 'comment',
            heading: 'Comment',
            hide: true,
            primary: false,
            render: (item: PersonalApplication) => (
              <Typography className="application-comment">
                {item.comment}
              </Typography>
            ),
          },
        ]}
      />
    </Stack>
  );
};

export default MyTrainingsAndEventsTab;
