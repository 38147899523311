import { COLORS } from '../../../../cms';
import { SkillTypes } from '../../../../cms/enums';
import { SkillTypeIcon } from '../../index';
import './TrainingCardIcon.scss';

type TrainingCardIconProps = {
  type: SkillTypes;
};

const TrainingCardIcon = ({ type }: TrainingCardIconProps) => {
  return (
    <div className={`training-card-icon ${type}`}>
      <SkillTypeIcon skillType={type} color={COLORS.SECONDARY} size={24} />
    </div>
  );
};

export default TrainingCardIcon;
