import dayjs from 'dayjs';
import { z } from 'zod';
import {
  ALLOWED_PATH_FILE_FORMATS,
  PATH_FILE_DESCRIPTION_LENGTH,
  PATH_FILE_MAX_SIZE,
  PATH_LINK_DESCRIPTION_LENGTH,
  PATH_MEETING_DESCRIPTION_LENGTH,
  VALIDATION_MESSAGES,
} from '..';
import { PathItemStatuses, PathItemTypes, SkillTypes } from '../enums';

// LINK
export const PathLinkSchema = z.object({
  url: z
    .string()
    .min(1, {
      message: VALIDATION_MESSAGES.REQUIRED,
    })
    .url({ message: VALIDATION_MESSAGES.VALID_LINK }),
  name: z.string().min(1, {
    message: VALIDATION_MESSAGES.REQUIRED,
  }),
  description: z.string().max(PATH_LINK_DESCRIPTION_LENGTH).optional(),
});

export type PathLinkSchemaType = z.infer<typeof PathLinkSchema>;

// MEETING
export const PathMeetingSchema = z.object({
  name: z.string().min(1, {
    message: VALIDATION_MESSAGES.REQUIRED,
  }),
  description: z.string().max(PATH_MEETING_DESCRIPTION_LENGTH).optional(),
  startDateTime: z.coerce
    .date()
    .refine((field) => dayjs(field).isAfter(dayjs(), 'minute'), {
      message: VALIDATION_MESSAGES.DATE_IN_THE_FUTURE,
    }),
});

export type PathMeetingSchemaType = z.infer<typeof PathMeetingSchema>;

// FILE
export const PathFileSchema = z
  .object({
    isCreate: z.boolean(),
    name: z.string().min(1, {
      message: VALIDATION_MESSAGES.REQUIRED,
    }),
    description: z.string().max(PATH_FILE_DESCRIPTION_LENGTH).optional(),
    file: z.any().optional(),
  })
  .refine(
    ({ isCreate, file }) => {
      if (isCreate && !file?.name?.length) return false;

      return true;
    },
    {
      message: VALIDATION_MESSAGES.REQUIRED,
      path: ['file'],
    },
  )
  .refine(
    ({ isCreate, file }) => {
      if (isCreate && !ALLOWED_PATH_FILE_FORMATS.includes(file?.type))
        return false;

      return true;
    },
    {
      message: VALIDATION_MESSAGES.FILE_FORMAT_NOT_ALLOWED,
      path: ['file'],
    },
  )
  .refine(
    ({ isCreate, file }) => {
      if (isCreate && file?.size >= PATH_FILE_MAX_SIZE) return false;

      return true;
    },
    {
      message: VALIDATION_MESSAGES.MAX_FILE_SIZE,
      path: ['file'],
    },
  );

export type PathFileSchemaType = z.infer<typeof PathFileSchema>;

// CONTENT ITEM
export const PathItemSchema = z.object({
  position: z.number().optional(),
  type: z.nativeEnum(PathItemTypes),
  status: z.nativeEnum(PathItemStatuses).optional(),
  displayData: z.object({
    title: z.string(),
    subTitle: z.string().optional(),
    rating: z.number().optional(),
    duration: z.string().optional(),
  }),
  data: z.any(),
});

export type PathItemSchemaType = z.infer<typeof PathItemSchema>;

// PATH
export const PathSchema = z.object({
  name: z.string().min(1, {
    message: VALIDATION_MESSAGES.REQUIRED,
  }),
  description: z.string().optional(),
  type: z.nativeEnum(SkillTypes),
  durationInMonths: z.coerce
    .number()
    .int({
      message: VALIDATION_MESSAGES.WHOLE_NUMBER,
    })
    .nonnegative(),
  items: z.array(PathItemSchema),
});

export type PathSchemaType = z.infer<typeof PathSchema>;
