import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import './TrainingsPage.scss';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Container,
  Fab,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import {
  ROLES,
  PLACEHOLDERS,
  BUTTON_LABELS,
  MODAL_CLOSE_BTNS,
  NOT_FOUND_MESSAGES,
} from '../../cms';
import PrivateTrainingsFilter from './PrivateTrainingsFilter/PrivateTrainingsFilter';
import { getUserRoles } from '../../common/userRoleRetriever';
import { useAppDispatch, useAppSelector } from '../../rtk/hooks';
import { useGetAllTrainingsQuery } from '../../rtk/api/trainingApi';
import {
  Loader,
  SearchField,
  Training,
  TrainingTypes,
} from '../../common/components';
import {
  setTrainingsTypes,
  incrementTrainingsPageNumber,
  resetTrainingsPagination,
  setTrainingsSearchText,
  setTrainingsArePrivate,
} from '../../rtk/features/trainingsPaginationSlice';
import { openModal } from '../../rtk/features/modalsSlice';
import { useDebounce } from '../../common/hooks/useDebounce';
import { ModalTypes, SkillTypes } from '../../cms/enums';

export default function TrainingsPage() {
  const dispatch = useAppDispatch();
  const { isUserLoggedIn } = useAppSelector((state) => state.userSession);
  const trainingsPagination = useAppSelector(
    (state) => state.trainingsPagination,
  );
  const debouncedSearchValue = useDebounce(
    trainingsPagination.searchText.trim().toLowerCase(),
    300,
  );
  const isAdmin = isUserLoggedIn && getUserRoles().includes(ROLES.ADMIN_ROLE);
  const { data: trainingsData, isLoading } = useGetAllTrainingsQuery(
    {
      ...trainingsPagination,
      searchText: debouncedSearchValue,
      onlyPublic: !isAdmin,
      types: trainingsPagination.types.join(','),
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { trainings = [], totalcount = 0 } = trainingsData || {};
  const hasMoreTrainings: boolean =
    totalcount - trainingsPagination.pageNumber * trainingsPagination.pageSize >
    0;

  useEffect(() => {
    return () => {
      dispatch(resetTrainingsPagination());
    };
  }, []);

  const fetchMoreData = () => {
    dispatch(incrementTrainingsPageNumber());
  };

  const handleOnTypesChange = (newTypes: SkillTypes[]) => {
    dispatch(setTrainingsTypes(newTypes));
  };

  const handleIsPrivateChange = (onlyPrivate: boolean) => {
    dispatch(setTrainingsArePrivate(onlyPrivate));
  };

  const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTrainingsSearchText(e.target.value));
  };

  const handleCreateTrainingButtonClick = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.TRAINING_FORM,
        modalData: {},
        closeBtn: MODAL_CLOSE_BTNS.OUTSIDE,
      }),
    );
  };

  return (
    <>
      <Container className="trainings-page" maxWidth="xl">
        <Stack
          direction="row"
          className="trainings-page-header"
          spacing={{ xs: 2, sm: 4 }}
          alignItems="center"
          flexWrap="wrap"
          useFlexGap
        >
          <SearchField
            placeholder={PLACEHOLDERS.SEARCH_TRAININGS}
            value={trainingsPagination.searchText}
            onChange={handleSearchValueChange}
            className="trainings-page-search"
            sx={{
              width: { xs: '100%', sm: 300 },
            }}
          />
          <TrainingTypes
            values={trainingsPagination.types}
            onChange={handleOnTypesChange}
          />
          {isAdmin && (
            <PrivateTrainingsFilter
              setIsPrivateFilterSelected={handleIsPrivateChange}
              isPrivateFilterSelected={trainingsPagination.onlyPrivate}
            />
          )}
          <Button
            startIcon={<AddCircleIcon />}
            variant="contained"
            className="create-training-btn"
            sx={{
              display: {
                xs: 'none',
                sm: 'flex',
              },
            }}
            onClick={handleCreateTrainingButtonClick}
          >
            {BUTTON_LABELS.CREATE_TRAINING}
          </Button>
          <Fab
            size="large"
            color="primary"
            aria-label="Add Training"
            sx={{
              position: 'absolute',
              bottom: '4rem',
              right: '1.5rem',
              display: {
                xs: 'flex',
                sm: 'none',
              },
            }}
            onClick={handleCreateTrainingButtonClick}
          >
            <AddIcon />
          </Fab>
        </Stack>
        <Stack direction="row">
          {isLoading && (
            <Box className="trainings-container-loading">
              <Loader />
            </Box>
          )}
          {!isLoading && (
            <Box className="trainings-container">
              {trainings.length === 0 ? (
                <Typography className="trainings-page-not-found">
                  {NOT_FOUND_MESSAGES.TRAININGS}
                </Typography>
              ) : (
                <InfiniteScroll
                  className="trainings-page-infinite-scroll"
                  dataLength={trainings.length}
                  next={fetchMoreData}
                  hasMore={hasMoreTrainings}
                  loader={<Loader />}
                  scrollableTarget="scrollableDiv"
                >
                  <Grid container spacing={4}>
                    {trainings.map((training) => (
                      <Grid
                        item
                        key={training.id}
                        title={training.title}
                        xs={12}
                        sm={4}
                        md={3}
                        lg={2.3}
                        xl={2}
                      >
                        <Training training={training} />
                      </Grid>
                    ))}
                  </Grid>
                </InfiniteScroll>
              )}
              <span id="end-of-trainings" />
            </Box>
          )}
        </Stack>
      </Container>
    </>
  );
}
