import { CREATE_GROUP_REQUEST_SUCCESS } from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/CreateGroupButton/CreateGroupForm/types';
import { GET_ALL_GROUPS_REQUEST_SUCCESS } from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/types';
import { GET_GROUPS_REQUEST_SUCCESS } from '../pages/AdministrationPage/tabs/GroupManagementTab/GetAllGroups/types';
import { GET_GROUP_REQUEST_SUCCESS } from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/GroupAccordeon/GroupDetails/types';
import {
  EDIT_GROUP_REQUEST_SUCCESS,
  EDIT_GROUP_REQUEST,
} from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/GroupAccordeon/GroupDetails/EditGroup/types';
import {
  ADD_MEMBERS_TO_GROUP_REQUEST_SUCCESS,
  ADD_MEMBERS_TO_GROUP_REQUEST,
} from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/GroupAccordeon/GroupDetails/AddMembersToGroup/types';
import {
  ASSIGN_TRAININGS_TO_GROUP_REQUEST_SUCCESS,
  ASSIGN_TRAININGS_TO_GROUP_REQUEST,
} from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/GroupAccordeon/GroupDetails/AddTrainingsToGroup/types';
import {
  REMOVE_MEMBER_FROM_GROUP_REQUEST_SUCCESS,
  REMOVE_MEMBER_FROM_GROUP_REQUEST,
} from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/GroupAccordeon/GroupDetails/RemoveMemberFromGroup/types';
import {
  REMOVE_TRAINING_FROM_GROUP_REQUEST_SUCCESS,
  REMOVE_TRAINING_FROM_GROUP_REQUEST,
} from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/GroupAccordeon/GroupDetails/RemoveTrainingFromGroup/types';
import { DELETE_GROUP_REQUEST_SUCCESS } from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupsOverview/GroupAccordeon/GroupDetails/DeleteGroup/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  groups: [],
  allGroups: [],
  group: {},
  createSuccess: false,
  createdGroupId: '',
  editSuccess: false,
  addMembersSuccess: false,
  assignGroupTrainingsSuccess: false,
  removeMemberSuccess: false,
  removeTrainingSuccess: false,
  deleteSuccess: false,
  deletedGroupId: '',
  lastEditedGroup: {},
  lastAddMembersToGroup: {},
  lastAssignTrainingsToGroup: {},
  lastRemovedMemberfromGroup: {},
  lastRemovedTrainingfromGroup: {},
};

/** events reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function groupsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_GROUPS_REQUEST_SUCCESS:
      return {
        ...state,
        groups: action.payload.groups,
        createSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        addMembersSuccess: false,
      };
    case GET_GROUPS_REQUEST_SUCCESS:
      return {
        ...state,
        allGroups: action.payload.groups,
      };
    case GET_GROUP_REQUEST_SUCCESS:
      return {
        ...state,
        group: action.payload.group,
        createSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        addMembersSuccess: false,
        removeMemberSuccess: false,
      };
    case CREATE_GROUP_REQUEST_SUCCESS:
      return {
        ...state,
        createSuccess: action.payload.isSuccessful,
        createdGroupId: action.payload.createdGroupId,
        editSuccess: false,
        deleteSuccess: false,
      };
    case EDIT_GROUP_REQUEST_SUCCESS:
      return {
        ...state,
        createSuccess: false,
        editSuccess: true,
        deleteSuccess: false,
      };
    case EDIT_GROUP_REQUEST:
      return {
        ...state,
        lastEditedGroup: action.payload.lastEditedGroupModel,
      };
    case ADD_MEMBERS_TO_GROUP_REQUEST_SUCCESS:
      return {
        ...state,
        createSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        addMembersSuccess: true,
      };
    case ADD_MEMBERS_TO_GROUP_REQUEST:
      return {
        ...state,
        lastAddMembersToGroup: action.payload.lastAddMembersToGroupModel,
      };
    case ASSIGN_TRAININGS_TO_GROUP_REQUEST_SUCCESS:
      return {
        ...state,
        createSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        addMembersSuccess: false,
        assignGroupTrainingsSuccess: true,
      };
    case ASSIGN_TRAININGS_TO_GROUP_REQUEST:
      return {
        ...state,
        lastAssignTrainingsToGroup:
          action.payload.lastAssignTrainingsToGroupModel,
      };
    case REMOVE_MEMBER_FROM_GROUP_REQUEST_SUCCESS:
      return {
        ...state,
        createSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        addMembersSuccess: false,
        removeMemberSuccess: true,
      };
    case REMOVE_MEMBER_FROM_GROUP_REQUEST:
      return {
        ...state,
        lastRemovedMemberfromGroup: action.payload.removeMemberModel,
      };
    case REMOVE_TRAINING_FROM_GROUP_REQUEST_SUCCESS:
      return {
        ...state,
        createSuccess: false,
        editSuccess: false,
        deleteSuccess: false,
        addMembersSuccess: false,
        removeMemberSuccess: false,
        removeTrainingSuccess: true,
      };
    case REMOVE_TRAINING_FROM_GROUP_REQUEST:
      return {
        ...state,
        lastRemovedTrainingfromGroup: action.payload.removeTrainingModel,
      };
    case DELETE_GROUP_REQUEST_SUCCESS:
      return {
        ...state,
        deleteSuccess: action.payload.isSuccessful,
        deletedGroupId: action.payload.entryId,
      };
    default:
      return {
        ...state,
        editSuccess: false,
        addMembersSuccess: false,
        assignGroupTrainingsSuccess: false,
        removeMemberSuccess: false,
        removeTrainingSuccess: false,
        deleteSuccess: false,
      };
  }
}

export default groupsReducer;
