import { DataLayerData } from '../../cms/types';

export const useDataLayerEvent = () => {
  const sendDataLayerEvent = (data: DataLayerData) => {
    if (window.dataLayer) {
      window.dataLayer.push(data);
    }
  };

  return sendDataLayerEvent;
};
