import { useState } from 'react';
import {
  LandingPageSections,
  SCREEN_SIZES,
  SUPPORT_EMAIL,
} from '../../cms/index';
import PricingCard from './PricingCard/PricingCard';
import './PricingSection.scss';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import { addSmallStyle } from '../../cms/Utils';
import { Pricing, PricingOption } from './types';
import { PaymentForm } from '../../forms';
import { BaseModal } from '../../common/components';

const PricingSection = () => {
  const isScreenSmall = useMediaQuery(SCREEN_SIZES.SMALL);
  const [selectedPricing, setSelectedPricing] = useState<Pricing | null>(null);

  const pricings = [
    {
      title: '0 to 10 Users',
      price: 39.9,
      pricePerUser: 3.99,
      plan: 'monthly',
      buttonText: 'Select',
      pricingOption: PricingOption.Tier1,
    },
    {
      title: '11 to 50 Users',
      price: 175,
      pricePerUser: 3.5,
      plan: 'monthly',
      buttonText: 'Select',
      pricingOption: PricingOption.Tier2,
    },
    {
      title: '51 to 100 Users',
      price: 299,
      pricePerUser: 2.99,
      plan: 'monthly',
      buttonText: 'Select',
      pricingOption: PricingOption.Tier3,
    },
    {
      title: 'Above 100 Users',
      buttonText: 'Select',
      onClick: () => window.open(`mailto:${SUPPORT_EMAIL}`),
      pricingOption: PricingOption.Tier4,
    },
  ];

  const handleOnPricingClick = (pricing: Pricing) => {
    if (pricing.onClick) pricing.onClick();
    else setSelectedPricing(pricing);
  };

  return (
    <section
      id={LandingPageSections.PRICING}
      className={`pricing-section-container${addSmallStyle(isScreenSmall)}`}
    >
      <h2 className="pricing-section-title">Pricing</h2>
      <p className="pricing-section-sub-title">
        Our payment structure is based on the number of users. Please choose the
        plan that aligns with the size of your company.
      </p>
      <div
        className={`pricing-section-items-container${addSmallStyle(
          isScreenSmall,
        )}`}
      >
        {pricings.map((pricing) => (
          <PricingCard
            key={pricing.title}
            item={pricing}
            onClick={handleOnPricingClick}
          />
        ))}
      </div>
      <BaseModal
        open={selectedPricing != null}
        onClose={() => setSelectedPricing(null)}
      >
        <PaymentForm
          onClose={() => setSelectedPricing(null)}
          pricing={selectedPricing!}
        />
      </BaseModal>
    </section>
  );
};

export default PricingSection;
