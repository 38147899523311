import { CONTACT_US_URL } from '../../api/trainingsPortalBackend/config';
import { ContactUsModel } from '../../cms/types';
import { api } from './api';

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    contactUs: build.mutation<any, ContactUsModel>({
      query: (model) => ({
        url: CONTACT_US_URL,
        method: 'POST',
        body: model,
      }),
    }),
  }),
});

export const { useContactUsMutation } = extendedApi;
