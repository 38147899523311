import { Stack, Typography } from '@mui/material';
import './EventDetailsItem.scss';
import { toKebabCase } from '../../../common/validation/helpers';

type EventDetailsItemProps = {
  item: {
    name: string;
    icon: JSX.Element;
    value: string;
  };
};

const EventDetailsItem = ({ item }: EventDetailsItemProps) => {
  const { name, icon, value } = item;

  return (
    <Stack direction="column" gap="0.5rem">
      <Typography className="event-details-name">{name}:</Typography>
      <Stack direction="row" gap="0.5rem" alignItems="center">
        {icon}
        <Typography className={("event-" + toKebabCase(item.name)) + " event-details-value"}>{value}</Typography>
      </Stack>
    </Stack>
  );
};

export default EventDetailsItem;
