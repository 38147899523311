import { useSearchParams } from 'react-router-dom';
import { Box, Chip, Rating, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import './Training.scss';
import { APPLICATION_STATUSES } from '../../../cms/index';
import TrainingCardIcon from './TrainingCardIcon/TrainingCardIcon';
import {
  retrieveExternalProvider,
  generateSkillTypeImage,
  getApplicationsStatusColor,
} from '../../../cms/Utils';
import { ApplicationStatuses } from '../../../cms/enums';
import { TrainingShortResponse } from '../../../cms/types/trainingTypes';

type TrainingProps = {
  training: TrainingShortResponse;
  applicationStatus?: ApplicationStatuses;
};

const Training = ({ training, applicationStatus }: TrainingProps) => {
  const [, setSearchParams] = useSearchParams();
  const { title, rating, type, cost } = training;
  const externalProvider = retrieveExternalProvider(training.url);
  const image = generateSkillTypeImage(externalProvider ?? type);
  const statusColor =
    applicationStatus && getApplicationsStatusColor(applicationStatus);
  const isExpired =
    training?.endDate !== null &&
    dayjs(training?.endDate).isBefore(dayjs(), 'date');

  const onTrainingCardClick = () => {
    setSearchParams((prev) => {
      prev.set('trainingId', training.id.toString());
      return prev;
    });
  };

  return (
    <Box
      className={`training-card-outer ${
        (training.isArchived || isExpired) && 'training-card-outer-archived'
      }`}
    >
      <TrainingCardIcon type={type} />

      {applicationStatus && (
        <Typography
          className="training-application-status"
          sx={{ backgroundColor: statusColor }}
        >
          {
            Object.values(APPLICATION_STATUSES).find(
              (s) => s.value === applicationStatus,
            )?.label
          }
        </Typography>
      )}

      <Box onClick={onTrainingCardClick} className="training-card">
        <img className="training-card-image" src={image.src} alt={image.alt} />
        <Box className="training-card-content">
          <Stack className="training-card-content-text" gap="0.5rem">
            <Typography noWrap className="training-card-title">
              {title}
            </Typography>

            <Box className="training-card-rating">
              {rating > 0 && (
                <>
                  <Rating
                    name="read-only"
                    value={rating}
                    precision={0.5}
                    size="small"
                    readOnly
                  />
                  <p>{rating}</p>
                </>
              )}
            </Box>
          </Stack>

          {cost > 0 ? (
            <Chip
              label="Paid"
              color="primary"
              className="training-card-price"
              size="small"
            />
          ) : (
            <Chip
              label="Free"
              color="success"
              className="training-card-price"
              size="small"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Training;
