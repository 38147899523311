import {
  USER_IMPORT_DEFAULT,
  IMPORT_USERS_CSV_REQUEST_ERROR,
  IMPORT_USERS_CSV_REQUEST_SUCCESS,
} from '../pages/AdministrationPage/tabs/EmployeeManagementTab/EmployeesTable/EmployeesTableHeader/UserImport/UserImportModalContent/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  isSuccessful: false,
  errorMessage: '',
};

/** events reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function importUsersReducer(state = initialState, action) {
  switch (action.type) {
    case IMPORT_USERS_CSV_REQUEST_SUCCESS:
      return {
        ...state,
        isSuccessful: action.payload.isSuccessful,
        errorMessage: '',
      };
    case IMPORT_USERS_CSV_REQUEST_ERROR:
      return {
        ...state,
        isSuccessful: false,
        errorMessage: action.payload,
      };
    case USER_IMPORT_DEFAULT:
      return {
        ...state,
        isSuccessful: false,
        errorMessage: '',
      };
    default:
      return {
        ...state,
        isSuccessful: false,
        errorMessage: '',
      };
  }
}

export default importUsersReducer;
