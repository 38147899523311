import EmployeesTable from './EmployeesTable/EmployeesTable';
import './EmployeeManagementTab.scss';

export default function EmployeeManagementTab() {
  return (
    <div className="applications-container">
      <EmployeesTable />
    </div>
  );
}
