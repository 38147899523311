import { PropsWithChildren, UIEvent } from 'react';
import { Stack, Typography } from '@mui/material';
import { Loader } from '../index';
import './InfiniteScrollList.scss';

type InfiniteScrollListProps = {
  className?: string;
  emptyMessage: string;
  currentCount: number;
  totalCount: number;
  isFetching: boolean;
  fetchData: () => void;
};

const InfiniteScrollList = ({
  className,
  emptyMessage,
  currentCount,
  totalCount,
  isFetching,
  fetchData,
  children,
}: PropsWithChildren<InfiniteScrollListProps>) => {
  const hasMore = currentCount < totalCount;

  const handleOnScroll = (event: UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;

    if (
      hasMore &&
      !isFetching &&
      Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) <=
        1
    )
      fetchData();
  };

  if (currentCount === 0 && !isFetching)
    return (
      <Typography className="infinite-scroll-list-empty">
        {emptyMessage}
      </Typography>
    );

  return (
    <Stack
      spacing="1.25rem"
      className={`infinite-scroll-list ${className}`}
      onScroll={handleOnScroll}
    >
      {children}
      {isFetching && <Loader />}
    </Stack>
  );
};

export default InfiniteScrollList;
