import { isEmpty } from 'lodash';
import { put, select } from 'redux-saga/effects';
import { applicationVersionNumberSelect } from '../../../selectors/application_version_number.js';
import { updateApplicationVersionNumber } from './actions.js';
import { resetApplicationStoreWithoutUserSession } from '../../redux/purgeStoreActions.js';

export default function* applicationCacheSaga() {
  while (true) {
    // Redux Saga allows to *sometimes* and preferrably rarely
    // access values from the Redux Store directly from a Saga.
    // It is a good practice to decentralize the access to store
    // properties in a selectors/ folder so that it is more transparent
    // where those interactions are happening and to extract them
    // from the Saga itself as in the case below.
    const lastApplicationVersionInLocalStorage = yield select(
      applicationVersionNumberSelect,
    );

    const envVariablesPassed = process.env;
    const currentApplicationVersionFromPackageJson =
      envVariablesPassed.REACT_APP_VERSION;

    const isVersionOutdated =
      isEmpty(lastApplicationVersionInLocalStorage) ||
      currentApplicationVersionFromPackageJson !==
        lastApplicationVersionInLocalStorage;
    if (isVersionOutdated) {
      yield put(
        updateApplicationVersionNumber(
          currentApplicationVersionFromPackageJson,
        ),
      );
      yield put(resetApplicationStoreWithoutUserSession()); // this will delete everything in the store apart from the application version information
    }
  }
}
