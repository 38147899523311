import { Link, useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import {
  LANDING_PAGE_FOOTER_LABELS,
  ROUTE_LINKS,
  SCREEN_SIZES,
} from '../../cms/index';
import UpskillPortalLogo from '../UpskillPortalLogo/UpskillPortalLogo';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import { addSmallStyle } from '../../cms/Utils';
import { useAppDispatch } from '../../rtk/hooks';
import { openModal } from '../../rtk/features/modalsSlice';
import './LandingFooter.scss';
import { ModalTypes } from '../../cms/enums';

const LandingFooter = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isScreenSmall = useMediaQuery(SCREEN_SIZES.SMALL);

  const handleOpenLogin = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.LOGIN_FORM,
        modalData: null,
        closeBtn: 1,
      }),
    );
  };

  const handleOpenRequestDemo = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.REQUEST_DEMO_FORM,
        modalData: null,
        closeBtn: 1,
      }),
    );
  };

  return (
    <footer
      className={`landing-footer-container${addSmallStyle(isScreenSmall)}`}
    >
      <div className="footer-main">
        <div>
          <UpskillPortalLogo />
          <p>{LANDING_PAGE_FOOTER_LABELS.FOOTER_SUB_TITLE}</p>
        </div>

        <nav>
          <ul>
            <h5>Access</h5>
            <li>
              <button onClick={handleOpenLogin} className="landing-footer-btn">
                {LANDING_PAGE_FOOTER_LABELS.LOGIN}
              </button>
            </li>
            <li>
              <button
                className="landing-footer-btn"
                onClick={handleOpenRequestDemo}
              >
                {LANDING_PAGE_FOOTER_LABELS.REQUEST_FREE_TRIAL}
              </button>
            </li>
          </ul>

          <ul>
            <h5>Company</h5>
            <li>
              <button
                onClick={() => navigate('/landing#aboutUs')}
                className="landing-footer-btn"
              >
                {LANDING_PAGE_FOOTER_LABELS.ABOUT_US}
              </button>
            </li>
            <li>
              <button
                onClick={() => navigate('/landing#contact')}
                className="landing-footer-btn"
              >
                {LANDING_PAGE_FOOTER_LABELS.CONTACT}
              </button>
            </li>
          </ul>
        </nav>
      </div>

      <hr />

      <Stack className="footer-policy">
        <Typography>
          <Typography component="span" className="ltd">
            {LANDING_PAGE_FOOTER_LABELS.STRPYES_LTD}
          </Typography>{' '}
          {LANDING_PAGE_FOOTER_LABELS.ALL_RIGHTS_RESERVED}
        </Typography>

        <Stack
          direction="row"
          columnGap="1rem"
          alignItems="center"
          flexWrap="wrap"
        >
          <Typography>{LANDING_PAGE_FOOTER_LABELS.PLEASE_NOTE}</Typography>
          <Link
            to={ROUTE_LINKS.COOKIE_POLICY}
            state={{ navBarItem: LANDING_PAGE_FOOTER_LABELS.COOKIE_POLICY }}
          >
            {LANDING_PAGE_FOOTER_LABELS.COOKIE_POLICY}
          </Link>
          <Link
            to={ROUTE_LINKS.PRIVACY_NOTICE}
            state={{ navBarItem: LANDING_PAGE_FOOTER_LABELS.PRIVACY_POLICY }}
          >
            {LANDING_PAGE_FOOTER_LABELS.PRIVACY_POLICY}
          </Link>
          <Link
            to="/tos"
            state={{ navBarItem: LANDING_PAGE_FOOTER_LABELS.TERMS_OF_SERVICE }}
          >
            {LANDING_PAGE_FOOTER_LABELS.TERMS_OF_SERVICE}
          </Link>
        </Stack>
      </Stack>
    </footer>
  );
};

export default LandingFooter;
