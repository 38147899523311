import { CSSInterpolation } from '@mui/material';
import personalizedDashboardImage from '../common/images/landing-page-feature-card-dashboard.svg';
import buildYourOwnLibraryImage from '../common/images/landing-page-feature-card-build-your-own-library.svg';
import careerPathImage from '../common/images/landing-page-feature-card-clear-carrer-paths.svg';
import progressOverviewImage from '../common/images/landing-page-progress-overview.svg';
import singleSignOnImage from '../common/images/landing-page-feature-card-single-sign-on.svg';
import productAdminImage from '../common/images/landing-page-product-admin.svg';
import productManagerImage from '../common/images/landing-page-product-manager.svg';
import productEmployeeImage from '../common/images/landing-page-product-employee.svg';
import {
  ApplicationStatuses,
  DemoRequestStatuses,
  LocationType,
  MyTrainingsAndEventsTypes,
  PathItemStatuses,
  PathStatuses,
  SkillTypes,
} from './enums';

export const STATUS_CODES = {
  OK: 200,
};

export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
};

export const ROLES = {
  ADMIN_ROLE: 'Administrator',
  MANAGER_ROLE: 'Manager',
  SUPER_ADMIN_ROLE: 'SuperAdministrator',
};

export const ROUTE_LINKS = {
  DEFAULT: '/',
  PATHS: '/paths',
  ASSIGN_PATHS: '/paths/assign',
  PERSONAL_PATHS: '/paths/personal',
  CREATE_PATH: '/paths/create',
  EDIT_PATH: '/paths/edit',
  MY_LEARNING_HUB: '/my-learning-hub',
  MY_TRAININGS_AND_EVENTS: '/my-learning-hub/my-trainings-and-events',
  MY_PATHS: '/my-learning-hub/my-paths',
  TRAININGS: '/trainings',
  EVENTS: '/events',
  MY_GROUP: '/my-group',
  MY_EMPLOYEES: '/my-group/my-employees',
  REQUESTS: '/my-group/requests',
  ADMINISTRATION: '/administration',
  TRAINING_APPLICATIONS: '/administration/training-applications',
  EVENT_APPLICATIONS: '/administration/event-applications',
  GROUP_MANAGEMENT: '/administration/group-management',
  EMPLOYEE_MANAGEMENT: '/administration/employee-management',
  REPORTS: '/administration/reports',
  SUBMISSIONS: '/administration/submissions',
  LOGIN: '/login',
  LANDING: '/landing',
  PRIVACY_NOTICE: '/privacy-notice',
  COOKIE_POLICY: '/cookie-policy',
  TOS: '/tos',
};

export const DATE_FORMATS = {
  SHORT_YEAR: 'YYYY',
  SHORT_MONTH: 'MMM',
  SHORT_DAY: 'D',
  SHORT_DAY_MONTH: 'D MMM',
  SHORT_DAY_MONTH_YEAR: 'D MMM YYYY',
  HOUR_MINUTES: 'H:mm',
  NORMAL: 'MMM D, YYYY',
  DATE_TIME: 'MMM D, YYYY H:mm',
  NORMAL_DAY_MONTH: 'MMM D',
  SIMPLE_DASH: 'DD-MM-YYYY',
  SIMPLE_SLASH: 'DD/MM/YYYY',
  SIMPLE_SLASH_SHORT: 'M/D/YYYY',
  FULL: 'MMMM Do YYYY, H:mm',
};

export const TIME = {
  MINUTES: 'minutes',
};

export const COLORS = {
  ERROR: '#F93C3C',
  ERROR_LIGHT: '#fee2e2',
  MINE_SHAFT_COLOR: '#252525',
  SECONDARY: '#00AAC2',
  SECONDARY_LIGHTER: '#d9f2f6',
  PRIMARY: '#EB6012',
  MANGO: '#FEE2E2',
  DARK_SILVER: '#B8B8B8',
  LIGHT_SILVER: '#b8b8b81c',
  GRAY: '#929292',
  TEXT: '#424242',
  DIVIDER: '#C4C4C4',
  BLACK: '#000',
  WHITE: '#FFF',
  ELECTRIC_VIOLET: '#4A38FF',
  WEST_SIDE: '#FE9C0E',
  CHATHAMS_BLUE: '#0F596E',
  LIGHT_BLUE: '#E5F4F8',
  SCOOTER: '#25C7DD',
  LIGHT_GRAY: '#eeeeee',
  PALE_VIOLET_RED: '#DA76B4',
  YELLOW: '#FBC646',
  OCEAN_GREEN: '#569B6D',
  SUCCESS: '#00c29b',
  WHITE_METAL: '#d0d0cf',
  ASTRAL: '#30889c',
  INPUT: '#F6F6F9',
  WHITE_SMOKE: '#f5f5f5',
  WARNING: '#FEE60E',
  ICON_SECOND: '#bbc2e1',
};

export const TRAINING_LEVELS = ['Beginner', 'Intermediate', 'Expert'] as const;
export type TrainingLevel = (typeof TRAINING_LEVELS)[number];

export const HEADER_LABELS = {
  DASHBOARD_TEXT: 'Dashboard',
  MAIN_TEXT: 'Upskill Portal',
  TRAININGS_TEXT: 'Trainings',
  EVENTS_TEXT: 'Events',
  PATH_TEXT: 'Path',
  MY_TRAININGS_TEXT: 'My Trainings',
  MY_TRAININGS_EVENTS_TEXT: 'My Trainings and events',
  MY_TRAININGS: 'My Trainings',
  MY_EVENTS: 'My Events',
  ADMINISTRATION_TEXT: 'Administration',
  MY_GROUP_TEXT: 'My Group',
  MY_PATHS_TEXT: 'My Paths',
  PATHS_TEXT: 'Paths',
  CATEGORIES_TEXT: 'Categories',
  FILTER_CRITERIA_TEXT: 'Filter Criteria',
  DEPARTMENTS_TEXT: 'Departments',
  OWNERS_TEXT: 'Owners',
  EMPLOYEES_TEXT: 'Employees',
  FILE_UPLOAD_TEXT: 'File Upload',
  NOTIFICATIONS_TEXT: 'Notifications',
  MY_PROFILE_TEXT: 'My Profile',
  LOGOUT_TEXT: 'Log Out',
  MY_LEARNING_HUB: 'My Learning Hub',
};

export const MY_LEARNING_HUB_LABELS = {
  MY_TRAININGS_AND_EVENTS: 'My Trainings And Events',
  MY_PATHS: 'My Paths',
  TRAININGS_AND_EVENTS: 'Trainings And Events',
  PATHS: 'Paths',
};

export const ADMIN_NAV_LABELS = {
  MY_TEAM_TEXT: 'My Team',
  APPLICATIONS_TEXT: 'Training Applications',
  EVENT_APPLICATIONS: 'Event Applications',
  EXPIRED_TRAININGS_TEXT: 'Expired Trainings',
  GROUP_MANAGEMENT_TEXT: 'Group Management',
  EMPLOYEE_MANAGEMENT_TEXT: 'Employee Management',
  MY_GROUP_TEXT: 'My Group',
  GROUPS_TEXT: 'Groups',
  COURSES_TEXT: 'Trainings',
  COSTS_TEXT: 'Costs',
  SUBMISSIONS: 'Submissions',
  REPORTS: 'Reports',
};

export const MY_GROUP_LABELS = {
  MY_EMPLOYEES: 'My Employees',
  REQUESTS: 'Requests',
  SEARCH_EMPLOYEE_PLACEHOLDER: 'Search employee by name...',
};

export const EXPIRED_TRAININGS = {
  RETRIEVE_EXPIRED_TRAININGS: 'Retrieve Expired Trainings',
  NO_RESULTS_TO_DISPLAY: 'No results are available',
  SLOW_REQUEST_WARNING:
    'Please be aware that this request would take some time to complete. Estimated time remaining: ',
};

export const PRICE_LABELS = {
  FREE_TEXT: 'Free',
  PAID_TEXT: 'Paid',
  PRICE_TEXT: 'Price',
};

export const TRAINER_DETAILS = {
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  JOB_TITLE: 'Job',
  COMPANY: 'Company',
  SPEAKER_PHOTO: 'Add Speaker Photo',
};

export const TRAINING_DETAILS = {
  ADDRESS_TEXT: 'Address',
  LEVEL_TEXT: 'Level',
  TOTAL_DURATION_TEXT: 'Total duration',
  LESSONS_TEXT: 'Lessons',
  TRAINING_TITLE_TEXT: 'Training Title',
  TITLE_TEXT: 'Title',
  TRAINER_TEXT: 'Trainer',
  DATES_TEXT: 'Dates',
  LOCATION_TEXT: 'Location Type',
  PARTICIPANTS_TEXT: 'Participants',
  DETAILS_TEXT: 'Details',
  DESCRIPTION: 'Description',
  ASSIGN_TRAINING_TO_TEXT: 'Assign Training To:',
  ASSIGN_TO_A_GROUP: 'Assign to a group',
  ADD_ADDITIONAL_DETAILS: 'Add additional details',
  MARK_AS_PRIVATE: 'Mark As Private',
  CREATE_TRAINING: 'Create Training',
  EDIT_TRAINING: 'Edit Training',
  DUPLICATE_TRAINING: 'Duplicate Training',
  EMPLOYEE: 'Employee',
  GROUP: 'Group',
  LIMIT_LABEL: 'Applications limit',
};

export const DATES_LABELS = {
  START_DATE_TEXT: 'Start Date',
  END_DATE_TEXT: 'End Date',
  APPLY_BEFORE_TEXT: 'Apply Before',
};

export const EXTERNAL_PROVIDERS = {
  PLURALSIGHT: 'pluralsight',
  UDEMY: 'udemy',
};

export const BUTTON_LABELS = {
  APPLY: 'Apply',
  LOGIN_BUTTON: 'Login',
  REJECT_BUTTON: 'Reject',
  CANCEL: 'Cancel',
  DELETE_BUTTON: 'Delete',
  APPROVE_BUTTON: 'Approve',
  SAVE_BUTTON: 'Save',
  ASSIGN_BUTTON: 'Assign',
  ASSIGN_TRAINING: 'Assign Training',
  NEW_TRAINING: 'Add Training',
  NEW_EVENT: 'Add Event',
  CREATE_TRAINING: 'Add Training',
  EDIT_TRAINING: 'Save Changes',
  DUPLICATE_TRAINING: 'Duplicate Training',
  COMPLETE: 'Complete',
  SUBMIT_BUTTON: 'Submit',
  START: 'Start',
  DROPOUT: 'Drop out',
  DROP: 'Drop',
  DUPLICATE_EVENT: 'Duplicate Event',
  CREATE_GROUP: 'Create Group',
  EDIT_GROUP: 'Save Changes',
  SAVE_CHANGES: 'Save Changes',
  DELETE_MULTIPLE_GROUPS: 'Delete Selected Groups',
  DELETE_MULTIPLE_DEMO_REQUESTS: 'Delete Marked Submissions',
  SIGN_IN_WITH_MICROSOFT: 'Sign in with Microsoft',
  IMPORT_EMPLOYEES_DATA: 'Import Employees Data',
  ARCHIVE_EMPLOYEES_BUTTON: 'Archive Deactivated Employees',
  ARCHIVE_BUTTON: 'Archive',
  EXPORT: 'Export To Csv',
  BROWSE: 'Browse File',
  ATTACH: 'Attach',
  SKIP: 'Skip',
  DEACTIVATE: 'Deactivate',
  NO: 'No',
  YES: 'Yes',
  GOT_IT: 'Got It',
  CREATE_ANYWAY: 'Create Anyway',
  APPLY_ANYWAY: 'Apply Anyway',
  DROP_IT: 'Drop It',
};

export const CHECKBOX_LABELS = {
  SELECT_ALL: 'Select all',
};

export const NOTIFICATION_LABELS = {
  SUCCESS_TITLE: 'Success',
  TRAININGS_HAVE_BEEN_ASSIGNED: 'Trainings have been assigned.',
  TRAINING_HAS_BEEN_CREATED: 'You have successfully created a training.',
  TRAINING_HAS_BEEN_DUPLICATED:
    'You have successfully duplicated the training.',
  EVENT_HAS_BEEN_CREATED: 'You have successfully created an event.',
  EVENT_HAS_BEEN_DUPLICATED: 'You have successfully duplicated the event.',
  EVENT_HAS_BEEN_EDITED: 'You have successfully edited an event.',
  GROUP_HAS_BEEN_CREATED: 'You have successfully created a group.',
  GROUP_HAS_BEEN_EDITED: 'You have successfully edited a group.',
  ADD_MEMBERS_TO_GROUP: 'You have successfully added members to a group',
  ASSIGN_TRAININGS_TO_GROUP:
    'You have successfully assigned trainings to a group',
  TRAINING_CREATION_ERROR_OCCURED:
    'An error occured during the creation of this training.',
  UPDATED_JUST_NOW: 'Just now',
  TRAINING_HAVE_BEEN_EDITED: 'You have successfully edited the training.',
  TRAINING_HAVE_BEEN_RATED: 'You have successfully rated the training.',
  TRAINING_RATING_ERROR: 'An error occured, please try again.',
  DELETE_MULTIPLE_GROUPS: 'You have successfully deleted selected Groups.',
  DELETE_MULTIPLE_DEMO_REQUESTS:
    'You have successfully deleted selected submissions.',
};

export const TRAINING_TYPE_LABELS = {
  ALL: 'All',
};

export const VIEW_MORE = {
  ITEMS_PER_PAGE: 100,
};

export const MODAL_VALUES = {
  MODAL_CLOSE_TIMEOUT: 1000,
  MODAL_CLOSE_FIVE_SECONDS_TIMEOUT: 5000,
  MODAL_CLOSE_TEN_SECONDS_TIMEOUT: 10000,
  MODAL_CLOSE_TWENTY_SECONDS_TIMEOUT: 20000,
};

export const MAX_LENGTHS = {
  TITLE_MAX_LENGTH: 150,
  PATH_NAME_MAX_LENGTH: 150,
};

export const PLACEHOLDERS = {
  SITE_SEARCH: 'Search',
  SEARCH_TRAININGS: 'Search trainings...',
  SEARCH_EVENTS: 'Search events...',
  SEARCH_PATH: 'Search path...',
  SEARCH_EMPLOYEES: 'Search employee...',
  EMPLOYEE_SEARCH: 'Search by name, job title or department...',
};

export const DESCRIPTIONS = {
  SEARCH_TRAININGS_DESCRIPTION:
    'You can filter trainings by title or description.',
};

export const TRAINING_COUNT_LABELS = {
  TITLE: 'My Trainings',
  APPROVED_TRAININGS: 'approved',
  COMPLETED_TRAININGS: 'completed',
  INPROGRESS_TRAININGS: 'in progress',
  VIEW_COURSES_BUTTON: 'View Trainings',
};
export const LANDING_PAGE_LABELS = {
  DELAYED_TRAININGS: 'delayed',
  TRAININGS: 'trainings.',
  DELAYED_TEXT: 'You have',
};

export const CONTACT_FORM_LABELS = {
  SUBMIT_ERROR: 'There was a problem with your submission, please try again.',
};

export const SKILLS_PROGRESS_LABELS = {
  TITLE: 'Skills',
};

export const MY_PATH_LABELS = {
  TITLE: 'My Path',
};

export const MY_EMPLOYEES_LABELS = {
  MY_EMPLOYEES_TEXT: 'My Employees',
  ADD_EMPLOYEE_BUTTON_TEXT: 'Add Employee',
  ASSIGN_PATH: 'Assign',
  APPROVED_TEXT: 'Approved',
  SPENT_TEXT: 'Spent',
  APPLICATIONS_TEXT: 'Applications',
  HAS_APPLIED_FOR_TEXT: 'has applied for',
  ASSIGN_PATH_TEXT: 'Assign path',
  VIEW_PATH_TEXT: 'View path',
  REMOVE_EMPLOYEE_TEXT:
    'Are you sure you want to remove the selected employee from your team?',
  ASSIGN_TRAINING_SUCCESS: 'You successfully assigned the trainings!',
  ASSIGN_EVENT_SUCCESS: 'You successfully assigned the event!',
  ASSIGN_PATH_SUCCESS: 'You successfully assigned the path!',
};

export const MY_APPLICATIONS_LABELS = {
  MY_APPS_TEXT: 'My Applications',
};

export const PATH_CATEGORY_TYPES = {
  ALL_PATHS: 'All Paths',
  PERSONAL: 'Personal',
  INTERNAL: 'Internal',
  CAREER: 'Career',
  DOMAIN: 'Domain',
};

export const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const MY_PATHS_LABELS = {
  PATHS_TEXT: 'Paths',
};

export const PATHS_LABELS = {
  NEW_PATH_TEXT: 'New Path',
  DELETE_PATH: 'Delete Path',
  SAVE_PATH: 'Save Path',
  CREATED_BY: 'Created by: ',
  EDIT_PATH_TEXT: 'Edit path',
  DELETE_CONFIRMATION_TEXT: 'Are you sure you would like to delete this path?',
  DROP_CONFIRMATION_TEXT: 'Are you sure you would like to drop this path?',
  SINGLE_UNASSIGN_CONFIRMATION_TEXT:
    'Are you sure you want to remove this employee from the path?',
  MULTIPLE_UNASSIGN_CONFIRMATION_TEXT:
    'Are you sure you want to remove these employees from the path?',
  DELETE_PATH_TEXT: 'You have successfully deleted the path.',
  EDITED_PATH_TEXT: 'You have successfully edited the path.',
  CREATED_PATH_TEXT: 'You have successfully created a path.',
  SELECT_PATH_TRAININGS_TEXT: 'Select Path Trainings',
  PATH_NAME_PLACEHOLDER: 'Path Name',
  TRAININGS_SELECTED_TEXT: ' trainings selected',
  YOU_ARE_EDITING: 'You are editing',
  USERS_PATH_TEXT: 'path',
  SELECT_TRAININGS: 'Select trainings',
  SELECT_TRAININGS_FROM_LEFT: 'Select trainings from the list on the left.',
  CONTENT: 'Content',
  ASSIGNEES: 'Assignees',
  EMPTY: 'The path is currently empty.',
  PATH_CONTENT_ITEM_TITLE: 'Content Item',
};

export const SET_PATH_WIZARD_LABELS = {
  SELECTED_TEXT: 'selected',
  CHOOSE_PATH_TEXT: 'Choose Path',
  CHOSEN_PATH_TEXT: 'Chosen path',
  ADD_TRAININGS_TO_THIS_PATH_TEXT: 'Add trainings to this path (optional)',
  ADDITIONAL_ADDED_TRAININGS_TO_PATH_TEXT:
    'Additional added trainings to the path',
  AVAILABLE_TRAININGS_TEXT: 'Available Trainings',
  NEXT_TEXT: 'Next',
  BACK_TEXT: 'Back',
  CLEAR_SELECTION_TEXT: 'Clear Selection',
  SUCCESS_MESSAGE_TEXT: "You have successfully set the user's training path.",
};

export const TRAINING_LABELS = {
  VIEW_TRAINING_TEXT: 'View Training',
  ADD_TRAININGS: 'Add Trainings',
  ASSIGN_TRAININGS: 'Assign Trainings',
};

export const VALIDATION_LABELS = {
  PLEASE_FILL_TEXT: 'Please fill in the required fields above.',
  REQUIRED_FIELD: 'Required field',
};

export const EVENT_LABELS = {
  DELETE_EVENT: 'Delete Event',
  DELETE_CONFIRMATION_TEXT: 'Are you sure you would like to delete this event?',
  EDIT_EVENT: 'Edit Event',
  DUPLICATE_EVENT: 'Duplicate Event',
};

export const GENERIC_MESSAGES = {
  GENERIC_INVALID_URL_MESSAGE: 'The url is invalid.',
  GENERIC_UNSUCCESSFUL_APPLICATION_MESSAGE:
    'The application was not successful',
};

export const SUCCESSUL_REQUEST_MESSAGES = {
  APPLY_TRAINING: 'You have successfully applied for the training.',
  CANCEL_APPLICATION: 'You have successfully cancelled your application.',
  START_TRAINING: 'You have successfully started your training.',
  DROP_TRAINING: 'You have successfully dropped your training.',
  APPLY_EVENT: 'You have successfully applied for the event.',
  START_EVENT: 'You have successfully started your event.',
  DROP_EVENT: 'You have successfully dropped your event.',
  COMPLETE_EVENT: 'You have successfully completed your event.',
};

export const APPROVE_APPLICATION_LABELS = {
  APPROVE_APPLICATION_TEXT: 'You are about to approve the application.',
  APPROVED_COMMENT_PLACEHOLDER_TEXT: 'Do you have anything to share?',
};

export const REJECT_APPLICATION_LABELS = {
  REJECT_APPLICATION_TEXT: 'You are about to reject the application.',
  REJECT_COMMENT_PLACEHOLDER_TEXT:
    'Please describe the reason for your rejection.',
};
export const START_TRAINING_LABELS = {
  CONTACT_MESSAGE_TEXT:
    'After Pressing "Start Training" please contact Lilia Zlatanova for credentials.',
};

export const EVENT_DETAIL_LABELS = {
  EVENT: 'Event',
  SPEAKERS: 'Speakers',
  SCHEDULE_AGENDA: 'Schedule/Agenda',
  AGENDA: 'Agenda',
  PREVIEW_EVENT: 'Preview Event',
  DOWNLOAD_FILES: 'Download file ',
};

export const TRAINING_DETAIL_LABELS = {
  CREATED_BY: 'created by',
  TRAINING: 'Training',
  TRAINING_BY: 'by',
  PRICE: 'Price',
  DATES: 'Dates',
  APPLY_BEFORE: 'Apply before',
  LOCATION: 'Location',
  TRAINING_OVERVIEW: 'Overview',
  TRAINING_REVIEWS: 'Reviews',
  TRAINING_DETAILS: 'Training Details',
  LEVEL: 'Level',
  DURATION: 'Total duration',
  HOURS: 'h',
  LESSONS: 'Lessons',
  TRAINING_LINK: 'Training Link',
  VISIT_PAGE: 'Visit Page',
  NOT_AVAILABLE: 'N/A',
  SELF_PACED: 'Self-paced',
  NO_DEADLINE: 'No deadline',
  PREVIEW_TRAINING: 'Preview Training',
  UDEMY: 'UDEMY',
  PLURALSIGHT: 'PLURALSIGHT',
  DUPLICATE_TRAINING: 'Duplicate training',
  EDIT_TRAINING: 'Edit training',
  DELETE_TRAINING: 'Delete training',
  RATE_TRAINING: 'Rate training',
  DELETE_CONFIRMATION_TEXT:
    'Are you sure you would like to delete this training?',
  TRAINER: 'Trainer',
  PARTICIPANTS: 'Participants',
  PARTICIPANTS_AVAILABLE: '%PARTICIPANTS% of %PARTICIPANTS_AVAILABLE%',
  DOWNLOAD_FILES: 'Download File ',
};

export const MY_TRAININGS_LABELS = {
  MY_TRAININGS_TEXT: 'My Trainings',
  TRAININGS_TEXT: 'Trainings',
  ACTIVE_TEXT: 'Active',
  PAST_TEXT: 'Past',
  PENDING_APPLICATION_MANAGER_TEXT: "Waiting for {0}'s approval",
  CANCELLED_APPLICATION_MANAGER_TEXT: 'applications by me',
  REJECTED_APPLICATION_MANAGER_TEXT: 'applications by {0}',
};

export const TRAINING_STATUS_LABELS = {
  IN_PROGRESS_TEXT: 'In Progress',
  APPROVED_TEXT: 'Approved',
  DELAYED_TEXT: 'Delayed',
  COMPLETED_TEXT: 'Completed',
  DROPPED_TEXT: 'Dropped',
  PENDING_TEXT: 'Pending',
  CANCEL_TEXT: 'Cancelled',
  REJECTED_TEXT: 'Rejected',
};

export const ADD_TRAINING_MODAL_LABELS = {
  ADD_TRAINING_TITLE_TEXT: 'Add Training',
  ADD_TRAINING_TEXT:
    'Would you like to create a new training or link to an external one from Udemy or Pluralsight?',
  LINK_TO_EXTERNAL: 'Link to external',
  PREFILL_TRAINING: 'Prefill Training',
  ATTACH_FILE: 'Attach Files',
  TOTAL_ATTCHED: '(/totalAttached/) Attached',
};

export const EVENT_STATUS_LABELS = {
  IN_PROGRESS_TEXT: 'In Progress',
  APPROVED_TEXT: 'Approved',
  DELAYED_TEXT: 'Delayed',
  COMPLETED_TEXT: 'Completed',
  DROPPED_TEXT: 'Dropped',
  PENDING_TEXT: 'Pending',
  CANCEL_TEXT: 'Cancelled',
  REJECTED_TEXT: 'Rejected',
};

export const MY_EVENTS_LABELS = {
  MY_EVENTS_TEXT: 'My Events',
  EVENTS_TEXT: 'Events',
  ACTIVE_TEXT: 'Active',
  PAST_TEXT: 'Past',
  PENDING_EVENTS_APPLICATION_MANAGER_TEXT: "Waiting for {0}'s approval",
  CANCELLED_EVENTS_APPLICATION_MANAGER_TEXT: 'event applications by me',
  REJECTED_EVENTS_APPLICATION_MANAGER_TEXT: 'event applications by {0}',
};

export const ADD_EVENT_MODAL_LABELS = {
  CREATE_EVENT: 'Create Event',
  ADD_EVENTS: 'Add Events',
  EDIT_EVENT: 'Edit Event',
  DUPLICATE_EVENT: 'Duplicate Event',
  EVENT_TITLE: 'Event Title',
  AGENDA_TOPIC: 'Agenda Topic',
  ADD_AGENDA_SPEAKERS: 'Add agenda/speakers',
  SPEAKERS: 'Speakers',
  DATE: 'Date',
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
  ADD_AGENDA: 'Add Agenda',
  EDIT_AGENDA: 'Edit Agenda',
  ADD_SPEAKER: 'Add Speaker',
  EDIT_SPEAKER: 'Edit Speaker',
  GENERAL_DETAILS: 'General Details',
  UPLOAD: 'Upload',
  DRAG_DROP_HERE: 'or drag and drop here',
  JPG_PNG: 'JPG, PNG.... (max size 200KB)',
  DESCRIPTION: 'Description',
  PROGRAM_AGENDA: 'Program Agenda',
  CANCEL: 'Cancel',
  SAVE_CHANGES: 'Save Changes',
  SPEAKERS_NOT_ADDED_YET: "You haven't added any speakers yet.",
  AGENDA_NOT_ADDED_YET: "You haven't added any agenda yet.",
  ATTACH_FILE: 'Attach Files',
  TOTAL_ATTCHED: '(/totalAttached/) Attached',
};

export const EXTERNAL_TRAINING_LABELS = {
  ADD_EXTERNAL_TRAINING: 'Add External Training',
};

export const LOCATION_TYPES_MAPPED: { name: string; value: LocationType }[] = [
  {
    name: 'Online',
    value: 'Online',
  },
  {
    name: 'On-Site',
    value: 'OnSite',
  },
  {
    name: 'Internal',
    value: 'Internal',
  },
];

export const LATEST_BUILD_TIMESTAMP_EXAMPLE = 0;

export const MOVE_TRAINING = {
  MOVETO: 'Move to',
  MOVE_BUTTON: 'Move',
  EMPTY_PATHS: 'There are no paths available.',
  MOVE_SUCCESS: 'You have successfully moved the training to another path.',
};
export const EXPORTS = {
  EXPORT_TO_CSV: 'Export to CSV',
};
export const APPLICATION_NOTIFICATION_LABELS = {
  APPROVED_APPLICATION: 'has approved your application for',
  REJECTED_APPLICATION: 'has rejected your application for',
  APPLIED_FOR_APPLICATION: 'has applied for',
  ASSIGNED: 'has assigned you ',
  REMOVED: 'has removed you from',
  APPLIED_FOR_DEMO_REQUEST: 'has applied for a Demo request on behalf of ',
  GO_TO_DEMO_REQUESTS: 'Go to Demo Requests →',
};

export const EVENT_APPLICATIONS_LABELS = {
  TITLE: 'Title',
  ORGANIZER: 'Organizer',
  USER: 'User',
  EMAIL: 'Email',
  MANAGER: 'Manager',
  LAST_UPDATED: 'Last Updated',
  STATUS: 'Status',
  DETAIL_INFORMATION: 'Detail Information: ',
  APPLIED_DATE: 'Applied On: ',
  APPROVED_DATE: 'Approved On: ',
  REJECTED_DATE: 'Rejected On: ',
  STARTED_DATE: 'Started On: ',
  COMPLETED_DATE: 'Completed On: ',
  REMOVE_LABEL: 'Action',
};

export const TRAINING_APPLICATIONS_LABELS = {
  TITLE: 'Title',
  USER: 'User',
  EMAIL: 'Email',
  MANAGER: 'Manager',
  LAST_UPDATED: 'Last Updated',
  TYPE: 'Type',
  STATUS: 'Status',
  DETAIL_INFORMATION: 'Detail Information: ',
  APPLIED_DATE: 'Applied On: ',
  APPROVED_DATE: 'Approved On: ',
  REJECTED_DATE: 'Rejected On: ',
  STARTED_DATE: 'Started On: ',
  COMPLETED_DATE: 'Completed On: ',
};

export const GROUP_MANAGEMENT_LABELS = {
  GROUPS_TEXT: 'User Groups',
  EDIT_GROUP: 'Edit group',
  DELETE_GROUP: 'Delete group',
  DELETE_CONFIRMATION_TEXT: 'Are you sure you would like to delete this group?',
  REMOVE_MEMBER: 'Are you sure you would like to remove this member?',
  REMOVE_TRAINING: 'Are you sure you would like to remove this training?',
  DELETE_MULTIPLE_GROUPS_CONFIRMATION_TEXT:
    'Are you sure you want to delete the selected groups?',
  REMOVE_MEMBER_FORBIDDEN:
    'A group must have at least one member to exists. If you want to delete the group you can do it through the overview menu.',
};

export const SUBMISSIONS_LABELS = {
  SUBMISSIONS: 'Submissions Overview',
  FULL_NAME: 'Full Name ',
  COMPANY_NAME: 'Company Name ',
  SUBMISSION_DATE: 'Submission Date ',
  STATUS: 'Status ',
  DETAIL_INFORMATION: 'Detail Information: ',
  WORK_EMAIL: 'Work Email: ',
  ROLE_IN_THE_COMPANY: 'Role In The Company: ',
  COMPANY_SIZE: 'Company Size: ',
  PHONE_NUMBER: 'Phone Number: ',
  DELETE_MULTIPLE_DEMO_REQUESTS_CONFIRMATION_TEXT:
    'Are you sure you would like to delete selected submissions?',
  SUBMISSIONS_SEARCH_PLACEHOLDER: 'Search by Company Name or Work Email...',
  REASON_OF_REJECTION: 'Reason Of Rejection',
  APPROVE_DEMO_REQUESTS_CONFIRMATION_TEXT:
    'Are you sure you would like to approve selected submission?',
  REJECT_DEMO_REQUESTS_CONFIRMATION_TEXT:
    'Are you sure you would like to reject selected submission?',
};

export const EMPLOYEES_MANAGEMENT_LABELS = {
  FULL_NAME: 'Full Name',
  EMAIL: 'Email',
  RESIGNED_ON: 'Resigned On',
  STATUS: 'Status',
  DETAIL_INFORMATION: 'Detail Information',
  MANAGER_EMAIL: 'Manager Email',
  DEPARTMENT: 'Department',
  ROLE_IN_THE_COMPANY: 'Role In The Company',
  OVERVIEW_EMPLOYEES: 'Employees Overview',
  DEACTIVATED: 'Deactivated',
  ACTIVE: 'Active',
  DEACTIVATE: 'Deactivate',
  EDIT: 'Edit',
};

export const EDIT_EMPLOYEE_LABELS = {
  DEPARTMENT: 'Department',
  ROLE_IN_THE_COMPANY: 'Role in the Company',
  MANAGER: 'Manager',
  UPDETE_INFO: 'UPDATE INFO',
  EDIT_EMPLOYEE_SUCCESSFULLY: 'You successfully edit the user!',
};

export const ADD_GROUP_MODAL_LABELS = {
  CREATE_NEW_GROUP: 'Create New Group',
  EDIT_GROUP: 'Edit Group',
  GROUP_TITLE: 'Group Title',
  DESCRIPTION_TITLE: 'Description',
  ADD_EMPLOYEES_TITLE: 'Add Employees',
  ADD_EMPLOYEE: 'Add Employee',
  ASSIGN_TRAINING: 'Assign Training',
};

export const SEARCH = {
  SEARCH_TIMEOUT: 350,
};

export const LOGIN_LABELS = {
  USERNAME: 'Username',
  PASSWORD: 'Password',
};

export const DEMO_REQUEST_LABELS = {
  FIRSTNAME: 'First Name',
  LASTNAME: 'Last Name',
  COMPANY_NAME: 'Company Name',
  COMPANY_SIZE: 'Company Size',
  WORK_EMAIL: 'Work Email',
  ROLE_IN_COMPANY: 'Role In The Company',
  PHONE_NUMBER: 'Phone Number',
};

export const COST_REPORT_LABELS = {
  DEPARTMENT: 'Department',
  TRAINING_COST: 'Trainings Costs',
  EVENT_COST: 'Event Costs',
  TOTAL_COST: 'Total Costs',
  COST_REPORT_LABEL: 'Departments Overview',
  COST_DATE_LABEL: 'Date range:',
  TRAINING_COST_DETAIL: 'Trainings Costs Detail Overview:',
  EVENT_COST_DETAIL: 'Events Costs Detail Overview:',
  TOTAL_COST_DETAIL: 'Total Costs Overview:',
  COST_YEAR_SELECT_LABEL: 'Select Year:',
};

export const DEMO_REQUEST_ERROR_MESSAGES = {
  PHONE_NUMBER_NOT_VALID: 'Phone number not valid',
  EMAIL_NOT_VALID: 'Email not valid',
};

export const IMPORT_USERS_SUCCESSFULLY = {
  SUCCESS_MESSAGE_TEXT: 'You have successfully imported users.',
};

export const IMPORT_USERS_UNSUCCESSFULLY = {
  ERROR_MESSAGE_TEXT: 'Try again or contact SUPPORT for further assistance.',
};

export const USERS_IMPORT_MODAL = {
  UPLOAD_BUTTON: 'Upload',
  BROWSE_FILE_BUTTON: 'Browse File',
  IMPORT_CSV_MODAL_HEADLINE: 'Import CSV File',
};

export const USERS_IMPORT = {
  IMPORT_FILE_BUTTON: 'Import File',
  USERS_IMPORT_HEADLINE:
    "Import your Employee's Information following three simple steps:",
  STEP_1: 'Step 1:',
  STEP_1_CONTENT: 'Download Template:',
  STEP_2: 'Step 2:',
  STEP_2_CONTENT: 'Fill Out the downloaded CSV File',
  STEP_3: 'Step 3:',
  STEP_3_CONTENT: 'Import the CSV File here',
};

export const FILE_UPLOAD_DROP_ZONE = {
  UPLOAD_FAILED: 'File upload failed.',
  NOT_SUPPORTED_TYPE: 'Uploaded file type was not supported.',
  DRAG_N_DROP_TEXT: 'Drag and drop your CSV File here.',
  BEING_IMPORTED: 'The users are currently being imported, please wait!',
  MAX_SIZE_ERROR:
    'The maximum size of the file need to be less than /maxSize/MB.',
  FILE_NAME_ALREADY_EXISTS: 'A file with the same name is already attached.',
  MAX_FILE_NUMBER_REACHED: 'Max file number to upload is reached.',
  FILE_UPLOAD_CENTER_MESSAGE:
    'Drag and drop your File here. Max size is /maxSize/MB.',
  FILE_UPLOAD_PLACEHOLDER: 'Drag and drop your file here or click to upload',
};

export const LOGIN_PROVIDERS = {
  GOOGLE: 'Google',
  MICROSOFT: 'Microsoft',
};

export const ENVIRONMENT_NAMES = {
  TEST: 'Test',
  PRODUCTION: 'Production',
  TRIAL: 'Trial',
};

export const ARCHIVE_EMPLOYEES_MODAL_LABELS = {
  ARCHIVE_EMPLOYEE_CONFIRMATION_LABEL:
    'Are you sure you would like to archive all deactivated employees from the last year?',
};

export const UPLOAD_PDF_MODAL = {
  UPLOAD_PDF_HEADLINE: 'Upload PDF',
};

export const LIMIT_APPLICATIONS_LABELS = {
  LIMIT_APPLICATIONS_TEXT: 'Limit Applications',
  PARTICIPANTS: 'Participants',
};

export const FOOTER_LABELS = {
  STRYPES_LINK: 'Strypes',
  ALL_RIGHTS_RESERVED: 'All Rights Reserved.',
};

export const LANDING_PAGE_FOOTER_LABELS = {
  FOOTER_SUB_TITLE:
    'Empower your workforce with our training and development platform',
  ACCESS: 'Access',
  COMPANY: 'Company',
  LOGIN: 'Login',
  REQUEST_FREE_TRIAL: 'Request Free Trial Environment',
  ABOUT_US: 'About Us',
  CONTACT: 'Contact',
  STRPYES_LTD: `© ${new Date().getFullYear()} Strypes Ltd.`,
  ALL_RIGHTS_RESERVED: 'All Rights Reserved.',
  PLEASE_NOTE: 'Please note that we use',
  COOKIE_POLICY: 'Cookie Policy',
  PRIVACY_POLICY: 'Privacy Policy',
  TERMS_OF_SERVICE: 'Terms of Service',
};

export const LANDING_PAGE_ABOUT_US_LABELS = {
  ABOUT_STRYPES: 'About Strypes',
  ABOUT_US_SUB_TITLE: 'Together we push the boundaries of IT.',
  ABOUT_US_CONTENT:
    'Our unique approach towards delivering smart solutions encompasses ' +
    'all benefits from nearshoring with working on-site with your teams. ' +
    'We apply our Nearsurance workmodel to all our services and it has helped ' +
    'us deliver over 450 successful projects of any scale and complexity.',
  LEARN_MORE: 'LEARN MORE',
};

export const LANDING_PAGE_CONTACT_LABELS = {
  CONTACT_TITLE: 'Get Your Free Demo Now',
  CONTACT_CONTENT:
    'Request your free demo environment, and we will give you three days to test the application. \nPlease note that the data is only for testing purposes and not a real one.',
  REQUEST_FREE_TRIAL: 'REQUEST FREE TRIAL ENVIRONMENT',
};

export const USER_GUIDE_TITLES = [
  'Upskill Portal - User Guide',
  'Dashboard',
  'How to apply for Trainings?',
  'How to add new training?',
  'How to apply for an event?',
  'How to add an event?',
  'Where you can track down your events and trainings?',
  'My paths',
  'Your Onboarding is complete',
];

export const USER_GUIDE_DESCRIPTIONS = [
  "In the following Guide we want to give you some useful tips to get you familiar with the app. Let's begin!",
  'Your Dashboard is your central hub, where you can find an overview of your learning journey. Here you can easily track down your progress and stay updated on upcoming events and trainings. You will find here also brief information regarding your role in the company and important links you may need.',
  'To apply for a training, go the “Trainings” page and select the course that interests you. Once you’ve chosen a specific training, open its details and click the “Apply” button. Your application will be reviewed by your manager, and you will receive a notification right away.',
  'With Upskill Portal, you have the flexibility to add new trainings based on your preferences. To do this, simply visit the “Trainings” page and click on the “Add Training” button. Once you submit the form with all required fields, the training will be listed in the “Trainings” page, and you can apply for in the same way as for other trainings.',
  'You can discover interesting events provided from your company in the “Events” section. Open the event that captures your attention in and apply with a single click. It’s simple as that.',
  'Similar to trainings, you can add new events that you and your colleagues find interested to attend. Go to "Events” page and click the “Add Event” button to get started. Fill out the required fields and once submitted, the event will be listed to the Events page. Apply for it using the same process as for other events.',
  'In the “My Trainings and Events” section, you can access all trainings and events you have applied for. This page provides an overview of their status and progress, and allowing you to stay informed and track your participation.',
  'Within the Upskill Portal, your manager can assign to you a set of related trainings known as “Paths”. There paths are targeted learning journeys aligned to your individual needs for professional development and career goals. To access these Paths, simply go to “My Paths” section.',
  "Now is your turn. Go to the “Trainings” page and start learning today. If you have any questions, don't hesitate to reach out through the “Support” Button in your dashboard.",
];

export const USER_GUIDE_BTNS = {
  SKIP: 'SKIP',
  DONE: 'DONE',
  NEXT: 'NEXT',
  PREVIOUS: 'PREVIOUS',
};

export const ADD_EMPLOYEE_TO_MY_TEAM_MODAL = {
  SUCCESS_MESSAGE_TEXT: 'You have successfully added an employee to your team.',
  ADD_EMPLOYEE_TITLE: 'Add Employee',
  FULL_NAME: 'Full Name',
  DEPARTMENT: 'Department',
  Role: 'Role',
};

export const EDIT_PROFILE_PHOTO_MODAL = {
  EDIT_PROFILE_PHOTO: 'Edit Profile Photo',
  UPLOAD_PHOTO: 'Upload Photo',
  CROP_AND_SAVE: 'Crop and Save',
  CANCEL: 'Cancel',
  IMAGE_UPLOAD_SIZE_ERROR_TEXT:
    'Uploaded file is too large! Maximum size is 2MB.',
  IMAGE_UPLOAD_TYPE_ERROR_TEXT: 'Uploaded file type is not supported!',
};

export const SUPPORT_EMAIL = 'training.portal@strypes.eu';

export const FEATURES_SECTION_ITEMS = [
  {
    title: 'Personalized Dashboard',
    description:
      'Stay organised and track your progress towards your career goals.',
    image: personalizedDashboardImage,
  },
  {
    title: 'Clear career paths',
    description:
      'Hone in on the skill sets needed for specific positions and how people can develop them.',
    image: careerPathImage,
  },
  {
    title: 'Build your own content library',
    description:
      'Easily add multiple types of trainings and events, add links to external digital sources, and even let them add their own.',
    image: buildYourOwnLibraryImage,
  },
  {
    title: 'Track & report progress',
    description:
      'As a manager, discover  the progress of each team member per domain knowledge, type of skill sets, career path or personal development goals.',
    image: progressOverviewImage,
  },
  {
    title: 'Single sign-on integration and more',
    description:
      'Make it easy for your employees to learn anywhere anytime. Make it easy for your HR team to put the platform to use and gain insights immediately.',
    image: singleSignOnImage,
  },
];

export const SOLUTION_SECTION_ITEMS = [
  {
    title: 'Engage employees with trainings and events',
    description:
      'Easily add technical trainings, soft skills seminars, management academies, and many other types of learning experiences that your organization may need.',
  },
  {
    title: 'Create and manage career paths',
    description:
      'Demonstrate structure and clarity in the personalized growth paths of each team member.',
  },
  {
    title: 'Gain insights into training history',
    description:
      'Provide your managers with a clear picture of their team’s development and give them the tools to grow the skillsets they need most.',
  },
];

export const RATING_FORM_PLACEHOLDER = 'Your Review (Optional)';

export const SOLUTION_SECTION_ROLES = [
  {
    title: 'Admins',
    image: productAdminImage,
  },
  {
    title: 'Managers',
    image: productManagerImage,
  },
  {
    title: 'Employees',
    image: productEmployeeImage,
  },
];

export enum LandingPageSections {
  HOME = 'home',
  FEATURES = 'features',
  SOLUTION = 'solution',
  PRICING = 'pricing',
  ABOUT_US = 'aboutUs',
  CONTACT = 'contact',
}

export const SCREEN_SIZES = {
  EXTRA_SMALL: 480,
  SMALL: 800,
  MEDIUM: 1200,
  LARGE: 1920,
};

export const TRAINING_TYPES = [
  {
    name: 'Technical Skills',
    type: SkillTypes.TECHNICAL_SKILLS,
  },
  {
    name: 'Soft Skills',
    type: SkillTypes.SOFT_SKILLS,
  },
  {
    name: 'Management Skills',
    type: SkillTypes.MANAGEMENT_SKILLS,
  },
  {
    name: 'Other',
    type: SkillTypes.OTHER,
  },
];

export const muiNoWrap = {
  whiteSpace: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
} as CSSInterpolation;

export const DRAWER_TYPES = {
  APP: 0,
  NOTIFICATIONS: 1,
};

export const ERROR_TYPES = {
  FORM: 0,
  SERVER: 1,
};

export const MODAL_CLOSE_BTNS = {
  HIDDEN: 0,
  INSIDE: 1,
  OUTSIDE: 2,
};

export const EVENT_FORM_TYPES = {
  CREATE: 0,
  EDIT: 1,
  DUPLICATE: 2,
};

export const MY_TRAININGS_AND_EVENTS_TYPES = [
  {
    value: MyTrainingsAndEventsTypes.TRAINING,
  },
  {
    value: MyTrainingsAndEventsTypes.EVENT,
  },
];

export const APPLICATION_STATUSES = [
  {
    label: 'Pending',
    value: ApplicationStatuses.PENDING,
  },
  {
    label: 'Approved',
    value: ApplicationStatuses.APPROVED,
  },
  {
    label: 'In Progress',
    value: ApplicationStatuses.IN_PROGRESS,
  },
  {
    label: 'Completed',
    value: ApplicationStatuses.COMPLETED,
  },
  {
    label: 'Rejected',
    value: ApplicationStatuses.REJECTED,
  },
  {
    label: 'Dropped',
    value: ApplicationStatuses.DROPPED,
  },
];

export const APPLICATION_STATUSES_FIRST = [
  ApplicationStatuses.PENDING,
  ApplicationStatuses.REJECTED,
];

export const APPLICATION_STATUSES_SECOND = [
  ApplicationStatuses.APPROVED,
  ApplicationStatuses.COMPLETED,
  ApplicationStatuses.IN_PROGRESS,
  ApplicationStatuses.DROPPED,
];

export const MENU_ACTIONS = {
  EDIT: 'Edit',
  DUPLICATE: 'Duplicate',
  DELETE: 'Delete',
  ASSIGN: 'Assign',
  MARK_IN_PROGRESS: 'Mark in progress',
  MARK_AS_DONE: 'Mark as done',
  MARK_AS_DROPPED: 'Mark as dropped',
};

export const EMPLOYEE_MENU_ACTIONS = {
  ASSIGN_EVENT: 'Assign Event',
  ASSIGN_TRAINING: 'Assign Training',
  ASSIGN_PATH: 'Assign Path',
  REMOVE_EMPLOYEE: 'Remove Employee',
};

export const NOT_AVAILABLE_TEXT = 'N/A';

export const NOT_FOUND_MESSAGES = {
  EVENTS: 'No events found',
  TRAININGS: 'No trainings found',
  PATHS: 'No paths found',
  EMPLOYEES: 'No employees found',
  PATH_ASSIGNEES:
    'There are currently no employees assigned to the selected path.',
  NO_DATA_AVAILABLE: 'No data available',
};

export const VALIDATION_MESSAGES = {
  REQUIRED: 'Required field',
  POSITIVE_NUMBER: 'Number must be greater than 0',
  AFTER_START_TIME: 'Must be after Start Time',
  VALID_LINK: 'Must be a valid link',
  VALID_EMAIL: 'Must be a valid email',
  WHOLE_NUMBER: 'Must be a whole number',
  AFTER_OR_EQUAL_TO_START_DATE: 'Must be after or equal to Start Date',
  BEFORE_OR_EQUAL_TO_START_DATE: 'Must be before or equal to Start Date',
  DATE_IN_THE_FUTURE: 'Must be in the future',
  FILE_FORMAT_NOT_ALLOWED: 'This file format is not allowed',
  MAX_FILE_SIZE: `Max file size is 3MB.`,
};

export const FAILED_REQUEST_MESSAGE = 'Something went wrong.';

export const PATH_CONTENT_LABELS = {
  TRAINING: 'Add Training',
  EVENT: 'Add Event',
  LINK: 'Add Link',
  FILE: 'Add File',
  MEETING: 'Add 1:1 Meeting',
};

export const EVENT_TYPES = [
  { id: SkillTypes.TECHNICAL, name: 'Technical Skills' },
  { id: SkillTypes.CAREER, name: 'Career' },
];

export const NEW_PATH_TYPES = [
  { id: SkillTypes.INTERNAL, name: 'Internal' },
  { id: SkillTypes.CAREER, name: 'Career' },
  { id: SkillTypes.DOMAIN, name: 'Domain' },
];

export const PATH_LINK_DESCRIPTION_LENGTH = 240;
export const PATH_MEETING_DESCRIPTION_LENGTH = 240;
export const PATH_FILE_DESCRIPTION_LENGTH = 240;
export const PATH_FILE_UPLOAD_HELPER_TEXT =
  'Accepted file types: Doc, PDF, JPEG, PNG. Max file size 3 MB';
export const PATH_FILE_MAX_SIZE = 3 * 10 * 10 * 10 * 10 * 10 * 10;

export const PATH_LINK_LABELS = {
  ADD_LINK: 'Add Link',
  EDIT_LINK: 'Edit Link',
};

export const PATH_MEETING_LABELS = {
  ADD_MEETING: 'Add Meeting',
  EDIT_MEETING: 'Edit Meeting',
  ADD_MEETING_ALTERNATIVE: 'Add 1:1 Meeting',
  EDIT_MEETING_ALTERNATIVE: 'Edit 1:1 Meeting',
};

export const PATH_FILE_LABELS = {
  ADD_FILE: 'Add File',
  EDIT_FILE: 'Edit File',
};

export const ALLOWED_PATH_FILE_FORMATS = [
  'application/pdf',
  'application/msword',
  'image/jpeg',
  'image/png',
];

export const PATH_STATUSES = [
  {
    label: 'All',
    value: PathStatuses.ALL,
  },
  {
    label: 'Not Started',
    value: PathStatuses.NOT_STARTED,
  },
  {
    label: 'In Progress',
    value: PathStatuses.IN_PROGRESS,
  },
  {
    label: 'Completed',
    value: PathStatuses.COMPLETED,
  },
  {
    label: 'Dropped',
    value: PathStatuses.DROPPED,
  },
];

export const PATH_ITEM_STATUSES = [
  {
    label: 'Not Started',
    value: PathItemStatuses.NOT_STARTED,
  },
  {
    label: 'Not Started',
    value: PathItemStatuses.APPROVED,
  },
  {
    label: 'In Progress',
    value: PathItemStatuses.IN_PROGRESS,
  },
  {
    label: 'Completed',
    value: PathItemStatuses.COMPLETED,
  },
  {
    label: 'Dropped',
    value: PathItemStatuses.DROPPED,
  },
];

export const YEAR_QUARTERS = ['Q1', 'Q2', 'Q3', 'Q4'];

export const DEMO_REQUEST_STATUSES = [
  { label: 'All', value: DemoRequestStatuses.ALL },
  { label: 'Pending', value: DemoRequestStatuses.PENDING },
  { label: 'Approved', value: DemoRequestStatuses.APPROVED },
  { label: 'Rejected', value: DemoRequestStatuses.REJECTED },
];

export const GO_TO_SECTION_TEXT = 'GO TO SECTION';

export const COMPANY_SIZES = [
  '1-10 people',
  '11-50 people',
  '51-200 people',
  '201+ people',
] as const;

export const COST_OPTIONS = ['free', 'paid'] as const;

export const APPLICATIONS_LIMITED_OPTIONS = ['no', 'yes'] as const;
