import { FormLabel, Stack, Typography } from '@mui/material';
import { TRAINING_DETAILS } from '../../../cms';
import AssignTrainingSection from '../AssignTrainingSection/AssignTrainingSection';
import AssignTrainingToGroups from '../../../components/AssignTrainingToGroups/AssignTrainingToGroups';

type TrainingFormAdminSectionProps = {
  trainingId: number | null;
  isAdmin: boolean;
};

const TrainingFormAdminSection = ({
  trainingId,
  isAdmin,
}: TrainingFormAdminSectionProps) => {
  return (
    <Stack spacing="0.75rem">
      <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
        {TRAINING_DETAILS.ASSIGN_TRAINING_TO_TEXT}
      </Typography>

      <Stack>
        <FormLabel sx={{ fontSize: 12, mb: '0.25rem' }}>
          {TRAINING_DETAILS.EMPLOYEE}
        </FormLabel>
        <AssignTrainingSection trainingId={trainingId} />
      </Stack>

      {isAdmin && (
        <Stack>
          <FormLabel sx={{ fontSize: 12, mb: '0.25rem' }}>
            {TRAINING_DETAILS.GROUP}
          </FormLabel>
          <AssignTrainingToGroups createdTrainingId={trainingId} />
        </Stack>
      )}
    </Stack>
  );
};

export default TrainingFormAdminSection;
