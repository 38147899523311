import { useSearchParams } from 'react-router-dom';
import { DraggableProvided } from 'react-beautiful-dnd';
import {
  Divider,
  Icon,
  IconButton,
  Rating,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { PathItemTypes } from '../../../cms/enums';
import './PathItemCard.scss';
import { PATHS_LABELS } from '../../../cms';
import { PathItemSchemaType } from '../../../cms/validation/pathValidation';
import PathItemStatusMenu from './PathItemStatusMenu/PathItemStatusMenu';

export type PathItemCardProps = {
  item: PathItemSchemaType;
  isSelected?: boolean;
  onClick?: (item: PathItemSchemaType) => void;
  onRemove?: (position: number) => void;
  onEdit?: () => void;
  draggableProvided?: DraggableProvided;
};

/**
 * @description Represents a path item.
  It is used in PathForm and PathDetails. As part of PathForm it can be draggable.
 */

const PathItemCard = ({
  item,
  isSelected = false,
  onClick,
  onRemove,
  onEdit,
  draggableProvided,
}: PathItemCardProps) => {
  const [, setSearchParams] = useSearchParams();
  const { position = 0, type, status, data, displayData } = item;
  const isEditable =
    item.type !== PathItemTypes.TRAINING && item.type !== PathItemTypes.EVENT;

  const chooseIconName = (): string => {
    switch (type) {
      case PathItemTypes.TRAINING:
        return 'school';
      case PathItemTypes.EVENT:
        return 'event';
      case PathItemTypes.LINK:
        return 'link';
      case PathItemTypes.FILE:
        return 'file_present';
      default:
        return 'video_chat';
    }
  };

  const handleOnRemove = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    onRemove!(position);
  };

  const handleCardTypeClick = (cardType: PathItemTypes, id: number) => {
    setSearchParams((prev) => {
      prev.set(
        cardType === PathItemTypes.TRAINING ? 'trainingId' : 'eventId',
        id.toString(),
      );
      return prev;
    });
  };

  const titleElement =
    type === PathItemTypes.TRAINING || type === PathItemTypes.EVENT ? (
      <Typography
        onClick={(e) => {
          e.stopPropagation();
          handleCardTypeClick(type, data.id);
        }}
        noWrap
        className="path-content-card-data-title"
      >
        {displayData.title}
      </Typography>
    ) : (
      <a href={data.url} target="_blank" rel="noreferrer">
        <Typography noWrap className="path-content-card-data-title">
          {displayData.title || PATHS_LABELS.PATH_CONTENT_ITEM_TITLE}
        </Typography>
      </a>
    );

  const handleOnClick = () => {
    if (onClick) onClick(item);
  };

  return (
    <Stack
      className="path-content-card-container"
      spacing="1rem"
      {...draggableProvided?.draggableProps}
      {...draggableProvided?.dragHandleProps}
      ref={draggableProvided?.innerRef}
    >
      {position > 0 && (
        <Stack className="path-content-card-position">
          <Divider orientation="vertical" />
          <Stack className="path-content-card-position-number-container">
            <Typography className="path-content-card-position-number">
              {position}
            </Typography>
          </Stack>
        </Stack>
      )}
      <Stack
        onClick={handleOnClick}
        direction="row"
        spacing="1rem"
        className={`path-content-card ${
          isSelected ? 'path-content-card-selected' : ''
        }`}
      >
        <Stack className="path-content-card-icon-container">
          <Icon className="path-content-card-icon">{chooseIconName()}</Icon>
        </Stack>
        <Stack direction="row" flexWrap="wrap" flex={1} spacing="1rem">
          <Stack className="path-content-card-data">
            {titleElement}
            <Typography className="path-content-card-data-sub-title">
              {displayData.subTitle}
            </Typography>
            {!onClick && (
              <Typography className="path-content-card-data-type">
                Type: {type}
              </Typography>
            )}
            {onClick && displayData.rating != null && (
              <Rating
                value={displayData.rating}
                readOnly
                className="path-content-card-data-rating"
              />
            )}
            {onClick && displayData.duration != null && (
              <Typography className="path-content-card-data-sub-title">
                {displayData.duration}
              </Typography>
            )}
          </Stack>
        </Stack>
        {/* EDIT BTN */}
        {onEdit && isEditable && (
          <Stack className="path-content-card-actions">
            <Tooltip title="Edit">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit();
                }}
                className="path-content-card-actions-btn"
              >
                <EditIcon className="path-content-card-actions-btn-icon" />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
        {/* REMOVE BTN */}
        {onRemove && (
          <Stack className="path-content-card-actions">
            <Tooltip title="Remove">
              <IconButton
                onClick={handleOnRemove}
                className="path-content-card-actions-btn"
              >
                <CloseIcon className="path-content-card-actions-btn-icon" />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
        {/* CHANGE STATUS MENU */}
        {status && (
          <PathItemStatusMenu status={status} id={data.id} type={type} />
        )}
      </Stack>
    </Stack>
  );
};

export default PathItemCard;
