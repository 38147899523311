import { put, take, call } from 'redux-saga/effects';
import { GET_ALL_DEPARTMENTS_REQUEST } from './types';
import { getAllDepartmentsError, getAllDepartmentsSuccess } from './actions';

/** If detect type: GET_ALL_DEPARTMENTS_REQUEST call getAllDepartments() */
export default function* getAllDepartmentsSaga(api: any): any {
  while (true) {
    const getAllDepartmentsRequest = yield take(GET_ALL_DEPARTMENTS_REQUEST);
    yield call(getAllDepartments, api);
  }
}

/** get all departments */
function* getAllDepartments(api: any): any {
  let response;
  try {
    response = yield call(api.getAllDepartments);
    const allDepartments = response.data;
    yield put(getAllDepartmentsSuccess(allDepartments));
  } catch (error) {
    yield put(getAllDepartmentsError(error));
  }
}
