import './SolutionRoleCard.scss';

type Role = {
  title: string;
  image: any;
};

type SolutionRoleCardProps = {
  item: Role;
};

const SolutionRoleCard = ({ item }: SolutionRoleCardProps) => {
  const { title, image } = item;

  return (
    <div>
      <h3 className="solution-role-card-title">{title}</h3>
      <img
        src={image}
        alt="Solution Role"
        className="solution-role-card-image"
      />
    </div>
  );
};

export default SolutionRoleCard;
