import React, { useState } from 'react';
import {
  APPROVE_APPLICATION_LABELS,
  BUTTON_LABELS,
  SUBMISSIONS_LABELS,
} from '../../../../../cms';
import DemoRequestActionButton from '../DemoRequestActionButton/DemoRequestActionButton';
import {
  DemoRequestApplicationArgs,
  useApproveDemoRequestMutation,
} from '../../../../../rtk/api/demoRequestApi';

export interface ApproveDemoRequestRequestModel {
  applicationId: string;
  comment: string;
}

export default function ApproveDemoRequest(props: { id: string }) {
  const [approveDemoRequest, { isSuccess }] = useApproveDemoRequestMutation();

  const [approveCommentText, setApproveCommentText] = useState('');

  const changeApproveCommentText = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setApproveCommentText(e.target.value);
  };

  const approve = () => {
    const model: DemoRequestApplicationArgs = {
      applicationId: props.id,
      comment: approveCommentText,
    };
    approveDemoRequest(model);
  };

  return (
    <DemoRequestActionButton
      onClick={() => approve}
      text={BUTTON_LABELS.APPROVE_BUTTON}
      className="mr-4 approved-color"
      changeTextareaHandler={changeApproveCommentText}
      textareaText={approveCommentText}
      textareaPlaceholderText={
        APPROVE_APPLICATION_LABELS.APPROVED_COMMENT_PLACEHOLDER_TEXT
      }
      confirmationText={
        SUBMISSIONS_LABELS.APPROVE_DEMO_REQUESTS_CONFIRMATION_TEXT
      }
      isConfirmationModalClosed={isSuccess}
    />
  );
}
