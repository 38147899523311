import { Container } from '@mui/material';
import AboutUsSection from '../../components/AboutUsSection/AboutUsSection';
import FeaturesSection from '../../components/FeaturesSection/FeaturesSection';
import HomeSection from '../../components/HomeSection/HomeSection';
import PricingSection from '../../components/PricingSection/PricingSection';
import SolutionSection from '../../components/SolutionSection/SolutionSection';
import ContactSection from '../../components/ContactSection/ContactSection';
import { openModal } from '../../rtk/features/modalsSlice';
import { useAppDispatch } from '../../rtk/hooks';
import './LandingPage.scss';
import { ModalTypes } from '../../cms/enums';

const LandingPage = () => {
  const dispatch = useAppDispatch();

  const handleOpenRequestDemo = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.REQUEST_DEMO_FORM,
        modalData: null,
        closeBtn: 1,
      }),
    );
  };

  return (
    <Container className="landing-page">
      <HomeSection openRequestDemoModal={handleOpenRequestDemo} />
      <FeaturesSection />
      <SolutionSection />
      <PricingSection />
      <AboutUsSection />
      <ContactSection openRequestDemoModal={handleOpenRequestDemo} />
    </Container>
  );
};

export default LandingPage;
