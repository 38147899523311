import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import {
  Avatar,
  Box,
  Grid,
  Stack,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import './PathCard.scss';
import { PathShortResponse } from '../../../cms/types/pathTypes';
import { extractInitials } from '../../../cms/Utils';
import { SkillTypeIcon } from '../index';
import {
  MENU_ACTIONS,
  COLORS,
  ROLES,
  NOT_AVAILABLE_TEXT,
  PATHS_LABELS,
  ROUTE_LINKS,
  MODAL_CLOSE_BTNS,
} from '../../../cms';
import { getUserRoles } from '../../userRoleRetriever';
import { useAppDispatch, useAppSelector } from '../../../rtk/hooks';
import { useDeletePathMutation } from '../../../rtk/api/pathApi';
import { openModal } from '../../../rtk/features/modalsSlice';
import PathStatusBox from './PathStatusBox/PathStatusBox';
import { ModalTypes } from '../../../cms/enums';
import DotMenu from '../DotMenu/DotMenu';
import { MenuOptions } from '../../../cms/types';
import InlineIconMenu from '../InlineIconMenu/InlineIconMenu';

export type PathCardProps = {
  path: PathShortResponse;
  isPathDetails?: boolean;
};

const menuOptionsIconSx: SxProps<Theme> = {
  fontSize: 18,
  color: COLORS.TEXT,
};

/**
 * @description The PathCard component represents the main information for a path.
  It is used for Path listing pages and as part of Path Details.
 */

const PathCard = ({ path, isPathDetails }: PathCardProps) => {
  const theme = useTheme();
  const isScreenXS = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const employeeId = searchParams.get('employeeId');
  const employeeName = searchParams.get('employeeName');
  const navigate = useNavigate();
  const {
    name,
    creator,
    itemsCount,
    durationInMonths,
    type,
    isArchived,
    status,
  } = path;
  const { id } = useAppSelector((state) => state.userSession);
  const isAdmin = getUserRoles().includes(ROLES.ADMIN_ROLE);
  const isCreator = id === creator?.id;
  const [deletePath] = useDeletePathMutation();
  const showOpacity = isArchived && !isPathDetails;
  const isMyPath = status != null;
  const isAssignPath = !!employeeId && !!employeeName;
  const showPathActions = !isMyPath && !isAssignPath;

  const handleCardClick = () => {
    if (status) {
      navigate(`${ROUTE_LINKS.PERSONAL_PATHS}/${path.id}`);
    } else if (isAssignPath) {
      navigate({
        pathname: `${ROUTE_LINKS.ASSIGN_PATHS}/${path.id}`,
        search: createSearchParams(searchParams).toString(),
      });
    } else {
      navigate(`${ROUTE_LINKS.PATHS}/${path.id}`);
    }
  };

  const handleOnEditClick = () =>
    navigate(`${ROUTE_LINKS.EDIT_PATH}/${path.id}`);

  const handleOnDuplicateClick = () => {
    navigate(ROUTE_LINKS.CREATE_PATH, {
      state: {
        pathId: path.id,
      },
    });
  };

  const handleOnDeleteClick = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.CONFIRM_ACTION,
        closeBtn: MODAL_CLOSE_BTNS.OUTSIDE,
        modalData: {
          text: PATHS_LABELS.DELETE_CONFIRMATION_TEXT,
          onConfirm: () => deletePath(path.id),
        },
      }),
    );
  };

  const handleOnAssignClick = () => {
    navigate(`${ROUTE_LINKS.PATHS}/${path.id}/assignees`);
  };

  const options: MenuOptions[] = [
    {
      show: isAdmin || isCreator,
      label: MENU_ACTIONS.EDIT,
      icon: <EditIcon sx={menuOptionsIconSx} />,
      action: handleOnEditClick,
    },
    {
      show: true,
      label: MENU_ACTIONS.DUPLICATE,
      icon: <ContentCopyIcon sx={menuOptionsIconSx} />,
      action: handleOnDuplicateClick,
    },
    {
      show: !isArchived && (isAdmin || isCreator),
      label: MENU_ACTIONS.DELETE,
      icon: <DeleteIcon sx={menuOptionsIconSx} />,
      action: handleOnDeleteClick,
    },
    {
      show: isAdmin && !isPathDetails,
      label: MENU_ACTIONS.ASSIGN,
      icon: <PersonAddAlt1Icon sx={menuOptionsIconSx} />,
      action: handleOnAssignClick,
    },
  ];

  const pathActions =
    isPathDetails && !isScreenXS ? (
      <InlineIconMenu options={options} />
    ) : (
      <DotMenu options={options} />
    );
  const pathStatusBox = isMyPath && <PathStatusBox status={status} />;

  return (
    <Box
      className={`path-card ${showOpacity && 'opacity-50'}`}
      onClick={() => !isPathDetails && handleCardClick()}
    >
      <Grid container spacing="1rem">
        <Grid item xs={12}>
          <Stack direction="row" spacing="1rem" alignItems="center">
            <SkillTypeIcon
              skillType={type}
              color={COLORS.SECONDARY}
              size={18}
            />
            <Typography noWrap className="path-card-title" title={name}>
              {name}
            </Typography>
            {showPathActions && pathActions}
          </Stack>
        </Grid>
        {status && (
          <Grid item xs={6} md={4} lg={3}>
            {pathStatusBox}
          </Grid>
        )}
        <Grid item xs={6} md={4} lg={3}>
          <Stack direction="row" spacing="1rem">
            <Avatar
              alt="Creator"
              className="path-card-creator-avatar"
              src={creator?.photoUrl ?? ''}
              variant="circular"
            >
              {extractInitials(creator?.fullName)}
            </Avatar>
            <Stack>
              <Typography className="path-card-creator-title">
                Created by:
              </Typography>
              <Typography
                className="path-card-creator-text"
                noWrap
                title={creator?.fullName ?? NOT_AVAILABLE_TEXT}
              >
                {creator?.fullName ?? NOT_AVAILABLE_TEXT}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6} md={2}>
          <Stack>
            <Typography className="path-card-items-title">Items</Typography>
            <Typography className="path-card-items-text">
              {itemsCount}
            </Typography>
          </Stack>
        </Grid>
        {durationInMonths > 0 && (
          <Grid item xs={6} md={2}>
            <Stack>
              <Typography className="path-card-time-frame-title">
                Time Frame:
              </Typography>
              <Typography className="path-card-time-frame-text">
                {durationInMonths} Months
              </Typography>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PathCard;
