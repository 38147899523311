import { UPDATE_PROFESSIONS, UPDATE_PROFESSIONS_SUCCESS } from './types';

/** Action to update the professions
 * @param {array} updatedProfessions - the new values for the professions
 * @return {Object} Return object
 */
export function updateProfessions(updatedProfessions) {
  return {
    type: UPDATE_PROFESSIONS,
    payload: {
      updatedProfessions,
    },
  };
}

/** On successfully update professions.
 * @param {string} professions - updated professions
 * @return {any} professions
 */
export function updateProfessionsSuccess(professions) {
  return {
    type: UPDATE_PROFESSIONS_SUCCESS,
    payload: {
      professions,
    },
  };
}
