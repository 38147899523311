import { Typography } from '@mui/material';
import { LANDING_PAGE_FOOTER_LABELS, ROUTE_LINKS } from '../../../cms';

type DisclaimerProps = {
  description: string;
  className?: string;
};

const Disclaimer = ({ description, className }: DisclaimerProps) => {
  return (
    <Typography
      sx={{
        fontSize: 12,
      }}
      className={className}
    >
      {description}, you confirm that you have read and agree to our{' '}
      <a
        href={ROUTE_LINKS.PRIVACY_NOTICE}
        target="_blank"
        rel="noopener noreferrer"
      >
        {LANDING_PAGE_FOOTER_LABELS.PRIVACY_POLICY}
      </a>{' '}
      and{' '}
      <a href={ROUTE_LINKS.TOS} target="_blank" rel="noopener noreferrer">
        {LANDING_PAGE_FOOTER_LABELS.TERMS_OF_SERVICE}
      </a>
    </Typography>
  );
};

export default Disclaimer;
