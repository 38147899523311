import { put, take, call } from 'redux-saga/effects';
import { IMPORT_USERS_CSV_REQUEST } from './types';
import { importUsersCsvSuccess, importUsersCsvError } from './actions';
import {
  IMPORT_USERS_UNSUCCESSFULLY,
  STATUS_CODES,
} from '../../../../../../../../cms/index';

/** If detect type: IMPORT_USERS_CSV_REQUEST call importUsersCsv() */
export default function* importUsersCsvSaga(api) {
  while (true) {
    const importCsvUsersRequest = yield take(IMPORT_USERS_CSV_REQUEST);
    if (importCsvUsersRequest.payload) {
      const { csvFile } = importCsvUsersRequest.payload;
      yield call(importUsersCsv, api, csvFile);
    }
  }
}

/** Import Users from CSV File */
function* importUsersCsv(api, csvFile) {
  let response;
  try {
    response = yield call(api.importUsersCsv, csvFile);
    if (response.status === STATUS_CODES.OK) {
      const isSuccessful = true;
      yield put(importUsersCsvSuccess(isSuccessful));
    }
  } catch (error) {
    const errorData = error.response.data;
    const errorMessage =
      errorData?.message || IMPORT_USERS_UNSUCCESSFULLY.ERROR_MESSAGE_TEXT;

    yield put(importUsersCsvError(errorMessage));
  }
}
