import { ElementType } from 'react';
import { Link, To } from 'react-router-dom';
import { SxProps, Tab, TabProps, Tabs, styled } from '@mui/material';
import { COLORS } from '../../../cms';
import { TabState } from '../../../cms/types';

type CustomTabsProps = {
  tabs: TabState[];
  divider?: boolean;
  value: any;
  onChange?: (_: React.SyntheticEvent<Element, Event>, newValue: any) => void;
  className?: string;
  sx?: SxProps;
};

type StyledTabsProps = {
  divider?: boolean;
};

const StyledTabs = styled(Tabs, {
  shouldForwardProp: (prop) => prop !== 'divider',
})<StyledTabsProps>(({ divider = false }) => ({
  '& .MuiTabs-flexContainer': {
    gap: '1rem',
  },
  '& .MuiTabs-indicator': {
    height: 0,
  },
  borderBottom: `${divider ? `thin solid ${COLORS.DIVIDER}` : 'none'}`,
}));

const StyledTab = styled(Tab)<TabProps & { component?: ElementType; to?: To }>({
  fontFamily: 'OpenSans',
  color: COLORS.TEXT,
  fontSize: '1rem',
  textTransform: 'none',
  borderBottom: `0.2rem solid transparent`,
  padding: 0,
  '&:hover': {
    borderColor: COLORS.TEXT,
    textDecoration: 'none',
    color: COLORS.TEXT,
  },
  '&.Mui-selected': {
    color: COLORS.TEXT,
    fontWeight: 'bold',
    borderColor: COLORS.TEXT,
  },
});

const CustomTabs = ({
  value,
  tabs,
  divider,
  onChange,
  className,
  sx,
}: CustomTabsProps) => {
  return (
    <StyledTabs
      value={value}
      onChange={onChange}
      divider={divider}
      className={className}
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
      sx={sx}
    >
      {tabs.map(({ label, link, hide, className }) => {
        if (hide) return null;

        if (link)
          return (
            <StyledTab
              label={label}
              key={label}
              to={link}
              component={Link}
              value={link}
              className={className}
            />
          );

        return <StyledTab label={label} key={label} />;
      })}
    </StyledTabs>
  );
};

export default CustomTabs;
