import { Stack, Typography } from '@mui/material';
import './GeneralInformationSection.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { PathSchemaType } from '../../../../cms/validation/pathValidation';
import { CustomTextField, PathCategories } from '../../../../common/components';

const GeneralInformationSection = () => {
  const { control } = useFormContext<PathSchemaType>();

  return (
    <Stack
      className="general-information-section"
      spacing="1.5rem"
      alignItems="flex-start"
    >
      <Typography className="general-information-section-title">
        1. General Information
      </Typography>
      <Controller
        name="name"
        control={control}
        render={({
          field: { name, value, onChange, onBlur, ref },
          fieldState: { error },
        }) => (
          <CustomTextField
            label="Path Name"
            color="secondary"
            required
            fullWidth
            name={name}
            value={value}
            onChange={onChange}
            inputRef={ref}
            onBlur={onBlur}
            error={Boolean(error)}
            helperText={error?.message}
            inputProps={{
              className: 'path-name-input',
            }}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        render={({
          field: { name, value, onChange, onBlur, ref },
          fieldState: { error },
        }) => (
          <CustomTextField
            label="Description"
            color="secondary"
            multiline
            rows={6}
            fullWidth
            name={name}
            value={value}
            onChange={onChange}
            inputRef={ref}
            onBlur={onBlur}
            error={Boolean(error)}
            helperText={error?.message}
            inputProps={{
              className: 'path-description-input',
            }}
          />
        )}
      />
      <Controller
        name="type"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <PathCategories
            exclusive
            value={value}
            onChange={onChange}
            error={Boolean(error)}
          />
        )}
      />
    </Stack>
  );
};

export default GeneralInformationSection;
