import dayjs from 'dayjs';
import { Button } from '@mui/material';
import { useApplyForEventMutation } from '../../../rtk/api/eventApi';
import {
  useCompleteEventApplicationMutation,
  useDropEventApplicationMutation,
  useStartEventApplicationMutation,
} from '../../../rtk/api/eventApplicationApi';
import { ApplicationStatuses, ModalTypes } from '../../../cms/enums';
import { useAppDispatch } from '../../../rtk/hooks';
import { openModal } from '../../../rtk/features/modalsSlice';
import {
  BUTTON_LABELS,
  FAILED_REQUEST_MESSAGE,
  MODAL_VALUES,
  SUCCESSUL_REQUEST_MESSAGES,
} from '../../../cms';
import { EventResponse } from '../../../cms/types/eventTypes';

type ChаngeEventStatusButtonsProps = {
  event: EventResponse;
};

const ChаngeEventStatusButtons = ({ event }: ChаngeEventStatusButtonsProps) => {
  const dispatch = useAppDispatch();
  const [applyForEvent] = useApplyForEventMutation();
  const [startEventApplication] = useStartEventApplicationMutation();
  const [completeEventApplication] = useCompleteEventApplicationMutation();
  const [dropEventApplication] = useDropEventApplicationMutation();
  const applicationStatus = event.applicationStatus;
  const applicationId = event.eventUserId;
  const isExpired =
    event.endDate !== null && dayjs().isAfter(event.endDate, 'date');

  const showArchived =
    event.isArchived &&
    (applicationStatus === null ||
      [
        ApplicationStatuses.COMPLETED,
        ApplicationStatuses.DROPPED,
        ApplicationStatuses.REJECTED,
      ].includes(applicationStatus));

  const showExpired =
    isExpired &&
    (applicationStatus === null ||
      [
        ApplicationStatuses.COMPLETED,
        ApplicationStatuses.DROPPED,
        ApplicationStatuses.REJECTED,
      ].includes(applicationStatus));

  const showApply =
    applicationStatus === null ||
    [ApplicationStatuses.DROPPED, ApplicationStatuses.REJECTED].includes(
      applicationStatus,
    );

  const showDrop =
    applicationStatus !== null &&
    [
      ApplicationStatuses.PENDING,
      ApplicationStatuses.APPROVED,
      ApplicationStatuses.IN_PROGRESS,
    ].includes(applicationStatus);

  const showStart = applicationStatus === ApplicationStatuses.APPROVED;
  const showComplete = applicationStatus === ApplicationStatuses.IN_PROGRESS;

  const handleOnRequestSuccess = (message: string) => {
    dispatch(
      openModal({
        modalType: ModalTypes.SUCCESS_MESSAGE,
        modalData: message,
        timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
      }),
    );
  };

  const handleOnRequestError = (error: any) => {
    const errorMessage = error?.data?.message ?? FAILED_REQUEST_MESSAGE;

    dispatch(
      openModal({
        modalType: ModalTypes.ERROR_MESSAGE,
        modalData: errorMessage,
        timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
      }),
    );
  };

  const handleOnApply = () => {
    applyForEvent(event.id)
      .unwrap()
      .then(() =>
        handleOnRequestSuccess(SUCCESSUL_REQUEST_MESSAGES.APPLY_EVENT),
      )
      .catch(handleOnRequestError);
  };

  const handleOnStart = () => {
    startEventApplication(applicationId)
      .unwrap()
      .then(() =>
        handleOnRequestSuccess(SUCCESSUL_REQUEST_MESSAGES.START_EVENT),
      )
      .catch(handleOnRequestError);
  };

  const handleOnComplete = () => {
    completeEventApplication(applicationId)
      .unwrap()
      .then(() =>
        handleOnRequestSuccess(SUCCESSUL_REQUEST_MESSAGES.COMPLETE_EVENT),
      )
      .catch(handleOnRequestError);
  };

  const handleOnDrop = () => {
    dropEventApplication(applicationId)
      .unwrap()
      .then(() => handleOnRequestSuccess(SUCCESSUL_REQUEST_MESSAGES.DROP_EVENT))
      .catch(handleOnRequestError);
  };

  if (showArchived)
    return (
      <Button variant="contained" disabled>
        Archived
      </Button>
    );

  if (showExpired)
    return (
      <Button variant="contained" disabled>
        Expired
      </Button>
    );

  return (
    <>
      {showApply && (
        <Button
          variant="contained"
          className="apply-event-button"
          onClick={handleOnApply}
        >
          {BUTTON_LABELS.APPLY}
        </Button>
      )}
      {showStart && (
        <Button
          variant="contained"
          className="start-event-button"
          onClick={handleOnStart}
        >
          {BUTTON_LABELS.START}
        </Button>
      )}

      {showDrop && (
        <Button
          variant="outlined"
          color="error"
          className="drop-event-button"
          onClick={handleOnDrop}
        >
          {BUTTON_LABELS.DROPOUT}
        </Button>
      )}
      {showComplete && (
        <Button
          variant="contained"
          color="success"
          className="complete-event-button"
          onClick={handleOnComplete}
        >
          {BUTTON_LABELS.COMPLETE}
        </Button>
      )}
    </>
  );
};

export default ChаngeEventStatusButtons;
