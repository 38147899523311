import { z } from 'zod';
import { VALIDATION_MESSAGES } from '..';


export const LoginSchema = z.object({
  username: z.string().min(1, {
    message: VALIDATION_MESSAGES.REQUIRED,
  }),
  password: z.string().min(1, {
    message: VALIDATION_MESSAGES.REQUIRED,
  }),
});

export type LoginSchemaType = z.infer<typeof LoginSchema>;
