import { Stack, Typography } from '@mui/material';
import { EVENT_DETAIL_LABELS } from '../../../cms';
import toBeDefinedSpeakers from '../../../common/images/to-be-defined-speakers.png';
import './SpeakersDetails.scss';
import SpeakerItem from './SpeakerItem/SpeakerItem';
import SpeakerItem2 from './SpeakerItem2/SpeakerItem2';
import { SpeakerSchemaType } from '../../../cms/validation/eventValidation';

type SpeakersProps = {
  speakers: any[];
  useImagePlaceholder?: boolean;
  column?: boolean;
  className?: string;
  onStartEdit?: (speaker: SpeakerSchemaType) => void;
  onDelete?: (index: number) => void;
};

const SpeakersDetails = ({
  speakers,
  useImagePlaceholder = true,
  column = false,
  className,
  onStartEdit = () => {},
  onDelete = () => {},
}: SpeakersProps) => {
  return (
    <Stack
      direction="column"
      gap="1rem"
      alignItems="flex-start"
      className={`speakers-details ${className}`}
    >
      <Typography className="section-title">
        {EVENT_DETAIL_LABELS.SPEAKERS}
      </Typography>
      {speakers?.length === 0 &&
        (useImagePlaceholder ? (
          <img
            alt="No Agenda"
            src={toBeDefinedSpeakers}
            className="img-no-agenda"
          />
        ) : (
          <Typography
            sx={{
              fontSize: 14,
            }}
          >
            {`You haven't added any speakers yet.`}
          </Typography>
        ))}
      {speakers?.length > 0 && (
        <Stack
          direction={column ? 'column' : 'row'}
          className="speakers-container"
          gap={column ? '1rem' : '2rem'}
        >
          {speakers.map((speaker, index) =>
            column ? (
              <SpeakerItem2
                key={index}
                speaker={speaker}
                speakerIndex={index}
                onStartEdit={onStartEdit}
                onDelete={onDelete}
              />
            ) : (
              <SpeakerItem key={index} speaker={speaker} />
            ),
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default SpeakersDetails;
