import { ButtonBase, Icon, Stack, Typography } from '@mui/material';
import './ContentSectionOption.scss';
import { PathItemTypes } from '../../../../../cms/enums';
import { PathContentOption } from '../../../../../cms/types/pathTypes';

type ContentSectionOptionProps = PathContentOption & {
  onClick: (type: PathItemTypes) => void;
};

const ContentSectionOption = ({
  onClick,
  type,
  label,
  iconName,
  disabled = false,
}: ContentSectionOptionProps) => (
  <ButtonBase
    className="content-section-option-btn"
    disabled={disabled}
    onClick={() => onClick(type)}
    sx={{
      width: { xs: '100%', sm: 160 },
    }}
  >
    <Stack key={label} className="content-section-option" spacing="0.5rem">
      <Icon className="content-section-option-icon">{iconName}</Icon>
      <Typography className="content-section-option-label">{label}</Typography>
    </Stack>
  </ButtonBase>
);
export default ContentSectionOption;
