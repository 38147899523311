import { useEffect } from 'react';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import { FormLabel, Grid, InputAdornment, Stack } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { LOCATION_TYPES_MAPPED, TRAINING_DETAILS } from '../../../cms';
import { EventSchemaType } from '../../../cms/validation/eventValidation';
import { TrainingFormSchemaType } from '../../../cms/validation/trainingValidation';
import {
  CustomTextField,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from '../index';
import { LocationType } from '../../../cms/enums';

export type LocationSectionType = 'Event' | 'Training';

type LocationSectionProps = {
  type: LocationSectionType;
};

const LocationSection = ({ type }: LocationSectionProps) => {
  const { control, setValue, watch, trigger } = useFormContext<
    EventSchemaType | TrainingFormSchemaType
  >();
  const watchLocation = watch('location');
  const address =
    type === 'Event' ? 'eventLocation' : 'trainingLocationAddress';

  const LOCATIONS_WITH_REQUIRED_ADDRESS: LocationType[] = [
    'OnSite',
    'Internal',
  ];

  useEffect(() => {
    if (watchLocation === 'Online') {
      setValue(address, '');
      trigger(address);
    }
  }, [watchLocation]);

  return (
    <Grid container spacing="1rem">
      <Grid item xs={12} lg="auto">
        <Stack className="location-section" flex={1}>
          <FormLabel required sx={{ fontSize: 12 }}>
            {TRAINING_DETAILS.LOCATION_TEXT}
          </FormLabel>
          <Controller
            name="location"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <StyledToggleButtonGroup
                  value={value}
                  onChange={onChange}
                  exclusive
                  aria-label="location type"
                >
                  {Object.values(LOCATION_TYPES_MAPPED).map(
                    ({ name, value: v }) => (
                      <StyledToggleButton
                        key={name}
                        value={v}
                        className={clsx(
                          'location-toggle-btn',
                          error && 'toggle-btn-error',
                        )}
                      >
                        {name}
                      </StyledToggleButton>
                    ),
                  )}
                </StyledToggleButtonGroup>
              );
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} lg>
        <Stack justifyContent="flex-end" height="100%">
          <Controller
            name={address}
            control={control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <CustomTextField
                color="secondary"
                required={LOCATIONS_WITH_REQUIRED_ADDRESS.includes(
                  watchLocation,
                )}
                disabled={
                  !LOCATIONS_WITH_REQUIRED_ADDRESS.includes(watchLocation)
                }
                fullWidth
                label={TRAINING_DETAILS.ADDRESS_TEXT}
                name={address}
                value={value}
                onChange={onChange}
                inputRef={ref}
                onBlur={onBlur}
                error={Boolean(error)}
                helperText={error?.message}
                inputProps={{
                  className: 'location-input',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default LocationSection;
