export function checkForValidationErrorsForField(validationErrors, field) {
  let result = false;
  const lowerCaseField = field.toLowerCase();
  if (validationErrors !== undefined && validationErrors.length) {
    result = validationErrors.some(
      (error) => error.key.toLowerCase() === lowerCaseField,
    );
  }

  return result;
}

export function getErrorMessageForField(validationErrors, field) {
  let result = '';
  const lowerCaseField = field.toLowerCase();
  const filteredErrors = validationErrors.filter(
    (error) => error.key.toLowerCase() === lowerCaseField,
  );
  if (filteredErrors.length) {
    result = filteredErrors[0].value[0].errorMessage;
  }

  return result;
}

export function prepareErrorText(validationErrors) {
  let result = '';
  const allErrors = Object.values(validationErrors);

  if (allErrors.length === 1) {
    result = allErrors[0];
  } else if (allErrors.length > 1) {
    allErrors.map((error, index) => {
      result += `${index + 1}) ${error}`;
      result += '\n';

      return error;
    });
  }
  return result;
}

export function toKebabCase(str) {
  return str.replace(/([a-z])([A-Z])/g, "$1-$2")
     .replace(/[\s_]+/g, '-')
     .toLowerCase();
}