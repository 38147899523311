import { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router';
import {
  AdministrationPage,
  EventsPage,
  TrainingsPage,
  CookiePolicy,
  CreatePathPage,
  DashboardPage,
  TermsOfServicePage,
  LandingPage,
  LoginPage,
  PathsPage,
  PrivacyNotice,
  PathDetailsPage,
  EditPathPage,
  MyPaths,
  MyGroup,
  MyEmployees,
  MyLearningHubPage,
  MyTrainingsAndEvents,
} from '../pages';
import { ROUTE_LINKS } from '.';
import TrainingApplicationsTab from '../pages/AdministrationPage/tabs/TrainingApplicationsTab/TrainingApplicationsTab';
import EventApplicationsTab from '../pages/AdministrationPage/tabs/EventApplicationsTab/EventApplicationsTab';
import GroupManagementTab from '../pages/AdministrationPage/tabs/GroupManagementTab/GroupManagementTab';
import EmployeeManagementTab from '../pages/AdministrationPage/tabs/EmployeeManagementTab/EmployeeManagementTab';
import ReportsTab from '../pages/AdministrationPage/tabs/ReportsTab/ReportsTab';
import SubmissionsTab from '../pages/AdministrationPage/tabs/SubmissionsTab/SubmissionsTab';
import RequestsTab from '../pages/MyGroupPage/RequestsTab/RequestsTab';
import ContentTab from '../pages/PathDetailsPage/tabs/ContentTab/ContentTab';
import AssigneesTab from '../pages/PathDetailsPage/tabs/AssigneesTab/AssigneesTab';

export const DEFAULT_ROUTES: RouteObject[] = [
  {
    path: ROUTE_LINKS.PRIVACY_NOTICE,
    element: <PrivacyNotice />,
  },
  {
    path: ROUTE_LINKS.COOKIE_POLICY,
    element: <CookiePolicy />,
  },
  {
    path: ROUTE_LINKS.TOS,
    element: <TermsOfServicePage />,
  },
];

export const UNAUTHORIZED_ROUTES = (isTrial: boolean): RouteObject[] =>
  isTrial
    ? [
        {
          path: ROUTE_LINKS.LANDING,
          element: <LandingPage />,
        },
        {
          path: '*',
          element: <Navigate to={ROUTE_LINKS.LANDING} />,
        },
      ]
    : [
        {
          path: ROUTE_LINKS.LOGIN,
          element: <LoginPage />,
        },
        {
          path: '*',
          element: <Navigate to={ROUTE_LINKS.LOGIN} />,
        },
      ];

export const AUTHORIZED_ROUTES = (
  isManager: boolean,
  isAdmin: boolean,
): RouteObject[] => [
  {
    path: ROUTE_LINKS.DEFAULT,
    element: <DashboardPage />,
  },
  {
    path: ROUTE_LINKS.TRAININGS,
    element: <TrainingsPage />,
  },
  {
    path: ROUTE_LINKS.EVENTS,
    element: <EventsPage />,
  },
  {
    path: ROUTE_LINKS.MY_LEARNING_HUB,
    element: <MyLearningHubPage />,
    children: [
      {
        path: ROUTE_LINKS.MY_TRAININGS_AND_EVENTS,
        element: <MyTrainingsAndEvents />,
      },
      {
        path: ROUTE_LINKS.MY_PATHS,
        element: <MyPaths />,
      },
    ],
  },
  ...(isManager
    ? [
        {
          path: ROUTE_LINKS.MY_GROUP,
          element: <MyGroup />,
          children: [
            {
              path: ROUTE_LINKS.MY_EMPLOYEES,
              element: <MyEmployees />,
            },
            {
              path: ROUTE_LINKS.REQUESTS,
              element: <RequestsTab />,
            },
          ],
        },
        {
          path: ROUTE_LINKS.ASSIGN_PATHS,
          element: <PathsPage />,
        },
        {
          path: `${ROUTE_LINKS.ASSIGN_PATHS}/:id`,
          element: <PathDetailsPage />,
          children: [
            {
              path: `${ROUTE_LINKS.ASSIGN_PATHS}/:id/content`,
              element: <ContentTab />,
            },
            ...(isAdmin
              ? [
                  {
                    path: `${ROUTE_LINKS.ASSIGN_PATHS}/:id/assignees`,
                    element: <AssigneesTab />,
                  },
                ]
              : []),
          ],
        },
      ]
    : []),
  ...(isAdmin
    ? [
        {
          path: ROUTE_LINKS.ADMINISTRATION,
          element: <AdministrationPage />,
          children: [
            {
              path: ROUTE_LINKS.TRAINING_APPLICATIONS,
              element: <TrainingApplicationsTab />,
            },
            {
              path: ROUTE_LINKS.EVENT_APPLICATIONS,
              element: <EventApplicationsTab />,
            },
            {
              path: ROUTE_LINKS.GROUP_MANAGEMENT,
              element: <GroupManagementTab />,
            },
            {
              path: ROUTE_LINKS.EMPLOYEE_MANAGEMENT,
              element: <EmployeeManagementTab />,
            },
            {
              path: ROUTE_LINKS.REPORTS,
              element: <ReportsTab />,
            },
            {
              path: ROUTE_LINKS.SUBMISSIONS,
              element: <SubmissionsTab />,
            },
          ],
        },
      ]
    : []),
  ...(isManager || isAdmin
    ? [
        {
          path: ROUTE_LINKS.PATHS,
          element: <PathsPage />,
        },
        {
          path: `${ROUTE_LINKS.EDIT_PATH}/:id`,
          element: <EditPathPage />,
        },
        {
          path: ROUTE_LINKS.CREATE_PATH,
          element: <CreatePathPage />,
        },
      ]
    : []),
  {
    path: `${ROUTE_LINKS.PATHS}/:id`,
    element: <PathDetailsPage />,
    children: [
      {
        path: `${ROUTE_LINKS.PATHS}/:id/content`,
        element: <ContentTab />,
      },
      ...(isAdmin
        ? [
            {
              path: `${ROUTE_LINKS.PATHS}/:id/assignees`,
              element: <AssigneesTab />,
            },
          ]
        : []),
    ],
  },
  {
    path: `${ROUTE_LINKS.PERSONAL_PATHS}/:id`,
    element: <PathDetailsPage />,
    children: [
      {
        path: `${ROUTE_LINKS.PERSONAL_PATHS}/:id/content`,
        element: <ContentTab />,
      },
      ...(isAdmin
        ? [
            {
              path: `${ROUTE_LINKS.PERSONAL_PATHS}/:id/assignees`,
              element: <AssigneesTab />,
            },
          ]
        : []),
    ],
  },
  {
    path: '*',
    element: <Navigate to={ROUTE_LINKS.DEFAULT} replace />,
  },
];
