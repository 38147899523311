import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useParams, useNavigate, useLocation, Outlet } from 'react-router';
import {
  Button,
  Container,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  useAssignPathToUsersMutation,
  useDropPathMutation,
  useLazyGetPathQuery,
  useLazyGetPersonalPathQuery,
} from '../../rtk/api/pathApi';
import { CustomTabs, PathCard, SectionLoader } from '../../common/components';
import './PathDetailsPage.scss';
import {
  BUTTON_LABELS,
  FAILED_REQUEST_MESSAGE,
  MODAL_CLOSE_BTNS,
  MODAL_VALUES,
  MY_EMPLOYEES_LABELS,
  PATHS_LABELS,
  ROLES,
  ROUTE_LINKS,
} from '../../cms';
import { ModalTypes, PathItemTypes, PathStatuses } from '../../cms/enums';
import { assignTypeToPathItem } from '../../cms/Utils';
import {
  PathDetailsPageOutletContext,
  PathResponse,
} from '../../cms/types/pathTypes';
import { getUserRoles } from '../../common/userRoleRetriever';
import { useNavigateBack } from '../../common/hooks/useNavigateBack';
import { useAppDispatch } from '../../rtk/hooks';
import { openModal } from '../../rtk/features/modalsSlice';

/**
 * @description Represents the detailed information for a path and provides varios actions.
  It handles normal path details view and personal path view.
  It handles assign path from admin and assign path from manager based on search params.
 */

const PathDetailsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const { id } = useParams();
  const pathId: number = id ? +id : -1;
  const [searchParams] = useSearchParams();
  const employeeId = searchParams.get('employeeId');
  const employeeName = searchParams.get('employeeName');
  const { pathname, search } = useLocation();
  const fullPathname = pathname + search;
  const isPersonal = fullPathname.includes(ROUTE_LINKS.PERSONAL_PATHS);
  const [getPath, { isError: isPathError, data: pathData }] =
    useLazyGetPathQuery();
  const [
    getPersonalPath,
    { isError: isPersonalPathError, data: personalPathData },
  ] = useLazyGetPersonalPathQuery();
  const [assignPathToUsers, { isLoading: isAssignLoading }] =
    useAssignPathToUsersMutation();
  const [dropPath, { isLoading: isDropLoading }] = useDropPathMutation();
  const [path, setPath] = useState<PathResponse>();
  const {
    trainings = [],
    events = [],
    items = [],
    assignees = [],
    status,
  } = path ?? {};
  const contentItems = [
    ...assignTypeToPathItem(trainings, PathItemTypes.TRAINING),
    ...assignTypeToPathItem(events, PathItemTypes.EVENT),
    ...items,
  ].sort((a, b) => a.position - b.position);
  const isAdmin = getUserRoles().includes(ROLES.ADMIN_ROLE);
  const isAssignPath = !!employeeId && !!employeeName;
  const isPathDroppable =
    isPersonal &&
    [
      PathStatuses.NOT_STARTED,
      PathStatuses.IN_PROGRESS,
      PathStatuses.APPROVED,
    ].includes(status!);

  const getPathLinkPrefix = (): string => {
    if (isAssignPath) return ROUTE_LINKS.ASSIGN_PATHS;
    if (isPersonal) return ROUTE_LINKS.PERSONAL_PATHS;

    return ROUTE_LINKS.PATHS;
  };

  const PATH_LINK = `${getPathLinkPrefix()}/${id}`;
  const PATH_CONTENT_LINK = `${PATH_LINK}/content${search}`;
  const PATH_ASSIGNEES_LINK = `${PATH_LINK}/assignees${search}`;

  const tabs = [
    {
      label: PATHS_LABELS.CONTENT,
      link: PATH_CONTENT_LINK,
      className: 'content-tab-btn',
    },
    ...(isAdmin
      ? [
          {
            label: `${PATHS_LABELS.ASSIGNEES} (${assignees.length})`,
            link: PATH_ASSIGNEES_LINK,
            className: 'assignees-tab-btn',
          },
        ]
      : []),
  ];

  const isATabSelected =
    fullPathname.includes(PATH_CONTENT_LINK) ||
    fullPathname.includes(PATH_ASSIGNEES_LINK);

  const tabValue = isATabSelected ? fullPathname : false;

  useEffect(() => {
    if (!isATabSelected) navigate(PATH_CONTENT_LINK, { replace: true });
  }, [isATabSelected]);

  useEffect(() => {
    if (pathId === -1) {
      handleGoBackClick();
      return;
    }

    if (isPersonal) {
      getPersonalPath(pathId);
    } else {
      getPath(pathId);
    }
  }, [isPersonal]);

  useEffect(() => {
    if (pathData) setPath(pathData);
  }, [pathData]);

  useEffect(() => {
    if (personalPathData) setPath(personalPathData);
  }, [personalPathData]);

  useEffect(() => {
    if (isPathError) handleGoBackClick();
  }, [isPathError]);

  useEffect(() => {
    // Try to open normal path if it's not assigned to the current user
    if (isPersonalPathError)
      navigate(`${ROUTE_LINKS.PATHS}/${pathId}`, { replace: true });
  }, [isPersonalPathError]);

  const handleGoBackClick = () => {
    navigateBack({ fallback: ROUTE_LINKS.PATHS });
  };

  const handleAssignPath = () => {
    if (path && employeeId) {
      assignPathToUsers({
        pathId: Number(path.id),
        userIds: [Number(employeeId)],
      })
        .unwrap()
        .then(() => {
          dispatch(
            openModal({
              modalType: ModalTypes.SUCCESS_MESSAGE,
              modalData: MY_EMPLOYEES_LABELS.ASSIGN_PATH_SUCCESS,
              timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
            }),
          );

          setTimeout(() => {
            navigate(ROUTE_LINKS.MY_EMPLOYEES);
          }, MODAL_VALUES.MODAL_CLOSE_TIMEOUT);
        })
        .catch((error) => {
          const errorMessage = error?.data?.message ?? FAILED_REQUEST_MESSAGE;

          dispatch(
            openModal({
              modalType: ModalTypes.ERROR_MESSAGE,
              modalData: errorMessage,
              timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
            }),
          );
        });
    }
  };

  const handleDropPath = () => {
    if (pathId) {
      dispatch(
        openModal({
          modalType: ModalTypes.CONFIRM_ACTION,
          closeBtn: MODAL_CLOSE_BTNS.OUTSIDE,
          modalData: {
            text: PATHS_LABELS.DROP_CONFIRMATION_TEXT,
            onConfirm: () => dropPath(pathId),
          },
        }),
      );
    }
  };

  if (!path) return <SectionLoader />;

  return (
    <Container maxWidth="lg">
      <Stack
        className="path-details-page"
        spacing="1rem"
        sx={{ padding: { xs: '1rem', sm: '2rem' } }}
      >
        <Stack spacing="1rem">
          <Stack
            direction="row"
            spacing="1rem"
            className="path-details-page-header"
          >
            <IconButton
              onClick={handleGoBackClick}
              className="path-details-page-back-btn"
            >
              <ArrowBackIosNewIcon className="path-details-page-header-btn" />
            </IconButton>
            <Typography className="path-details-page-header-title">
              Path Details
            </Typography>
            {isAssignPath && (
              <Typography sx={{ fontSize: '18px' }}>
                {`(Review path for ${employeeName})`}
              </Typography>
            )}
          </Stack>
          <Divider />
        </Stack>

        <PathCard path={path} isPathDetails />

        <Typography className="path-details-page-description">
          {path.description}
        </Typography>
        <CustomTabs
          tabs={tabs}
          value={tabValue}
          divider
          className="path-details-tabs"
        />
        <Outlet
          context={
            {
              isPersonal,
              items: contentItems,
              pathId,
              assignees,
            } satisfies PathDetailsPageOutletContext
          }
        />
        <Stack direction="row" gap="1rem" sx={{ marginLeft: 'auto' }}>
          {isPathDroppable && (
            <Button
              variant="outlined"
              color="primary"
              disabled={isDropLoading}
              onClick={handleDropPath}
              className="drop-path-btn"
            >
              {BUTTON_LABELS.DROP}
            </Button>
          )}

          {isAssignPath && (
            <>
              <Button
                variant="outlined"
                color="primary"
                disabled={isAssignLoading}
                onClick={handleGoBackClick}
                className="cancel-assign-path-btn"
              >
                {BUTTON_LABELS.CANCEL}
              </Button>
              <LoadingButton
                onClick={handleAssignPath}
                variant="contained"
                loading={isAssignLoading}
                className="assign-path-btn"
              >
                {MY_EMPLOYEES_LABELS.ASSIGN_PATH_TEXT}
              </LoadingButton>
            </>
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export default PathDetailsPage;
