import './AboutUsSection.scss';
import strypesCEOImage from '../../common/images/strypes-ceo-image.png';
import strypesSmallLogo from '../../common/images/strypes-logo-about-us-section.png';
import {
  LANDING_PAGE_ABOUT_US_LABELS,
  LandingPageSections,
  SCREEN_SIZES,
} from '../../cms/index';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import { addSmallStyle } from '../../cms/Utils';

const AboutUsSection = () => {
  const isScreenSmall = useMediaQuery(SCREEN_SIZES.SMALL);

  return (
    <section
      id={LandingPageSections.ABOUT_US}
      className={`about-us-section-container${addSmallStyle(isScreenSmall)}`}
    >
      <div className="about-us-section-data-container">
        <h2 className="about-us-section-data-title">
          {LANDING_PAGE_ABOUT_US_LABELS.ABOUT_STRYPES}
        </h2>
        <p className="about-us-section-data-sub-title">
          {LANDING_PAGE_ABOUT_US_LABELS.ABOUT_US_SUB_TITLE}
        </p>
        <p className="about-us-section-data-description">
          {LANDING_PAGE_ABOUT_US_LABELS.ABOUT_US_CONTENT}
        </p>
        <div className="about-us-section-data-footer">
          <button
            className="about-us-section-learn-more-btn"
            onClick={() => window.open('https://www.strypes.eu', '_blank')}
          >
            {LANDING_PAGE_ABOUT_US_LABELS.LEARN_MORE}
          </button>
          <img
            src={strypesSmallLogo}
            alt="Strypes Logo"
            className="about-us-section-strypes-logo"
          />
        </div>
      </div>
      <img
        src={strypesCEOImage}
        alt="CEO"
        className={`about-us-section-image${addSmallStyle(isScreenSmall)}`}
      />
    </section>
  );
};

export default AboutUsSection;
