import { Avatar, Stack, Typography } from '@mui/material';
import { extractInitials } from '../../../../cms/Utils';
import './SpeakerItem.scss';
import { SpeakerResponse } from '../../../../cms/types/eventTypes';

type SpeakerItemProps = {
  speaker: SpeakerResponse;
};

const SpeakerItem = ({ speaker }: SpeakerItemProps) => {
  const fullName = `${speaker.firstName} ${speaker.lastName}`;

  return (
    <Stack
      direction="column"
      className="speaker-item"
      alignItems="center"
      gap="0.5rem"
    >
      <Avatar
        className="speaker-avatar"
        src={`data:image/png;base64, ${speaker.photoUrl}`}
        alt="Speaker"
        variant="circular"
      >
        {extractInitials(fullName)}
      </Avatar>
      <Stack direction="column" alignItems="center" textAlign="center">
        <Typography title={fullName} className="speaker-name" noWrap>
          {fullName}
        </Typography>
        {speaker.jobTitle && speaker.jobTitle?.length > 0 && (
          <Typography
            title={speaker.jobTitle}
            className="speaker-job-title"
            noWrap
          >
            {speaker.jobTitle}
          </Typography>
        )}
        {speaker.company && speaker.company?.length > 0 && (
          <Typography
            title={speaker.company}
            className="speaker-company"
            noWrap
          >
            ({speaker.company})
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default SpeakerItem;
