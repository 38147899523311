import { useEffect, useRef, useState } from 'react';
import './UserImportModalContent.scss';
import { useSelector } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { Button, Divider, Stack, Typography } from '@mui/material';
import {
  BUTTON_LABELS,
  FILE_UPLOAD_DROP_ZONE,
  IMPORT_USERS_SUCCESSFULLY,
  MODAL_VALUES,
  USERS_IMPORT_MODAL,
} from '../../../../../../../../cms';
import { importUsersCsv, userImportReducerDefault } from './actions';
import DragAndDropFiles from '../../../../../../../../common/components/DragAndDropFiles/DragAndDropFiles';
import { openModal } from '../../../../../../../../rtk/features/modalsSlice';
import { useAppDispatch } from '../../../../../../../../rtk/hooks';
import { ModalTypes } from '../../../../../../../../cms/enums';

interface Props {
  handleCloseUserImportModal: () => void;
}
interface UserImportState {
  isSuccessful: boolean;
  errorMessage: string;
}
interface RootState {
  userImport: UserImportState;
}

function UserImportModalContent({ handleCloseUserImportModal }: Props) {
  const dispatch = useAppDispatch();
  const { isSuccessful, errorMessage } = useSelector(
    (state: RootState) => state.userImport,
  );
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [isImporting, setIsImporting] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileNameAlreadyExistsError, setFileNameAlreadyExistsError] =
    useState(false);
  const [maxFileNumberError, setMaxFileNumberError] = useState(false);
  const [errorResponseMessage, setErrorResponseMessage] = useState<string>('');

  const clearFileSelection = () => setFiles([]);

  const handleChange = (file: any) => {
    setErrorResponseMessage('');
    setFiles([file]);
  };

  const onFileChange = (data: any) => {
    if (data.target.files[0].type !== 'text/csv') setFileTypeError(true);
    else setFiles([data.target.files[0]]);
  };

  const uploadCsv = () => {
    const formData = new FormData();
    formData.append('csvFile', files[0], 'multipart/form-data');
    dispatch(importUsersCsv(formData) as AnyAction);
    setIsImporting(true);
  };

  const handleSuccessClose = () => {
    handleCloseUserImportModal();
  };

  useEffect(() => {
    if (isSuccessful) {
      setTimeout(() => {
        setIsImporting(false);
        setFiles([]);
        dispatch(userImportReducerDefault());
        handleSuccessClose();
      }, MODAL_VALUES.MODAL_CLOSE_TIMEOUT);

      dispatch(
        openModal({
          modalType: ModalTypes.SUCCESS_MESSAGE,
          modalData: IMPORT_USERS_SUCCESSFULLY.SUCCESS_MESSAGE_TEXT,
          timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
        }),
      );
    }
  }, [isSuccessful]);

  useEffect(() => {
    setErrorResponseMessage(errorMessage);
    setFiles([]);
  }, [errorMessage]);

  useEffect(() => {
    if (errorResponseMessage && errorResponseMessage.length > 0)
      setIsImporting(false);
  }, [errorResponseMessage]);

  useEffect(() => {
    if (files[0]) {
      setErrorResponseMessage('');
      if (files[0] && files[0].type === 'text/csv') setFileTypeError(false);
      else setFileTypeError(true);
    }
  }, [files]);

  useEffect(() => {
    setErrorResponseMessage('');
  }, []);

  useEffect(() => {
    setErrorResponseMessage('');
  }, [fileTypeError]);

  return (
    <Stack className="pdf-upload">
      <Stack>
        <Typography className="pdf-upload-title">
          {USERS_IMPORT_MODAL.IMPORT_CSV_MODAL_HEADLINE}
        </Typography>
        <Divider />
      </Stack>
      <Stack gap="1.5rem" alignItems="center" padding="2rem">
        <DragAndDropFiles
          handleChange={handleChange}
          files={files}
          clearFileSelection={clearFileSelection}
          fileTypeError={fileTypeError}
          fileSizeError={fileSizeError}
          fileNameAlreadyExistsError={fileNameAlreadyExistsError}
          maxFileNumberError={maxFileNumberError}
          setFileTypeError={setFileTypeError}
          setFileSizeError={setFileSizeError}
          errorMessage={errorResponseMessage}
          isImporting={isImporting}
          checkForImporting
          uploadFileCenterMessage={FILE_UPLOAD_DROP_ZONE.DRAG_N_DROP_TEXT}
          supportedFileTypes={['CSV']}
          maxSize={100}
          allowMultiple={false}
          disableClearFile={false}
        />
        <Typography>OR</Typography>
        <Stack
          direction="row"
          gap="1rem"
          flexWrap="wrap"
          justifyContent="center"
        >
          <Button
            variant="contained"
            className="browse-file-btn"
            onClick={() => inputFile.current!.click()}
            disabled={isImporting}
          >
            {BUTTON_LABELS.BROWSE}
          </Button>
          <input
            type="file"
            accept="text/csv"
            defaultValue={[]}
            onChange={onFileChange}
            id="file"
            ref={inputFile}
            style={{ display: 'none' }}
          />
          {files.length > 0 && (
            <Button
              variant="contained"
              className="browse-file-btn"
              onClick={uploadCsv}
              disabled={isImporting}
            >
              {USERS_IMPORT_MODAL.UPLOAD_BUTTON}
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default UserImportModalContent;
