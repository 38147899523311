import './PrivateTrainingsFilter.scss';
import { Chip } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

type PrivateTrainingsFilterProps = {
  isPrivateFilterSelected: boolean;
  setIsPrivateFilterSelected: (isPrivate: boolean) => void;
};

const PrivateTrainingsFilter = ({
  isPrivateFilterSelected,
  setIsPrivateFilterSelected,
}: PrivateTrainingsFilterProps) => {
  return (
    <Chip
      onClick={() => setIsPrivateFilterSelected(!isPrivateFilterSelected)}
      icon={<LockIcon color="primary" sx={{ fontSize: 16 }} />}
      label="Private"
      className={`private-filter ${
        isPrivateFilterSelected ? `skill-type-selected` : 'skill-type'
      }`}
    />
  );
};

export default PrivateTrainingsFilter;
