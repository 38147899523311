import { isEqual } from 'lodash';
import { api } from './api';
import {
  APPROVE_TRAINING_APPLICATION_URL,
  COMPLETE_TRAINING_APPLICATION_URL,
  DROP_TRAINING_APPLICATION_URL,
  GET_ALL_TRAINING_APPLICATIONS_URL,
  REJECT_TRAINING_APPLICATION_URL,
  START_TRAINING_APPLICATION_URL,
} from '../../api/trainingsPortalBackend/config';
import {
  GetTrainingApplicationsForExportParams,
  GetTrainingApplicationsParams,
  TrainingApplication,
  TrainingApplicationActionRequestModel,
} from '../../cms/types/trainingApplicationTypes';

export const trainingApplicationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTrainingApplications: build.query<
      TrainingApplication[],
      GetTrainingApplicationsParams
    >({
      query: (params) => ({
        url: GET_ALL_TRAINING_APPLICATIONS_URL,
        params,
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentData, responseData, { arg }) => {
        if (arg?.pageNumber === 1) {
          return responseData;
        }

        currentData.push(...responseData);
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        isEqual(currentArg, previousArg),
    }),
    getTrainingApplicationsForExport: build.query<
      TrainingApplication[],
      GetTrainingApplicationsForExportParams
    >({
      query: (params) => ({
        url: GET_ALL_TRAINING_APPLICATIONS_URL,
        params,
      }),
    }),
    approveTrainingApplication: build.mutation<
      any,
      TrainingApplicationActionRequestModel
    >({
      query: (model) => ({
        url: APPROVE_TRAINING_APPLICATION_URL,
        method: 'POST',
        body: model,
      }),
      invalidatesTags: ['MyTeamApplications'],
    }),
    rejectTrainingApplication: build.mutation<
      any,
      TrainingApplicationActionRequestModel
    >({
      query: (model) => ({
        url: REJECT_TRAINING_APPLICATION_URL,
        method: 'POST',
        body: model,
      }),
      invalidatesTags: ['MyTeamApplications'],
    }),
    startTrainingApplication: build.mutation<any, number>({
      query: (applicationId) => ({
        url: `${START_TRAINING_APPLICATION_URL}/${applicationId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error) =>
        error ? [] : ['Training', 'TrainingApplication', 'Application', 'Path'],
    }),
    completeTrainingApplication: build.mutation<any, number>({
      query: (applicationId) => ({
        url: `${COMPLETE_TRAINING_APPLICATION_URL}/${applicationId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error) =>
        error ? [] : ['Training', 'TrainingApplication', 'Application', 'Path'],
    }),
    dropTrainingApplication: build.mutation<any, number>({
      query: (applicationId) => ({
        url: `${DROP_TRAINING_APPLICATION_URL}/${applicationId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error) =>
        error ? [] : ['Training', 'TrainingApplication', 'Application', 'Path'],
    }),
  }),
});

export const {
  useGetTrainingApplicationsQuery,
  useLazyGetTrainingApplicationsForExportQuery,
  useStartTrainingApplicationMutation,
  useDropTrainingApplicationMutation,
  useCompleteTrainingApplicationMutation,
  useApproveTrainingApplicationMutation,
  useRejectTrainingApplicationMutation,
} = trainingApplicationApi;
