import ThumbUp from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDown from '@mui/icons-material/ThumbDownOffAlt';
import { Stack, IconButton, Tooltip } from '@mui/material';
import { useAppDispatch } from '../../../../rtk/hooks';
import { MODAL_CLOSE_BTNS } from '../../../../cms';
import { openModal } from '../../../../rtk/features/modalsSlice';
import { MyTeamApplication } from '../../../../rtk/api/adminApi';
import { ModalTypes } from '../../../../cms/enums';

type ApplicationActionsProps = {
  application: MyTeamApplication;
};

const ApplicationActions = ({
  application: {
    applicationId,
    applicationType,
    title,
    applicantFirstName,
    applicantLastName,
  },
}: ApplicationActionsProps) => {
  const dispatch = useAppDispatch();

  const handleActionClick = (respondType: 'Approve' | 'Reject') => {
    dispatch(
      openModal({
        modalType:
          respondType === 'Approve'
            ? ModalTypes.APPROVE_APPLICATION
            : ModalTypes.REJECT_APPLICATION,
        modalData: {
          applicationId,
          applicationType,
          title,
          applicantName: `${applicantFirstName} ${applicantLastName}`,
        },
        closeBtn: MODAL_CLOSE_BTNS.INSIDE,
      }),
    );
  };

  return (
    <Stack direction="row">
      <Tooltip title="Approve">
        <IconButton
          aria-label="Approve"
          onClick={() => {
            handleActionClick('Approve');
          }}
          color="inherit"
          className="approve-button"
        >
          <ThumbUp fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Reject">
        <IconButton
          aria-label="Reject"
          onClick={() => {
            handleActionClick('Reject');
          }}
          color="inherit"
          className="reject-button"
        >
          <ThumbDown fontSize="small" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default ApplicationActions;
