import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { DonutChart } from '../../../common/components/DonutChart/DonutChart';
import { useGetMyTrainingsQuery } from '../../../rtk/api/trainingApi';
import { createTrainingsChartData } from '../../../cms/Utils';
import { TrainingStatusData } from '../../../cms/types';
import TrainingStatusDataItem from './TrainingStatusDataItem/TrainingStatusDataItem';
import { Loader } from '../../../common/components';
import { ApplicationStatuses } from '../../../cms/enums';
import { TrainingApplicationResponse } from '../../../cms/types/trainingTypes';
import './TrainingsSection.scss';

const initialChartdata = createTrainingsChartData();

const generateTrainingStatusesData = (
  applications: TrainingApplicationResponse[],
): TrainingStatusData[] => {
  const pendingCount = applications.filter(
    (a) => a.status === ApplicationStatuses.PENDING,
  ).length;

  const approvedCount = applications.filter(
    (a) => a.status === ApplicationStatuses.APPROVED,
  ).length;

  const inProgressCount = applications.filter(
    (a) => a.status === ApplicationStatuses.IN_PROGRESS,
  ).length;

  const completedCount = applications.filter(
    (a) => a.status === ApplicationStatuses.COMPLETED,
  ).length;

  return [
    {
      count: pendingCount,
      iconName: 'pending_actions',
      statusName: 'Pending',
      className: 'pending-trainings-data',
    },
    {
      count: approvedCount,
      iconName: 'thumb_up_off_alt',
      statusName: 'Approved',
      className: 'approved-trainings-data',
    },
    {
      count: inProgressCount,
      iconName: 'checklist',
      statusName: 'In Progress',
      className: 'in-progress-trainings-data',
    },
    {
      count: completedCount,
      iconName: 'workspace_premium',
      statusName: 'Completed',
      className: 'completed-trainings-data',
    },
  ];
};

const TrainingsSection = () => {
  const { data: myTrainings, isLoading } = useGetMyTrainingsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [chartData, setChartData] = useState(initialChartdata);
  const trainingStatusesData = generateTrainingStatusesData(
    myTrainings?.applications ?? [],
  );

  useEffect(() => updatedChartData(), [myTrainings]);

  const updatedChartData = () => {
    // Get completed training applications
    const completedApplications: TrainingApplicationResponse[] =
      myTrainings?.applications?.filter(
        (a: TrainingApplicationResponse) =>
          a.status === ApplicationStatuses.COMPLETED,
      ) ?? [];

    if (completedApplications.length === 0) return;

    const newChartData = createTrainingsChartData();

    // Add new values
    completedApplications.forEach((a) => {
      const chartDataItem = newChartData.find((d) => d.type === a.type);
      if (chartDataItem != null) chartDataItem.value += a.duration;
    });

    setChartData(newChartData);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="my-trainings-section">
      <div className="my-trainings-section-data">
        <Grid container spacing="2rem">
          {trainingStatusesData.map((item) => (
            <Grid item xs={12} sm={6} key={item.statusName}>
              <TrainingStatusDataItem item={item} />
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="my-trainings-section-chart-data">
        <DonutChart
          className="trainings-donut-chart"
          data={chartData}
          desc1="spent in"
          desc2="Trainings"
          dataSymbol="h"
        />
      </div>
    </div>
  );
};

export default TrainingsSection;
