import { Grid, Stack, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { TRAINER_DETAILS } from '../../../cms/index';
import { CustomTextField } from '../../../common/components';

const TrainerSection = () => {
  const { control } = useFormContext();

  return (
    <Stack spacing="0.75rem">
      <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>Trainer</Typography>
      <Stack direction="row" className="trainer-section">
        <Grid container spacing="1rem">
          <Grid item xs={12} sm="auto" lg={4}>
            <Controller
              name="trainerFirstName"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange, onBlur, ref },
                fieldState: { error },
              }) => (
                <CustomTextField
                  color="secondary"
                  fullWidth
                  label={TRAINER_DETAILS.FIRST_NAME}
                  name="trainerFirstName"
                  onChange={onChange}
                  value={value}
                  onBlur={onBlur}
                  inputRef={ref}
                  error={Boolean(error)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm="auto" lg={4}>
            <Controller
              name="trainerLastName"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange, onBlur, ref },
                fieldState: { error },
              }) => (
                <CustomTextField
                  color="secondary"
                  fullWidth
                  label={TRAINER_DETAILS.LAST_NAME}
                  name="trainerLastName"
                  onChange={onChange}
                  value={value}
                  onBlur={onBlur}
                  inputRef={ref}
                  error={Boolean(error)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm="auto" lg={4}>
            <Controller
              name="trainerJobTitle"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange, onBlur, ref },
                fieldState: { error },
              }) => (
                <CustomTextField
                  color="secondary"
                  fullWidth
                  label={TRAINER_DETAILS.JOB_TITLE}
                  name="trainerJobTitle"
                  onChange={onChange}
                  value={value}
                  onBlur={onBlur}
                  inputRef={ref}
                  error={Boolean(error)}
                />
              )}
            />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default TrainerSection;
