import { put, take, call } from 'redux-saga/effects';
import { GET_ALL_COST_REPORTS_REQUEST } from './types';
import { getAllCostReportsError, getAllCostReportsSuccess } from './actions';
import { STATUS_CODES } from '../../../../cms/index';
import { GetAllCostReportRequestModel } from './ReportsTab';

/** If detect type:  GET_ALL_COST_REPORTS_REQUEST call getAllCostReports() */
export default function* getAllCostReportsSaga(api: any): any {
  while (true) {
    const getAllCostReportsRequest = yield take(GET_ALL_COST_REPORTS_REQUEST);
    if (getAllCostReportsRequest.payload) {
      const getAllCostReportsRequestModel: GetAllCostReportRequestModel =
        getAllCostReportsRequest.payload.getAllCostReportRequestModel;
      yield call(getAllCostReports, api, getAllCostReportsRequestModel);
    }
  }
}
/** Get All Cost Reports */
function* getAllCostReports(
  api: any,
  getAllCostReportsRequestModel: GetAllCostReportRequestModel,
): any {
  let response;
  try {
    response = yield call(api.getAll, getAllCostReportsRequestModel);
    if (response.status === STATUS_CODES.OK) {
      const isSuccessful: boolean = true;
      yield put(getAllCostReportsSuccess(isSuccessful, response.data));
    }
  } catch (error) {
    yield put(getAllCostReportsError(error));
  }
}
