import {
  GET_GROUP_REQUEST,
  GET_GROUP_REQUEST_SUCCESS,
  GET_GROUP_REQUEST_ERROR,
} from './types';

/** Action for get my team info.
 * @param {string} groupId - group Id
 * @return {Object} Return object
 */
export function getGroup(groupId) {
  return {
    type: GET_GROUP_REQUEST,
    payload: {
      groupId,
    },
  };
}

/** On successfully get my team info set data in store .
 * @param {string} group - group
 */
export function getGroupSuccess(group) {
  return {
    type: GET_GROUP_REQUEST_SUCCESS,
    payload: {
      group,
    },
  };
}

/** Handle error
 * @param {string} errorMessage - Error message
 */
export function getGroupError(errorMessage) {
  return {
    type: GET_GROUP_REQUEST_ERROR,
    payload: errorMessage,
  };
}
