import { Dispatch, SetStateAction } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import './DragAndDropFiles.scss';
import FileDropZone from './FileDropZone/FileDropZone';

interface Props {
  handleChange: (file: any) => void;
  files: any;
  clearFileSelection: () => void;
  fileTypeError: boolean;
  setFileTypeError: Dispatch<SetStateAction<boolean>>;
  fileSizeError: boolean;
  setFileSizeError: Dispatch<SetStateAction<boolean>>;
  fileNameAlreadyExistsError: boolean;
  maxFileNumberError: boolean;
  errorMessage: string;
  isImporting: boolean;
  checkForImporting: boolean;
  uploadFileCenterMessage: string;
  supportedFileTypes: string[];
  maxSize: number;
  allowMultiple: boolean;
  disableClearFile: boolean;
}

export default function DragAndDropFiles({
  handleChange,
  files,
  clearFileSelection,
  fileTypeError,
  fileSizeError,
  fileNameAlreadyExistsError,
  maxFileNumberError,
  setFileTypeError,
  setFileSizeError,
  errorMessage,
  isImporting,
  checkForImporting,
  uploadFileCenterMessage,
  supportedFileTypes,
  maxSize,
  allowMultiple,
  disableClearFile,
}: Props) {
  const onTypeErrorHandler = () => {
    setFileTypeError(true);
  };

  const onSizeErrorHandler = () => {
    setFileSizeError(true);
  };

  return (
    <>
      {files.length === 0 || allowMultiple ? (
        <FileUploader
          handleChange={handleChange}
          name="file"
          types={supportedFileTypes}
          maxSize={maxSize}
          classes="drop-area"
          onTypeError={onTypeErrorHandler}
          onSizeError={onSizeErrorHandler}
          multiple={allowMultiple}
          children={
            <FileDropZone
              files={files}
              clearFileSelection={clearFileSelection}
              fileTypeError={fileTypeError}
              fileSizeError={fileSizeError}
              fileNameAlreadyExistsError={fileNameAlreadyExistsError}
              maxFileNumberError={maxFileNumberError}
              errorMessage={errorMessage}
              isImporting={isImporting}
              checkForImporting={checkForImporting}
              uploadFileCenterMessage={uploadFileCenterMessage}
              maxFileSize={maxSize}
              allowMultiple={allowMultiple}
              disableClearFile={disableClearFile}
            />
          }
        />
      ) : (
        <div className="drop-area">
          <FileDropZone
            files={files}
            clearFileSelection={clearFileSelection}
            fileTypeError={fileTypeError}
            fileSizeError={fileSizeError}
            fileNameAlreadyExistsError={fileNameAlreadyExistsError}
            maxFileNumberError={maxFileNumberError}
            errorMessage={errorMessage}
            isImporting={isImporting}
            checkForImporting={checkForImporting}
            uploadFileCenterMessage={uploadFileCenterMessage}
            maxFileSize={maxSize}
            allowMultiple={allowMultiple}
            disableClearFile={disableClearFile}
          />
        </div>
      )}
    </>
  );
}
