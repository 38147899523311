import { put, take, call } from 'redux-saga/effects';
import { DELETE_GROUP_REQUEST } from './types';
import { deleteGroupError, deleteGroupSuccess } from './actions';
import { STATUS_CODES } from '../../../../../../../../cms/index';

/** If detect type: DELETE_GROUP_REQUEST call deleteGroup() */
export default function* deleteGroupSaga(api) {
  while (true) {
    const deletedGroupRequest = yield take(DELETE_GROUP_REQUEST);
    if (deletedGroupRequest.payload) {
      const { entryId } = deletedGroupRequest.payload;
      yield call(deleteGroup, api, entryId);
    }
  }
}
/** Delete group */
function* deleteGroup(api, entryId) {
  let response;
  try {
    response = yield call(api.deleteGroup, entryId);
    if (response.status === STATUS_CODES.OK) {
      const isSuccessful = true;
      yield put(deleteGroupSuccess(isSuccessful, entryId));
    }
  } catch (error) {
    yield put(deleteGroupError(error));
  }
}
