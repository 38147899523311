import { Stack } from '@mui/material';
import linkedInIcon from './images/LinkedIn.svg';
import facebookIcon from './images/Facebook.svg';
import { FOOTER_LABELS } from '../../cms';
import './AppFooter.scss';

export default function AppFooter() {
  return (
    <footer className="main-footer">
      <p className="footer-text">
        &copy;&nbsp;
        <span className="footer-light">{new Date().getFullYear()}</span>
        &nbsp;
        <a
          href="http://www.strypes.eu"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          {FOOTER_LABELS.STRYPES_LINK}
        </a>
        ,{' '}
        <span className="footer-light">
          {FOOTER_LABELS.ALL_RIGHTS_RESERVED}
        </span>
      </p>
      <Stack direction="row" gap="1rem">
        <a
          href="https://www.linkedin.com/company/strypes"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={linkedInIcon}
            alt="Strypes's Linkedn"
            className="cursor-pointer"
          />
        </a>

        <a
          href="https://www.facebook.com/StrypesBulgaria/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={facebookIcon}
            alt="Strype's Facebook"
            className="cursor-pointer"
          />
        </a>
      </Stack>
    </footer>
  );
}
