import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  SUBMISSIONS_LABELS,
  NOTIFICATION_LABELS,
  DATE_FORMATS,
} from '../../../../cms';
import './SubmissionsTab.scss';
import DemoRequestsTableRow from './DemoRequestsTableRow';
import DemoRequestsTableHeader from './DemoRequestsTableHeader/DemoRequestsTableHeader';
import {
  BaseModal,
  Loader,
  SuccessMessage,
} from '../../../../common/components';
import { useAppDispatch, useAppSelector } from '../../../../rtk/hooks';
import {
  DeleteDemoRequestsArgs,
  useDeleteDemoRequestMutation,
  useGetDemoRequestsQuery,
} from '../../../../rtk/api/demoRequestApi';
import {
  incrementDemoRequestsPageNumber,
  setDemoRequestsPagination,
} from '../../../../rtk/features/demoRequestsPaginationSlice';
import { DemoRequestStatuses } from '../../../../cms/enums';

export default function SubmissionsTab() {
  const dispatch = useAppDispatch();
  const demoRequestsPagination = useAppSelector(
    (state) => state.demoRequestsPagination,
  );
  const { data: demoRequestsData, isLoading: areDemoRequestsLoading } =
    useGetDemoRequestsQuery(
      {
        ...demoRequestsPagination,
      },
      {
        refetchOnMountOrArgChange: true,
      },
    );
  const { demoRequests = [], totalCount = 0 } = demoRequestsData || {};
  const [deleteDemoRequest] = useDeleteDemoRequestMutation();
  const hasMoreDemoRequests: boolean =
    totalCount -
      demoRequestsPagination.pageNumber * demoRequestsPagination.pageSize >
    0;

  const [selected, setSelected] = useState<string[]>([]);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(
        setDemoRequestsPagination({
          pageNumber: 1,
          pageSize: 20,
          sortType: 'createdOn',
          sortOrder: 'desc',
          dateFrom: null,
          dateTo: null,
          status: '',
          search: '',
          refetch: 0,
        }),
      );
    };
  }, []);

  const fetchMoreData = () => {
    dispatch(incrementDemoRequestsPageNumber());
  };

  const sortDemoRequests = (sortType: string) => {
    dispatch(
      setDemoRequestsPagination({
        sortType: sortType,
        sortOrder: demoRequestsPagination.sortOrder === 'desc' ? 'asc' : 'desc',
        pageNumber: 1,
      }),
    );
  };

  const addSelectedDemoRequestsIdsHandler = (demoRequestId: string) => {
    setSelected((selected) => [...selected, demoRequestId]);
  };

  const removeSelectedDemoRequestsIdsHandler = (demoRequestId: string) => {
    if (selected.length > 0) {
      setSelected(selected.filter((selected) => selected !== demoRequestId));
    }
  };

  const deleteMultipleDemoRequests = () => {
    const model: DeleteDemoRequestsArgs = {
      applicationIds: selected,
    };
    deleteDemoRequest(model);
    setSelected([]);
  };

  const handleSuccessClose = () => {
    dispatch(
      setDemoRequestsPagination({
        pageNumber: 1,
      }),
    );
    setShowSuccess(false);
  };

  const handleOnSearchTextChange = (search: string) => {
    dispatch(
      setDemoRequestsPagination({
        search,
        pageNumber: 1,
      }),
    );
  };

  const handleOnFromDateChange = (newFromDate: string) => {
    if (newFromDate !== undefined)
      dispatch(
        setDemoRequestsPagination({
          dateFrom: dayjs.utc(newFromDate, DATE_FORMATS.SIMPLE_DASH).toDate(),
          pageNumber: 1,
        }),
      );
  };

  const handleOnToDateChange = (newToDate: string) => {
    if (newToDate !== undefined)
      dispatch(
        setDemoRequestsPagination({
          dateTo: dayjs.utc(newToDate, DATE_FORMATS.SIMPLE_DASH).toDate(),
          pageNumber: 1,
        }),
      );
  };

  const handleOnStatusFilterChange = (status: string) => {
    dispatch(
      setDemoRequestsPagination({
        status: status === DemoRequestStatuses.ALL ? null : status,
        pageNumber: 1,
      }),
    );
  };

  return (
    <Stack spacing="2rem">
      <h4 className="submissions-title">{SUBMISSIONS_LABELS.SUBMISSIONS}</h4>
      <DemoRequestsTableHeader
        onSearch={handleOnSearchTextChange}
        selected={selected}
        deleteMultipleDemoRequests={deleteMultipleDemoRequests}
        onFromDateChange={handleOnFromDateChange}
        onToDateChange={handleOnToDateChange}
        onStatusFilterChange={handleOnStatusFilterChange}
      />
      <TableContainer component={Paper}>
        {areDemoRequestsLoading ? (
          <div className="demo-requests-container-loading">
            <Loader />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={demoRequests.length}
            next={fetchMoreData}
            hasMore={hasMoreDemoRequests}
            loader=""
            scrollableTarget="scrollableDiv"
          >
            <Table
              aria-label="collapsible table"
              className="demo-request-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell className="column">
                    {SUBMISSIONS_LABELS.FULL_NAME}
                    <TableSortLabel
                      active={demoRequestsPagination.sortType === 'fullName'}
                      direction={
                        demoRequestsPagination.sortOrder === 'asc'
                          ? 'asc'
                          : 'desc'
                      }
                      onClick={() => sortDemoRequests('fullName')}
                    />
                  </TableCell>
                  <TableCell className="column" align="left">
                    {SUBMISSIONS_LABELS.COMPANY_NAME}
                    <TableSortLabel
                      active={demoRequestsPagination.sortType === 'companyName'}
                      direction={
                        demoRequestsPagination.sortOrder === 'asc'
                          ? 'asc'
                          : 'desc'
                      }
                      onClick={() => sortDemoRequests('companyName')}
                    />
                  </TableCell>
                  <TableCell className="column" align="left">
                    {SUBMISSIONS_LABELS.SUBMISSION_DATE}
                    <TableSortLabel
                      active={demoRequestsPagination.sortType === 'createdOn'}
                      direction={
                        demoRequestsPagination.sortOrder === 'asc'
                          ? 'asc'
                          : 'desc'
                      }
                      onClick={() => sortDemoRequests('createdOn')}
                    />
                  </TableCell>
                  <TableCell className="column" align="left">
                    {SUBMISSIONS_LABELS.STATUS}
                    <TableSortLabel
                      active={demoRequestsPagination.sortType === 'status'}
                      direction={
                        demoRequestsPagination.sortOrder === 'asc'
                          ? 'asc'
                          : 'desc'
                      }
                      onClick={() => sortDemoRequests('status')}
                    />
                  </TableCell>
                  <TableCell className="action-column" />
                </TableRow>
              </TableHead>
              <TableBody>
                {demoRequests.map((row) => (
                  <DemoRequestsTableRow
                    key={row.externalId}
                    row={row}
                    addSelectedDemoRequestsIdsHandler={
                      addSelectedDemoRequestsIdsHandler
                    }
                    removeSelectedDemoRequestsIdsHandler={
                      removeSelectedDemoRequestsIdsHandler
                    }
                  />
                ))}
              </TableBody>
            </Table>
          </InfiniteScroll>
        )}
      </TableContainer>
      <BaseModal hideCloseBtn open={showSuccess} onClose={handleSuccessClose}>
        <SuccessMessage
          text={NOTIFICATION_LABELS.DELETE_MULTIPLE_DEMO_REQUESTS}
        />
      </BaseModal>
    </Stack>
  );
}
