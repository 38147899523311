import { PropsWithChildren, useEffect } from 'react';
import { Box, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { darken } from '@mui/material/styles';
import './BaseModal.scss';
import { COLORS } from '../../../cms';
import { ModalAlignments } from '../../../cms/enums';

type BaseModalProps = {
  open: boolean;
  onClose: () => void;
  hideCloseBtn?: boolean;
  showCloseBtnOutside?: boolean;
  timeout?: number;
  alignment?: ModalAlignments;
  showBlur?: boolean;
};

const BaseModal = ({
  open,
  onClose,
  hideCloseBtn = false,
  showCloseBtnOutside = false,
  timeout = 0,
  children,
  alignment = ModalAlignments.CENTER,
  showBlur = true,
}: PropsWithChildren<BaseModalProps>) => {
  useEffect(() => {
    if (timeout > 0) {
      setTimeout(onClose, timeout);
    }
  }, []);

  const generateModalContainerClass = () => {
    switch (alignment) {
      case ModalAlignments.RIGHT:
        return 'modal-container-right';
      default:
        return 'modal-container';
    }
  };

  const generateModalDialogClass = () => {
    switch (alignment) {
      case ModalAlignments.RIGHT:
        return 'modal-dialog-right';
      default:
        return 'modal-dialog';
    }
  };

  const generateModalContentClass = () => {
    switch (alignment) {
      case ModalAlignments.RIGHT:
        return 'modal-content-right';
      default:
        return 'modal-content';
    }
  };

  const generateModalCloseBtnClass = () => {
    if (showCloseBtnOutside && alignment === ModalAlignments.CENTER) {
      return 'modal-close-btn-outside';
    }

    return 'modal-close-btn-inside';
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={generateModalContainerClass()}
      sx={{
        backdropFilter: showBlur ? 'blur(5px)' : 'none',
        WebkitBackdropFilter: showBlur ? 'blur(5px)' : 'none',
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'transparent',
          },
        },
      }}
    >
      <Box className={generateModalDialogClass()}>
        <Box className={generateModalContentClass()}>{children}</Box>
        {!hideCloseBtn && (
          <IconButton
            aria-label="Close modal"
            className={generateModalCloseBtnClass()}
            onClick={onClose}
            sx={{
              backgroundColor: COLORS.WHITE,
              '&:hover': {
                backgroundColor: darken(COLORS.WHITE, 0.1),
              },
            }}
          >
            <CloseIcon
              sx={{
                fontSize: '1rem',
                color: COLORS.TEXT,
              }}
            />
          </IconButton>
        )}
      </Box>
    </Modal>
  );
};

export default BaseModal;
