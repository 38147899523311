import axiosInstance from './axiosInstance';

import {
  GET_EMPLOYEE_STATS_URL,
  GET_ALL_COST_REPORTS_URL,
  GET_ALL_TRAINING_STATISTICS_URL,
  GET_ALL_EVENTS_STATISTICS_URL,
  GET_PATHS_STATISTICS_URL,
} from './config';

export const api = {
  async getEmployeeStats() {
    return axiosInstance.get(GET_EMPLOYEE_STATS_URL);
  },

  async getAll(requestModel) {
    return axiosInstance.get(GET_ALL_COST_REPORTS_URL, {
      params: {
        from: requestModel.from,
        to: requestModel.to,
      },
    });
  },

  async getAllTrainings() {
    return axiosInstance.get(GET_ALL_TRAINING_STATISTICS_URL);
  },

  async getAllEvents(userId) {
    return axiosInstance.get(GET_ALL_EVENTS_STATISTICS_URL, {
      params: { userId, lastEventsNumber: 4 },
    });
  },

  async getAllPaths() {
    return axiosInstance.get(GET_PATHS_STATISTICS_URL);
  },
};
