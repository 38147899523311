import './UpskillPortalLogo.scss';

type UpskillPortalLogoProps = {
  className?: string;
};

const UpskillPortalLogo = ({ className = '' }: UpskillPortalLogoProps) => (
  <div className={`upskillportal-logo ${className}`}>
    <p>
      <mark>Up</mark>
      skill Portal
    </p>
  </div>
);

export default UpskillPortalLogo;
