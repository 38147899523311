import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import config from '../../config';
import { getUserRoles } from '../../common/userRoleRetriever';
import { useAppSelector } from '../../rtk/hooks';
import { ENVIRONMENT_NAMES, ROLES } from '../../cms';
import {
  AUTHORIZED_ROUTES,
  DEFAULT_ROUTES,
  UNAUTHORIZED_ROUTES,
} from '../../cms/routes';
import AppLayout from '../AppLayout/AppLayout';

const AppRouter = () => {
  const { isUserLoggedIn } = useAppSelector((state) => state.userSession);
  const isAdmin = isUserLoggedIn && getUserRoles().includes(ROLES.ADMIN_ROLE);
  const isManager =
    isUserLoggedIn && getUserRoles().includes(ROLES.MANAGER_ROLE);
  const isTrial = config.ENVIRONMENT === ENVIRONMENT_NAMES.TRIAL;

  const authorizedRoutes = AUTHORIZED_ROUTES(isManager, isAdmin);
  const unauthorizedRoutes = UNAUTHORIZED_ROUTES(isTrial);

  const router = createBrowserRouter([
    {
      element: <AppLayout />,
      children: [
        ...DEFAULT_ROUTES,
        ...(isUserLoggedIn ? authorizedRoutes : unauthorizedRoutes),
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default AppRouter;
