import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Stack, Tab, Tabs, Typography, styled } from '@mui/material';
import './AgendaDetails.scss';
import { COLORS, DATE_FORMATS, EVENT_DETAIL_LABELS } from '../../../cms';
import toBeDefinedAgenda from '../../../common/images/to-be-defined-agenda.png';
import AgendaEventItem from './AgendaEventItem/AgendaEventItem';
import {
  AgendaEventItemResponse,
  AgendaItemResponse,
} from '../../../cms/types/eventTypes';
import { AgendaEventItemSchemaType } from '../../../cms/validation/eventValidation';

const AgendaTabs = styled((props: any) => (
  <Tabs
    {...props}
    variant="scrollable"
    scrollButtons
    allowScrollButtonsMobile
    aria-label="Agenda days"
  />
))({
  minHeight: 0,
  width: '100%',
  '.MuiTabs-scrollButtons.Mui-disabled': {
    opacity: 0.3,
  },
  '& .MuiTabs-indicator': {
    backgroundColor: COLORS.TEXT,
    color: COLORS.TEXT,
  },
});

const AgendaTab = styled((props: any) => (
  <Tab disableRipple {...props} color="secondary" />
))({
  padding: '0.5rem',
  fontSize: 14,
  minWidth: 0,
  minHeight: 0,
  color: COLORS.TEXT,
  fontFamily: 'OpenSans',
  '&.Mui-selected': {
    color: COLORS.TEXT,
    fontWeight: 'bold',
    fontFamily: 'OpenSans',
  },
});

type AgendaDetailsProps = {
  agenda: AgendaItemResponse[];
  useImagePlaceholder?: boolean;
  className?: string;
  showActions?: boolean;
  onStartEdit?: (data: any) => void;
  onDelete?: (data: AgendaEventItemSchemaType) => void;
};

const AgendaDetails = ({
  agenda,
  useImagePlaceholder = true,
  className = '',
  showActions = false,
  onStartEdit = () => {},
  onDelete = () => {},
}: AgendaDetailsProps) => {
  const [tabsInfo, setTabsInfo] = useState<{
    sortedAgenda: AgendaItemResponse[];
    selectedTab: number | boolean;
  }>({
    sortedAgenda: [],
    selectedTab: false,
  });
  const selectedAgendaDate = tabsInfo.sortedAgenda?.find(
    (_, index) => index === tabsInfo.selectedTab,
  );

  useEffect(() => {
    setTabsInfo(() => {
      let newSelectedTab: number | boolean;

      // Unselect prev tab
      if (agenda.length === 0) {
        newSelectedTab = false;
      } else if (
        typeof tabsInfo.selectedTab === 'number' &&
        tabsInfo.selectedTab > agenda.length - 1
      ) {
        newSelectedTab = agenda.length - 1;
      } else if (typeof tabsInfo.selectedTab === 'number') {
        newSelectedTab = tabsInfo.selectedTab;
      } else {
        // Select first tab
        newSelectedTab = 0;
      }

      return {
        sortedAgenda: agenda.slice().sort(sortAgendaItems),
        selectedTab: newSelectedTab,
      };
    });
  }, [agenda]);

  const handleTabChange = (_: React.SyntheticEvent, newTab: number) => {
    setTabsInfo((prev) => ({
      ...prev,
      selectedTab: newTab,
    }));
  };

  const sortAgendaItems = (a: AgendaItemResponse, b: AgendaItemResponse) => {
    if (dayjs(a.date).isBefore(b.date, 'date')) {
      return -1;
    }

    if (dayjs(a.date).isAfter(b.date, 'date')) {
      return 1;
    }

    return 0;
  };

  const sortAgendaEventItems = (
    a: AgendaEventItemResponse,
    b: AgendaEventItemResponse,
  ) => {
    if (dayjs(a.startTime).isBefore(b.startTime, 'minute')) {
      return -1;
    }

    if (dayjs(a.startTime).isAfter(b.startTime, 'minute')) {
      return 1;
    }

    return 0;
  };

  return (
    <Stack
      direction="column"
      className={`agenda-details ${className}`}
      gap="1rem"
      alignItems="flex-start"
    >
      <Typography className="section-title">
        {EVENT_DETAIL_LABELS.AGENDA}
      </Typography>
      {tabsInfo.sortedAgenda.length === 0 &&
        (useImagePlaceholder ? (
          <img
            alt="No Agenda"
            src={toBeDefinedAgenda}
            className="img-no-agenda"
          />
        ) : (
          <Typography
            sx={{
              fontSize: 14,
            }}
          >
            {`You haven't added any agenda yet.`}
          </Typography>
        ))}

      {tabsInfo.sortedAgenda.length > 0 && (
        <>
          <AgendaTabs
            value={tabsInfo.selectedTab}
            onChange={handleTabChange}
            className="event-details-agenda-tabs"
          >
            {tabsInfo.sortedAgenda.slice().map((agendaItem, index) => (
              <AgendaTab
                key={agendaItem.date}
                label={dayjs(agendaItem.date).format(
                  DATE_FORMATS.NORMAL_DAY_MONTH,
                )}
                value={index}
              />
            ))}
          </AgendaTabs>
          <Stack
            direction="column"
            className="event-details-agenda-items"
            gap="1rem"
            sx={{ flex: 1 }}
          >
            {selectedAgendaDate?.eventItems
              ?.slice()
              .sort(sortAgendaEventItems)
              .map((eventItem: any, index: number) => (
                <AgendaEventItem
                  key={index}
                  item={eventItem}
                  itemIndex={index}
                  showActions={showActions}
                  onStartEdit={onStartEdit}
                  onDelete={onDelete}
                />
              ))}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default AgendaDetails;
