import { Controller, useFormContext } from 'react-hook-form';
import { MAX_LENGTHS } from '../../../cms';
import { EventSchemaType } from '../../../cms/validation/eventValidation';
import { CustomTextField } from '..';

const TitleInput = () => {
  const { control } = useFormContext<EventSchemaType>();

  return (
    <Controller
      name="title"
      control={control}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { error },
      }) => (
        <CustomTextField
          required
          fullWidth
          color="secondary"
          label="Title"
          name={name}
          value={value}
          onChange={onChange}
          inputRef={ref}
          onBlur={onBlur}
          error={Boolean(error)}
          helperText={error?.message}
          inputProps={{
            maxLength: MAX_LENGTHS.TITLE_MAX_LENGTH,
            className: 'title-input',
          }}
        />
      )}
    />
  );
};

export default TitleInput;
