import { useState } from 'react';
import {
  useMediaQuery,
  TableRow,
  TableCell,
  IconButton,
  Box,
  useTheme,
  Collapse,
  Stack,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Column } from '../ResponsiveTable';
import { COLORS } from '../../../../cms';
import TableDetails from '../TableDetails/TableDetails';
import CustomTableCell from '../CustomTableCell/CustomTableCell';

type ResponsiveRowProps<T> = {
  row: T;
  columns: Column<T>[];
};

const ResponsiveRow = <T,>({ row, columns }: ResponsiveRowProps<T>) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isScreenMd = useMediaQuery(theme.breakpoints.down('md'));
  const showArrowBtn = columns.some(
    (column) =>
      !column.primary && !!(row[column.key as keyof typeof row] as string),
  );

  const details = columns
    .filter((column) => isScreenMd && column.hide && column.primary)
    .concat(columns.filter((column) => column.hide && !column.primary));

  const detailsTab = (
    <Stack spacing="1rem" paddingBottom="1rem">
      {isScreenMd && (
        <Typography sx={{ color: COLORS.SECONDARY }}>Details:</Typography>
      )}
      {details.map((column, index) => (
        <TableDetails key={index} column={column} row={row} />
      ))}
    </Stack>
  );

  const arrowBtn = (
    <TableCell width="1px">
      <Box sx={{ width: 'min-content', marginLeft: 'auto' }}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
          className="responsive-row-toggle-btn"
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Box>
    </TableCell>
  );

  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 'none !important' } }}
        className="responsive-row"
      >
        {!isScreenMd && (
          <>
            {columns.map(
              (column, index) =>
                column.primary && (
                  <CustomTableCell key={index} column={column} row={row} />
                ),
            )}
            {showArrowBtn && arrowBtn}
          </>
        )}
        {isScreenMd && (
          <>
            {columns.map(
              (column, index) =>
                !column.hide && (
                  <CustomTableCell key={index} column={column} row={row} />
                ),
            )}
            {arrowBtn}
          </>
        )}
      </TableRow>

      <TableRow
        sx={{ borderBottom: `1px solid ${COLORS.INPUT}`, width: 'max-content' }}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, width: '100%' }}
          colSpan={columns.length}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {detailsTab}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ResponsiveRow;
