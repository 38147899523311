import './TrainingDetailsCard.scss';
import { Divider, Stack, Typography } from '@mui/material';
import { AccessTimeRounded, ContentPaste } from '@mui/icons-material';
import SignalCellularAltRoundedIcon from '@mui/icons-material/SignalCellularAltRounded';
import { COLORS, TRAINING_DETAIL_LABELS, TrainingLevel } from '../../../cms';

type TrainingDetailsCardProps = {
  level: TrainingLevel;
  duration: number;
  lessons: number;
};

const TrainingDetailsCard = ({
  level,
  duration,
  lessons,
}: TrainingDetailsCardProps) => {
  return (
    <Stack direction="column" flexWrap="wrap" className="training-details-card">
      <Typography component="h5" fontWeight="bold">
        {TRAINING_DETAIL_LABELS.TRAINING_DETAILS}
      </Typography>
      <Stack className="training-details-card-item">
        <SignalCellularAltRoundedIcon
          sx={{ fontSize: '1.25rem', color: COLORS.TEXT }}
        />
        <Typography>{TRAINING_DETAIL_LABELS.LEVEL}</Typography>

        <Typography color="secondary" marginLeft="auto">
          {level}
        </Typography>
      </Stack>
      <Divider flexItem />
      <Stack className="training-details-card-item">
        <AccessTimeRounded
          sx={{ fontSize: '1.25rem', color: COLORS.TEXT }}
        />
        <Typography>{TRAINING_DETAIL_LABELS.DURATION}</Typography>
        <Typography color="secondary">{`${duration} h`}</Typography>
      </Stack>
      <Divider flexItem />
      <Stack className="training-details-card-item">
        <ContentPaste sx={{ fontSize: '1.25rem', color: COLORS.TEXT }} />
        <Typography>{TRAINING_DETAIL_LABELS.LESSONS}</Typography>

        <Typography color="secondary">{lessons}</Typography>
      </Stack>
    </Stack>
  );
};

export default TrainingDetailsCard;
