import { put, take, call } from 'redux-saga/effects';
import { CREATE_GROUP_REQUEST } from './types';
import { createGroupSuccess } from './actions';
import { handleValidationErrors } from '../../../../../../../common/validation/actions';

/** If detect type: CREATE_GROUP_REQUEST call createGroup() */
export default function* createGroupSaga(api) {
  while (true) {
    const createGroupRequest = yield take(CREATE_GROUP_REQUEST);
    if (createGroupRequest.payload) {
      const { createGroupModel } = createGroupRequest.payload;
      yield call(createGroup, api, createGroupModel);
    }
  }
}

/** Create group */
function* createGroup(api, createGroupModel) {
  let response;
  try {
    response = yield call(api.createGroup, createGroupModel);
    const isSuccessful = true;
    yield put(createGroupSuccess(isSuccessful, response.data));
  } catch (error) {
    yield put(handleValidationErrors(error.response.data));
  }
}
