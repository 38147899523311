import { Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormContext } from 'react-hook-form';
import { TrainingFormType } from '../TrainingForm';
import { BUTTON_LABELS } from '../../../cms';

type TrainingFormFooterProps = {
  type: TrainingFormType;
  onClose: () => void;
  onClick: () => void;
};

const TrainingFormFooter = ({ type, onClose, onClick }: TrainingFormFooterProps) => {
  const {
    formState: { isLoading, isSubmitting },
  } = useFormContext();

  return (
    <Stack direction="row" flexWrap="wrap" gap="1rem" marginLeft="auto">
      <Button
        disabled={isLoading || isSubmitting}
        variant="outlined"
        onClick={onClose}
      >
        {BUTTON_LABELS.CANCEL}
      </Button>
      <LoadingButton
        loading={isLoading || isSubmitting}
        type="submit"
        variant="contained"
        className="training-submit-btn"
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
      >
        {type.toUpperCase()}
      </LoadingButton>
    </Stack>
  );
};

export default TrainingFormFooter;
