import { ReactNode } from 'react';

import './PolicySection.scss';

type PolicySectionProps = {
  heading: string;
  children: ReactNode;
};

const PolicySection = ({ heading, children }: PolicySectionProps) => {
  return (
    <section className="policy-section">
      <h2>{heading}</h2>
      {children}
    </section>
  );
};

export default PolicySection;
