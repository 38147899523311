import { useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Button } from '@mui/material';
import { BUTTON_LABELS, SUBMISSIONS_LABELS } from '../../../../../../cms/index';
import { BaseModal } from '../../../../../../common/components';
import './DeleteMultipleDemoRequestsButton.scss';

type DeleteMultipleDemoRequestsButtonProps = {
  selectedDemoRequestsCount: number;
  deleteMultipleDemoRequests: Function;
};

const DeleteMultipleDemoRequestsButton = ({
  deleteMultipleDemoRequests,
  selectedDemoRequestsCount,
}: DeleteMultipleDemoRequestsButtonProps) => {
  const [isConfirmDeleteModalOpened, setIsConfirmDeleteModalOpened] =
    useState(false);

  const closeDeleteConfirmationModal = () => {
    setIsConfirmDeleteModalOpened(false);
  };

  const openDeleteConfirmationModal = () => {
    setIsConfirmDeleteModalOpened(true);
  };

  const deleteSelectedDemoRequests = () => {
    deleteMultipleDemoRequests();
    closeDeleteConfirmationModal();
  };

  return (
    <>
      <Button
        variant="outlined"
        color="error"
        className="delete-multiple-group-btn"
        disabled={selectedDemoRequestsCount === 0}
        onClick={openDeleteConfirmationModal}
        startIcon={<DeleteForeverIcon />}
        sx={{
          width: { xs: '100%', sm: 'auto' },
        }}
      >
        {BUTTON_LABELS.DELETE_MULTIPLE_DEMO_REQUESTS}
      </Button>
      <BaseModal
        hideCloseBtn
        open={isConfirmDeleteModalOpened}
        onClose={closeDeleteConfirmationModal}
      >
        <div className="delete-multiple-demo-requests">
          <p className="delete-multiple-demo-requests-text">
            {SUBMISSIONS_LABELS.DELETE_MULTIPLE_DEMO_REQUESTS_CONFIRMATION_TEXT}
          </p>
          <div className="delete-multiple-demo-requests-btns">
            <Button
              variant="outlined"
              color="secondary"
              onClick={closeDeleteConfirmationModal}
              className="delete-multiple-demo-requests-btns-btn"
            >
              {BUTTON_LABELS.CANCEL}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={deleteSelectedDemoRequests}
              className="delete-multiple-demo-requests-btns-btn"
            >
              {BUTTON_LABELS.DELETE_BUTTON}
            </Button>
          </div>
        </div>
      </BaseModal>
    </>
  );
};

export default DeleteMultipleDemoRequestsButton;
