import { Box } from '@mui/material';
import { Instagram } from 'react-content-loader';

const SectionLoader = () => (
  <Box sx={{ width: 1200, height: 720 }} margin="0 auto">
    <Instagram viewBox="0 0 400 250" />
  </Box>
);

export default SectionLoader;
