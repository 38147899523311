import { put, take, call } from 'redux-saga/effects';
import { REMOVE_EMPLOYEE_FROM_MY_TEAM_REQUEST } from './types';
import {
  removeEmployeeFromMyTeamSuccess,
  removeEmployeeFromMyTeamError,
} from './actions';
import { STATUS_CODES } from '../../../../../cms/index';

/** If detect type: REMOVE_EMPLOYEE_FROM_MY_TEAM_REQUEST call removeEmployeeFromMyTeam() */
export default function* removeEmployeeFromMyTeamSaga(api: any): any {
  while (true) {
    const removeEmployeeFromMyTeamRequest = yield take(
      REMOVE_EMPLOYEE_FROM_MY_TEAM_REQUEST,
    );
    if (removeEmployeeFromMyTeamRequest.payload) {
      const { employeeId } = removeEmployeeFromMyTeamRequest.payload;
      yield call(removeEmployeeFromMyTeam, api, employeeId);
    }
  }
}

/** Remove employee from my team */
function* removeEmployeeFromMyTeam(api: any, employeeId: number): any {
  let response;
  try {
    response = yield call(api.removeEmployeeFromMyTeam, employeeId);
    if (response.status === STATUS_CODES.OK) {
      const isSuccessful = true;
      yield put(removeEmployeeFromMyTeamSuccess(isSuccessful));
    }
  } catch (error: any) {
    yield put(removeEmployeeFromMyTeamError(error));
  }
}
