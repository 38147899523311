import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { isArray } from 'lodash';
import { Divider, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import EventMainForm from './EventMainForm/EventMainForm';
import './EventForm.scss';
import AgendaSpeakersForm from '../AgendaSpeakersForm/AgendaSpeakersForm';
import {
  useCreateEventMutation,
  useEditEventMutation,
  useUploadEventPdfsMutation,
} from '../../rtk/api/eventApi';
import {
  DATE_FORMATS,
  EVENT_FORM_TYPES,
  MODAL_VALUES,
  NOTIFICATION_LABELS,
} from '../../cms';
import { BaseModal, SuccessMessage } from '../../common/components';
import EventFormFooter from './EventFormFooter/EventFormFooter';
import {
  AgendaEventItemSchemaType,
  AgendaItemSchemaType,
  EventSchema,
  EventSchemaType,
} from '../../cms/validation/eventValidation';
import { EventResponse } from '../../cms/types/eventTypes';

type EventFormProps = {
  type: number;
  initialFormData?: EventResponse;
  onCloseHandler: () => void;
};

const EventForm = ({
  type,
  initialFormData,
  onCloseHandler,
}: EventFormProps) => {
  const methods = useForm<EventSchemaType>({
    resolver: zodResolver(EventSchema),
    defaultValues: {
      id: initialFormData?.id ?? -1,
      title: initialFormData?.title ?? '',
      startDate: initialFormData?.startDate,
      endDate: initialFormData?.endDate,
      applyBeforeDate: initialFormData?.applyBeforeDate,
      location: initialFormData?.location,
      eventLocation: initialFormData?.eventLocation ?? '',
      applicationsLimitOption:
        initialFormData?.applicationsLimit ?? 0 ? 'yes' : 'no',
      applicationsLimit: initialFormData?.applicationsLimit ?? 0,
      type: initialFormData?.type,
      costOption: (initialFormData?.cost ?? 0) > 0 ? 'paid' : 'free',
      cost: initialFormData?.cost ?? 0,
      description: initialFormData?.description ?? '',
      url: initialFormData?.url ?? '',
      agenda: initialFormData?.agenda ?? [],
      speakers: initialFormData?.speakers ?? [],
    },
  });
  const [createEvent, { error: createEventErrors }] = useCreateEventMutation();
  const [uploadEventPdfs] = useUploadEventPdfsMutation();
  const [editEvent, { error: editEventErrors }] = useEditEventMutation();

  const [showAgendaSpeakersForm, setShowAgendaSpeakersForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  // TODO load files on editing
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (isArray(createEventErrors)) {
      createEventErrors.forEach(({ name, message }: any) => {
        methods.setError(name, {
          type: 'server',
          message,
        });
      });
    }
  }, [createEventErrors]);

  useEffect(() => {
    if (isArray(editEventErrors)) {
      editEventErrors.forEach(({ name, message }: any) => {
        methods.setError(name, {
          type: 'server',
          message,
        });
      });
    }
  }, [editEventErrors]);

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => onCloseHandler(), MODAL_VALUES.MODAL_CLOSE_TIMEOUT);
    }
  }, [successMessage]);

  const onSubmit = async (data: EventSchemaType) => {
    const requestData = {
      id: data.id,
      title: data.title,
      startDate: data.startDate,
      endDate: data.endDate,
      applyBeforeDate: data.applyBeforeDate,
      location: data.location,
      eventLocation: data.eventLocation,
      applicationsLimit:
        data.applicationsLimit && data.applicationsLimit > 0
          ? data.applicationsLimit
          : null,
      type: data.type,
      cost: data.cost,
      description: data.description,
      url: data.url,
      speakers: data.speakers,
      agenda: data.agenda.map((ai: AgendaItemSchemaType) => ({
        ...ai,
        eventItems: ai.eventItems.map((ei: AgendaEventItemSchemaType) => ({
          ...ei,
          startTime: dayjs(ei.startTime).format(DATE_FORMATS.HOUR_MINUTES),
          endTime: dayjs(ei.endTime).format(DATE_FORMATS.HOUR_MINUTES),
        })),
      })),
    };

    if (requestData.id === -1) {
      try {
        const { id } = await createEvent(requestData).unwrap();

        if (files.length > 0) {
          await uploadEventPdfs({ files, id });
        }

        setSuccessMessage(
          type === EVENT_FORM_TYPES.DUPLICATE
            ? NOTIFICATION_LABELS.EVENT_HAS_BEEN_DUPLICATED
            : NOTIFICATION_LABELS.EVENT_HAS_BEEN_CREATED,
        );
      } catch (err) {}
    } else {
      try {
        const { id } = await editEvent(requestData).unwrap();

        if (files.length > 0) {
          await uploadEventPdfs({ files, id });
        }

        setSuccessMessage(NOTIFICATION_LABELS.EVENT_HAS_BEEN_EDITED);
      } catch (err) {}
    }
  };

  const onErrors = () => {
    if (showAgendaSpeakersForm) setShowAgendaSpeakersForm(false);
  };

  return (
    <Stack className="event-form-container">
      {showAgendaSpeakersForm && <AgendaSpeakersForm eventMethods={methods} />}
      <FormProvider {...methods}>
        <Stack
          spacing="2rem"
          component="form"
          onSubmit={methods.handleSubmit(onSubmit, onErrors)}
          noValidate
        >
          {!showAgendaSpeakersForm && (
            <EventMainForm
              type={type}
              setShowAgendaSpeakersForm={setShowAgendaSpeakersForm}
              files={files}
              setFiles={setFiles}
            />
          )}
          {/* Always visible */}
          <Divider />
          <EventFormFooter
            type={type}
            onClose={onCloseHandler}
            showAgendaSpeakersForm={showAgendaSpeakersForm}
            setShowAgendaSpeakersForm={setShowAgendaSpeakersForm}
          />
        </Stack>
      </FormProvider>
      <BaseModal
        hideCloseBtn
        open={successMessage != null}
        onClose={onCloseHandler}
      >
        <SuccessMessage text={successMessage!} />
      </BaseModal>
    </Stack>
  );
};

export default EventForm;
