import { Link } from 'react-router-dom';
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Box,
  useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import { useAppDispatch, useAppSelector } from '../../rtk/hooks';
import './AppHeader.scss';
import { DRAWER_TYPES, HEADER_LABELS, ROLES, ROUTE_LINKS } from '../../cms';
import AppNavbarLinks from './AppNavbarLinks/AppNavbarLinks';
import AppNavbarProfile from './AppNavbarProfile/AppNavbarProfile';
import AppNavbarNotifications from './AppNavbarNotifications/AppNavbarNotifications';
import AppDrawer from './AppDrawer/AppDrawer';
import { getUserRoles } from '../../common/userRoleRetriever';
import { toggleDrawer } from '../../rtk/features/drawerSlice';
import AppNotificationsDrawer from './AppNotificationsDrawer/AppNotificationsDrawer';

const AppHeader = () => {
  const dispatch = useAppDispatch();
  const { isUserLoggedIn } = useAppSelector((state) => state.userSession);
  const isAdmin = isUserLoggedIn && getUserRoles().includes(ROLES.ADMIN_ROLE);
  const isManager =
    isUserLoggedIn && getUserRoles().includes(ROLES.MANAGER_ROLE);
  const theme = useTheme();
  const isScreenLG = useMediaQuery(theme.breakpoints.up('lg'));

  const navLinks = [
    {
      name: HEADER_LABELS.DASHBOARD_TEXT,
      link: ROUTE_LINKS.DEFAULT,
    },
    {
      name: HEADER_LABELS.ADMINISTRATION_TEXT,
      link: ROUTE_LINKS.ADMINISTRATION,
      hide: !isAdmin,
    },
    {
      name: HEADER_LABELS.MY_GROUP_TEXT,
      link: ROUTE_LINKS.MY_GROUP,
      hide: !isManager,
    },
    {
      name: HEADER_LABELS.EVENTS_TEXT,
      link: ROUTE_LINKS.EVENTS,
    },
    {
      name: HEADER_LABELS.TRAININGS_TEXT,
      link: ROUTE_LINKS.TRAININGS,
    },
    {
      name: HEADER_LABELS.PATHS_TEXT,
      link: ROUTE_LINKS.PATHS,
      hide: !isAdmin && !isManager,
    },
    {
      name: HEADER_LABELS.MY_LEARNING_HUB,
      link: ROUTE_LINKS.MY_LEARNING_HUB,
    },
  ];

  const handleDrawerToggle = () => {
    dispatch(toggleDrawer(DRAWER_TYPES.APP));
  };

  return (
    <>
      <AppBar
        component="header"
        className="app-header"
        position="sticky"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        elevation={0}
      >
        <Toolbar
          variant="dense"
          className="navbar-toolbar"
          sx={{ padding: { xs: '0.25rem 0.5rem', lg: '0 2rem' } }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{ display: { lg: 'none' } }}
            className="navbar-toggle-btn"
          >
            <MenuIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <Box
            className="navbar-logo"
            sx={{
              textAlign: { xs: 'center', lg: 'start' },
            }}
          >
            <Typography
              variant="h6"
              component={Link}
              to={ROUTE_LINKS.DEFAULT}
              className="navbar-logo-text"
            >
              {HEADER_LABELS.MAIN_TEXT}
            </Typography>
          </Box>
          <AppNavbarLinks links={navLinks} />
          {isUserLoggedIn ? (
            <Box
              sx={{
                display: 'flex',
                gap: '0.5rem',
                alignItems: 'center',
              }}
            >
              <AppNavbarProfile />
              <AppNavbarNotifications />
            </Box>
          ) : null}
        </Toolbar>
      </AppBar>
      {!isScreenLG && (
        <>
          <AppDrawer links={navLinks} />
          <AppNotificationsDrawer />
        </>
      )}
    </>
  );
};

export default AppHeader;
