import { useEffect, useState } from 'react';
import { Autocomplete, Divider, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import './EmployeesSelect.scss';
import { PLACEHOLDERS } from '../../../cms';
import EmployeeList from '../EmployeeList/EmployeeList';
import { useGetAllEmployeesQuery } from '../../../rtk/api/employeeApi';
import CustomTextField from '../CustomTextField/CustomTextField';
import { Employee } from '../../../cms/types';
import EmployeeAutocompleteOption from './EmployeeAutocompleteOption/EmployeeAutocompleteOption';

type EmployeesSelectProps = {
  title: string;
  onSubmit: (employeeIds: number[]) => void;
  isSubmitting: boolean;
  employeeIdsToHide?: number[];
};

const EmployeesSelect = ({
  title,
  onSubmit,
  isSubmitting = false,
  employeeIdsToHide = [],
}: EmployeesSelectProps) => {
  const { data: employees, isFetching } = useGetAllEmployeesQuery();
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(
    null,
  );
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const unavailableEmployeeIds = [
    ...employeeIdsToHide,
    ...selectedEmployees.map((se) => se.id),
  ];
  const employeeOptions: Employee[] =
    employees?.filter((e) => !unavailableEmployeeIds.includes(e.id)) ?? [];

  useEffect(() => {
    if (selectedEmployee) {
      setSelectedEmployees((prev) => [...prev, selectedEmployee]);
      setSelectedEmployee(null);
    }
  }, [selectedEmployee]);

  const handleOnEmployeeUnselect = (employeeId: number) => {
    setSelectedEmployees((prev) => prev.filter((se) => se.id !== employeeId));
  };

  const handleOnSubmit = () => {
    onSubmit(selectedEmployees.map((se) => se.id));
  };

  return (
    <Stack
      className="employees-select"
      spacing="1rem"
      sx={{
        padding: { xs: '1rem', sm: '1rem 2rem 1.5rem 2rem' },
      }}
    >
      <Typography className="employees-select-title">{title}</Typography>
      <Divider flexItem className="employees-select-divider" />
      <Autocomplete
        className="employee-autocomplete"
        disablePortal
        loading={isFetching}
        options={employeeOptions}
        getOptionLabel={(option) => option.fullName}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            placeholder={PLACEHOLDERS.SEARCH_EMPLOYEES}
            className="employee-autocomplete-text-field"
            label="Select Employee"
          />
        )}
        renderOption={(props, { id, fullName, photoUrl }: Employee) => (
          <EmployeeAutocompleteOption
            renderOptionProps={props}
            key={id}
            fullName={fullName}
            photoUrl={photoUrl ?? undefined}
          />
        )}
        sx={{ width: '100%' }}
        onChange={(_, value) => setSelectedEmployee(value)}
        value={selectedEmployee}
      />
      <Typography className="employees-select-list-title">
        Selected Employees ({selectedEmployees.length})
      </Typography>
      <EmployeeList
        employees={selectedEmployees}
        emptyMessage="No selected employees"
        className="employees-select-list"
        onEmployeeRemove={handleOnEmployeeUnselect}
      />
      <Divider flexItem className="employees-select-divider" />
      <LoadingButton
        variant="contained"
        className="employees-select-add-btn"
        sx={{
          marginTop: {
            xs: 0,
            sm: '0.5rem',
          },
        }}
        onClick={handleOnSubmit}
        disabled={selectedEmployees.length === 0}
        loading={isSubmitting}
        startIcon={<GroupAddIcon />}
        loadingPosition="start"
      >
        {title}
      </LoadingButton>
    </Stack>
  );
};

export default EmployeesSelect;
