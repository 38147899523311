import { isEqual } from 'lodash';
import {
  CREATE_TRAINING_URL,
  DELETE_TRAINING_URL,
  TRAINING_UPLOAD_PDF_URL,
  RATE_TRAINING_URL,
  GET_TRAINING_URL,
  EDIT_TRAINING_URL,
  GET_TRAININGS_URL,
  GET_TRAINING_BY_EXTERNAL_LINK_URL,
  GET_MY_TRAININGS_URL,
  APPLY_FOR_TRAININGS_URL,
  ASSIGN_TRAINING_TO_EMPLOYEES_URL,
} from '../../api/trainingsPortalBackend/config';
import {
  AssignTrainingRequestModel,
  CreateTrainingType,
  EditTrainingType,
  PrefillTrainingResponse,
  RateTraining,
  SearchTrainingsParams,
  UploadPdfsModel,
} from '../../cms/types';
import { api } from './api';
import { transformErrorResponse } from '../../cms/Utils';
import {
  MyTrainingsResponse,
  TrainingResponse,
  TrainingShortResponse,
  TrainingsResponse,
} from '../../cms/types/trainingTypes';

export const trainingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMyTrainings: build.query<MyTrainingsResponse, void>({
      query: () => ({
        url: `${GET_MY_TRAININGS_URL}`,
        method: 'GET',
      }),
      forceRefetch: () => true,
      providesTags: ['TrainingApplication'],
    }),
    getAllTrainings: build.query<TrainingsResponse, SearchTrainingsParams>({
      query: (params) => ({
        url: `${GET_TRAININGS_URL}`,
        params,
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCacheData, responseData, { arg }) => {
        if (arg?.pageNumber === 1) return responseData;

        return {
          totalcount: responseData.totalcount,
          // Combine both arrays and skip duplicates
          trainings: [...currentCacheData.trainings, ...responseData.trainings],
        };
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        isEqual(currentArg, previousArg),
    }),
    getPrivateTrainings: build.query<
      { trainings: TrainingShortResponse[] },
      void
    >({
      query: () => ({
        url: GET_TRAINING_URL,
        params: {
          pageNumber: 1,
          pageSize: 1000,
          onlyPrivate: true,
        },
      }),
    }),
    getTrainingById: build.query<TrainingResponse, number>({
      query: (id) => `${GET_TRAINING_URL}/${id}`,
      providesTags: ['Training'],
    }),
    deleteTraining: build.mutation<any, number>({
      query: (id) => ({
        url: `${DELETE_TRAINING_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Training'],
    }),
    rateTraining: build.mutation<any, RateTraining>({
      query: (model) => ({
        url: RATE_TRAINING_URL,
        method: 'PATCH',
        body: model,
      }),
      invalidatesTags: ['Training'],
    }),
    createTraining: build.mutation<any, CreateTrainingType>({
      query: (model) => ({
        url: CREATE_TRAINING_URL,
        method: 'POST',
        body: model,
      }),
      transformErrorResponse,
    }),
    editTraining: build.mutation<any, EditTrainingType>({
      query: (model) => ({
        url: EDIT_TRAINING_URL,
        method: 'PUT',
        body: model,
      }),
      transformErrorResponse,
      invalidatesTags: ['Training'],
    }),
    uploadTrainingPdfs: build.mutation<any, UploadPdfsModel>({
      query: (model) => {
        const formData = new FormData();
        model.files.forEach((file: any, index: number) => {
          formData.append(`file${index}`, file);
        });

        return {
          url: `${TRAINING_UPLOAD_PDF_URL}/${model.id}`,
          method: 'POST',
          headers: {
            'content-type': 'multipart/form-data',
          },
          body: formData,
        };
      },
      invalidatesTags: ['Training'],
    }),
    getTrainingByExternalLink: build.query<PrefillTrainingResponse, string>({
      query: (url) => ({
        url: `${GET_TRAINING_BY_EXTERNAL_LINK_URL}?url=${url}`,
        method: 'GET',
      }),
    }),
    applyForTraining: build.mutation<any, number>({
      query: (trainingId) => ({
        url: APPLY_FOR_TRAININGS_URL,
        method: 'POST',
        body: {
          trainingId,
        },
      }),
      invalidatesTags: (result, error) =>
        error ? [] : ['Training', 'TrainingApplication', 'Application'],
    }),
    assignTrainingsToEmployees: build.mutation<any, AssignTrainingRequestModel>(
      {
        query: (model) => ({
          url: ASSIGN_TRAINING_TO_EMPLOYEES_URL,
          method: 'POST',
          body: model,
        }),
      },
    ),
  }),
});

export const {
  useGetMyTrainingsQuery,
  useRateTrainingMutation,
  useGetAllTrainingsQuery,
  useGetTrainingByIdQuery,
  useLazyGetTrainingByIdQuery,
  useDeleteTrainingMutation,
  useCreateTrainingMutation,
  useEditTrainingMutation,
  useUploadTrainingPdfsMutation,
  useLazyGetTrainingByExternalLinkQuery,
  useApplyForTrainingMutation,
  useAssignTrainingsToEmployeesMutation,
  useGetPrivateTrainingsQuery,
} = trainingApi;
