import { Box, Stack, Typography } from '@mui/material';
import './EventDetailsOverview.scss';
import MDEditor from '@uiw/react-md-editor';
import { TRAINING_DETAIL_LABELS } from '../../../cms';
import toBeDefinedImg from '../../../common/images/to-be-defined.png';

interface EventDetailsOverviewProps {
  description: string;
  className?: string;
}

const EventDetailsOverview = ({
  description,
  className = '',
}: EventDetailsOverviewProps) => (
  <Stack
    direction="column"
    gap="1rem"
    sx={{ flex: 1, maxHeight: '100%' }}
    className={className}
    alignItems="flex-start"
  >
    <Typography className="section-title">
      {TRAINING_DETAIL_LABELS.TRAINING_OVERVIEW}
    </Typography>
    <Box
      className="event-details-overview-text-container"
      data-color-mode="light"
    >
      {description?.length > 0 ? (
        <MDEditor.Markdown
          source={description}
          className="event-details-overview-text"
        />
      ) : (
        <img
          src={toBeDefinedImg}
          alt="No Overview"
          className="img-no-overview"
        />
      )}
    </Box>
  </Stack>
);

export default EventDetailsOverview;
