import { Controller, useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import { CustomDateTimePicker } from '../index';
import { AgendaEventItemSchemaType } from '../../../cms/validation/eventValidation';
import { DATE_FORMATS } from '../../../cms';

type DateTimeSectionProps = {
  minDate: Date | null;
  maxDate: Date | null;
};

const DateTimeSection = ({ minDate, maxDate }: DateTimeSectionProps) => {
  const { control, watch } = useFormContext<AgendaEventItemSchemaType>();

  return (
    <Grid container spacing="1rem" className="date-time-section">
      <Grid item xs={12} sm="auto" lg={4}>
        <Controller
          name="date"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <CustomDateTimePicker
              required
              label="Date"
              value={value}
              onChange={onChange}
              error={Boolean(error)}
              helperText={error?.message}
              minDate={minDate}
              maxDate={maxDate}
              disablePast
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm="auto" lg={4}>
        <Controller
          name="startTime"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <CustomDateTimePicker
              required
              error={Boolean(error)}
              helperText={error?.message}
              label="Start Time"
              value={value}
              onChange={onChange}
              views={['hours', 'minutes']}
              format={DATE_FORMATS.HOUR_MINUTES}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm="auto" lg={4}>
        <Controller
          name="endTime"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <CustomDateTimePicker
              required
              error={Boolean(error)}
              helperText={error?.message}
              label="End Time"
              value={value}
              onChange={onChange}
              minTime={watch('startTime')}
              views={['hours', 'minutes']}
              format={DATE_FORMATS.HOUR_MINUTES}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default DateTimeSection;
