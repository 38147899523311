import axiosInstance from './axiosInstance';
import {
  GET_ALL_EMPLOYEES_URL,
  ADD_EMPLOYEE_TO_MY_TEAM_URL,
  GET_SINGLE_EMPLOYEE_URL,
  REMOVE_EMPLOYEE_FROM_MY_TEAM_URL,
  UPDATE_IMAGE_URL,
} from './config';

export const api = {
  async getAll(requestModel) {
    return axiosInstance.get(GET_ALL_EMPLOYEES_URL, {
      params: {
        pageNumber:
          Object.keys(requestModel).length > 0 ? requestModel.pageNumber : null,
        pageSize:
          Object.keys(requestModel).length > 0 ? requestModel.pageSize : null,
        departmentId:
          Object.keys(requestModel).length > 0
            ? requestModel.departmentId
            : null,
      },
    });
  },
  async getOne(userId) {
    return axiosInstance.get(`${GET_SINGLE_EMPLOYEE_URL}/${userId}`);
  },
  async addEmployeeToMyTeam(model) {
    return axiosInstance.put(`${ADD_EMPLOYEE_TO_MY_TEAM_URL}${model.id}`);
  },
  async removeEmployeeFromMyTeam(employeeId) {
    return axiosInstance.put(
      `${REMOVE_EMPLOYEE_FROM_MY_TEAM_URL}${employeeId}`,
    );
  },
  async updateProfilePhoto(rawImage) {
    return axiosInstance.post(UPDATE_IMAGE_URL, rawImage);
  },
};
