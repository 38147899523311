import './TrainingDetailsOverview.scss';
import MDEditor from '@uiw/react-md-editor';
import { Box, Stack } from '@mui/material';
import toBeDefinedImg from '../../../common/images/to-be-defined.png';

type TrainingDetailsOverviewProps = {
  description: string;
};

const TrainingDetailsOverview = ({
  description,
}: TrainingDetailsOverviewProps) => (
  <Stack direction="column" gap="1rem" alignItems="flex-start">
    <Box className="training-overview-text-container">
      {description?.length > 0 ? (
        <Box className="training-details-overview-text" data-color-mode="light">
          <MDEditor.Markdown source={description} className='training-details-overview' />
        </Box>
      ) : (
        <img
          src={toBeDefinedImg}
          alt="No Overview"
          className="img-no-overview"
        />
      )}
    </Box>
  </Stack>
);

export default TrainingDetailsOverview;
