import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { HiMiniUserGroup } from 'react-icons/hi2';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { LIMIT_APPLICATIONS_LABELS } from '../../../cms';
import { EventSchemaType } from '../../../cms/validation/eventValidation';
import { CustomTextField } from '..';

const ApplicationsLimitSection = () => {
  const { control, setValue, watch, trigger } =
    useFormContext<EventSchemaType>();
  const watchApplicationsLimitOption = watch('applicationsLimitOption');
  const watchApplicationsLimit = watch('applicationsLimit');

  useEffect(() => {
    if (watchApplicationsLimitOption === 'no') {
      setValue('applicationsLimit', 0);
      trigger('applicationsLimit');
    }
  }, [watchApplicationsLimitOption]);

  useEffect(() => {
    if (watchApplicationsLimit > 0) {
      setValue('applicationsLimitOption', 'yes');
    }
  }, [watchApplicationsLimit]);

  return (
    <Stack
      className="applications-limit-section"
      direction="row"
      columnGap="1.25rem"
    >
      <Controller
        name="applicationsLimitOption"
        control={control}
        render={({ field: { value, onChange } }) => (
          <FormControl>
            <FormLabel
              id="applications-limit-radio-buttons-group-label"
              sx={{ fontSize: 12, margin: 0 }}
              color="secondary"
            >
              Limit Applications
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="applications-limit-radio-buttons-group-label"
              name="applications-limit-radio-buttons-group"
              value={value}
              onChange={onChange}
            >
              <FormControlLabel
                value="no"
                control={
                  <Radio
                    color="secondary"
                    size="small"
                    sx={{ fontSize: 18 }}
                    className="no-applications-limit-radio-btn"
                  />
                }
                label={<span style={{ fontSize: 14, padding: 0 }}>No</span>}
              />
              <FormControlLabel
                value="yes"
                control={
                  <Radio
                    color="secondary"
                    size="small"
                    sx={{ fontSize: 18 }}
                    className="yes-applications-limit-radio-btn"
                  />
                }
                label={<span style={{ fontSize: 14, padding: 0 }}>Yes</span>}
              />
            </RadioGroup>
          </FormControl>
        )}
      />
      <Controller
        name="applicationsLimit"
        control={control}
        render={({
          field: { name, value, onChange, onBlur, ref },
          fieldState: { error },
        }) => (
          <CustomTextField
            required={watchApplicationsLimitOption === 'yes'}
            type="number"
            label="Max limit"
            color="secondary"
            placeholder={LIMIT_APPLICATIONS_LABELS.PARTICIPANTS}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            inputRef={ref}
            error={Boolean(error)}
            helperText={error?.message}
            disabled={watchApplicationsLimitOption !== 'yes'}
            inputProps={{
              className: 'applications-limit-input',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HiMiniUserGroup />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Stack>
  );
};

export default ApplicationsLimitSection;
