import { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import ReactCrop, { type Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './ProfilePhotoUpload.scss';
import { canvasPreview } from './canvasPreview';
import accountIcon from '../../common/images/avatar.png';
import { EDIT_PROFILE_PHOTO_MODAL } from '../../cms';
import { useAppSelector } from '../../rtk/hooks';
import { BaseModal, ErrorMessage } from '../../common/components';
import { UpdateImageParams, useUpdateImageMutation } from '../../rtk/api/userApi';

const ProfilePhotoUpload = () => {
  const { photoUrl } = useAppSelector((state) => state.userSession);

  const [updateImage, { isSuccess }] = useUpdateImageMutation();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [showError, setShowError] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileErrorText, setFileErrorText] = useState('');
  const handleErrorClose = () => setShowError(false);
  const [blobImageUrl, setBlobImageUrl] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
  const [imageContainerHeight] = useState(220);

  async function onImageLoadCalculateCrop(url: string) {
    const image = new Image();
    image.src = url;
    await image.decode();

    // Get the displayed image aspect ratio
    const displayedAspectRatio = image.height / imageContainerHeight;
    // Get the displayed image width
    const displayedImageWidth = image.width / displayedAspectRatio;
    // Get the displayed image height
    const displayedImageHeight = image.height / displayedAspectRatio;
    // Desired circle size
    const imageDimensions = [displayedImageHeight, displayedImageWidth];
    const circleSize = Math.min(...imageDimensions) * 0.85;

    setCrop({
      unit: 'px',
      x:
        displayedImageWidth -
        circleSize -
        (displayedImageWidth - circleSize) / 2,
      y:
        displayedImageHeight -
        circleSize -
        (displayedImageHeight - circleSize) / 2,
      width: circleSize,
      height: circleSize,
    });
  }

  useEffect(() => {
    if (blobImageUrl) onImageLoadCalculateCrop(blobImageUrl);
  }, [blobImageUrl]);

  useEffect(() => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      canvasRef.current
    ) {
      canvasPreview(imgRef.current, canvasRef.current, completedCrop);
    }
  }, [completedCrop, onImageLoadCalculateCrop]);

  useEffect(() => {
    if (isSuccess) {
      if (blobImageUrl) URL.revokeObjectURL(blobImageUrl);
      setBlobImageUrl(null);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (fileSizeError || fileTypeError) {
      if (fileSizeError)
        setFileErrorText(EDIT_PROFILE_PHOTO_MODAL.IMAGE_UPLOAD_SIZE_ERROR_TEXT);
      if (fileTypeError)
        setFileErrorText(EDIT_PROFILE_PHOTO_MODAL.IMAGE_UPLOAD_TYPE_ERROR_TEXT);
      setShowError(true);
    }
  }, [fileSizeError, fileTypeError]);

  useEffect(() => {
    setShowError(false);
  }, []);

  const onFileChange = (data: any) => {
    if (data) {
      setFileTypeError(false);
      setFileSizeError(false);
      setFileErrorText('');
      if (data.target.files[0]?.type !== 'image/jpeg') setFileTypeError(true);
      else if (data.target.files[0].size / 1024 > 2048) setFileSizeError(true);
      else {
        if (blobImageUrl) URL.revokeObjectURL(blobImageUrl);
        setBlobImageUrl(URL.createObjectURL(data.target.files[0]));
      }
    }
  };

  const cropAndSave = () => {
    if (canvasRef.current?.toDataURL()) {
      resizeImage(canvasRef.current?.toDataURL(), 400, 400).then((result) => {
        const model: UpdateImageParams = {
          rawImage: result.substring(result.indexOf(',') + 1)
        }
        updateImage(model);
      });
    }
  };

  const cancelCropAndSave = () => {
    if (blobImageUrl) URL.revokeObjectURL(blobImageUrl);
    setBlobImageUrl(null);
  };

  function resizeImage(base64Str: string, maxWidth = 300, maxHeight = 300) {
    return new Promise<string>((resolve) => {
      const img = new Image();
      img.src = base64Str;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = maxWidth;
        const MAX_HEIGHT = maxHeight;
        let { width } = img;
        let { height } = img;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL());
      };
    });
  }

  return (
    <>
      <div className="profile-photo-upload">
        <div className="row m-0">
          <div className="py-3 pl-4">
            {EDIT_PROFILE_PHOTO_MODAL.EDIT_PROFILE_PHOTO}
          </div>
        </div>
        <div>
          <hr style={{ marginTop: 0, marginBottom: 0 }} />
        </div>
        <div className="edit-profile-photo-content-container">
          {blobImageUrl ? (
            <div className="edit-profile-photo-container text-center">
              {blobImageUrl && (
                <ReactCrop
                  circularCrop
                  keepSelection
                  aspect={1}
                  onComplete={(c) => setCompletedCrop(c)}
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                >
                  <img
                    alt="Avatar"
                    ref={imgRef}
                    className="block"
                    src={blobImageUrl}
                    height={imageContainerHeight}
                  />
                </ReactCrop>
              )}
            </div>
          ) : (
            <div className="edit-profile-photo-container text-center">
              <img
                alt="Avatar"
                className="block rounded-circle"
                src={photoUrl || accountIcon}
                height={imageContainerHeight}
              />
            </div>
          )}
          <div>
            <hr style={{ marginTop: 0, marginBottom: 0 }} />
          </div>
          <div className="upload-profile-photo-actions">
            {!blobImageUrl ? (
              <>
                <Button
                  variant="contained"
                  className="upload-profile-photo-btn"
                  onClick={() => inputFile.current!.click()}
                >
                  {EDIT_PROFILE_PHOTO_MODAL.UPLOAD_PHOTO}
                </Button>
                <input
                  type="file"
                  accept="image/jpeg"
                  defaultValue={[]}
                  onChange={onFileChange}
                  id="file"
                  ref={inputFile}
                  style={{ display: 'none' }}
                />
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  className="cancel-edit-photo-btn"
                  onClick={cancelCropAndSave}
                >
                  {EDIT_PROFILE_PHOTO_MODAL.CANCEL}
                </Button>
                <Button
                  variant="contained"
                  className="crop-and-save-btn"
                  onClick={cropAndSave}
                >
                  {EDIT_PROFILE_PHOTO_MODAL.CROP_AND_SAVE}
                </Button>
              </>
            )}
          </div>
          <canvas
            ref={canvasRef}
            hidden
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop?.width,
              height: completedCrop?.height,
            }}
          />
        </div>
      </div>
      <BaseModal open={showError} onClose={handleErrorClose}>
        <ErrorMessage text={fileErrorText} />
      </BaseModal>
    </>
  );
};

export default ProfilePhotoUpload;
