import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { trainingApi } from '../api/trainingApi';
import { SkillTypes } from '../../cms/enums';

export type TrainingsPaginationState = {
  pageNumber: number;
  pageSize: number;
  types: SkillTypes[];
  onlyPrivate: boolean;
  onlyPublic: boolean;
  searchText: string;
  refetch: number;
};

const initialState: TrainingsPaginationState = {
  pageNumber: 1,
  pageSize: 18,
  types: [],
  onlyPrivate: false,
  onlyPublic: false,
  searchText: '',
  refetch: 0,
};

const trainingsPaginationSlice = createSlice({
  name: 'trainingsPagination',
  initialState,
  reducers: {
    incrementTrainingsPageNumber: (state: TrainingsPaginationState) => {
      state.pageNumber++;
    },
    setTrainingsTypes: (
      state: TrainingsPaginationState,
      { payload }: PayloadAction<SkillTypes[]>,
    ) => {
      return {
        ...state,
        pageNumber: 1,
        types: payload,
      };
    },
    setTrainingsSearchText: (
      state: TrainingsPaginationState,
      { payload }: PayloadAction<string>,
    ) => {
      return {
        ...state,
        pageNumber: 1,
        searchText: payload,
      };
    },
    setTrainingsArePrivate: (
      state: TrainingsPaginationState,
      { payload }: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        pageNumber: 1,
        onlyPrivate: payload,
      };
    },
    resetTrainingsPagination: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        trainingApi.endpoints.createTraining.matchFulfilled,
        trainingApi.endpoints.deleteTraining.matchFulfilled,
        trainingApi.endpoints.editTraining.matchFulfilled,
        trainingApi.endpoints.uploadTrainingPdfs.matchFulfilled,
      ),
      (state: TrainingsPaginationState) => {
        return {
          ...state,
          pageNumber: 1,
          refetch: state.refetch + 1,
        };
      },
    );
  },
});

export const {
  incrementTrainingsPageNumber,
  setTrainingsTypes,
  setTrainingsSearchText,
  setTrainingsArePrivate,
  resetTrainingsPagination,
} = trainingsPaginationSlice.actions;
export default trainingsPaginationSlice.reducer;
