import { put, take, call } from 'redux-saga/effects';
import { EDIT_GROUP_REQUEST } from './types';
import { editGroupSuccess } from './actions';
import { handleValidationErrors } from '../../../../../../../../common/validation/actions';

/** If detect type: EDIT_GROUP_REQUEST call editGroup() */
export default function* editGroupSaga(api) {
  while (true) {
    const editGroupRequest = yield take(EDIT_GROUP_REQUEST);
    if (editGroupRequest.payload) {
      const { editGroupModel } = editGroupRequest.payload;
      yield call(editGroup, api, editGroupModel);
    }
  }
}

/** Edit group */
function* editGroup(api, editGroupModel) {
  try {
    yield call(api.editGroup, editGroupModel);

    const isSuccessful = true;
    yield put(editGroupSuccess(isSuccessful));
  } catch (error) {
    yield put(handleValidationErrors(error.response.data));
  }
}
