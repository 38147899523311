import { Icon, Stack, Typography } from '@mui/material';
import { TrainingStatusData } from '../../../../cms/types';
import './TrainingStatusDataItem.scss';

type TrainingStatusDataItemProps = {
  item: TrainingStatusData;
};

const TrainingStatusDataItem = ({ item }: TrainingStatusDataItemProps) => {
  const { className, count, statusName, iconName } = item;

  return (
    <Stack direction="row" spacing="1rem" className={className}>
      <Stack className="training-status-count-container">
        <Typography className="training-status-count">{count}</Typography>
      </Stack>
      <Stack justifyContent="center" spacing="0.25rem">
        <Icon className="training-status-icon">{iconName}</Icon>
        <Typography className="training-status-name">{statusName}</Typography>
      </Stack>
    </Stack>
  );
};

export default TrainingStatusDataItem;
