import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Container, Stack, Typography } from '@mui/material';
import { CustomTabs } from '../../common/components';
import { MY_LEARNING_HUB_LABELS, ROUTE_LINKS } from '../../cms';
import { TabState } from '../../cms/types';

const MyLearningHubPage = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const employeeName = searchParams.get('employeeName');

  useEffect(() => {
    if (
      !pathname.includes(ROUTE_LINKS.MY_TRAININGS_AND_EVENTS) &&
      !pathname.includes(ROUTE_LINKS.MY_PATHS)
    )
      navigate(ROUTE_LINKS.MY_TRAININGS_AND_EVENTS, { replace: true });
  }, [pathname]);

  const tabs: TabState[] = [
    {
      label: employeeName
        ? MY_LEARNING_HUB_LABELS.TRAININGS_AND_EVENTS
        : MY_LEARNING_HUB_LABELS.MY_TRAININGS_AND_EVENTS,
      link: `${ROUTE_LINKS.MY_TRAININGS_AND_EVENTS}${search}`,
      className: 'my-trainings-and-events-tab-btn',
    },
    {
      label: employeeName
        ? MY_LEARNING_HUB_LABELS.PATHS
        : MY_LEARNING_HUB_LABELS.MY_PATHS,
      link: `${ROUTE_LINKS.MY_PATHS}${search}`,
      className: 'my-paths-tab-btn',
    },
  ];

  if (pathname === ROUTE_LINKS.MY_LEARNING_HUB) return null;

  return (
    <Container maxWidth="lg">
      <Stack
        className="my-learning-hub"
        spacing="1rem"
        padding={{ xs: '1rem', sm: '2rem' }}
      >
        {employeeName && (
          <Typography className="paths-page-title">
            {`Learning Hub of ${employeeName}`}
          </Typography>
        )}
        <CustomTabs tabs={tabs} divider value={`${pathname}${search}`} />
        <Outlet />
      </Stack>
    </Container>
  );
};

export default MyLearningHubPage;
