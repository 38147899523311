import { ThreeDots } from 'react-loader-spinner';
import { COLORS } from '../../../cms';
import './Loader.scss';

const Loader = () => (
  <ThreeDots
    wrapperClass="loader-wrapper"
    color={COLORS.PRIMARY}
    height={80}
    width={80}
  />
);

export default Loader;
