import './TrainingDetailsComment.scss';
import { Box, Rating, Stack, Typography } from '@mui/material';
import accountIcon from '../../../common/images/account-circle.png';
import { TrainingComment } from '../TrainingDetailsReviews/TrainingDetailsReviews';

type TrainingDetailsCommentProps = {
  comment: TrainingComment;
};

const TrainingDetailsComment = ({ comment }: TrainingDetailsCommentProps) => (
  <li>
    <Stack direction="row">
      <img
        className={`${
          comment.userPhoto
            ? 'account-icon-border '
            : 'account-icon-not-available '
        }`}
        width="34px"
        height="34px"
        src={comment.userPhoto ? `${comment.userPhoto}` : accountIcon}
        alt="account icon"
      />
      <Box flexDirection="column" className="comment-content">
        <Typography className="comment-user-name">{comment.user}</Typography>
        <Rating
          name="read-only"
          value={comment.rating}
          precision={0.5}
          size="small"
          readOnly
        />
      </Box>
    </Stack>
    <p className="comment">{comment.comment}</p>
  </li>
);

export default TrainingDetailsComment;
