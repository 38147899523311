import './LoginPage.scss';
import { LoginForm } from '../../forms';

const LoginPage = () => (
  <div className="login-page">
    {/* Needed for showing overflow scroll */}
    <div className="login-page-container">
      {/* Needed for showing  borderRadius */}
      <div className="login-page-content">
        <LoginForm />
      </div>
    </div>
  </div>
);

export default LoginPage;
