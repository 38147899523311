import { COLORS } from '../../../../cms';

type DonutChartCenterLabelProps = {
  viewBox?: any;
  value: string | number;
  desc1: string;
  desc2: string;
};

export const DonutChartCenterLabel = ({
  viewBox,
  value,
  desc1,
  desc2,
}: DonutChartCenterLabelProps) => {
  const { cx, cy } = viewBox;
  return (
    <>
      <circle
        r={50}
        cx={cx}
        cy={cy}
        fill={COLORS.WHITE}
        style={{
          filter: `drop-shadow(0px 1px 2px ${COLORS.DARK_SILVER})`,
        }}
      />
      <text
        x={cx}
        y={cy - 5}
        textAnchor="middle"
        fontSize={24}
        fontWeight="bold"
        fontFamily="OpenSans"
        fill={COLORS.TEXT}
        className="donut-chart-total-value"
      >
        {value}
      </text>
      <text
        x={cx}
        y={cy + 12.5}
        textAnchor="middle"
        fontSize={12}
        fontFamily="OpenSans"
        fill={COLORS.TEXT}
      >
        {desc1}
      </text>
      <text
        x={cx}
        y={cy + 27.5}
        textAnchor="middle"
        fontSize={12}
        fontFamily="OpenSans"
        fill={COLORS.TEXT}
      >
        {desc2}
      </text>
    </>
  );
};
