import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Divider, MenuItem, Stack } from '@mui/material';
import { useDebounce } from '../../../common/hooks/useDebounce';
import { useGetPersonalPathsQuery } from '../../../rtk/api/pathApi';
import {
  FilterSelect,
  PathCard,
  PathCategories,
  ScrollList,
  SearchField,
} from '../../../common/components';
import { NOT_FOUND_MESSAGES, PATH_STATUSES } from '../../../cms';
import './MyPathsTab.scss';
import { PathStatuses, SkillTypes } from '../../../cms/enums';
import { useAppSelector } from '../../../rtk/hooks';

const MyPathsTab = () => {
  const { id } = useAppSelector((state) => state.userSession);
  const [searchParams] = useSearchParams();
  const employeeId = searchParams.get('employeeId');
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText.trim().toLowerCase());
  const [types, setTypes] = useState<SkillTypes[]>([]);
  const [status, setStatus] = useState<PathStatuses>(PathStatuses.ALL);
  const { data, isFetching } = useGetPersonalPathsQuery(
    {
      userId: employeeId ? Number(employeeId) : id!,
      searchText: debouncedSearchText,
      types: types.join(','),
      status: status === PathStatuses.ALL ? '' : status,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { paths = [] } = data || {};

  const handleOnSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleOnTypesChange = (newTypes: any) => {
    setTypes(newTypes);
  };

  const handleOnStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.value as PathStatuses);
  };

  return (
    <Stack className="my-paths" spacing="2rem">
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="flex-end"
        spacing="1rem"
      >
        <SearchField
          value={searchText}
          onChange={handleOnSearchTextChange}
          placeholder="Search paths..."
          className="my-paths-search"
          sx={{
            width: { xs: '100%', sm: 300 },
          }}
        />
        <PathCategories value={types} onChange={handleOnTypesChange} />
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            height: 37,
            alignSelf: 'flex-end',
            display: { xs: 'none', sm: 'flex' },
          }}
        />
        <FilterSelect
          label="Path Status"
          onChange={handleOnStatusChange}
          value={status}
          sx={{
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          {PATH_STATUSES.map((s) => (
            <MenuItem key={s.value} value={s.value}>
              {s.label}
            </MenuItem>
          ))}
        </FilterSelect>
      </Stack>
      <ScrollList
        className="my-paths-list"
        emptyMessage={NOT_FOUND_MESSAGES.PATHS}
        isFetching={isFetching}
        count={paths.length}
      >
        {paths.map((path: any) => (
          <PathCard key={path.id} path={path} />
        ))}
      </ScrollList>
    </Stack>
  );
};

export default MyPathsTab;
