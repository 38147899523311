import { useState } from 'react';
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import dayjs from 'dayjs';
import strypesLogoIct from '../../../common/images/strypes-ict.svg';
import {
  TRAINING_DETAIL_LABELS,
  EVENT_DETAIL_LABELS,
  ROLES,
  MODAL_CLOSE_BTNS,
  EVENT_LABELS,
  BUTTON_LABELS,
  COLORS,
  EVENT_FORM_TYPES,
} from '../../../cms';
import ChаngeEventStatusButtons from '../ChаngeEventStatusButtons/ChаngeEventStatusButtons';
import { useAppDispatch, useAppSelector } from '../../../rtk/hooks';
import { getUserRoles } from '../../../common/userRoleRetriever';
import { useDeleteEventMutation } from '../../../rtk/api/eventApi';
import './EventDetailsHeader.scss';
import { closeModal, openModal } from '../../../rtk/features/modalsSlice';
import { toKebabCase } from '../../../common/validation/helpers';
import { ModalTypes } from '../../../cms/enums';
import { EventResponse } from '../../../cms/types/eventTypes';

type EventDetailsHeaderProps = {
  event: EventResponse;
};

const options = [
  {
    name: EVENT_LABELS.EDIT_EVENT,
    icon: <EditIcon sx={{ fontSize: '1.25rem', color: COLORS.TEXT }} />,
  },
  {
    name: EVENT_LABELS.DUPLICATE_EVENT,
    icon: <ContentCopyIcon sx={{ fontSize: '1.25rem', color: COLORS.TEXT }} />,
  },
  {
    name: EVENT_LABELS.DELETE_EVENT,
    icon: <DeleteIcon sx={{ fontSize: '1.25rem', color: COLORS.TEXT }} />,
  },
];

const EventDetailsHeader = ({ event }: EventDetailsHeaderProps) => {
  const theme = useTheme();
  const isScreenXS = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const { id } = useAppSelector((state) => state.userSession);
  const isAdmin = getUserRoles().includes(ROLES.ADMIN_ROLE);
  const isOwner = id === event.creator?.id;
  const eventHasEnded = dayjs().isAfter(dayjs(event.endDate), 'date');
  const [deleteEvent] = useDeleteEventMutation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteSpecifiedEvent = () => {
    deleteEvent(event!.id)
      .then(() => {
        dispatch(closeModal(ModalTypes.CONFIRM_ACTION));
      })
      .catch();
  };

  const handleMenuItemClick = (menuItem: string) => {
    switch (menuItem) {
      case EVENT_LABELS.EDIT_EVENT:
        handleShowEdit();
        break;
      case EVENT_LABELS.DUPLICATE_EVENT:
        handleShowDuplicate();
        break;
      case EVENT_LABELS.DELETE_EVENT:
        handleShowDelete();
        break;
      default:
        break;
    }
    handleClose();
  };

  const handleShowEdit = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.EVENT_FORM,
        modalData: {
          initialFormData: { ...event },
          type: EVENT_FORM_TYPES.EDIT,
        },
        closeBtn: MODAL_CLOSE_BTNS.OUTSIDE,
      }),
    );
  };

  const handleShowDuplicate = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.EVENT_FORM,
        modalData: {
          initialFormData: {
            ...event,
            id: -1,
            title: `${event.title} (Duplicate)`,
            startDate: undefined,
            endDate: undefined,
            applyBeforeDate: undefined,
          },
          type: EVENT_FORM_TYPES.DUPLICATE,
        },
        closeBtn: MODAL_CLOSE_BTNS.OUTSIDE,
      }),
    );
  };

  const handleShowDelete = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.CONFIRM_ACTION,
        closeBtn: MODAL_CLOSE_BTNS.OUTSIDE,
        modalData: {
          text: EVENT_LABELS.DELETE_CONFIRMATION_TEXT,
          btnText: BUTTON_LABELS.DELETE_BUTTON,
          onConfirm: deleteSpecifiedEvent,
        },
      }),
    );
  };

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      gap="2rem"
      flexWrap="wrap"
      className="event-details-header"
      alignItems={{ xs: 'flex-start', sm: 'center' }}
    >
      <img
        src={strypesLogoIct}
        alt="Event"
        className="event-details-header-image"
      />
      {!isScreenXS && <Divider orientation="vertical" flexItem />}
      <Stack direction="column" className="event-details-header-content">
        <Typography noWrap className="event-details-title">
          {event.title}
        </Typography>
        <Stack direction="row" flexWrap="wrap" gap="1rem">
          <Typography className="event-details-location">
            {event.location} {EVENT_DETAIL_LABELS.EVENT}
          </Typography>
          {event.createdBy && (
            <Typography className="event-details-creator">
              {TRAINING_DETAIL_LABELS.CREATED_BY}
              :&nbsp;
              {event.createdBy}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        gap="1rem"
        flexWrap="wrap"
        className="event-details-actions"
        alignItems="center"
      >
        <ChаngeEventStatusButtons event={event} />
        {(isAdmin || isOwner) && (
          <>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon color="primary" />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
                disablePadding: true,
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
              }}
            >
              {options.map(({ name, icon }, index) => {
                if (eventHasEnded && name === EVENT_LABELS.EDIT_EVENT)
                  return null;

                return (
                  <MenuItem
                    className={toKebabCase(name)}
                    key={name}
                    onClick={() => handleMenuItemClick(name)}
                    divider={index < options.length - 1}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primaryTypographyProps={{ fontSize: 14 }}>
                      {name}
                    </ListItemText>
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default EventDetailsHeader;
