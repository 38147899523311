import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type DrawerType = {
  openedDrawer: number | null;
};

const initialState: DrawerType = {
  openedDrawer: null,
};

const drawer = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    openDrawer: (state: DrawerType, { payload }: PayloadAction<number>) => {
      state.openedDrawer = payload;
    },
    closeDrawer: (state: DrawerType) => {
      state.openedDrawer = null;
    },
    toggleDrawer: (state: DrawerType, { payload }: PayloadAction<number>) => {
      state.openedDrawer = state.openedDrawer === payload ? null : payload;
    },
  },
});

export const { openDrawer, closeDrawer, toggleDrawer } = drawer.actions;
export default drawer.reducer;
