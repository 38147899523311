import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Stack, Typography } from '@mui/material';
import { ADD_EVENT_MODAL_LABELS } from '../../cms';
import DateTimeSection from '../../common/components/DateTimeSection/DateTimeSection';
import {
  AgendaEventItemSchema,
  AgendaEventItemSchemaType,
} from '../../cms/validation/eventValidation';
import { CustomTextField } from '../../common/components';

type AgendaFormProps = {
  minDate: Date | null;
  maxDate: Date | null;
  agendaEventItemToEdit: AgendaEventItemSchemaType | null;
  onSubmit: (data: AgendaEventItemSchemaType) => void;
  onCancelEdit: () => void;
};

const AgendaForm = ({
  minDate,
  maxDate,
  agendaEventItemToEdit,
  onSubmit,
  onCancelEdit,
}: AgendaFormProps) => {
  const methods = useForm<AgendaEventItemSchemaType>({
    resolver: zodResolver(AgendaEventItemSchema),
    defaultValues: {
      index: -1,
      agendaTopic: '',
    },
  });

  useEffect(() => {
    if (agendaEventItemToEdit != null) {
      methods.reset(agendaEventItemToEdit, { keepDefaultValues: true });
    } else {
      methods.reset();
    }
  }, [agendaEventItemToEdit]);

  const handleOnSubmit = (data: AgendaEventItemSchemaType) => {
    onSubmit(data);
    if (data.index === -1) methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <Stack
        component="form"
        noValidate
        direction="column"
        spacing="2rem"
        onSubmit={methods.handleSubmit(handleOnSubmit)}
      >
        <Typography className="section-title">
          {agendaEventItemToEdit
            ? ADD_EVENT_MODAL_LABELS.EDIT_AGENDA
            : ADD_EVENT_MODAL_LABELS.ADD_AGENDA}
        </Typography>
        <DateTimeSection minDate={minDate} maxDate={maxDate} />
        <Controller
          name="agendaTopic"
          control={methods.control}
          render={({
            field: { name, onBlur, value, onChange, ref },
            fieldState: { error },
          }) => (
            <CustomTextField
              color="secondary"
              required
              fullWidth
              label="Agenda Topic"
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              inputRef={ref}
              error={Boolean(error)}
              helperText={error?.message}
              inputProps={{
                className: 'agenda-topic-input',
              }}
            />
          )}
        />
        <Stack
          direction="row"
          flexWrap="wrap"
          spacing="1rem"
          justifyContent="flex-end"
        >
          {agendaEventItemToEdit && (
            <Button
              variant="outlined"
              className="agenda-cancel-btn"
              onClick={onCancelEdit}
            >
              {ADD_EVENT_MODAL_LABELS.CANCEL}
            </Button>
          )}
          <Button
            type="submit"
            variant="outlined"
            className="agenda-submit-btn"
          >
            {agendaEventItemToEdit
              ? ADD_EVENT_MODAL_LABELS.SAVE_CHANGES
              : ADD_EVENT_MODAL_LABELS.ADD_AGENDA}
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
};

export default AgendaForm;
